import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { CloseHeaderBtn } from 'entities/pim/MediaFilePreview/ui/MediaPreviewDialogContent/styled'


export const MediaModal = ({ close, image }: { close: () => void, image: string }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <CloseHeaderBtn
      aria-label="close-modal"
      onClick={close}
      size="small"
    >
      <CloseIcon/>
    </CloseHeaderBtn>
    <img src={image} alt=""/>
  </div>
)