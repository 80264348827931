import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface DeleteUserRolePayload {
  userId: UniqueId,
  roleId: UniqueId,
  value: pimApi.DeletingObject
}

const deleteUserRole = ({ userId, roleId, value }: DeleteUserRolePayload) =>
  api.delete(`/customer/admin/users/${userId}/roles/${roleId}`, { data: value })

export const useDeleteUserRoleMutation = () =>
  useMutation<AxiosResponse,
    AxiosError<errorType>,
    DeleteUserRolePayload>(deleteUserRole)