/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'




export type IRequest = CamelCasedPropertiesDeep<{
  userId: number
}>

export const apiFunction = ({ userId }: IRequest) =>
  api.delete(`/customer/admin/users/${userId}/photo`).then(res => res.data)


export const useDeleteUsersPhoto = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

