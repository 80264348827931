/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditWBNomenclature, WBNomenclature } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<WBNomenclature>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: EditWBNomenclature
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/products/wb/product/${productId}`,data).then(res => res.data)


export const useChangeProduct = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

