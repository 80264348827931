import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { api } from '../base'

interface IActivityTypes {
  id: number
  name: string
}
export function fetchActivityTypes(): Promise<AxiosResponse<IActivityTypes[]>> {
  return api.get<IActivityTypes[]>('/activity/activity/type')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'activity/activity/type'] as const

function getActivityTypes() {
  return fetchActivityTypes().then((res) => res.data)
}

export const useActivityTypesQuery = () => useQuery<
  Array<IActivityTypes>,
  errorType,
  Array<IActivityTypes>,
  QueryKey
>(QUERY_KEY, getActivityTypes, {
  refetchOnMount: false,
})