import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface EditUserPrivilegePayload {
  userId: UniqueId,
  privilegeId: UniqueId,
  privilegeEditedData: pimApi.admin.EditContragentUser
}
export const editUserPrivilege = ({ userId, privilegeId, privilegeEditedData }: EditUserPrivilegePayload) =>
  api.put<pimApi.admin.EditContragentUser>(
    `/customer/admin/users/${userId}/privileges/${privilegeId}`,
    privilegeEditedData
  )


export const useEditUserPrivilegeMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.EditContragentUser>,
    AxiosError<errorType>,
    EditUserPrivilegePayload
    >(editUserPrivilege)
