import React, { useEffect } from 'react'
import { Notifications } from 'shared/lib/generated/notifications/Api'
import ClearIcon from '@mui/icons-material/Clear'
import { Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { downloadFileWithPath } from 'shared/lib/utils'
import { useInView } from 'react-intersection-observer'
import { useNotificationsStore } from 'store/notifications/useNotificationsStore'

import { formatDate } from '../lib/formatDate'

// eslint-disable-next-line global-require
const { markdown } = require( 'markdown' )

interface INotificationItem {
  item: Notifications
}

export const NotificationItem = ({ item }: INotificationItem) => {
  const sendMessage = useNotificationsStore((store) => store.sendMessage)
  const messages = useNotificationsStore((store) => store.messages)
  const setMessage = useNotificationsStore((store) => store.setMessage)

  const { ref, inView } = useInView({
    threshold: 1,
  })

  const isNotRead = item.status !== 'read'
  const sendDate = formatDate({ date: new Date(item.created) } )

  useEffect(() => {
    if (inView && isNotRead) {
      sendMessage({
        type: 'read',
        data: {
          notifications_ids: [item.id]
        }
      })
      setMessage(messages.map(el => {
        if (el.id === item.id) {
          return { ...el, status: 'read' }
        }
        return el
      }))
    }
  }, [inView])

  const deleteMessage = () => {
    sendMessage({
      type: 'delete',
      data: {
        notifications_ids: [item.id]
      }
    })
    setMessage(messages.filter(el => el.id !== item.id))
  }

  const handleDownloadFile = () => {
    if (item.data.media?.url) {
      const url = new URL(item.data.media?.url)
      downloadFileWithPath(url.pathname, item.data.media!.title || '')
    }
  }

  return (
    <div className="notification-item">
      <div className="header">

        <div className="viewed-flag-container">
          {isNotRead && <div className="new-notification-flag"/>}
        </div>

        <span className="item-title">{ item.data.title }</span>

        <span className="notification-date">{ sendDate }</span>

        <ClearIcon className="delete-icon" onClick={deleteMessage} fontSize="small" />
      </div>

      {item.data.description || item.data.md_description ?
        <>
          { item.data.md_description ?
            <div
              className="markdown-description-container"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: markdown.toHTML(item.data.md_description) }}/>
            :

            <div className="description-container">
              { item.data.description }
            </div>
          }
        </>
        :
        <></>
      }

      {(item.data.link || item.data.media) &&
        <div className="link-container">
          {item.data.link &&
            <a href={item.data.link?.url}>
              <OpenInNewIcon/>
              {item.data.link.title || 'Перейти по ссылке'}
            </a>
          }
          {item.data.media &&
            <Button onClick={handleDownloadFile} startIcon={<DownloadIcon/>} size="small">Скачать файл</Button>
          }
        </div>
      }
      <div ref={ref}/>
    </div>
  )
}