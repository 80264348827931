import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'
import { DataParamsTypes } from 'features/reviews/lib/types'
import { reviewApi } from 'shared/api'

type ParamsType = Omit<DataParamsTypes, 'viewed' | 'processed'>
export const getReviewsStatQueryKey = (queryParams: ParamsType) =>
  ['get', '/review/review/stats', queryParams] as const

type GetReviewsStatQueryKey = ReturnType<typeof getReviewsStatQueryKey>

function getReviewsStat(queryParams: ParamsType) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get(
    '/review/review/stats', { params: queryParamsSnake }
  ).then(res => res.data)
}

const getAllReviewsStat = (context: QueryFunctionContext<GetReviewsStatQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getReviewsStat(queryParams)
}

export const useReviewsStatQuery = (queryParams: ParamsType) =>
  useQuery<
    reviewApi.ReviewStat,
    errorType,
    reviewApi.ReviewStat,
    GetReviewsStatQueryKey
  >(
    getReviewsStatQueryKey(queryParams),
    getAllReviewsStat,
    { refetchOnMount: false, staleTime: Infinity, keepPreviousData: true }
  )
