/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Unit } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Unit[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Применимость для упаковки
   * При необходимости можно отфильтровать возвращаемый список единиц измерения, чтобы вернулись только те, которые можно использовать для измерения упаковки товара
   * @default false
   */
    packaging_support?: boolean,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/pim/units`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/units`, { query }] as const

export const useGetUnits = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

