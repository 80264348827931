/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { TemplateFormat } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** @default "excel" */
    template_format?: TemplateFormat,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get(`/pim/ops/goods/delete/competitors/template`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/ops/goods/delete/competitors/template`, { query }] as const

export const useGetDeleteGoodsCompetitorTemplate = (queryParams: IRequest, options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

