/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Product, AppPimGoodsMediaModelsMediaGoodsMethod } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  mediaId: number,
  query: {
  /** Product Id */
    product_id: number,
  /** Операция над медиа товара */
    method?: AppPimGoodsMediaModelsMediaGoodsMethod,

}
}>

export const apiFunction = ({ goodsId,mediaId,query }: IRequest) =>
  api.patch(`/pim/goods/${goodsId}/photos/${mediaId}`, {}, { params: query}).then(res => res.data)


export const useCopyPhotoFromGoods = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

