import React from 'react'
import { Box, Checkbox, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { pimApi } from 'shared/api'
import imagePlaceholder from 'assets/images/photo-size-select-actual.svg'
import { CustomTooltip } from 'pages/goods/edit/CompetitorsStep/ui/CompetitorCard/CompetitorCard'
import { COLORS } from 'shared/ui/components/PieChart'
import { HelpWithMargin } from 'shared/ui/styled/Help'

import { CompetitorItem, CompetitorName, ProductImage, ShortInfoContainer, StyledLink } from './styled'

interface competitorType extends pimApi.goods.Competitor {
  totalStocks?: number
}

const ImageBase64 = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} alt="competitor_image"/>
export const Competitor = ({ competitor, handleEditCompetitor }: { competitor: competitorType, handleEditCompetitor: (e) => void }) => (
  <CompetitorItem key={competitor.id}>
    <ProductImage $photoBase64={!!competitor.photoBase64}>
      {competitor.photoBase64
        ? <ImageBase64 data={competitor.photoBase64}/>
        :
        <img src={imagePlaceholder} alt="placeholder"/>
      }
    </ProductImage>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '390px', padding: '0 16px' }}>
      <CompetitorName>{competitor.name}</CompetitorName>
      <ShortInfoContainer>
        <div>
          <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            {competitor.marketplaceName}
          </span>
        </div>
        <div>
          <StyledLink href={competitor.url} target="_blank" rel="noreferrer">{competitor.sku}</StyledLink>
        </div>
        <div>
          {`Остатки ${competitor.totalStocks} `}
          {competitor.totalStocks !== 0 &&
            <CustomTooltip title={
              <Box display="flex" flexDirection="column" gap="5px">
                {competitor.stocks?.map((el, ind) => (
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography
                      fontSize={14}
                      fontWeight="400"
                      textAlign="left"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {el.marketplacewarehouseName}
                    </Typography>
                    <Typography
                      fontSize={14}
                      ml={3}
                      fontWeight="500"
                      color={COLORS[ind + 3 % COLORS.length]}
                    >
                      {el.available}
                    </Typography>
                  </Box>
                ))}
              </Box>
            }
            placement="right">
              <HelpWithMargin style={{ marginLeft: '4px', marginRight: '0' }}>
                <InfoOutlined/>
              </HelpWithMargin>
            </CustomTooltip>
          }
        </div>
        <div>
          {`Цена, р.: ${competitor.price}`}
        </div>
      </ShortInfoContainer>
    </Box>
    <Box>
      <Checkbox onChange={() => {
        handleEditCompetitor(competitor)
      }} checked={competitor.reckonedFlag}/>
    </Box>
  </CompetitorItem>
)