/* eslint-disable */
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReviewTemplateModel } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewTemplateModel[]>

type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** Поиск по названию */
    searchstring?: string,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/review/models`,{ params: toSnakeCase(query) }).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/review/models`, { query: toSnakeCase(query) }] as const

export const useGetReviewTemplateModels = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

