import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { tradeApi } from 'shared/api/index'

interface CopackingOrdersQueryParams {
  copackingordersId: number
  only_diffs?: boolean
}

const getOrderForPackingQueryKey = (
  queryParams: CopackingOrdersQueryParams
) =>
  [
    'get',
    `trade/orders/copackingorders/${queryParams.copackingordersId}`,
    queryParams
  ] as const

type GetOrderForPackingQueryKey = ReturnType<
  typeof getOrderForPackingQueryKey
>

function getOrderForPackingFunc(queryParams: CopackingOrdersQueryParams) {
  const { copackingordersId, only_diffs } = queryParams
  return api.get(`trade/orders/copackingorders/${copackingordersId}?only_diffs=${only_diffs}`)
    .then((res) => res.data)
}

const getOrderForPacking = (
  context: QueryFunctionContext<GetOrderForPackingQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getOrderForPackingFunc(queryParams)
}

export const useOrderForPacking = (queryParam: CopackingOrdersQueryParams) =>
  useQuery<
    tradeApi.CoPackingOrder,
    errorType,
    tradeApi.CoPackingOrder,
    GetOrderForPackingQueryKey
  >(getOrderForPackingQueryKey(queryParam), getOrderForPacking)