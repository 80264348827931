import { useParams } from 'react-router'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { Form } from 'react-final-form'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import {
  validationSchema,
  creationValidationSchema,
} from 'features/product/WB/PriceChange/ui/PriceChangeModal/lib'
import { useQueryClient } from 'react-query'
import { getProductsPriceQueryKey } from 'shared/api/pim/price'
import { snackActions } from 'shared/lib/react/snackbar'

import { PriceControl } from './ui/PriceControl'
import { PriceInfo } from './ui/PriceInfo'
import { WidgetsContainer } from './ui/WidgetsContainer'
import { PriceTabWrap, PriceWrap } from './ui/styled'

export function PriceTab() {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const { wbProductQuery } = useWBProductContext()
  const { data: priceDetail } = pimApi.price.useGetProductsPriceQuery(productId)

  const queryClient = useQueryClient()

  const { mutate: createProductPrice, isLoading: isCreating } =
    pimApi.products.wb.useCreateProductPriceChangeMutation()

  const [variation] = useMemo(() => {
    const variationSelected = wbProductQuery.data?.variations?.find(
      (v) => v?.productId === productId
    )
    return [variationSelected]
  }, [wbProductQuery, productId])

  const isPriceChangeable = !!priceDetail?.price

  const validate = useValidationSchema(
    isPriceChangeable ? validationSchema : creationValidationSchema
  )

  const onSubmit = (values, form) => {
    createProductPrice(
      {
        productId,
        newProductPrice: {
          ...(values.comments ? { comments: values.comments } : {}),
          discount: values.newDiscount || priceDetail?.discount || 0,
          price: values.newPrice || priceDetail?.price,
          pricechangereasonId: values.pricechangereasonId,
          ...(values.revertDate ? { revertDate: values.revertDate } : {}),
          ...(values.changeDate ? { changeDate: values.changeDate } : {}),
        },
      },
      {
        onSuccess: ({ data }) => {
          queryClient.setQueryData<pimApi.price.WBPriceDetail>(
            getProductsPriceQueryKey(productId),
            (priceDetailUpdater) => {
              const newObj = { ...priceDetailUpdater }
              // eslint-disable-next-line no-return-assign
              Object.keys(data).map((el) => (newObj[el] = data[el]))
              return newObj as pimApi.price.WBPriceDetail
            }
          )
          form.restart()
          snackActions.info('Цена успешно сохранена!')
        },
      }
    )
  }

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => (
        <PriceTabWrap>
          <PriceWrap>
            <PriceControl
              isCreating={isCreating}
              values={values}
              handleSubmit={handleSubmit}
              form={form}
            />
            <PriceInfo copackingCostInfo={variation?.copackingCostInfo} />
          </PriceWrap>
          <div>
            <WidgetsContainer />
          </div>
        </PriceTabWrap>
      )}
    />
  )
}
