import { memo } from 'react'
import { wbproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { SelectField } from 'shared/ui/components/form'
import { sort } from 'shared/lib/utils/sort'

export interface TNVEDProps extends FieldRenderProps<string> {
  companymarketplaceId: number,
  tnvedObject: string,
  subjectId: number
}

const getOptionFromTNVED = ({ code }: wbproxyApi.model.TNVEDCode) => ({
  label: `${code}`,
  value: code
})

export const TNVEDCode = ({ tnvedObject, companymarketplaceId, subjectId, ...props } : TNVEDProps) => {
  const TNVEDQuery = wbproxyApi.useFetchTNVEDQuery<Array<Option>>(
    tnvedObject,
    companymarketplaceId,
    subjectId,
    {
      enabled: !!companymarketplaceId,
      select: ({ data }) => sort(data, 'code', 'desc').map(getOptionFromTNVED), 
      staleTime: Infinity,
    }
  )

  return (
    <SelectField
      {...props}
      options={TNVEDQuery.data ?? []}
      onChange={props.input.onChange}
      noOptionsText="Введите строку для поиска"
    />
  )
}

export const TNVEDCodeMemo = memo(TNVEDCode)
