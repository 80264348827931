/* eslint-disable */
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyTemplateDeleteReviewTemplate } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

type IRequest = CamelCasedPropertiesDeep<{
  templateId: number,
  data: BodyTemplateDeleteReviewTemplate
}>

export const apiFunction = ({ templateId,data }: IRequest) =>
  api.delete<ResponseType>(`/review/models/template/${templateId}`,{ data: toSnakeCase(data) }).then(res => res.data)


export const useDeleteReviewTemplate = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

