/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'




export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  mediaId: number,
  query?: {
  /** Операция над медиа продукта */
    method?: string,

}
}>

export const apiFunction = ({ productId,mediaId,query }: IRequest) =>
  api.patch(`/pim/products/ozon/product/${productId}/photos/${mediaId}`, {}, { params: query}).then(res => res.data)


export const useCopyPhotoFromProduct = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

