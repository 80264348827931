import React, { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { Box, Button, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pimApi, wbproxyApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import { Field, InputLabel } from 'shared/ui/components'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import CloseIcon from 'assets/images/closeIcon.svg'

import { StyledDialogActions, StyledDialogTitle } from './styled'


export const ChangeWBCategoryWindow = (
  { close, companymarketplaceId, productId, onlyOneNomenclature }:
  { close: () => void; companymarketplaceId: UniqueId; productId: UniqueId; onlyOneNomenclature: boolean }) => {
  const fetchCatalog = useCallback(
    (search: string) =>
      wbproxyApi
        .fetchCategories(search, companymarketplaceId)
        .then((data) => data.data.map(
          (category: wbproxyApi.model.Category) => ({
            label: `${category.parentName}/${category.subjectName}`,
            value: category.subjectId
          })
        )),
    [companymarketplaceId]
  )
  const queryClient = useQueryClient()
  const { mutateAsync: changeWBProductCategory, isLoading: changingCategoryInProgress } =
    pimApi.products.wb.useChangeWBProductCategory()
  const onSubmit = (values) => {
    const category = values?.category?.value
    if (!category) {
      return
    }
    changeWBProductCategory(
      {
        productId,
        category
      }, {
        onSuccess(response) {
          snackActions.info('Категория успешно изменена!')
          close()
          queryClient.setQueryData(pimApi.products.wb.getWBProductQueryKey(productId), response.data)
        }
      }).then()
  }

  return (
    <Box sx={{ width: '600px', maxWidth: '600px' }}>
      <IconCloseButton aria-label="close" onClick={close}>
        <img src={CloseIcon} alt="close icon" />
      </IconCloseButton>
      <StyledDialogTitle>
        {onlyOneNomenclature ? 'Изменить категорию продукта?' : 'Изменить категорию на всех номенклатурах?'}
      </StyledDialogTitle>

      <Box p={3} pt={0}>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              {!onlyOneNomenclature &&
                <Box>
                  <Typography mb={3} fontSize={14}>После изменения категории изменятся характеристики на всех<br/>
                    номенклатурах</Typography>
                </Box>
              }
              <Box mt={0}>
                <InputLabel
                  label="Новая категория"
                  required={true}
                />
              </Box>
              <Box mt={1}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <Field
                      name="category"
                      component={AutocompleteCatalogSearchField}
                      fetchCatalog={fetchCatalog}
                      label="Категория"
                    />
                  </Grid>
                </Grid>
              </Box>
              <StyledDialogActions>
                <Box pt={1} pb={1}>
                  <Button onClick={close} color="primary">
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={changingCategoryInProgress}
                    disabled={!values?.category?.value}
                    sx={{
                      marginLeft: 2,
                    }}
                  >
                    ИЗМЕНИТЬ
                  </LoadingButton>
                </Box>
              </StyledDialogActions>
            </form>
          )}
        />
      </Box>
    </Box>
  )
}
