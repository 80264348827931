import { FilterAlt } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'

export const StyledTableHeaderWrapper = styled.div<{ align: string }>`
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    flex: auto;
    align-items: center;
    justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'start')};
    padding-right: ${({ align }) => (align === 'right' ? '32px' : '0')};
    display: flex;
  }
  

  &.MuiTableCell-head .CellLayout-cell {
    .CellLayout-container {
      display: block;
    }
    padding: 0;
    display: flex;
  }

  .MuiTableSortLabel-icon {
    display: none;
  }

  .Title-title {
    display: none;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-popupIndicator {
    transform: none;
  }

  &:hover {
    .MuiTableSortLabel-root {
      color: rgba(0,0,0,0.54);
      .MuiIconButton-root {
        opacity: 1 !important;
      }
    }
  }

  .MuiSelect-select {
    width: 17px;
    padding-right: 8px !important;
    padding-left: 2px !important;
  }
  
  /* .MuiInputBase-formControl {
    margin-right: -10px;
  } */

  .MuiTypography-root {
    padding-right: 6px;
  }

  .ResizingControl-root {
    width: 20px;
    padding-right: 32px;
  }
/* 
  .MuiListItemIcon-root {
    width: 0;
  } */

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInput-root {
    padding-bottom: 10px;
    :before {
      border: none !important;
    }

    :after {
      border: none !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 22px);
  }

  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    &.Mui-focused {
      font-weight: 400;
      color: #007DFF;
    }
  }
  
`

export const StyledFilter = styled(FilterAlt)<{ $hasValue?: boolean }>`
  transform: none !important;
  color: ${({ $hasValue, theme }) => $hasValue ? theme.palette.primary.light : '#a6a6a6' };
  border-radius: 100%;
  padding: 2px 2px 0;
`

export const StyledHeaderIconButton = styled(IconButton)<{ $open?: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 100%;
`

export const SelectWrapper = styled.div`
  .MuiInputLabel-root {
    margin-left: -10px;
    min-width: 24px;
  }
`

export const ClassWrapper = styled.div`
  .MuiInputLabel-root {
    margin-top: 1px;
    margin-left: -17px;
    min-width: 35px;
  }
  .Mui-focused.MuiInputLabel-root{
      margin-top: 7px;
    }
  .MuiFormLabel-filled.MuiInputLabel-root{
    margin-top: 6px;
    font-weight: 400;
  }
  #class-select {
    > div {
      display: none;
    }
  }
`
