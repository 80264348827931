/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Brand, NewBrand } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Brand>

export type IRequest = CamelCasedPropertiesDeep<{
  data: NewBrand
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/pim/brands`,data).then(res => res.data)


export const useCreateBrand = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

