/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditGoodsSupplierPackaging, GoodsSupplierPackaging } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsSupplierPackaging>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  supplierId: number,
  packagingId: number,
  data: EditGoodsSupplierPackaging
}>

export const apiFunction = ({ goodsId,supplierId,packagingId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/goods/${goodsId}/suppliers/${supplierId}/packaging/${packagingId}`,data).then(res => res.data)


export const useEditGoodssupplierpackaging = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

