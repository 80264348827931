/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditSupplyOrderProductPosition, SupplyOrderProductPosition } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<SupplyOrderProductPosition>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyorderId: number,
  positionId: number,
  data: EditSupplyOrderProductPosition
}>

export const apiFunction = ({ supplyorderId,positionId,data }: IRequest) =>
  api.put<ResponseType>(`/trade/orders/supplyorders/${supplyorderId}/productpositions/${positionId}`,data).then(res => res.data)


export const useEditSupplyOrderProductPosition = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

