import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

import { ActivityInfo, NewActivityBaseParam } from './model'

const createNewActivity = (newActivity: NewActivityBaseParam) =>
  api.post<ActivityInfo>(
    '/activity/activity',
    newActivity
  )

export const useCreateActivityMutation = () =>
  useMutation<
    AxiosResponse<ActivityInfo>,
    AxiosError<errorType>,
    NewActivityBaseParam
  >(createNewActivity)