/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Brand, Contragent, Marketplace, ProductPriceHistory } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ProductPriceHistory[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * С даты
   * Ограничить историю цен не ранее этой даты
   * @format date
   */
    from_date?: string,
  /**
   * До даты
   * Ограничить историю цен не позднее этой даты
   * @format date
   */
    to_date?: string,
  /**
   * Search String
   * Поиск по набору слов
   */
    search_string?: string,
  /**
   * Marketplace Id
   * Идентификатор маркетплейса
   */
    marketplace_id?: number,
  /**
   * Brand Id
   * Идентификатор бренда
   */
    brand_id?: number,
  /**
   * Companymarketplace Id
   * Идентификатор кабинета
   */
    companymarketplace_id?: number,
  /**
   * Contragent Id
   * Идентификатор постащика
   */
    contragent_id?: number,
  /**
   * Trend
   * Тренд изменения цены
   */
    trend?: "increased" | "decreased",
  /**
   * Trend Percent Threshold
   * Порог изменения в процентах
   * @min 0
   */
    trend_percent_threshold?: number,
  /**
   * Changer Id
   * Идентификатор пользователя
   */
    changer_id?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/pim/products/pricehistory`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/products/pricehistory`, { query }] as const

export const useGetPriceHistory = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

