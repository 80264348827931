import React, { FC, memo } from 'react'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'

import { NormalizedAttributesRight as NormalizedAttributesRightType } from '../../lib/getNormalizedAttributes'
import { RightComponent } from '../RightComponent'

interface NormalizedAttributesRightProps {
  normalizedAttributesRights: Array<NormalizedAttributesRightType>
  showGoodsInfo: boolean
  onAttributeValueChange: (
    id: number
  ) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  disabled?: boolean
}

export const NormalizedAttributesRight: FC<NormalizedAttributesRightProps> = ({
  normalizedAttributesRights,
  onAttributeValueChange,
  showGoodsInfo,
  disabled,
}) => (
  <>
    {normalizedAttributesRights.map(({ right }) => (
      <InputsWrapper
        key={right.attributeName}
        arrow={null}
        globalAttributeName={`attributes.${right.index}.attributeGlobal`}
        hideGoodsInfo={!showGoodsInfo}
      >
        <></>
        <RightComponent
          right={right}
          onChange={onAttributeValueChange(right.id)}
          disabled={disabled}
        />
      </InputsWrapper>
    ))}
  </>
)

export const MemoNormalizedAttributesRight = memo(NormalizedAttributesRight,
  (prevProps, nextProps) =>
    (JSON.stringify(nextProps.normalizedAttributesRights) === JSON.stringify(prevProps.normalizedAttributesRights)) &&
    (JSON.stringify(nextProps.disabled) === JSON.stringify(prevProps.disabled)) &&
    JSON.stringify(prevProps.showGoodsInfo) === JSON.stringify(nextProps.showGoodsInfo)
)
