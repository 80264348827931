import { memo, useCallback, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { ProductChangelogTable as Changelog } from 'features/product/changelog'
import HistoryIcon from '@mui/icons-material/History'
import { ProductSettings } from 'widgets/product/Simple/SettingsMenu'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { FlexWrapper, TabsNav } from './ui/styled'

import { GeneralInformation } from '../GeneralInformation'
import { Media } from '../Media'

const TABS = [
  {
    label: 'Основное',
    Component: GeneralInformation
  },
  {
    label: 'МЕДИА',
    Component: Media
  },
  {
    label:  'История изменений',
    Component: Changelog
  },

] as const


interface ProductInfoTabsProps {
  isArchived: boolean
  goods: pimApi.goods.Goods | null
}

export const ProductInfoTabs = ({
  isArchived,
  goods
}: ProductInfoTabsProps) => {
  const [currentGeneralTabsIndex, setCurrentGeneralTabsIndex] = useState(0)
  
  const handleTabChange = useCallback((_, newTabIndex) => {
    setCurrentGeneralTabsIndex(newTabIndex)
  },[currentGeneralTabsIndex])
  
  return (
    <>
      <Box mb={ 3 }>
        <FlexWrapper>
          <TabsNav>
            <Tabs sx={ { minHeight: '42px' } } value={ currentGeneralTabsIndex } onChange={ handleTabChange }>
              { TABS.map(({ label }, index) => {
                if (index !== TABS.length - 1) {
                  return (
                    <Tab
                    // eslint-disable-next-line react/no-array-index-key
                      key={ index }
                      label={ label }
                      { ...a11yProps(index) }
                    />
                  )
                }
                return (
                  <Tab
                  // eslint-disable-next-line react/no-array-index-key
                    key={ index }
                    icon={ <HistoryIcon sx={ { fontSize: '22px' } }/> }
                    { ...a11yProps(index) }
                  />
                )
              }) }
            </Tabs>
          </TabsNav>
          <ProductSettings/>
        </FlexWrapper>
      </Box>

      { TABS.map(({ Component }, index) => (
        <TabPanel
        // eslint-disable-next-line react/no-array-index-key
          key={ index }
          value={ currentGeneralTabsIndex }
          index={ index }
        >
          <Component editingDisabled={ isArchived } goods={ goods }/>
        </TabPanel>
      )) }
    </>
  )
}

export const MemoProductInfoTabs = memo(ProductInfoTabs)
