/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { WBPriceHistory } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<WBPriceHistory>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  query?: {
  /**
   * С даты
   * Ограничить историю цен не ранее этой даты
   * @format date
   */
    from_date?: string,
  /**
   * До даты
   * Ограничить историю цен не позднее этой даты
   * @format date
   */
    to_date?: string,

}
}>

export const apiFunction = ({ productId,query }: IRequest) =>
  api.get<ResponseType>(`/pim/products/wb/product/${productId}/pricehistory`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ productId,query }: IRequest) => ['get', `/pim/products/wb/product/${productId}/pricehistory`, { productId,query }] as const

export const useGetPrices = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

