import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { reviewApi } from 'shared/api'


interface EditTemplate {
  templateId: UniqueId
  reviewType: number
  text: string
  name: string
  templateModelId: number
}
export const editTemplate = (editedTemplate: EditTemplate) =>
  api.put<reviewApi.ReviewTemplate>(
    `/review/models/template/${editedTemplate.templateId}`,
    ({ ...editedTemplate, template_id: undefined })
  )


export const useEditTemplateMutation = () =>
  useMutation<
    AxiosResponse<reviewApi.ReviewTemplate>,
    AxiosError<errorType>,
    EditTemplate
  >(editTemplate)
