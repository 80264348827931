/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Company, Stats } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Stats[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Company Id
   * ID компании
   */
    company_id?: number,
  /**
   * Cabinet Id
   * ID кабинета
   */
    cabinet_id?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/customer/stats`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/customer/stats`, { query }] as const

export const useGetstats = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

