/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Task } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Task>

export type IRequest = CamelCasedPropertiesDeep<{
  taskId: string
}>

export const apiFunction = ({ taskId }: IRequest) =>
  api.get<ResponseType>(`/reports/report/${taskId}`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ taskId }: IRequest) => ['get', `/reports/report/${taskId}`, { taskId }] as const

export const useGetTask = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

