import { Box } from '@mui/material'
import styled from 'styled-components'

export const SelectWrapper = styled.div<{ width: number }>`
  .MuiAutocomplete-root {
    min-width: ${({ width }) => `${width}px`};
    color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    .MuiFormLabel-root {
      position: absolute;
      top: -2px;
    }
    .MuiAutocomplete-inputRoot {
      height: 40px;
      padding-bottom: 3px;
      padding-top: 1px;
      .MuiAutocomplete-input {
        width: 0;
        min-width: max-content;
        ::-webkit-input-placeholder {
          opacity: 1;
          color: #0000008a;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 4px;
    }
    .MuiInputBase-root {
      border: 0 solid rgba(0, 0, 0, 0);
    }
    .MuiAutocomplete-popupIndicator {
      color: #00000042;
    }
    .MuiAutocomplete-popupIndicatorOpen {
      color: #00000042;
    }
  }
`

export const FilterWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  padding: 16px 24px 24px;

  .searchInput {
    color: rgba(0, 0, 0, 0.26);
    display: flex;
    min-width: 220px;
    margin-top: 10px;
    justify-content: flex-end;
  }
`

export const StyledFilterContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
`
