/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationMasseProductsArchiving, ImportMetadata, ProductsBulkMethod } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** ID причины архивации продукта */
    archivationreason_id?: number,
  /** Комментарий архивации продукта */
    archivation_comments?: string,
  /** Операция над продуктами */
    method: ProductsBulkMethod,

},
  data: BodyBulkOperationMasseProductsArchiving
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.patch<ResponseType>(`/pim/ops/product`,data, { params: query }).then(res => res.data)


export const useMasseProductsArchiving = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

