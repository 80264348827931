import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { diff } from 'deep-object-diff'
import { pimApi } from 'shared/api'
import styled from 'styled-components'

import { snackActions } from '../../../../../../lib/react/snackbar'


const NumberInput = styled.input<{ adviceDate: string }>`
  width: ${p => (p.adviceDate ? '88px' : '108px')};
  height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 8px;
`

export const RepriceIncomingPriceCell = ({ row }: {row: any}) => {
  const queryClient = useQueryClient()
  const { mutate: editPriceSettings } =
    pimApi.price.useEditPriceSettingsMutation()

  const handleSaveChanges = (values: pimApi.price.PriceSettings) => {
    if (
      row
      && values
      && Object.keys(diff(values, row)).length !== 0
    ) {
      editPriceSettings( {
        editedSettings: {
          id: values.id,
          versionNo: values.versionNo,
          productId: values.productId,
          minPrice: values.minPrice,
          targetMargin: values.targetMargin,
          targetMarginPercents: values.targetMarginPercents,
          autoChangePrice: values.autoChangePrice,
          adviceInterval: values.adviceInterval
        }
      },
      {
        onSuccess: (response) => {
          queryClient.setQueriesData<pimApi.price.ProductPriceAdvice[]>(
            ['get', 'pim/pricecontrol/priceadvice'],
            (priceSettings) => {
              const arrIndex = priceSettings!.findIndex(el => el.id === response.data.id)
              const item: any = priceSettings?.[arrIndex!]
              Object.keys(response.data).forEach((key) => {
                item[key] = response.data[key]
              })
              // eslint-disable-next-line no-param-reassign
              priceSettings![arrIndex] = item
              return priceSettings as pimApi.price.ProductPriceAdvice[]
            }
          )
          queryClient.setQueriesData<pimApi.price.ProductPriceAdvice[]>(
            ['get', 'pim/pricecontrol/pricesettings'],
            (priceSettings) => {
              const arrIndex = priceSettings!.findIndex(el => el.id === response.data.id)
              const item: any = priceSettings?.[arrIndex!]
              Object.keys(response.data).forEach((key) => {
                item[key] = response.data[key]
              })
              // eslint-disable-next-line no-param-reassign
              priceSettings![arrIndex] = item
              return priceSettings as pimApi.price.ProductPriceAdvice[]
            }
          )
          snackActions.info('Изменение сохранено!')
        },
      })
    }
  }

  const [values, setValues] = useState(row)
  const handleChange = e => {
    const { name, value } = e.target
    if ( name === 'targetMarginPercents' && (value > 100 || value < 0)) {
      return
    }
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleBlur = () => {
    handleSaveChanges(values)
  }

  return (
    <div style={{
      height: '100%',
      paddingTop: '59px'
    }}>
      <NumberInput
        name="incomingPrice"
        id="incominPrice"
        value={values.incomingPrice}
        adviceDate={row.adviceDate}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        autoComplete="off"
        type="number"
      />
    </div>
  )
}
