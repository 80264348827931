import { Box } from '@mui/material'
import React from 'react'

import { Subtitle, Title } from './styled'

export const Header = ({ activeCompetitors }: {activeCompetitors?: number}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Title>Конкуренты продукта</Title>
    {activeCompetitors ?
      <Subtitle>Отметьте конкурентов, которые относятся к продукту и будут участвовать в волшебнике цен</Subtitle> : <></>
    }
  </Box>
)