/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Distributions } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Distributions>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number
}>

export const apiFunction = ({ copackingorderId }: IRequest) =>
  api.delete<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}/distributions`).then(res => res.data)


export const useDeleteDistributions = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

