import { pimApi } from 'shared/api'

import { getNomenclatureArchivationStatus } from './getNomenclatureArchivationStatus'

const DEFAULT_ARCHIVATION_REASON = 'Все размеры в архиве'

export const useProductArchiveInfo = (product?: pimApi.products.wb.WBNomenclature) => {
  if(!product) return { archivationReason: '', archivationComments: '' }

  const variationsArchiveInfo = getNomenclatureArchivationStatus(product?.variations)
  
  const showLowerLevelArchivationInfo = Boolean(
    product.variations && product.variations.length && product.variations.length <= 1
  )

  const archivationReason =
    variationsArchiveInfo && showLowerLevelArchivationInfo
      ? variationsArchiveInfo?.reason
      : DEFAULT_ARCHIVATION_REASON

  const archivationComments =
    variationsArchiveInfo && showLowerLevelArchivationInfo
      ? variationsArchiveInfo?.comments
      : ''

  return {
    archivationReason,
    archivationComments
  }
}
