/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CompanyMarketplace, EditCompanyMarketplace } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CompanyMarketplace>

export type IRequest = CamelCasedPropertiesDeep<{
  companyId: number,
  cabinetId: number,
  data: EditCompanyMarketplace
}>

export const apiFunction = ({ companyId,cabinetId,data }: IRequest) =>
  api.put<ResponseType>(`/customer/companies/${companyId}/cabinets/${cabinetId}`,data).then(res => res.data)


export const useEditCompanymarketplace = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

