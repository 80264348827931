import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { api } from '../base'

interface ParametersValue {
  id: UniqueId;
  value: string
}
interface getReportFilePayload {
  parametersValue: Array<ParametersValue>
  reportId: UniqueId
}

const getReportFile = ({ parametersValue, reportId }: getReportFilePayload) =>
  api.post<File>(
    `/reports/report/${reportId}`,
    parametersValue,
    {
      responseType: 'blob',
    }
  )

export const useGetReportFileMutation = () =>
  useMutation<
    AxiosResponse<File>,
    AxiosError<errorType>,
    getReportFilePayload
  >(getReportFile)