import React, { useCallback } from 'react'
import { pimApi } from 'shared/api'
import { reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { getProductCompetitorsQueryKey } from 'shared/api/pim/products/common'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { Header } from './ui/Header'
import { CompetitorList } from './ui/CompetitorList'
import { EmptyCompetitorsPage } from './ui/EmptyCompetitorsPage'
import { StyledLoader } from './ui/styled'


export const CompetitorsTabPage = ({ commonProduct }: { commonProduct: pimApi.products.common.Product }) => {
  const queryClient = useQueryClient()
  const { data: competitorsData, isLoading } = pimApi.products.common.useGetProductCompetitorsQuery(commonProduct.productId)
  const { mutate: editCompetitor } = pimApi.products.common.useEditProductCompetitorMutation()

  const handleEditCompetitor = useCallback((competitor: pimApi.goods.Competitor) => {
    editCompetitor({
      productId: commonProduct.productId,
      editedCompetitor: {
        id: competitor.id,
        versionNo: competitor.versionNo,
        reckonedFlag: !competitor.reckonedFlag,
      }
    }, {
      onSuccess:
        reactQueryCacheUpdateByKey({
          queryClient,
          queryCacheKey: getProductCompetitorsQueryKey(commonProduct.productId),
          onSuccess: () => {snackActions.info('Успешно')}
        })
    })
  },[])

  return (
    <>
      <Header activeCompetitors={commonProduct.activeCompetitors}/>
      <>
        {!isLoading ?
          <>
            {isNotEmptyArray(competitorsData) && competitorsData ?
              <CompetitorList handleEditCompetitor={handleEditCompetitor} competitorsData={competitorsData}/>
              :
              <EmptyCompetitorsPage goodsId={commonProduct.goodsId}/>
            }
          </>
          :
          <StyledLoader size={60} />
        }
      </>
    </>
  )
}