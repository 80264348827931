/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditGoodsSupplierCode, GoodsSupplierCode } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsSupplierCode>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  supplierId: number,
  codeId: number,
  data: EditGoodsSupplierCode
}>

export const apiFunction = ({ goodsId,supplierId,codeId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/goods/${goodsId}/suppliers/${supplierId}/codes/${codeId}`,data).then(res => res.data)


export const useEditGoodssuppliercode = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

