import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { reviewApi } from 'shared/api'
import { api } from 'shared/api/base'

interface ReviewType {
  reviewId: number
}
const returnGoodByReview = (reviewData: ReviewType) =>
  api.post('/review/reviews/return', null, { params: { id: reviewData.reviewId } })


export const useReturnGoodByReview = () => useMutation<
  AxiosResponse<reviewApi.ReviewTemplate>,
  AxiosError<errorType>,
  ReviewType
>(returnGoodByReview)