import { Checkbox, TableCell } from '@mui/material'
import React from 'react'
import { Image } from 'shared/ui/components'

export const CustomCheckbox = ({ row, selected, onToggle }) => (
  <TableCell
    style={{ padding: 0, display: 'flex' }}
  >
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 8,
      alignItems: 'center',
      height: '100%'
    }}>
      <Checkbox
        sx={{
          marginTop: '10px',
          marginBottom: '8px',
          color: 'rgba(0, 0, 0, 0.26)',
        }}
        size="small"
        checked={selected}
        onClick={(e) => {
          e.stopPropagation()
          onToggle()
        }}
      />
      <Image style={{ objectFit: 'contain', borderRadius: '4px' }}
        width="28" height="28" src={row.photo} alt="qwe"/>
    </div>
  </TableCell>
)