/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationChangeGoodsSuppliers, ImportMetadata } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  data: BodyBulkOperationChangeGoodsSuppliers
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/goods/change/suppliers`,data).then(res => res.data)


export const useChangeGoodsSuppliers = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

