import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { customerApi } from 'shared/api'
import { api } from 'shared/api/base'


const createPrivilege = (newPrivilege: customerApi.NewPrivilege) =>
  api.post('/customer/admin/privileges', newPrivilege)


export const useCreateNewPrivilege = () => useMutation<
  AxiosResponse<customerApi.Privilege>,
  AxiosError<errorType>,
  customerApi.NewPrivilege
>(createPrivilege)