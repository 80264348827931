/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationChangeOzonPrices, ImportMetadata } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Дата изменения цены
   * @format date-time
   */
    change_date?: string,
  /** Идентификатор причины изменения цены */
    pricechangereason_id: number,
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
    revert_date?: string,

},
  data: BodyBulkOperationChangeOzonPrices
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/products/ozon/change/price`,data, { params: query }).then(res => res.data)


export const useChangeOzonPrices = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

