/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyGoodsUploadGoodsmedia, GoodsFile } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsFile>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  query: {
  /** Тип медиа */
    media_type: "photo" | "video" | "document",

},
  data: BodyGoodsUploadGoodsmedia
}>

export const apiFunction = ({ goodsId,query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/media/file`,data, { params: query }).then(res => res.data)


export const useUploadGoodsmedia = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

