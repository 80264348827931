/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NewValue, Value } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Value>

export type IRequest = CamelCasedPropertiesDeep<{
  dictionaryId: number,
  data: NewValue
}>

export const apiFunction = ({ dictionaryId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/dictionaries/${dictionaryId}/values`,data).then(res => res.data)


export const useCreateDictionaryValue = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

