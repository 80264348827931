import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api/index'

import { api } from '../../base'

interface advicesProps {
  productId: UniqueId,
  adviceDate: string
}
interface PatchPriceAdvicePayload {
  selectedAdvices: Array<advicesProps>
  action: string
}
export const patchPriceAdvice = ({ selectedAdvices, action }: PatchPriceAdvicePayload) =>
  api.patch<pimApi.price.PriceAdvice>(
    `/pim/pricecontrol/priceadvice?action=${action}`,
    selectedAdvices
  )


export const usePatchPriceAdviceMutation = () =>
  useMutation<
    AxiosResponse<pimApi.price.PriceAdvice>,
    AxiosError<errorType>,
    PatchPriceAdvicePayload
  >(patchPriceAdvice)
