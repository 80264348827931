/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { GoodsSupplier, NewGoodsSupplier } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsSupplier>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: NewGoodsSupplier
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/suppliers`,data).then(res => res.data)


export const useCreateGoodssupplier = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

