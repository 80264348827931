/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ContragentUser, NewContragentUser } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ContragentUser>

export type IRequest = CamelCasedPropertiesDeep<{
  contragentId: number,
  data: NewContragentUser
}>

export const apiFunction = ({ contragentId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/admin/contragents/${contragentId}/users`,data).then(res => res.data)


export const useCreateContragentUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

