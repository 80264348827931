import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { api } from '../base'
import { toSnakeCase } from '../../lib/transform'
import { StatsSearchParams } from '../../lib/generated/analytics/Api'
import { toSnake } from '../../lib/transform/toSnakeCase'

export type granularityVariations = 'day' | 'week' |'month' | 'quarter' | 'year'
export interface AnalyticsFileParams extends StatsSearchParams {
  date_to?: string
  date_from?: string
  sort?: string
  order?: string
  groupby?: granularityVariations
  orderStatusInterval?: number
  orderStatus?: string
}

const downloadAnalyticsFile = async ({ queryParams }: { queryParams: AnalyticsFileParams }) => {
  const snakeQueryParams = toSnakeCase(queryParams)
  const {
    start_value,
    end_value,
    sort,
    tab,
    order,
    range,
    units,
    groupby,
    timeline_start,
    timeline_end,
    date_from,
    date_to,
    ...rest } = snakeQueryParams

  return api.post<File>('/analytics/file', rest, 
    {
      responseType: 'blob',
      params: {
        date_from,
        date_to,
        groupby,
        units,
        format: 'excel',
        sort: sort ? toSnake(sort) : undefined, order: order?.toUpperCase() },
    })
}

export const useDownloadAnalyticsFile = () =>
  useMutation<
  AxiosResponse<File>,
  AxiosError<errorType>,
  AnalyticsFileParams
>(queryParams => downloadAnalyticsFile({ queryParams }))