/* eslint-disable */
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReviewsStat } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewsStat>

type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
    reviewtype_id?: number,
  /** Поиск по строке */
    search_string?: string,
  /** Поставщик */
    suppliers_id?: number,
  /** Бренд */
    brand_id?: number,
  /** Компания */
    company_id?: number,
  /** Кабинет */
    companymarketplace_id?: number,
  /** Маркетплейс */
    marketplace_id?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/review/review/stats`,{ params: toSnakeCase(query) }).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/review/review/stats`, { query: toSnakeCase(query) }] as const

export const useGetReviewStats = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

