import React from 'react'
import { round } from 'shared/lib/utils/round'

import { StyledPrice } from './styled'

export const PriceContainer = ({ currentPrice, prevPrice, discount }: { currentPrice?: number, prevPrice?: number, discount?: number }) => (
  <StyledPrice>
    {currentPrice ? <span id="current-price">{round(currentPrice, 0)}</span> : <></>}
    <div id="old-price-container">
      {prevPrice ? <span id="old-price-text">{round(prevPrice, 0)}</span> : <></>}
      {(discount && discount >=0) ? <div id="discount-container">{round(discount, 0)}%</div> : <></>}
    </div>
  </StyledPrice>
)