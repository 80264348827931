/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Category } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Category>

export type IRequest = CamelCasedPropertiesDeep<{
  categoryId: number
}>

export const apiFunction = ({ categoryId }: IRequest) =>
  api.get<ResponseType>(`/pim/categories/${categoryId}`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ categoryId }: IRequest) => ['get', `/pim/categories/${categoryId}`, { categoryId }] as const

export const useGetCategory = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

