/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NewSupplyOrderProductPosition, SupplyOrderProductPosition } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<SupplyOrderProductPosition>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyorderId: number,
  data: NewSupplyOrderProductPosition
}>

export const apiFunction = ({ supplyorderId,data }: IRequest) =>
  api.post<ResponseType>(`/trade/orders/supplyorders/${supplyorderId}/productpositions`,data).then(res => res.data)


export const useCreateSupplyOrderPosition = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

