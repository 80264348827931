/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { TemplateFormat } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** Идентификатор компании */
    company_id?: number,
  /** Идентификатор маркетплейса */
    marketplace_id?: number,
  /** Идентификатор поставщика */
    supplier_id?: number,
  /** Идентификатор кабинета */
    companymarketplace_id?: number,
  /** Идентификатор бренда продукта */
    brand_id?: number,
  /** Флаг получения пустого шаблона */
    blank_template?: boolean,
  /** @default "excel" */
    template_format?: TemplateFormat,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get(`/pim/ops/products/change/product/attributes`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/ops/products/change/product/attributes`, { query }] as const

export const useGetChangeProductAttributesTemplate = (queryParams: IRequest, options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

