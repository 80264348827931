/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ProductsMethod } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Batch Id */
    batch_id: string,
  /** Операция над продуктами */
    method: ProductsMethod,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.patch<ResponseType>(`/pim/ops/products`, {}, { params: query}).then(res => res.data)


export const usePatchProduct = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

