import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'

import { Option } from '../interface'

type CatalogSelectFieldI = FieldRenderProps<string | number> & RadioGroupProps & {
  options: Array<Option>;
  label: string;
}

const RadioGroupField = ({
  label,
  meta,
  input,
  row,
  options,
  size,
  children,
  ...rest
}: CatalogSelectFieldI) => (
  <FormControl component="fieldset">
    {label && <FormLabel component="legend">{label}</FormLabel>}
    <RadioGroup
      sx={{ display: 'flex', gap: '10px' }}
      row={row}
      aria-label={label} {...input} {...rest} onChange={event => input.onChange(event.target.value)}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={<Radio color="primary" size={size}/>}
          value={option.value}
          label={option.label}
          disabled={rest.disabled}
          checked={option.value === input.value}
        />
      ))}
    </RadioGroup>
  </FormControl>
)

export default RadioGroupField
