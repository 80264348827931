/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReportParameterValue } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReportParameterValue[]>

export type IRequest = CamelCasedPropertiesDeep<{
  reportId: number,
  parameterId: number
}>

export const apiFunction = ({ reportId,parameterId }: IRequest) =>
  api.get<ResponseType>(`/reports/report/${reportId}/parameters/${parameterId}/values`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ reportId,parameterId }: IRequest) => ['get', `/reports/report/${reportId}/parameters/${parameterId}/values`, { reportId,parameterId }] as const

export const useGetReportParameterValues = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

