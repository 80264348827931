import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface DeletePrivilege {
  privilegeId: UniqueId,
  value: pimApi.DeletingObject
}
const deletePrivilege = ({ privilegeId, value }: DeletePrivilege) =>
  api.delete(
    `/customer/admin/privileges/${privilegeId}`,
    { data: value }
  )


export const useDeletePrivilegeMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeletePrivilege
  >(deletePrivilege)