/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'




export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  fileId: number,
  query: {
  /**
   * Максимальная высота изображения
   * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
   */
    max_height: number,
  /**
   * Максимальная ширина изображения
   * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
   */
    max_width: number,

}
}>

export const apiFunction = ({ productId,fileId,query }: IRequest) =>
  api.get(`/pim/products/wb/product/${productId}/video/thumbnail/${fileId}`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ productId,fileId,query }: IRequest) => ['get', `/pim/products/wb/product/${productId}/video/thumbnail/${fileId}`, { productId,fileId,query }] as const

export const useDownloadVideoThumbnail = (queryParams: IRequest, options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

