import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'

interface EditUserContragentPayload {
  userId: UniqueId
  contragentId: UniqueId
  managerEditedData: pimApi.admin.EditContragentUser
}
export const editUserContragent = ({
  userId,
  contragentId,
  managerEditedData,
}: EditUserContragentPayload) =>
  api.put<pimApi.admin.EditContragentUser>(
    `/pim/admin/users/${userId}/contragents/${contragentId}`,
    managerEditedData
  )

export const useEditUserContragentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.EditContragentUser>,
    AxiosError<errorType>,
    EditUserContragentPayload
  >(editUserContragent)
