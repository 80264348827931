/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { PIMWBPriceDetail, SetWBPriceInfo } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<PIMWBPriceDetail>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: SetWBPriceInfo
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/products/wb/product/${productId}/price`,data).then(res => res.data)


export const useSetPrice = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

