import { HOME } from 'shared/config/routes'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Container } from '@mui/material'
import logo from 'assets/images/newLogo.svg'

const LogoWrapper = styled.div`
  margin-bottom: 32px;
  img {
    width: 260px;
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
    object-fit: scale-down;
  }
`
const Wrapper = styled.div`
  padding-top: 40px;
  font-weight: 500;
  position: relative;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
`

const CopyrightSection = styled(Typography)`
  text-align: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`
const BoxWrapper = styled.div`
  background: #fff;
  padding: 48px;
  border-radius: 4px;
`

export const AuthLayout = ({ children }: PropsWithChildren<void>) => (
  <Wrapper>
    <Container component="main" maxWidth="xs">
      <BoxWrapper>
        <LogoWrapper>
          <Link to={HOME}>
            <img src={logo} alt=""/>
          </Link>
        </LogoWrapper>
        <div>{children}</div>
      </BoxWrapper>
    </Container>
    <CopyrightSection>Copyright © E-COM Portal 2024.</CopyrightSection>
  </Wrapper>
)
