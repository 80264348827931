import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledLinkName = styled(Link)<{ $isArchived?: boolean, $isSingleString?: boolean }>`
  width: max-content;
  text-decoration: none;
  white-space: ${({ $isSingleString }) => $isSingleString ? 'nowrap !important' : 'pre-wrap !important' };
  color:  ${({ $isArchived }) => $isArchived ? '#a4a4a4' : '#007DFF' };
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`