import React from 'react'
import { useWebSocket } from 'shared/lib/hooks/useWebSocket'

import { NotificationsContainer } from './ui/NotificationsContainer'

export const Notifications = () => {
  useWebSocket()
  return (
    <NotificationsContainer />
  )
}