/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { TemplateFormat } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Идентификатор бренда
   * @min 0
   */
    brand_id?: number,
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
    category_id: number,
  /**
   * Идентификатор родительской категории
   * @exclusiveMin 0
   */
    parent_category_id: number,
  /** Идентификатор кабинета */
    companymarketplace_id: number,
  /**
   * Начало диапазона для даты создания
   * @format date
   */
    date_from?: string,
  /**
   * Конец диапазона для даты создания
   * @format date
   */
    date_to?: string,
  /** @default "excel" */
    template_format?: TemplateFormat,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get(`/pim/ops/products/ozon/change/product`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/ops/products/ozon/change/product`, { query }] as const

export const useGetOzonChangeProductTemplate = (queryParams: IRequest, options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

