import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  min-height: 250px;
  position: relative;
  border: none;
  box-shadow: none;
  .MuiTableCell-head {
    height: 88px;
    white-space: break-spaces;
    background: rgba(247, 247, 247, 0.01);
    line-height: 40px;
  }

  .MuiTableCell-body {
    height: 108px;
  }
  
  .iIHnRO.CellLayout-cell{
    :first-of-type {
      padding-left: 16px;
    }
  }

  .MuiToolbar-root {
    display: none;
  }

  .kRGPbM.TableCell-cell{
    :first-of-type {
      padding-left: 10px;
    }
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`