/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<any>



export const apiFunction = () =>
  api.patch<ResponseType>(`/customer/scraping/nmid`).then(res => res.data)


export const useSchedulescrapingwb = () =>
  useMutation<
    ResponseType,
    errorType
  >(apiFunction)

