import { memo, useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { Box, DialogActions, Grid } from '@mui/material'
import { InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { FieldWithCount, InputField, MaskedInputField, SelectFieldWithAutoComplete, } from 'shared/ui/components/form'
import { Accordion } from 'shared/ui/components/Accordion'
import { Option } from 'shared/ui/components/interface'
import { pimApi } from 'shared/api'
import { PriceCard } from 'entities/pim/price'
import { StyledDiscount } from 'entities/pim/price/ui/styled'
import { SPP } from 'shared/api/pim/price'
import { getDiscountPrice } from 'entities/pim/price/lib'
import { round } from 'shared/lib/utils/round'
import { isNotNil } from 'shared/lib/checkers'

import { PriceContainer, SppRangesContainer, StyledAccordionBox, StyledLoadingButton } from './styled'

interface OptionItem {
  name?: string
  id: number
  commentsRequired?: boolean
}

export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
  commentsRequired: optionItem.commentsRequired,
})

export const PriceChangeModalFormRender = memo(
  ({
    handleSubmit,
    isLoading,
  }: {
    handleSubmit: (any) => void
    isLoading?: boolean
  }) => {
    const price = useField('price').input.value
    const discount = useField('discount').input.value
    const spp: SPP | null = useField('spp').input.value || null

    const newSpp = (newPrice) => {
      if (isNotNil(spp) && spp?.ranges?.length !== 0) {
        const range = spp?.ranges?.filter(el => newPrice >= el.min && newPrice <= el.max)
        if (range?.length === 0) {
          return range?.[0]?.spp
        }
        return range?.reduce((prev, curr) => prev.spp < curr.spp ? prev : curr).spp
      }
      return undefined
    }

    const isPriceChangeable = !!price

    const newPrice = useField('newPrice').input.value
    const newDiscount = useField('newDiscount').input.value

    const changeDateField = useField('changeDate')
    const revertDateField = useField('revertDate')
    const commentsField = useField('comments')

    const {
      data: priceChangeReasonsQuery,
      isLoading: priceChangeReasonsLoading,
    } = pimApi.dictionaries.usePriceChangeReasonsQuery()
    const priceChangeReasonOptionList = useMemo(
      () => priceChangeReasonsQuery?.map(getOptions) || [],
      [priceChangeReasonsQuery]
    )

    const curDate = new Date()
    const defaultDateValue = useMemo(
      () =>
        new Date(
          new Date(curDate.setHours(24, 0, 0, 0)).getTime() -
            curDate.getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 16),
      []
    )

    const handleChangeDateExpand = () => changeDateField.input.onChange(defaultDateValue)
    const handleChangeDateClose = () => changeDateField.input.onChange(null)
  
    const handleRevertDateExpand = () => revertDateField.input.onChange(defaultDateValue)
    const handleRevertDateClose = () => revertDateField.input.onChange(null)

    const handleCommentsClose = () => commentsField.input.onChange(null)

    return (
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', marginTop: '24px' }}>
          <Box sx={{ width: '245px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <PriceContainer>
                <PriceCard
                  lastChangeDate={spp?.lastChangeDate}
                  sppValue={spp?.value}
                  price={price}
                  discount={discount}
                  title="Текущая цена"/>
              </PriceContainer>
              <PriceContainer>
                <PriceCard
                  sppValue={newSpp(getDiscountPrice(newPrice || price, newDiscount || discount))}
                  price={newPrice || price}
                  discount={newDiscount || discount}
                  title="Новая цена"/>
              </PriceContainer>
            </Box>
            <Grid container={true} columnSpacing={{ md: 2 }}>
              <Grid item={true} xs={6.8}>
                <InputWrapper>
                  <Field
                    sx={{ '& .MuiInputBase-inputAdornedEnd': { paddingRight: '8px' } }}
                    name="newPrice"
                    label="Цена на МП"
                    autoComplete="off"
                    type="number"
                    component={MaskedInputField}
                    disabled={false}
                    scale={0}
                    min={0}
                  />
                </InputWrapper>
              </Grid>
              <Grid item={true} xs={5.2}>
                <InputWrapper>
                  <Field
                    sx={{ '& .MuiInputBase-inputAdornedEnd': {
                      paddingRight: '8px'
                    }
                    }}
                    name="newDiscount"
                    label="Скидка"
                    type="number"
                    autoComplete="off"
                    component={MaskedInputField}
                    disabled={false}
                    scale={0}
                    min={0}
                    max={99}
                  />
                </InputWrapper>
              </Grid>
            </Grid>
          </Box>
          { (isNotNil(spp) && spp?.ranges?.length !== 0) ?
            <Grid item={true} xs={12}>
              <InputLabel label="Зависимость СПП от цены" required={false}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                { spp?.ranges?.map((el) => (
                  <SppRangesContainer>
                    <StyledDiscount>{el.spp}%</StyledDiscount>
                    <span>{round(el.min, 0)}–{round(el.max, 0)}</span>
                  </SppRangesContainer>
                )
                )}
              </Box>
            </Grid>
            :
            <></>
          }
          {isPriceChangeable && (
            <>
              <Grid item={true} xs={12}>
                <InputLabel label="Выберите причину изменения цены" />
                <InputWrapper>
                  <Field
                    name="pricechangereasonId"
                    id="pricechangereasonId"
                    label="Причина изменения"
                    required={true}
                    errorAfterTouch={true}
                    component={SelectFieldWithAutoComplete}
                    fullWidth={true}
                    options={priceChangeReasonOptionList}
                    isLoading={priceChangeReasonsLoading}
                  />
                </InputWrapper>
              </Grid>
              <Grid item={true} xs={12}>
                <Accordion
                  title="Оставить комментарий"
                  onClose={handleCommentsClose}
                >
                  <Grid item={true} xs={12} pb={0}>
                    <FieldWithCount>
                      <Field
                        name="comments"
                        component={InputField}
                        multiline={true}
                        fullWidth={true}
                        minRows={1}
                        maxRows={10}
                        max={255}
                      />
                    </FieldWithCount>
                  </Grid>
                </Accordion>
              </Grid>
              <Grid item={true} xs={12}>
                <StyledAccordionBox>
                  <Accordion key="first-acc"
                    title="Запланировать время изменения"
                    onOpen={handleChangeDateExpand}
                    onClose={handleChangeDateClose}
                  >
                    <Field
                      name="changeDate"
                      label="Дата время"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={InputField}
                    />
                  </Accordion>
                  <Accordion
                    title="Вернуться к исходной цене"
                    onOpen={handleRevertDateExpand}
                    onClose={handleRevertDateClose}
                  >
                    <Field
                      name="revertDate"
                      label="Дата время"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={InputField}
                    />
                  </Accordion>
                </StyledAccordionBox>
              </Grid>
            </>
          )}
        </Box>
        <DialogActions sx={{ padding: '0', marginTop: '24px' }}>
          <Box sx={{ width: '100%' }}>
            <StyledLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={isPriceChangeable ? (!newPrice && !newDiscount) : (!newPrice)}
              loading={isLoading}
            >
              {isPriceChangeable ? 'ИЗМЕНИТЬ' : 'УСТАНОВИТЬ'}
            </StyledLoadingButton>
          </Box>
        </DialogActions>
      </form>
    )
  }
)
