/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Brand, EditingBrand } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Brand>

export type IRequest = CamelCasedPropertiesDeep<{
  brandId: number,
  data: EditingBrand
}>

export const apiFunction = ({ brandId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/brands/${brandId}`,data).then(res => res.data)


export const useEditBrand = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

