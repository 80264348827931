import { useMutation } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'

interface PostNewCompany {
  newCompany: customerApi.NewCompany
}

export const postNewCompany = ({ newCompany }: PostNewCompany) =>
  api.post<customerApi.Company>(
    '/customer/companies', newCompany
  )

export const usePostNewCompanyMutation = () =>
  useMutation<
    AxiosResponse<customerApi.Company>,
    AxiosError<errorType>,
    PostNewCompany
  >(postNewCompany)