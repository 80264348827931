import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { customerGen } from 'shared/lib/generated'

import { DialogTitleWithBtn } from './DialogTitleWithBtn'

import { UserPhotoCell } from '../Table/ui/TableCells'
import { getPhotoUrl } from '../Table/ui/TableCellComponent'

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

const MODAL_STYLES = { width: '380px', minHeight: '150px' }
const SX_TITLE = { fontSize: '20px', padding: '0', marginBottom: '8px' }
export const PrivilegesUsersModal = ({ row, close }) => {
  const { data, isLoading, isFetching } =
    customerGen.administration.GetUsers.useGetUsers({
      query: {
        withPrivilegeId: row.id,
      },
    })

  return (
    <Box p={3} sx={MODAL_STYLES}>
      <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
        {'Пользователи с доступом\nк привилегии'}
      </DialogTitleWithBtn>
      <span>{row.name}</span>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          gap: '16px',
          flexDirection: 'column',
        }}
      >
        {(isFetching || isLoading) && <StyledLoader size={60} />}
        {data &&
          data.map((el) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <div>
                <UserPhotoCell apiUrl={getPhotoUrl(el.id)} name={el.name} />
              </div>
              <span>{el.name}</span>
            </div>
          ))}
      </Box>
    </Box>
  )
}
