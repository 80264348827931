import { pimApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnName =
  | keyof Omit<
  pimApi.price.ProductPriceAdvice, ''
> |
  'id'
  | 'photo'
interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

type ColumnNames = Record<ColumnName, ColumnName>
export const columns: ColumnNames = {
  productId: 'productId',
  margin: 'margin',
  marginPercents: 'marginPercents',
  advicePriceDetail: 'advicePriceDetail',
  advicePriceWithoutDiscount: 'advicePriceWithoutDiscount',
  marketplaceId: 'marketplaceId',
  adviceDate: 'adviceDate',
  advicePrice: 'advicePrice',
  newMargin: 'newMargin',
  newMarginPercents: 'newMarginPercents',
  priceSet: 'priceSet',
  declined: 'declined',
  photo: 'photo',
  comments: 'comments',
  id: 'id',
  versionNo: 'versionNo',
  settingsDate: 'settingsDate',
  minPrice: 'minPrice',
  targetMargin: 'targetMargin',
  targetMarginPercents: 'targetMarginPercents',
  autoChangePrice: 'autoChangePrice',
  adviceInterval: 'adviceInterval',
  sku: 'sku',
  marketplaceUrl: 'marketplaceUrl',
  name: 'name',
  stocks: 'stocks',
  price: 'price',
  perDay: 'perDay',
  incomingPrice: 'incomingPrice'
}
export const columnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config:
    [
      {
        name: columns.photo,
        columnName: columns.photo,
        title: 'Фото\n ',
        width: 80,
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepricePhoto
      },
      {
        name: columns.name,
        columnName: columns.name,
        title: 'Номенклатура\nНаименование',
        width: 'auto',
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepriceNameLink,
      },
      {
        name: columns.sku,
        columnName: columns.sku,
        title: '',
        width: 0,
        sortingEnabled: false,
      },
      {
        name: columns.stocks,
        columnName: columns.stocks,
        title: 'Остатки\nВ день',
        width: 140,
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepriceStocks
      },
      {
        name: columns.price,
        columnName: columns.price,
        title: 'Тек. цена\nТек. марж.',
        width: 160,
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepriceCurrentPrice
      },
      {
        name: columns.minPrice,
        columnName: columns.minPrice,
        width: 200,
        title: 'Мин. цена\nЦелевая марж.',
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepriceMinPrice
      },
      {
        name: columns.incomingPrice,
        columnName: columns.incomingPrice,
        width: 160,
        title: ' \nВход. цена',
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepriceIncomingPrice
      },
      {
        name: columns.id,
        columnName: columns.id,
        width: 200,
        title: 'Автоизменение\nПроверка',
        sortingEnabled: false,
        wordWrapEnabled: true,
        cellType: CellType.RepriceAutoChange
      },
    ]
}