import { useCallback } from 'react'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { AutocompleteValue, FilterOptionsState } from '@mui/material/useAutocomplete'

export const AutocompleteSearchField = ({ ...props }) => {

  const handleChange = (value: AutocompleteValue<Option<any>, false, undefined, undefined>) => {
    if (typeof value?.value === 'string') {
      props.input.onChange({ value: value.value, label: value.value })
    } else {
      props.input.onChange(value)
    }
  }

  const filterOptions = useCallback(
    (selectedValues: Option<string> | Array<Option<string>>) =>
      (
        options: Array<Option<string>>,
        state: FilterOptionsState<Option<string>>
      ) => {
        const filtered = options.filter((optionElement) =>
          Array.isArray(selectedValues)
            ? !selectedValues.some(
              (element) => element.value === optionElement.value
            )
            : optionElement !== selectedValues
        )

        if (state.inputValue !== '' && !filtered.find(el => el.label === state.inputValue)) {
          filtered.push({
            value: state.inputValue,
            label: `Добавить "${state.inputValue}"`,
          })
        }

        return filtered
      },
    []
  )

  return (
    <AutocompleteCatalogSearchField
      {...props as FieldRenderProps<any>}
      input={props.input}
      meta={props.meta}
      // @ts-ignore
      onChange={handleChange}
      fetchCatalog={props.fetchCatalog}
      noOptionsText="Введите строку для поиска"
      disableClearable={false}
      multiple={false}
      filterOptions={filterOptions}
      freeSolo={true}
    />
  )
}