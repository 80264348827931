/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { MediaURL } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: MediaURL
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.post(`/pim/products/ozon/product/${productId}/photos/url/validator`,data).then(res => res.data)


export const useCheckPhotoUrl = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

