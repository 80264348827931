import React, { memo, useCallback } from 'react'
import { useField, useForm } from 'react-final-form'
import { useQueryClient } from 'react-query'
import { Box, Grid } from '@mui/material'
import forOwn from 'lodash/forOwn'
import { productModel } from 'entities/pim/product'
import { PriceChange } from 'features/product/Simple/PriceChange'
import { Stocks } from 'features/product/stocks'
import { StocksAndOrders } from 'features/product/stockAndOrders'
import { CheckboxField, Field, InputField } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { AbcClassCard } from 'widgets/product/Common/abcClass/ui/AbcClassCard'
import { ProductBarcodes } from 'widgets/ProductBarcodes'
import { snackActions } from 'shared/lib/react/snackbar'
import { Costs } from 'features/product/Costs'
import { ProductsProfitability } from 'widgets/product/Common/Profitability'


export const ProductCardForm = ({
  isArchived,
  productId,
}: {
  isArchived?: boolean
  productId: number
}) => {
  const { change, batch } = useForm<productModel.simpleModel.EditFormValues>()
  const price = useField('price').input.value || null

  const priceRecentHistory = useField('priceRecentHistory').input.value?.map(
    ({ date, ...rest }) => ({
      date: Number(new Date(date)),
      ...rest,
    })
  )
  const futurePrices = useField('futurePrices').input.value
  const simpleProduct = pimApi.products.simple.useFetchSimpleProductQuery(productId)
  const abcClass = simpleProduct.data?.abcClass

  const handlePriceUpdate = (
    res: pimApi.products.SetSimpleProductPriceInfo
  ) => {
    batch(() => {
      forOwn(res, (value, key) => {
        // @ts-ignore
        change(key, value)
      })
    })
  }

  const queryClient = useQueryClient()
  const { mutate: createProductBarcode, isLoading: createProductBarcodeInProgress } =
    pimApi.products.simple.useCreateProductBarcodeMutation()
  const { mutate: editProductBarcode, isLoading: editProductBarcodeInProgress } =
    pimApi.products.simple.useEditProductBarcodeMutation()
  const { mutate: deleteProductBarcode, isLoading: deleteProductBarcodeInProgress } =
    pimApi.products.simple.useDeleteProductBarcodeMutation()
  const { mutate: editSimpleProduct, isLoading: editSimpleProductInProgress } =
    pimApi.products.simple.useEditSimpleProductMutation()

  const createNewBarcode = useCallback((barcode: pimApi.products.NewBarcode, onCreate: () => void) => {
    createProductBarcode(
      { productId, barcode },
      {
        onSuccess: (newBarcode) => {
          queryClient.setQueryData<pimApi.products.SimpleProduct>(
            pimApi.products.simple.getSimpleProductQueryKey(productId),
            (curr) => {
              const newObj = { ...curr }
              newObj.barcodes?.push(newBarcode)
              if (newObj.mainBarcodeId === null) {
                newObj.mainBarcodeId = newBarcode.id
              }
              return newObj as pimApi.products.SimpleProduct
            }
          )
          snackActions.info('Штрих-код добавлен на продукт!')
          onCreate()
        }
      })
  }, [productId])

  const editBarcode = useCallback((barcode: pimApi.products.EditBarcode) => {
    editProductBarcode(
      { productId, barcode },
      { onSuccess: (editedBarcode) => {
        queryClient.setQueryData<pimApi.products.SimpleProduct>(
          pimApi.products.simple.getSimpleProductQueryKey(productId),
          (curr) => {
            const newObj = { ...curr }
            newObj.barcodes = newObj.barcodes?.map(b => {
              if (b.id === editedBarcode.id) {
                return editedBarcode
              }
              return b
            })
            return newObj as pimApi.products.SimpleProduct
          }
        )
        snackActions.info('Штрих-код изменён успешно!')
      }
      })
  }, [productId])

  const deleteBarcode = useCallback((barcode: DeletingObject) => {
    deleteProductBarcode(
      { productId, barcode },
      { onSuccess: () => {
        queryClient.setQueryData<pimApi.products.SimpleProduct>(
          pimApi.products.simple.getSimpleProductQueryKey(productId),
          (curr) => {
            const newObj = { ...curr }
            newObj.barcodes = newObj.barcodes?.filter(b => b.id !== barcode.id)
            return newObj as pimApi.products.SimpleProduct
          }
        )
        snackActions.info('Штрих-код удалён!')
      }
      })
  }, [productId,])

  const makeMainBarcode = useCallback((barcodeId: UniqueId) => {
    if (simpleProduct?.data?.versionNo !== undefined) {
      editSimpleProduct({
        productId,
        editedSimpleProduct: {
          id: productId,
          versionNo: simpleProduct.data.versionNo,
          mainBarcodeId: barcodeId,
        }
      },
      {
        onSuccess: (editedOzonProduct) => {
          queryClient.setQueryData<pimApi.products.SimpleProduct>(
            pimApi.products.simple.getSimpleProductQueryKey(productId),editedOzonProduct)
          snackActions.info('Штрих-код сделан основным!')
        }
      })
    }
  }, [productId,])


  return (
    <Box>
      <Grid item={true} xs={12}>
        <Grid container={true} sx={{ borderBottom: '1px solid #E0E0E0', paddingBottom: '12px' }}>
          <Grid item={true} xs={12} mb={2}>
            <PriceChange
              discount={0}
              futurePrices={futurePrices}
              price={price}
              priceRecentHistory={priceRecentHistory}
              readOnly={isArchived}
              onUpdate={handlePriceUpdate}
            />
          </Grid>
          <Grid item={true} xs={2.4}>
            <Field
              component={InputField}
              name="sku"
              label="Номенклатура"
              variant="outlined"
              size="small"
              fullWidth={true}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={3} ml="19px">
            <Field
              name="autoOrdering"
              label="Автозаказ"
              size="small"
              type="checkbox"
              component={CheckboxField}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ProductBarcodes
              barcodes={simpleProduct?.data?.barcodes}
              editingDisabled={
                isArchived || createProductBarcodeInProgress || editProductBarcodeInProgress ||
                  deleteProductBarcodeInProgress || editSimpleProductInProgress
              }
              mainBarcodeId={simpleProduct?.data?.mainBarcodeId}
              createNewBarcode={createNewBarcode}
              editBarcode={editBarcode}
              deleteBarcode={deleteBarcode}
              makeMainBarcode={makeMainBarcode}
              barcodeType="text"
            />
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Costs copackingCostInfo={simpleProduct?.data?.copackingCostInfo}/>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} gap={2} display="flex">
        <AbcClassCard abc={abcClass}/>
        <Stocks productId={productId}/>
        <StocksAndOrders productId={productId} />
        <ProductsProfitability productId={productId} price={price}/>
      </Grid>
    </Box>
  )
}

export const MemoProductCardForm = memo(ProductCardForm)
