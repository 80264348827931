import { version } from '../../../package.json'

export * from './env'
export * from './routes'
export * from './queryClient'
export * from './theme'
export * from './httpStatus'
export * from './snackbar'
export * from './product'

export const APP_VERSION = version