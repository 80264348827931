import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Paper,
  Grid,
  CircularProgress,
  Typography,
  Accordion,
  AccordionDetails
} from '@mui/material'
import { Form, InputField, Field } from 'shared/ui/components/form'
import { Button, InputLabel } from 'shared/ui/components'
import { UserProfile } from 'shared/services/interfaces/user'
import { userSelector } from 'store/user/selectors'
import usePending from 'shared/lib/hooks/PendingHook'
import DefaultUserAvatar from 'assets/images/DefaultUserAvatar.svg'
import { updateUserInfo, getUserPhoto, uploadUserPhoto, deleteUserPhoto } from 'store/user'
import * as yup from 'yup'
import useYupValidation from 'shared/lib/hooks/useYupValidation'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { HelperTextSupply } from 'features/auth/ui/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import * as UI from './styles'
import { ExpandedAccordion } from './styles'
import BadgeDownload from './BadgeDownload'


const yupValidation = yup.object().shape({
  repeatPassword: yup.string().test('password match', 'Пароли не совпадают',
    (password, { parent }) => password === parent.newPassword),
  newPassword: yup.string().min(8),
})

const Profile = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { name, login, versionNo, id, avatarUrl, telegramCode } = useSelector(userSelector)
  const validation = useYupValidation(yupValidation)
  const [updateUserProfile, updateUserProfileIsLoading] = usePending(updateUserInfo)
  const [, requestUserPhotoIsLoading] = usePending(getUserPhoto)
  const [updateUserPhoto, updateUserPhotoIsLoading] = usePending(uploadUserPhoto)
  const [removeUserPhoto, removeUserPhotoIsLoading] = usePending(deleteUserPhoto)
  const [initialValues, setInitialValues] = useState<UserProfile>({
    name: '',
    login: '',
    telegramCode: '',
  })
  const onSubmit = (values, event) => {
    updateUserProfile({ ...values, versionNo, id })
    if (!updateUserProfileIsLoading) {
      event.restart()
    }
  }

  useEffect(() => {
    setInitialValues(values => ({ ...values, name, login, telegramCode }))
  }, [name, login, telegramCode])
  const handleAvatarPlaceHolderClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [inputRef])
  const uploadAvatar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      updateUserPhoto(file)
    }
  }, [updateUserPhoto])

  return (
    <>
      <Form
        onSubmit={onSubmit}
        id="userForm"
        initialValues={initialValues}
        validate={validation}
        render={({ visited, valid, handleSubmit, errors, values, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Box component={Paper} pt={3} pr={3} pl={3} mt="28px" mb={3} pb={4}>
              <Grid container={true} justifyContent="space-between">
                <Grid item={true} xs={12}>
                  <UI.Form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Box>
                        <UI.Block marginBottom={3}>
                          <Typography fontSize="20px" mb={3}>{name}</Typography>
                          {
                            (requestUserPhotoIsLoading || updateUserPhotoIsLoading || removeUserPhotoIsLoading) ?
                              <CircularProgress /> :
                              <UI.Avatar>
                                <UI.AvatarImage bgUrl={avatarUrl || DefaultUserAvatar} />
                                {avatarUrl && <UI.DeleteAvatar onClick={removeUserPhoto} />}
                                <UI.AvatarPlaceHolder onClick={handleAvatarPlaceHolderClick}>
                                  <input type="file" accept="image/jpeg" ref={inputRef} onChange={uploadAvatar} />
                                </UI.AvatarPlaceHolder>
                              </UI.Avatar>
                          }
                        </UI.Block>
                        <UI.Block marginBottom={3}>
                          <InputLabel label="Имя пользователя" required={true}/>
                          <Field
                            sx={{ width: '350px' }}
                            name="name"
                            component={InputField}
                            onBlur={handleSubmit}
                          />
                        </UI.Block>
                        <UI.Block marginBottom={3}>
                          <InputLabel label="Логин" required={true}/>
                          <Field
                            sx={{ width: '350px' }}
                            name="login"
                            component={InputField}
                            onBlur={handleSubmit}
                          />
                        </UI.Block>
                        <UI.Block>
                          <InputLabel label="Telegram" required={false} />
                          <Field
                            sx={{ width: '350px' }}
                            name="telegramCode"
                            component={InputField}
                            onBlur={handleSubmit}
                            type="number"
                            step="1"
                          />
                        </UI.Block>
                      </Box>
                      <BadgeDownload/>
                    </Box>
                  </UI.Form>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Accordion>
                <ExpandedAccordion sx={{ padding: '13px 24px' }} expandIcon={<ExpandMoreIcon />}>
                  <Typography fontSize="20px">Обновление пароля</Typography>
                </ExpandedAccordion>
                <AccordionDetails sx={{ padding: '0 24px' }}>
                  <Box display="flex" flexDirection="row" gap={3} pb={3}>
                    <Box width={240} height="auto" borderRadius="4px" padding="12px 16px" style={{ backgroundColor: '#FAFAFA' }}>
                      <Box marginBottom="14px">
                        <span style={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: '12px', fontWeight: '500'
                        }}>Требование к паролю</span>
                      </Box>
                      <Box display="flex" flexDirection="row" gap="13px" mb={1}>
                        {!values.newPassword &&
                        <InfoOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '17px', height: '17px' }}/>}
                        {(!!values?.newPassword && values?.newPassword?.length < 8) &&
                        <ReportProblemOutlinedIcon sx={{ color: '#F44336', width: '17px', height: '17px' }}/>}
                        {(values?.newPassword?.length >= 8) &&
                        <CheckCircleOutlineOutlinedIcon sx={{ color: '#4CAF50', width: '17px', height: '17px' }}/>}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', fontWeight: '500' }}>
                          Не менее 8 символов
                        </span>
                      </Box>
                      <Box display="flex" flexDirection="row" gap="13px">
                        {!values.newPassword &&
                        <InfoOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '17px', height: '17px' }}/>}
                        {(dirty && values?.newPassword?.length > 30) &&
                        <ReportProblemOutlinedIcon sx={{ color: '#F44336', width: '17px', height: '17px' }}/>}
                        {(!!values?.newPassword && values?.newPassword?.length <= 30) &&
                        <CheckCircleOutlineOutlinedIcon sx={{ color: '#4CAF50', width: '17px', height: '17px' }}/>}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', fontWeight: '500' }}>
                          Не более 30 символов
                        </span>
                      </Box>
                    </Box>
                    <UI.Block mt={1}>
                      <Typography fontSize={16} fontWeight={500} mb={3}>Пароль</Typography>
                      <UI.Block marginBottom={3}>
                        <HelperTextSupply>
                          <Field
                            name="oldPassword"
                            sx={{ width: '320px' }}
                            component={InputField}
                            errorAfterTouch={true}
                            helperText=""
                            label="Действующий пароль"
                            type="password"
                          />
                        </HelperTextSupply>
                      </UI.Block>
                      <UI.Block marginBottom={3}>
                        <HelperTextSupply>
                          <Field
                            name="newPassword"
                            sx={{ width: '320px' }}
                            errorAfterTouch={true}
                            helperText=""
                            component={InputField}
                            label="Новый пароль"
                            type="password"
                          />
                        </HelperTextSupply>
                      </UI.Block>
                      <UI.Block marginBottom={3}>
                        <HelperTextSupply>
                          <Field
                            name="repeatPassword"
                            sx={{ width: '320px' }}
                            errorAfterTouch={true}
                            helperText={visited?.repeatPassword ? errors?.repeatPassword : ''}
                            component={InputField}
                            required={true}
                            InputProps={{ autoComplete: 'off' }}
                            label="Подтвердите пароль"
                            type="password"
                          />
                        </HelperTextSupply>
                      </UI.Block>
                      <Button
                        disabled={!valid || values?.oldPassword?.length === 0}
                        type="submit"
                      >
                        Сохранить
                      </Button>
                    </UI.Block>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </form>
        )}
      />
    </>
  )
}

export default Profile
