/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationPostDeleteGoodsCompetitor, ImportMetadata } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  data: BodyBulkOperationPostDeleteGoodsCompetitor
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/goods/delete/competitors`,data).then(res => res.data)


export const usePostDeleteGoodsCompetitor = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

