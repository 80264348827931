import { HOME } from 'shared/config/routes'
import React, { memo, useContext } from 'react'
import adminPanel from 'assets/images/icons/sidebarIcons/ChildMenuIcons/adminPanel.svg'
import bulkOperations from 'assets/images/icons/sidebarIcons/ChildMenuIcons/bulkOperations.svg'
import docList from 'assets/images/icons/sidebarIcons/ChildMenuIcons/docList.svg'
import goodsList from 'assets/images/icons/sidebarIcons/ChildMenuIcons/goodsList.svg'
import home from 'assets/images/icons/sidebarIcons/ChildMenuIcons/home.svg'
import priceWizard from 'assets/images/icons/sidebarIcons/ChildMenuIcons/priceWizard.svg'
import productList from 'assets/images/icons/sidebarIcons/ChildMenuIcons/productList.svg'
import charts from 'assets/images/icons/sidebarIcons/ChildMenuIcons/charts.svg'
import reports from 'assets/images/icons/sidebarIcons/ChildMenuIcons/reports.svg'
import settingsProfile from 'assets/images/icons/sidebarIcons/ChildMenuIcons/settingsProfile.svg'
import priceHistory from 'assets/images/icons/sidebarIcons/ChildMenuIcons/priceHistory.svg'
import stocksGoodsSupplying from 'assets/images/icons/sidebarIcons/ChildMenuIcons/stocksGoodsSupplying.svg'
import supplyOrders from 'assets/images/icons/sidebarIcons/ChildMenuIcons/supplyOrders.svg'
import reviews from 'assets/images/icons/sidebarIcons/ChildMenuIcons/reviews.svg'
import activity from 'assets/images/icons/sidebarIcons/ChildMenuIcons/activity.svg'
import copackingOrders from 'assets/images/icons/sidebarIcons/ChildMenuIcons/copackingOrders.svg'
import Settings from 'assets/images/icons/sidebarIcons/MenuIcons/Settings.svg'
import ShoppingCart from 'assets/images/icons/sidebarIcons/MenuIcons/ShoppingCart.svg'
import Box from 'assets/images/icons/sidebarIcons/MenuIcons/Box.svg'
import Analytics from 'assets/images/icons/sidebarIcons/MenuIcons/Analytics.svg'
import {
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Link, useLocation } from 'react-router-dom'
import logo from 'assets/images/sidebarLogo.svg'
import miniLogo from 'assets/images/sidebarMiniLogo.svg'
import { SidebarContext } from 'shared/ui/layouts/AppLayout'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { routesList } from './routes'
import {
  CollapseButton, CollapseStyled,
  CollapseWrapper, DrawerStyled, DrawerWrapper, ListItemNested, ListItemStyled, ListStyled, Logo, LogoWrapper
} from './styled'

import { localStore } from '../../../services/localStore'

const MenuIcons = {
  Settings: <img src={ Settings } alt=""/>,
  Box: <img src={ Box } alt=""/>,
  Analytics: <img src={ Analytics } alt=""/>,
  ShoppingCart: <img src={ ShoppingCart } alt=""/>,
}
const ChildMenuIcons = {
  home: <img src={ home } alt=""/>,
  activity: <img src={ activity } alt=""/>,
  goodsList: <img src={ goodsList } alt=""/>,
  productList: <img src={ productList } alt=""/>,
  bulkOperations: <img src={ bulkOperations } alt=""/>,
  supplyOrders: <img src={ supplyOrders } alt=""/>,
  docList: <img src={ docList } alt=""/>,
  stocksGoodsSupplying: <img src={ stocksGoodsSupplying } alt=""/>,
  copackingOrders: <img src={copackingOrders} alt=""/>,
  priceWizard: <img src={ priceWizard } alt=""/>,
  priceHistory: <img src={ priceHistory } alt=""/>,
  reviews: <img src={ reviews } alt=""/>,
  charts: <img src={ charts } alt=""/>,
  reports: <img src={ reports } alt=""/>,
  adminPanel: <img src={ adminPanel } alt=""/>,
  settingsProfile: <img src={ settingsProfile } alt=""/>,
}

export const Sidebar = memo(() => {
  const { sidebarOpen, setSidebarOpen, expand, setExpand } = useContext(SidebarContext)
  const { pathname } = useLocation()
  const routes = routesList()

  const handleDrawerOpen = () => {
    localStore.set('sidebarActive', 'true')
    setSidebarOpen(true)
  }

  const handleDrawerClose = () => {
    localStore.set('sidebarActive', 'false')
    setSidebarOpen(false)
  }

  const isSelected = (href) => {
    if (pathname.includes('bulkoperations')) {
      return href.includes('bulkoperations')
    }
    if (href === '/') {
      return href === pathname
    }
    return !!pathname.match(`^${ href }`)
  }

  const handleChangeExpand = (index) => {
    setExpand(prevState => {
      const rest = [...prevState]
      rest[index].open = !prevState[index].open
      localStore.set('expandedSidebarList', JSON.stringify(rest))
      return rest
    })
  }

  const includesPath = (href) => {
    let include = false
    href.forEach(el => {
      if (isSelected(el)) {
        include = true
      }
    })
    return include
  }

  return (
    <DrawerWrapper $isActive={ sidebarOpen } className="sidebar-container">
      <DrawerStyled variant="permanent">
        <LogoWrapper $isActive={ sidebarOpen }>
          <Logo to={ HOME }>
            <img style={ { opacity: sidebarOpen ? '1' : '0', transition: 'all 0.3s ease-out 0.1s' } } src={ logo }
              alt=""/>
            <img src={ miniLogo } alt=""/>
          </Logo>
        </LogoWrapper>

        <ListStyled $isActive={ sidebarOpen }>
          { routes.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={ index }>
              {item.icon ? 
                <ListItemStyled
                  onClick={() => {handleChangeExpand(index)}}
                  $includes={includesPath(item.child.map(el => el.href))}>
                  <ListItemIcon sx={ { minWidth: '0', marginRight: '16px' } }>
                    { MenuIcons[item.icon] }
                  </ListItemIcon>
                  <ListItemText sx={ { opacity: sidebarOpen ? '1' : '0' } } primary={ item.title }/>
                  {expand[index].open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </ListItemStyled>
                : 
                <div style={{ height: '38px' }}/>
              }
              { item.child.length && (
                <CollapseStyled in={ item.title ? expand[index].open : true } timeout="auto" $isActive={ sidebarOpen }>
                  { item.child.map((child, childIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <List component="div" disablePadding={ true } key={ childIndex }>
                      <ListItemNested
                        sx={ {
                          paddingLeft: sidebarOpen ? '8px' : '9px',
                          paddingRight: sidebarOpen ? '8px' : '7px',
                          transition: '0.3s'
                        } }
                        className={ `${ isSelected(child.href) ? 'menu-link-active' : '' }` }
                        title={ sidebarOpen ? '' : child.title }
                        component={ Link }
                        to={ child.href }
                      >
                        <ListItemIcon>{ ChildMenuIcons[child.icon] }</ListItemIcon>
                        <ListItemText sx={ { opacity: sidebarOpen ? '1' : '0' } } primary={ child.title }/>
                      </ListItemNested>
                    </List>
                  )) }
                </CollapseStyled>
              ) }
            </div>
          )) }
          <CollapseWrapper $isActive={ sidebarOpen }>
            { sidebarOpen ? (
              <CollapseButton onClick={ handleDrawerClose }>
                <KeyboardDoubleArrowLeftIcon/>
              </CollapseButton>
            ) : (
              <CollapseButton onClick={ handleDrawerOpen }>
                <KeyboardDoubleArrowRightIcon/>
              </CollapseButton>
            ) }
          </CollapseWrapper>
        </ListStyled>
      </DrawerStyled>
    </DrawerWrapper>
  )
}
)
