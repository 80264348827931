// @ts-nocheck
import { TemplateConnector } from '@devexpress/dx-react-core'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'

const TableRow = (props: Table.DataRowProps) =>{
  const { row } = props
  
  return (
    <TemplateConnector>
      {({ expandedDetailRowIds }) => {
        const isRowExpanded = expandedDetailRowIds?.includes(row.id)
        const className = isRowExpanded ? 'TableRow-expanded' : 'TableRow-not-expanded'
        return (
          <Table.Row
            {...props}
            className={`${className} TableRow-with-detailBtn`}
          />
        )
      }}
    </TemplateConnector>
  )
}

export const TableRowComponent = memo(TableRow)
