/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditValue, Value } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Value>

export type IRequest = CamelCasedPropertiesDeep<{
  dictionaryId: number,
  valueId: number,
  data: EditValue
}>

export const apiFunction = ({ dictionaryId,valueId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/dictionaries/${dictionaryId}/values/${valueId}`,data).then(res => res.data)


export const useEditDictionaryValue = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

