import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

const createNewUser = (newUser: pimApi.admin.NewUser) =>
  api.post<pimApi.admin.CurrentUser>(
    '/customer/admin/users',
    newUser
  )

export const useCreateUserMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.CurrentUser>,
    AxiosError<errorType>,
    pimApi.admin.NewUser
    >(createNewUser)