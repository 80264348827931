/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddWBProduct */
export interface AddWBProduct {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Признак, что следует использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /** Идентификатор номенклатуры на Wildberries в которую необходимо добавить номенклатуру или размер */
  wbnomenclature_id: number
  /**
   * Признак, что продукт нужно создать как новый размер
   * @default false
   */
  new_variation?: boolean
}

/**
 * AdviceInterval
 * Возможные интервалы для расчета рекомендованной цены
 */
export enum AdviceInterval {
  Value1Hour = '1 hour',
  Value1Day = '1 day',
  Value1Minute = '1 minute',
}

/** AdviceIntervalOption */
export interface AdviceIntervalOption {
  /** Значение в формате interval */
  value: AdviceInterval
  /** Название временного интервала */
  label: string
}

/** ArchivationInfo */
export interface ArchivationInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
}

/** ArchivationReason */
export interface ArchivationReason {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование причины архивации
   * @maxLength 100
   */
  name: string
  /**
   * Код причины архивации
   * @maxLength 100
   */
  code?: string
  /** Нужны комментарии */
  comments_required: boolean
}

/** Attribute */
export interface Attribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /**
   * Changed
   * @format date-time
   */
  changed?: string
  /** Changer */
  changer?: string
  /** Идентификатор атрибута */
  attribute_id: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /** Призак обязательности */
  required: boolean
  /**
   * Тип данных
   * @default "string"
   * @example "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /**
   * Единица измерения для числового значения
   * @maxLength 100
   */
  unit?: string
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /** Справочник возможных значений атрибута */
  dictionary_values?: string[]
  /**
   * Значения только из справочника
   * @default true
   */
  fixed_values?: boolean
  /** Может быть несколько значений */
  multiple_values: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   * @example "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /** Значение или массив значений атрибута */
  value?: string[]
}

/** AttributeGroup */
export interface AttributeGroup {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /** Список атрибутов */
  attributes: Attribute[]
}

/** AttributesSearchResult */
export interface AttributesSearchResult {
  /** Список атрибутов */
  result?: CommonAttribute[]
  /** Количество атрибутов */
  total: number
}

/** Barcode */
export interface Barcode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Штрих-код
   * @maxLength 100
   */
  barcode: string
  /** Устаревший код */
  expired_flag: boolean
  /** Возможно редактирование и удаление */
  edit_possible: boolean
}

/** Body_administration-load_contragent_orderform */
export interface BodyAdministrationLoadContragentOrderform {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_goods_suppliers */
export interface BodyBulkOperationChangeGoodsSuppliers {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_ozon_prices */
export interface BodyBulkOperationChangeOzonPrices {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
  /** Комментарий к изменению цены */
  comments?: string
}

/** Body_bulk_operation-change_ozon_products */
export interface BodyBulkOperationChangeOzonProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_products_attributes */
export interface BodyBulkOperationChangeProductsAttributes {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_simple_prices */
export interface BodyBulkOperationChangeSimplePrices {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
  /** Комментарий к изменению цены */
  comments?: string
}

/** Body_bulk_operation-change_simple_products */
export interface BodyBulkOperationChangeSimpleProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_wb_prices */
export interface BodyBulkOperationChangeWbPrices {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
  /** Комментарий к изменению цены */
  comments?: string
}

/** Body_bulk_operation-change_wb_products */
export interface BodyBulkOperationChangeWbProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-create_goodsexcel */
export interface BodyBulkOperationCreateGoodsexcel {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-masse_goods_archiving */
export interface BodyBulkOperationMasseGoodsArchiving {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-masse_products_archiving */
export interface BodyBulkOperationMasseProductsArchiving {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-new_ozon_products */
export interface BodyBulkOperationNewOzonProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-new_simple_products */
export interface BodyBulkOperationNewSimpleProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-new_wb_products */
export interface BodyBulkOperationNewWbProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_add_add_goods_competitor */
export interface BodyBulkOperationPostAddAddGoodsCompetitor {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_change_product_descriptions */
export interface BodyBulkOperationPostChangeProductDescriptions {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_change_review_setting */
export interface BodyBulkOperationPostChangeReviewSetting {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_delete_goods_competitor */
export interface BodyBulkOperationPostDeleteGoodsCompetitor {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_goods_create_suppliercode */
export interface BodyBulkOperationPostGoodsCreateSuppliercode {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_goods-check_goodsmedia_file */
export interface BodyGoodsCheckGoodsmediaFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_goods-upload_goodsmedia */
export interface BodyGoodsUploadGoodsmedia {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_photo_file */
export interface BodyOzonProductCheckPhotoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_photos360_file */
export interface BodyOzonProductCheckPhotos360File {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_picturesample_file */
export interface BodyOzonProductCheckPicturesampleFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_videocover_file */
export interface BodyOzonProductCheckVideocoverFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_videos_file */
export interface BodyOzonProductCheckVideosFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_pdf */
export interface BodyOzonProductUploadPdf {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_photo */
export interface BodyOzonProductUploadPhoto {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_photo360 */
export interface BodyOzonProductUploadPhoto360 {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_picturesample */
export interface BodyOzonProductUploadPicturesample {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_video */
export interface BodyOzonProductUploadVideo {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_videocover */
export interface BodyOzonProductUploadVideocover {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_simple_product-check_photo_file */
export interface BodySimpleProductCheckPhotoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_simple_product-upload_photo */
export interface BodySimpleProductUploadPhoto {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-check_photo_file */
export interface BodyWildberriesProductCheckPhotoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-check_video_file */
export interface BodyWildberriesProductCheckVideoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-upload_photo_by_file */
export interface BodyWildberriesProductUploadPhotoByFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-upload_video */
export interface BodyWildberriesProductUploadVideo {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Brand */
export interface Brand {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование бренда
   * @maxLength 100
   */
  name?: string
  /**
   * Код бренда
   * @maxLength 100
   */
  code?: string
}

/**
 * BrandInputType
 * An enumeration.
 */
export enum BrandInputType {
  Fixed = 'fixed',
  Variable = 'variable',
}

/** BulkOperation */
export interface BulkOperation {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Systemuser Id */
  systemuser_id: number
  /** Bulkoperationtype Name */
  bulkoperationtype_name: string
  /** Bulkoperationtype Id */
  bulkoperationtype_id: number
  /** Source File Url */
  source_file_url?: string
  /** Result File Url */
  result_file_url?: string
  /** In Progress */
  in_progress: boolean
  /** Error */
  error: boolean
  /** Error Text */
  error_text?: string
  /** Record Count */
  record_count: number
  /** Error Count */
  error_count: number
  /** Total Count */
  total_count: number
  /** Object Type */
  object_type?: string
}

/** BulkOperationType */
export interface BulkOperationType {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Name */
  name: string
  /** Code */
  code?: string
  /** Description */
  description?: string
}

/** CardStatus */
export interface CardStatus {
  /** Код статуса */
  id: number
  /** Наименование статуса */
  name: string
}

/** Category */
export interface Category {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Название категории
   * @maxLength 255
   */
  name?: string
  /**
   * Код привилегии
   * @maxLength 100
   */
  code?: string
  /**
   * Родительская привилегия
   * @exclusiveMin 0
   */
  parentcategory_id?: number
  /** Информация всех категорий родительской категории */
  children?: Category[]
  /** Название категории вместе со всеми родителями */
  full_path?: string
}

/** CategoryAttribute */
export interface CategoryAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Category Id
   * Идентификатор категории
   */
  category_id?: number
  /**
   * Attribute Id
   * Идентификатор атрибута
   */
  attribute_id?: number
  /**
   * Attribute Name
   * Название атрибута
   */
  attribute_name?: string
  /**
   * Required
   * Признак обязательности
   * @default false
   */
  required?: boolean
  /**
   * Attributegroup Id
   * Группа атрибутов
   */
  attributegroup_id?: number
  /**
   * Order Number
   * Порядок отображения
   */
  order_number?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
}

/** ChangeLog */
export interface ChangeLog {
  /**
   * Change Date
   * @format date-time
   */
  change_date: string
  /** Changer */
  changer: string
  /**
   * Действие над объектом
   * @maxLength 255
   */
  operation: string
  /** Список изменений */
  changes: ChangeOperation[]
}

/** ChangeOperation */
export interface ChangeOperation {
  /**
   * Изменяемое поле
   * @maxLength 255
   */
  field: string
  /** Изменяемое значение */
  value?: string
  /**
   * URL на измененный объект
   * @maxLength 255
   */
  url?: string
}

/** ChangePriceTemplateFilters */
export interface ChangePriceTemplateFilters {
  /** Идентификатор компании */
  company_id?: number[]
  /** Идентификатор кабинета */
  companymarketplace_id?: number[]
  /** Идентификатор поставщика */
  contragent_id?: number[]
}

/** Classification */
export interface Classification {
  /** Abc Class */
  abc_class?: string
  /** Xyz Class */
  xyz_class?: string
  /**
   * Count
   * @min 0
   */
  count: number
}

/** CoPackingCostInfo */
export interface CoPackingCostInfo {
  /** Оператор услуги переупаковки */
  copacking_center: string
  /** Стоимость */
  cost?: number
  /**
   * Описание расчета
   * @maxLength 255
   */
  cost_description?: string
}

/** CommonAttribute */
export interface CommonAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Тип данных
   * @default "string"
   * @example "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /** Единица измерения для числового значения */
  unit_id?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Значения только из справочника
   * @default false
   */
  fixed_values?: boolean
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   * @example "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
  /**
   * Может использоваться в номенклатуре WB
   * @default false
   */
  wb_nomenclature_support?: boolean
  /**
   * Может использоваться в размере WB
   * @default false
   */
  wb_size_support?: boolean
  /**
   * Используется ли словарь
   * @default false
   */
  use_dictionary?: boolean
  /** Количество значений */
  number_of_values?: number
}

/** CommonEditAttribute */
export interface CommonEditAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Тип данных
   * @default "string"
   * @example "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /** Единица измерения для числового значения */
  unit_id?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Значения только из справочника
   * @default false
   */
  fixed_values?: boolean
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   * @example "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
  /**
   * Может использоваться в номенклатуре WB
   * @default false
   */
  wb_nomenclature_support?: boolean
  /**
   * Может использоваться в размере WB
   * @default false
   */
  wb_size_support?: boolean
  /**
   * Используется ли словарь
   * @default false
   */
  use_dictionary?: boolean
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /** Количество значений */
  number_of_values?: number
}

/** CommonNewAttribute */
export interface CommonNewAttribute {
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Тип данных
   * @default "string"
   * @example "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /** Единица измерения для числового значения */
  unit_id?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Значения только из справочника
   * @default false
   */
  fixed_values?: boolean
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   * @example "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
  /**
   * Может использоваться в номенклатуре WB
   * @default false
   */
  wb_nomenclature_support?: boolean
  /**
   * Может использоваться в размере WB
   * @default false
   */
  wb_size_support?: boolean
  /**
   * Используется ли словарь
   * @default false
   */
  use_dictionary?: boolean
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /** Количество значений */
  number_of_values?: number
}

/** CompanyMarketplace */
export interface CompanyMarketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  name?: string
}

/** Competitor */
export interface Competitor {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /** Наименование маркетплейса */
  marketplace_name: string
  /** URL на продукт */
  url?: string
  /**
   * Дата последнего сканирования
   * @format date-time
   */
  scanned?: string
  /** Наименование */
  name?: string
  /** Продавец */
  seller?: string
  /** Категория */
  category?: string
  /** Бренд */
  brand?: string
  /** Номенклатура МП */
  sku?: string
  /** Текущая цена */
  price?: number
  /** Фото продуктов */
  photo_base64?: string
  /** Статус */
  status?: number
  /**
   * Признак - тот же товар или аналог
   * @default false
   */
  analogue_flag?: boolean
  /** Остатки */
  stocks?: Stocks[]
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
  reckoned_flag?: boolean
  /** Рейтинг продукта */
  rating?: number
}

/** Contragent */
export interface Contragent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name?: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /** Рейтинг */
  rating?: number
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** В архиве */
  in_archive: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
  /**
   * Внешний ID для интеграций
   * @maxLength 255
   */
  external_id?: string
  /** Поставка в коробах */
  use_boxes: boolean
  /** Поставшика можно удалить */
  delete_possible: boolean
  /** Настройки бланка заказа по форме поставщика */
  orderform_template_settings?: OrderFormTemplateSettings
  /** Настройки столбцов загрузки исполнения заказа */
  invoice_template_settings?: InvoiceTemplateSettings
  /** Бренды товаров поставщика */
  brands?: ContragentBrand[]
  /** Менеджеры поставщика */
  users?: ContragentUser[]
}

/** ContragentBrand */
export interface ContragentBrand {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Название бренда */
  brand_name: string
}

/** ContragentShortInfo */
export interface ContragentShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name?: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /** Рейтинг */
  rating?: number
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** В архиве */
  in_archive: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
}

/** ContragentUser */
export interface ContragentUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Наименование пользователя */
  user_name: string
  /** Наименование контрагента */
  contragent_name: string
  /**
   * С даты
   * @format date-time
   */
  date_in: string
  /**
   * По дату
   * @format date-time
   */
  date_out?: string
}

/** CopyWBProduct */
export interface CopyWBProduct {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Признак, что следует использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Идентификатор продукта, который необходимо скопировать
   * @exclusiveMin 0
   */
  product_id: number
  /** Добавить как новый размер */
  new_variation?: boolean
}

/** Country */
export interface Country {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование страны
   * @maxLength 100
   */
  name?: string
  /**
   * Код страны
   * @maxLength 100
   */
  code?: string
}

/** CreateCopyCard */
export interface CreateCopyCard {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** CreateNewCard */
export interface CreateNewCard {
  /**
   * Код категории товара на Ozon
   * @exclusiveMin 0
   */
  category_id: number
  /**
   * Родительская категория товара на Ozon
   * @exclusiveMin 0
   */
  parent_category_id: number
}

/** CreateWBProduct */
export interface CreateWBProduct {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Признак, что следует использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /** Идентификатор категории на Wildberries */
  category_id: number
}

/** DeArchivationInfo */
export interface DeArchivationInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
}

/** DeletingObject */
export interface DeletingObject {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditAttribute */
export interface EditAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Значение или массив значений атрибута */
  value?: string[]
}

/** EditBarcode */
export interface EditBarcode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Штрих-код
   * @maxLength 100
   */
  barcode?: string
  /** Устаревший код */
  expired_flag?: boolean
}

/** EditCategory */
export interface EditCategory {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Название категории
   * @maxLength 255
   */
  name?: string
  /**
   * Код категории
   * @maxLength 100
   */
  code?: string
  /** Родительская категория */
  parentcategory_id?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditCategoryAttribute */
export interface EditCategoryAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Attribute Id
   * Идентификатор атрибута
   */
  attribute_id: number
  /**
   * Required
   * Признак обязательности
   * @default false
   */
  required?: boolean
  /**
   * Attributegroup Id
   * Группа атрибутов
   */
  attributegroup_id?: number
  /**
   * Order Number
   * Порядок отображения
   */
  order_number?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditContragentUser */
export interface EditContragentUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * По дату
   * @format date-time
   */
  date_out: string
}

/** EditGoods */
export interface EditGoods {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name?: string
  /**
   * Артикул
   * @maxLength 100
   */
  code?: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id?: number
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id?: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /**
   * Код ТН ВЭД
   * @maxLength 10
   */
  tnved_code?: string
  /**
   * Идентификатор страны производства
   * @exclusiveMin 0
   */
  made_in_country_id?: number
  /**
   * Идентификатор сезона
   * @exclusiveMin 0
   */
  season_id?: number
  /**
   * Ставка НДС
   * @min 0
   */
  vat_rate?: number
  /**
   * Ширина
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Идентификатор единицы измерения упаковки товара
   * @exclusiveMin 0
   */
  unit_id?: number
  /** Поставщики товара */
  suppliers?: EditGoodsSupplier[]
  /**
   * Товар, опубликованный на публичной витрине
   * @default false
   */
  is_public?: boolean
}

/** EditGoodsBundleComponent */
export interface EditGoodsBundleComponent {
  /**
   * Количество
   * @exclusiveMin 0
   */
  quantity: number
}

/** EditGoodsComponent */
export interface EditGoodsComponent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Компонент
   * @maxLength 100
   */
  component?: string
  /** Процентное содержание */
  percents?: number
}

/** EditGoodsFile */
export interface EditGoodsFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Порядковый номер */
  index_number?: number
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
}

/** EditGoodsMedia */
export interface EditGoodsMedia {
  /** Фотографии */
  photo?: EditGoodsFile[]
  /** Видео */
  video?: EditGoodsFile[]
  /** Разные документы */
  document?: EditGoodsFile[]
}

/** EditGoodsSupplier */
export interface EditGoodsSupplier {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Входящая цена */
  incoming_price?: number
  /** Рекомендуемая розничная цена */
  rrp?: number
  /** Коды товара поставщика */
  codes?: EditGoodsSupplierCode[]
  /** Виды упаковки товара поставщика */
  packaging?: EditGoodsSupplierPackaging[]
}

/** EditGoodsSupplierCode */
export interface EditGoodsSupplierCode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Код товара поставщика
   * @maxLength 100
   */
  code?: string
  /** Признак устаревшего кода */
  expired_flag?: boolean
}

/** EditGoodsSupplierPackaging */
export interface EditGoodsSupplierPackaging {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Код вида упаковки
   * @exclusiveMin 0
   */
  packagingtype_id?: number
  /**
   * Штрих-код упаковки
   * @maxLength 100
   */
  barcode?: string
  /**
   * Количество единиц товара в упаковке
   * @exclusiveMin 0
   */
  unit_quantity?: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /** Признак устаревшего кода */
  expired_flag?: boolean
}

/** EditMarketplaceWarehouse */
export interface EditMarketplaceWarehouse {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование склада маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /** Является ли РЦ приоритетным */
  priority?: boolean
}

/** EditMediaFile */
export interface EditMediaFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Порядковый номер */
  index_number?: number
}

/** EditOzonProduct */
export interface EditOzonProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Артикул продавца
   * @maxLength 255
   */
  offer_id?: string
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id?: number
  /**
   * Описание
   * @maxLength 10000
   */
  description?: string
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Новый короб */
  new_box?: boolean
  /**
   * Ставка НДС
   * Ставка НДС для товара:
   *
   * 0 — не облагается НДС,
   *
   * 0.1 — 10%,
   *
   * 0.2 — 20%.
   * @example 0.1
   */
  vat_rate?: VatRate
  /**
   * Ширина, мм.
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота, мм.
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина, мм.
   * @exclusiveMin 0
   */
  depth?: number
  /** Вес, г. */
  weight?: number
  /** Автосинхронизация с Ozon */
  sync_enabled?: boolean
  /** Автозаказ */
  auto_ordering?: boolean
  /** Атрибуты продукта Ozon */
  attributes?: EditOzonProductAttribute[]
  /** Фотографии товара */
  photos?: EditMediaFile[]
  /** Фотографии товара 360 градусов */
  photos360?: EditMediaFile[]
  /** Список PDF-файлов */
  pdf_list?: EditMediaFile[]
  /** Основной штрих-код */
  main_barcode_id?: number
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** EditOzonProductAttribute */
export interface EditOzonProductAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /** Значения атрибута */
  values: OzonProductAttributeValue[]
}

/** EditPriceSettings */
export interface EditPriceSettings {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /** Идентификатор продукта */
  product_id: number
  /** Минимально возможная цена */
  min_price?: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг - автоуправление ценой */
  auto_change_price?: boolean
  /** Периодичность проверки */
  advice_interval?: AdviceInterval
}

/** EditProduct */
export interface EditProduct {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
}

/** EditSimpleProduct */
export interface EditSimpleProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /**
   * Наименование категории
   * @maxLength 255
   */
  category_name?: string
  /**
   * Артикул продавца
   * @maxLength 255
   */
  code?: string
  /** Идентификатор продукта на маркетплейсе */
  sku?: string
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Новый короб */
  new_box?: boolean
  /** Автозаказ */
  auto_ordering?: boolean
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Комментарий к операции */
  comments?: string
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
}

/** EditSizeInfo */
export interface EditSizeInfo {
  /**
   * Значение
   * @exclusiveMin 0
   */
  value: number
}

/** EditValue */
export interface EditValue {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Название значения
   * @maxLength 255
   */
  name?: string
  /**
   * Код значения
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditWBMedia */
export interface EditWBMedia {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Порядковый номер */
  index_number?: number
  /** Медиа общее для всех номенклатур */
  media_global: boolean
}

/** EditWBNomenclature */
export interface EditWBNomenclature {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /** Описание общее для всех номенклатур */
  description_global?: boolean
  /** Идентификатор номенклатуры WB */
  nmid?: number
  /**
   * Артикул товара
   * @maxLength 255
   */
  vendor_code?: string
  /**
   * Наименование товара
   * @maxLength 100
   */
  name?: string
  /** Наименование общее для всех номенклатур */
  name_global?: boolean
  /** Цвета */
  colors?: string[]
  /**
   * Бренд
   * @maxLength 255
   */
  brand?: string
  /** Бренд общий для всех номенклатур */
  brand_global?: boolean
  /**
   * Код ТН ВЭД
   * @maxLength 20
   */
  tnved_code?: string
  /** ТН ВЭД общий для всех номенклатур */
  tnved_global?: boolean
  /**
   * Страна производства
   * @maxLength 255
   */
  countryproduction?: string
  /** Страна производства общий для всех номенклатур */
  countryproduction_global?: boolean
  /** Атрибуты товара */
  attributes?: EditWBNomenclatureAttribute[]
  /** Ширина */
  width?: EditSizeInfo
  /** Высота */
  height?: EditSizeInfo
  /** Глубина */
  depth?: EditSizeInfo
  /** Вес */
  weight?: EditSizeInfo
  /** Размеры и вес общие для всех номенклатур */
  sizeinfo_global?: boolean
  /** Идентификатор требований по упаковке */
  packagingrequirement_id?: number
  /** Новый короб */
  new_box?: boolean
  /** Размеры номенклатуры товара */
  variations?: EditWBVariation[]
  /** Фотографии товара */
  photos?: EditWBMedia[]
  /** Видео ролики товара */
  video?: EditWBMedia
}

/** EditWBNomenclatureAttribute */
export interface EditWBNomenclatureAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /** Значения */
  values?: string[]
  /** Атрибут общий для всех номенклатур */
  attribute_global: boolean
}

/** EditWBVariation */
export interface EditWBVariation {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Размер
   * @maxLength 255
   */
  size?: string
  /**
   * Рос размер
   * @maxLength 255
   */
  ru_size?: string
  /** Автозаказ */
  auto_ordering?: boolean
  /** Автосинхронизация с WB */
  sync_enabled?: boolean
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: EditBarcode[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** EditingBrand */
export interface EditingBrand {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование бренда
   * @maxLength 100
   */
  name?: string
  /**
   * Код бренда
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditingCompetitor */
export interface EditingCompetitor {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
  reckoned_flag?: boolean
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditingContragent */
export interface EditingContragent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name?: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /**
   * Внешний ID для интеграций
   * @maxLength 255
   */
  external_id?: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** Поставка в коробах */
  use_boxes?: boolean
  /** Настройки бланка заказа по форме поставщика */
  orderform_template_settings?: OrderFormTemplateSettings
  /** Настройки столбцов загрузки исполнения заказа */
  invoice_template_settings?: InvoiceTemplateSettings
  /** В архиве */
  in_archive: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
}

/** EditingSeason */
export interface EditingSeason {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование сезона
   * @maxLength 100
   */
  name?: string
  /**
   * Код сезона
   * @maxLength 100
   */
  code?: string
  /**
   * Дата начала сезона
   * @format date-time
   */
  date_from: string
  /**
   * Дата конца сезона
   * @format date-time
   */
  date_to: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Код ошибки */
  code?: number
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  message?: string
}

/** Goods */
export interface Goods {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Наименование бренда
   * @maxLength 100
   */
  brand: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id: number
  /**
   * Название категории
   * @maxLength 255
   */
  category: string
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id: number
  /** Тип товара */
  goods_type: GoodsType
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /**
   * Код ТН ВЭД
   * @maxLength 10
   */
  tnved_code?: string
  /** Наименование кода ТН ВЭД */
  tnved?: string
  /**
   * Страна производства
   * @maxLength 100
   */
  made_in_country?: string
  /**
   * Идентификатор страны производства
   * @exclusiveMin 0
   */
  made_in_country_id?: number
  /**
   * Ставка НДС
   * @min 0
   */
  vat_rate?: number
  /**
   * Ширина
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Единица измерения упаковки товара
   * @maxLength 100
   */
  unit?: string
  /**
   * Идентификатор единицы измерения упаковки товара
   * @exclusiveMin 0
   */
  unit_id?: number
  /**
   * Признак делимости единицы измерения
   * @default false
   */
  unit_partible?: boolean
  /**
   * Сезон
   * @maxLength 100
   */
  season?: string
  /**
   * Идентификатор сезона
   * @exclusiveMin 0
   */
  season_id?: number
  /**
   * Идентификатор основного изображения товара
   * @example 1001
   */
  goods_base_image_id?: number
  /** Поставщики товара */
  suppliers?: GoodsSupplier[]
  /** Есть состав */
  has_content: boolean
  /** Информация о вариациях товара */
  variations?: VariationInfo
  /** Другие товары, входящие в товар */
  components?: AppPimGoodsModelsGoodsGoodsComponent[]
  /** Список атрибутов товара */
  attributes?: Attribute[]
  /** Для ассортиментной карточки другие товары, входящие в ассортимент */
  assortment?: GoodsAssortment[]
  /** Список спаек, созданных из товара */
  exists_in_packs?: RelatedGoodsInfo[]
  /** Список комплектов, в которые входит товар */
  exists_in_bundles?: RelatedGoodsInfo[]
  /** Информация об ассортиментной карточке, если товар входит в ассортимент */
  exists_in_assortment?: RelatedGoodsInfo
  /** Идентификатор во внешней системе */
  external_id?: string
  /** Возможно или нет удаление данного товара */
  delete_possible: boolean
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /**
   * Товар, опубликованный на публичной витрине
   * @default false
   */
  is_public?: boolean
}

/** GoodsAssortment */
export interface GoodsAssortment {
  /** Идентификатор товара, входящего в ассортимент */
  goods_id: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор основного изображения товара
   * @example 1001
   */
  goods_base_image_id?: number
  /** Тип товара */
  goods_type: GoodsType
}

/** GoodsAttribute4Variation */
export interface GoodsAttribute4Variation {
  /** Идентификатор атрибута */
  attribute_id: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id: number
}

/** GoodsAttributes */
export interface GoodsAttributes {
  /** Список сгруппированных атрибутов */
  groups?: AttributeGroup[]
  /** Список атрибутов не входящих в группы */
  attributes?: Attribute[]
}

/** GoodsFile */
export interface GoodsFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Порядковый номер */
  index_number: number
  /**
   * Имя файла
   * @maxLength 255
   */
  file_name?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Путь для скачивания
   * @maxLength 255
   */
  download_url: string
}

/** GoodsMedia */
export interface GoodsMedia {
  /** Фотографии */
  photo: GoodsFile[]
  /** Видео */
  video: GoodsFile[]
  /** Разные документы */
  document: GoodsFile[]
}

/** GoodsProduct */
export interface GoodsProduct {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name?: string
  /**
   * Артикул товара на маркетплейсе
   * @maxLength 255
   */
  marketplace_code?: string
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  marketplace_name: string
  /**
   * Наименование категории продукта на маркетплейсе
   * @maxLength 255
   */
  marketplace_category?: string
  /**
   * Ссылка на товар на маркетплейсе
   * @maxLength 255
   */
  marketplace_url?: string
  /**
   * Наименование компании продавца на маркетплейсе
   * @maxLength 255
   */
  company_name: string
  /** Наименование кабинета */
  cabinet_name: string
  /** Цена */
  price?: number
  /** Штрих-код продукта */
  barcode?: string
  /** Признак - в архиве */
  in_archive: boolean
  /** Признак автозаказа */
  auto_ordering: boolean
}

/** GoodsShortInfo */
export interface GoodsShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Наименование бренда
   * @maxLength 100
   */
  brand: string
  /**
   * Название категории
   * @maxLength 255
   */
  category: string
  /**
   * Идентификатор основного изображения товара
   * @example 1001
   */
  goods_base_image_id?: number
  /** Тип товара */
  goods_type: GoodsType
  /**
   * Код единицы измерения упаковки товара
   * @maxLength 100
   */
  unit_code?: string
  /**
   * Признак делимости единицы измерения
   * @default false
   */
  unit_partible?: boolean
}

/** GoodsSupplier */
export interface GoodsSupplier {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /**
   * Changed
   * @format date-time
   */
  changed?: string
  /** Changer */
  changer?: string
  /**
   * Наименование контрагента
   * @maxLength 100
   */
  contragent: string
  /** Код контрагента */
  contragent_id: number
  /** Входящая цена */
  incoming_price?: number
  /** Рекомендуемая розничная цена */
  rrp?: number
  /** Коды товара поставщика */
  codes?: GoodsSupplierCode[]
  /** Виды упаковки товара поставщика */
  packaging?: GoodsSupplierPackaging[]
}

/** GoodsSupplierCode */
export interface GoodsSupplierCode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Код товара поставщика
   * @maxLength 100
   */
  code: string
  /** Признак устаревшего кода */
  expired_flag: boolean
}

/** GoodsSupplierPackaging */
export interface GoodsSupplierPackaging {
  /**
   * Id
   * @exclusiveMin 0
   * @example 100
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Название вида упаковки
   * @maxLength 10
   */
  packagingtype: string
  /**
   * Код вида упаковки
   * @exclusiveMin 0
   */
  packagingtype_id: number
  /** Штрих-код обязателен */
  barcode_required: boolean
  /**
   * Штрих-код упаковки
   * @maxLength 100
   */
  barcode?: string
  /**
   * Количество единиц товара в упаковке
   * @exclusiveMin 0
   */
  unit_quantity: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /** Признак устаревшего кода */
  expired_flag: boolean
}

/**
 * GoodsType
 * An enumeration.
 */
export enum GoodsType {
  Common = 'common',
  Bundle = 'bundle',
  Pack = 'pack',
  Assortment = 'assortment',
}

/** GoodsVariation */
export interface GoodsVariation {
  /** Список значений атрибутов вариации */
  attributes: GoodsVariationAttribute[]
  /** Идентификатор товара */
  goods_id: number
}

/** GoodsVariationAttribute */
export interface GoodsVariationAttribute {
  /**
   * Наименование атрибута
   * @maxLength 255
   */
  attribute_name: string
  /**
   * Значение атрибута
   * @maxLength 255
   */
  value: string
  /** Порядковый номер */
  order_number: number
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** ImportMessage */
export interface ImportMessage {
  /**
   * Текст сообщения
   * @maxLength 255
   */
  text?: string
  /** Тип сообщения */
  type: MessageEnum
}

/** ImportMetadata */
export interface ImportMetadata {
  /** Тип ответа */
  response_type: ModalEnum
  /** Сообщения для пользователя */
  messages: ImportMessage[]
  /** Идентификатор массовой операции */
  batch_id?: string
  /** Ссылка на файл с результатом массовой операции */
  file_url?: string
}

/** InvoiceTemplateSettings */
export interface InvoiceTemplateSettings {
  /** Номер столбца с количеством товара */
  quantity_column?: number
  /** Номер столбца с кодом поставшика */
  supplier_code_column?: number
  /** Номер столбца с ценой товара */
  price_column?: number
}

/** Marketplace */
export interface Marketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
}

/** MarketplaceWarehouse */
export interface MarketplaceWarehouse {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование склада маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /** Является ли РЦ приоритетным */
  priority?: boolean
}

/** MediaFile */
export interface MediaFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Порядковый номер */
  index_number: number
  /**
   * Имя файла
   * @maxLength 255
   */
  file_name?: string
  /**
   * Путь для скачивания
   * @maxLength 255
   */
  download_url?: string
}

/** MediaURL */
export interface MediaURL {
  /** Адрес в Интернет */
  url: string
}

/**
 * MessageEnum
 * An enumeration.
 */
export enum MessageEnum {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

/**
 * ModalEnum
 * An enumeration.
 */
export enum ModalEnum {
  SuccessfulImport = 'successful_import',
  PartialSuccessfulImport = 'partial_successful_import',
  FailedImport = 'failed_import',
  WrongTemplate = 'wrong_template',
}

/** NewAttribute */
export interface NewAttribute {
  /** Идентификатор атрибута */
  attribute_id: number
  /** Значение или массив значений атрибута */
  value?: string[]
}

/** NewBarcode */
export interface NewBarcode {
  /**
   * Штрих-код
   * @maxLength 100
   */
  barcode: string
}

/** NewBrand */
export interface NewBrand {
  /**
   * Наименование бренда
   * @maxLength 100
   */
  name: string
  /**
   * Код бренда
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewCategory */
export interface NewCategory {
  /**
   * Название категории
   * @maxLength 255
   */
  name: string
  /**
   * Код категории
   * @maxLength 100
   */
  code?: string
  /**
   * Родительская категория
   * @exclusiveMin 0
   */
  parentcategory_id?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewCategoryAttribute */
export interface NewCategoryAttribute {
  /**
   * Attribute Id
   * Идентификатор атрибута
   */
  attribute_id?: number
  /**
   * Required
   * Признак обязательности
   * @default false
   */
  required?: boolean
  /**
   * Attributegroup Id
   * Группа атрибутов
   */
  attributegroup_id?: number
  /**
   * Order Number
   * Порядок отображения
   */
  order_number?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewCompetitor */
export interface NewCompetitor {
  /**
   * URL на продукт
   * @maxLength 1024
   */
  url?: string
  /** Идентификатор маркетплейса */
  marketplace_id?: number
  /** Номенклатура МП */
  sku?: string
  /** Признак - тот же товар или аналог */
  analogue_flag: boolean
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
  reckoned_flag?: boolean
}

/** NewContragent */
export interface NewContragent {
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /**
   * Внешний ID для интеграций
   * @maxLength 255
   */
  external_id?: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** Поставка в коробах */
  use_boxes?: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
}

/** NewContragentBrand */
export interface NewContragentBrand {
  /** Идентификатор бренда */
  brand_id: number
}

/** NewContragentUser */
export interface NewContragentUser {
  /** Идентификатор пользователя */
  user_id: number
  /**
   * С даты
   * @format date-time
   */
  date_in?: string
  /**
   * По дату
   * @format date-time
   */
  date_out?: string
}

/** NewGoods */
export interface NewGoods {
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id: number
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /**
   * Код ТН ВЭД
   * @maxLength 10
   */
  tnved_code?: string
  /**
   * Идентификатор страны производства
   * @exclusiveMin 0
   */
  made_in_country_id?: number
  /**
   * Ставка НДС
   * @min 0
   */
  vat_rate?: number
  /**
   * Ширина
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Идентификатор единицы измерения упаковки товара
   * @exclusiveMin 0
   */
  unit_id?: number
  /** Поставщики товара */
  suppliers?: NewGoodsSupplier[]
  /** Другие товары, входящие в товар */
  components?: NewGoodsBundleComponent[]
  /** Для ассортиментной карточки другие товары, входящие в ассортимент */
  assortment?: NewGoodsAssortment[]
  /**
   * Идентификатор сезона
   * @exclusiveMin 0
   */
  season_id?: number
  /**
   * Товар, опубликованный на публичной витрине
   * @default false
   */
  is_public?: boolean
  /** Комментарий к операции */
  comments?: string
}

/** NewGoodsAssortment */
export interface NewGoodsAssortment {
  /** Идентификатор товара, входящего в ассортимент */
  goods_id: number
}

/** NewGoodsBundleComponent */
export interface NewGoodsBundleComponent {
  /** Идентификатор товара, входящего в набор */
  component_id: number
  /**
   * Количество
   * @exclusiveMin 0
   */
  quantity: number
}

/** NewGoodsComponent */
export interface NewGoodsComponent {
  /**
   * Компонент
   * @maxLength 100
   */
  component: string
  /** Процентное содержание */
  percents?: number
}

/** NewGoodsFileURL */
export interface NewGoodsFileURL {
  /**
   * Адрес в Интернет
   * @maxLength 4000
   */
  url?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
}

/** NewGoodsPack */
export interface NewGoodsPack {
  /**
   * Идентификатор товара, из которого создается спайка
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Количество товаров в спайке
   * @exclusiveMin 0
   */
  quantity: number
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
}

/** NewGoodsSupplier */
export interface NewGoodsSupplier {
  /** Код контрагента */
  contragent_id: number
  /** Входящая цена */
  incoming_price?: number
  /** Рекомендуемая розничная цена */
  rrp?: number
  /** Коды товара поставщика */
  codes?: NewGoodsSupplierCode[]
  /** Виды упаковки товара поставщика */
  packaging?: NewGoodsSupplierPackaging[]
}

/** NewGoodsSupplierCode */
export interface NewGoodsSupplierCode {
  /**
   * Код товара поставщика
   * @maxLength 100
   */
  code: string
}

/** NewGoodsSupplierPackaging */
export interface NewGoodsSupplierPackaging {
  /**
   * Код вида упаковки
   * @exclusiveMin 0
   */
  packagingtype_id: number
  /**
   * Штрих-код упаковки
   * @maxLength 100
   */
  barcode?: string
  /**
   * Количество единиц товара в упаковке
   * @exclusiveMin 0
   */
  unit_quantity: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
}

/** NewGoodsVariation */
export interface NewGoodsVariation {
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /** Копировать медиа данные из базового товара */
  copy_media: boolean
  /** Список атрибутов вариации и их значений */
  variation_attributes: NewGoodsVariationAttribute[]
}

/** NewGoodsVariationAttribute */
export interface NewGoodsVariationAttribute {
  /** Идентификатор атрибута */
  attribute_id: number
  /** Порядковый номер */
  order_number: number
  /**
   * Значение атрибута
   * @maxLength 255
   */
  value: string
}

/** NewOzonProductRequest */
export interface NewOzonProductRequest {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /** Card */
  card:
    | CreateCopyCard
    | CreateNewCard
    | UseExistingCard
    | (CreateCopyCard & CreateNewCard & UseExistingCard)
}

/** NewOzonProductResponse */
export interface NewOzonProductResponse {
  /**
   * Идентификатор созданного продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** NewSeason */
export interface NewSeason {
  /**
   * Наименование сезона
   * @maxLength 100
   */
  name?: string
  /**
   * Код сезона
   * @maxLength 100
   */
  code?: string
  /**
   * Дата начала сезона
   * @format date-time
   */
  date_from: string
  /**
   * Дата конца сезона
   * @format date-time
   */
  date_to: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewSimpleProductRequest */
export interface NewSimpleProductRequest {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /**
   * Артикул продавца
   * @maxLength 255
   */
  code?: string
  /** Категория продукта */
  category: string
  /** Идентификатор продукта на маркетплейсе */
  sku?: string
  /** Цена */
  price?: number
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /**
   * Новый короб
   * @default false
   */
  new_box?: boolean
  /**
   * Автозаказ
   * @default true
   */
  auto_ordering?: boolean
  /** Комментарий к операции */
  comments?: string
  /** Использовать медиа товара */
  use_goods_media: boolean
}

/** NewSimpleProductResponse */
export interface NewSimpleProductResponse {
  /**
   * Идентификатор созданного продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** NewUserContragent */
export interface NewUserContragent {
  /** Идентификатор контрагента */
  contragent_id: number
  /**
   * С даты
   * @format date-time
   */
  date_in?: string
  /**
   * По дату
   * @format date-time
   */
  date_out?: string
}

/** NewValue */
export interface NewValue {
  /**
   * Название значения
   * @maxLength 255
   */
  name: string
  /**
   * Код значения
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewWBProductResponse */
export interface NewWBProductResponse {
  /**
   * Идентификатор созданного продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** NotAuthenticatedError */
export interface NotAuthenticatedError {
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  detail?: string
}

/** OrderFormTemplateSettings */
export interface OrderFormTemplateSettings {
  /**
   * Имя файла шаблона
   * @maxLength 255
   */
  file_name: string
  /**
   * Ссылка на файл шаблона
   * @maxLength 255
   */
  template_url?: string
  /** Номер столбца с количеством товара */
  quantity_column?: number
  /** Номер столбца с кодом поставшика */
  supplier_code_column?: number
  /** Номер столбца с количеством товара в коробе */
  quantity_in_box_column?: number
}

/** OtherNomenclature */
export interface OtherNomenclature {
  /** Идентификатор продукта */
  product_id?: number
  /** Идентификатор номенклатуры WB */
  nmid?: number
  /**
   * Артикул товара
   * @maxLength 255
   */
  vendor_code?: string
  /** Признак - в архиве */
  in_archive: boolean
}

/** OzonPriceDetail */
export interface OzonPriceDetail {
  /**
   * Цена
   * @min 0
   */
  price: number
  /**
   * Цена до скидки
   * @min 0
   */
  old_price?: number
}

/** OzonPriceHistory */
export interface OzonPriceHistory {
  /** Начальная цена диапазона истории цен */
  start_price?: number
  /** Начальный цена до скидки */
  start_old_price?: number
  /** Список изменений цен */
  history: AppPimProductsOzonModelsProductOzonPriceInfo[]
}

/** OzonProduct */
export interface OzonProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Идентификатор продукта на Ozon
   * @exclusiveMin 0
   */
  ozon_product_id?: number
  /**
   * Артикул продавца
   * @maxLength 255
   */
  offer_id?: string
  /**
   * Новый артикул продавца
   * @maxLength 255
   */
  new_offer_id?: string
  /**
   * Идентификатор Ozon для FBO
   * @exclusiveMin 0
   */
  sku_fbs?: number
  /**
   * Идентификатор Ozon для FBS
   * @exclusiveMin 0
   */
  sku_fbo?: number
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id?: number
  /**
   * Наименование категории
   * @maxLength 255
   */
  category_name?: string
  /**
   * Идентификатор родительской категории
   * @exclusiveMin 0
   */
  parent_category_id?: number
  /**
   * Наименование родительской категории
   * @maxLength 255
   */
  parent_category_name?: string
  /** Цена */
  price?: number
  /** Цена на товар с учетом всех акций. Это значение будет указано на витрине Ozon. */
  marketing_price?: number
  /** Цена на товар с учетом акций продавца. */
  marketing_seller_price?: number
  /** Цена до скидки */
  old_price?: number
  /** Запланированные изменения цен */
  future_prices?: AppPimProductsOzonModelsProductOzonPriceInfo[]
  /** История изменения цен */
  price_recent_history?: PriceHistory[]
  /**
   * Ссылка на товар на маркетплейсе
   * @maxLength 255
   */
  marketplace_url?: string
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /** Поле с брендом доступно для заполнения */
  brand_available: boolean
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id?: number
  /**
   * Наименования бренда
   * @maxLength 255
   */
  brand_name?: string
  /**
   * Описание
   * @maxLength 10000
   */
  description?: string
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: Barcode[]
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Требования по упаковке */
  packagingrequirement?: string
  /**
   * Требования по упаковке у маркетплейса
   * @maxLength 100
   */
  base_packagingrequirement?: string
  /** Новый короб */
  new_box: boolean
  /**
   * Ставка НДС
   * Ставка НДС для товара:
   *
   * 0 — не облагается НДС,
   *
   * 0.1 — 10%,
   *
   * 0.2 — 20%.
   * @example 0.1
   */
  vat_rate?: VatRate
  /**
   * Ширина, мм.
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота, мм.
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина, мм.
   * @exclusiveMin 0
   */
  depth?: number
  /** Вес, г. */
  weight?: number
  /** Объёмный вес */
  volume_weight?: number
  /** Автосинхронизация с Ozon */
  sync_enabled: boolean
  /** Автозаказ */
  auto_ordering: boolean
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Ozon */
  sync_status: StatusInfo
  /** Возможность синхронизации c Ozon */
  sync_possible: boolean
  /** Доступность изменения карточки */
  edit_possible: boolean
  /** Атрибуты продукта Ozon */
  attributes: OzonProductAttribute[]
  /** Фотографии товара */
  photos?: MediaFile[]
  /** Фотографии товара 360 градусов */
  photos360?: MediaFile[]
  /** Список PDF-файлов */
  pdfs?: MediaFile[]
  /** Видео товара */
  videos?: MediaFile[]
  /** Видео с обложкой */
  videocover?: MediaFile
  /** Образец цвета */
  picture?: MediaFile
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /** Классификация продукта по ABC анализу */
  abc_class?: string
  /** Стоимость упаковки */
  copacking_cost_info?: CoPackingCostInfo[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** OzonProductAttribute */
export interface OzonProductAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /**
   * Идентификатор атрибута Ozon
   * @exclusiveMin 0
   */
  ozon_attribute_id: number
  /**
   * Наименование атрибута Ozon
   * @maxLength 255
   */
  name: string
  /**
   * Тип атрибута
   * @maxLength 100
   */
  type?: string
  /**
   * Описание
   * @maxLength 4000
   */
  description?: string
  /**
   * Идентификатор группы
   * @min 0
   */
  group_id?: number
  /**
   * Название группы
   * @maxLength 100
   */
  group_name?: string
  /** Признак нескольких значений */
  is_collection: boolean
  /** Признак обязательности */
  is_required: boolean
  /** Использовать словарь для значений */
  is_dictionary: boolean
  /** Используется для объединение в вариации */
  is_aspect?: boolean
  /** Максимальное допустимое число значений */
  max_value_count?: number
  /** Признак. Зависит ли значения атрибута от категории */
  category_dependent?: boolean
  /** Значения атрибута */
  values: OzonProductAttributeValue[]
}

/** OzonProductAttributeValue */
export interface OzonProductAttributeValue {
  /** Значение */
  value?: string
  /** Идентификатор значения Ozon */
  dictionary_value_id?: number
  /**
   * Устаревшее значение
   * @default false
   */
  obsolete?: boolean
}

/** OzonProductStatus */
export interface OzonProductStatus {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Ozon */
  sync_status: StatusInfo
  /** Возможность синхронизации c Ozon */
  sync_possible: boolean
}

/** OzonProductUpdateData */
export interface OzonProductUpdateData {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Ozon */
  sync_status: StatusInfo
  /** Возможность синхронизации c Ozon */
  sync_possible: boolean
  /** Sku Fbs */
  sku_fbs: number
  /** Sku Fbo */
  sku_fbo: number
  /** Product Id */
  product_id: number
  /** Marketplace Url */
  marketplace_url: string
}

/**
 * OzonTemplateType
 * An enumeration.
 */
export enum OzonTemplateType {
  ByCategory = 'by_category',
  Minimal = 'minimal',
}

/** PIMUser */
export interface PIMUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /** Имя пользователя */
  name: string
}

/** PIMWBPriceDetail */
export interface PIMWBPriceDetail {
  /** Запланированные изменения цен */
  future_prices?: AppPimProductsWbModelsCardWBPriceInfo[]
  /** Информация об СПП */
  spp?: SPP
  /** Цена */
  price?: number
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
}

/** PackagingRequirement */
export interface PackagingRequirement {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование требования к упаковке
   * @maxLength 100
   */
  name?: string
  /**
   * Код требования к упаковке
   * @maxLength 100
   */
  code?: string
  /**
   * Описание требования к упаковке
   * @maxLength 255
   */
  description?: string
}

/** PackagingType */
export interface PackagingType {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование вида упаковки
   * @maxLength 100
   */
  name?: string
  /**
   * Код вида упаковки
   * @maxLength 100
   */
  code?: string
  /** Штрих-код обязателен */
  barcode_required: boolean
}

/**
 * PriceAdvice
 * Совет по изменению цены
 */
export interface PriceAdvice {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Дата расчета рекомендуемой цены
   * @format date-time
   */
  advice_date: string
  /** Рекомендуемая цена */
  advice_price: number
  /** Рекомендуемая цена без скидок */
  advice_price_without_discount: number
  /** Детализация по цене в зависимости он маркетплейса */
  advice_price_detail?:
    | WBPriceDetail
    | OzonPriceDetail
    | (WBPriceDetail & OzonPriceDetail)
  /** Новая маржинальность */
  new_margin?: number
  /** Новая маржинальность в процентах */
  new_margin_percents?: number
  /** Цена была установлена */
  price_set: boolean
  /** Рекомендация отклонена */
  declined: boolean
  /** Обоснование для изменения цены */
  comments?: string
}

/**
 * PriceAdviceAction
 * Возможные действия, совершаемые над рекомендованной ценой
 */
export enum PriceAdviceAction {
  Approve = 'approve',
  Declined = 'declined',
}

/** PriceAdviceIdentifier */
export interface PriceAdviceIdentifier {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Дата расчета рекомендуемой цены
   * @format date-time
   */
  advice_date: string
}

/** PriceChangeReason */
export interface PriceChangeReason {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование причины изменения цены
   * @maxLength 100
   */
  name: string
  /**
   * Код причины изменения цены
   * @maxLength 100
   */
  code?: string
}

/** PriceHistory */
export interface PriceHistory {
  /**
   * Дата цены
   * @format date-time
   */
  date: string
  /** Цена */
  price: number
}

/** PriceInfo */
export interface PriceInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date: string
  /** Цена */
  price?: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id: number
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Путь к файлу при массовой загрузке */
  batch_file_url?: string
}

/**
 * PriceSettings
 * Информация о настройках политики изменения цены
 */
export interface PriceSettings {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /** Идентификатор продукта */
  product_id: number
  /**
   * Начало действия настройки
   * @format date-time
   */
  settings_date?: string
  /** Минимально возможная цена */
  min_price?: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг - автоуправление ценой */
  auto_change_price?: boolean
  /** Периодичность проверки */
  advice_interval?: AdviceInterval
}

/** Product */
export interface Product {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
  /** Номер (описание) продукта */
  product_number: string
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор компании поставщика
   * @exclusiveMin 0
   */
  company_id: number
  /**
   * Идентификатор маркетплейса
   * @exclusiveMin 0
   */
  marketplace_id: number
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Количество активных конкурентов
   * @min 0
   * @default 0
   */
  active_competitors?: number
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** ProductArchivationInfo */
export interface ProductArchivationInfo {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
}

/** ProductDeArchivationInfo */
export interface ProductDeArchivationInfo {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
}

/**
 * ProductMethod
 * An enumeration.
 */
export enum ProductMethod {
  Sync = 'sync',
  CreateCard = 'create_card',
  MoveToCard = 'move_to_card',
  ChangeCategory = 'change_category',
  ChangeOfferid = 'change_offerid',
  CopyMedia = 'copy_media',
  CopyProduct = 'copy_product',
  AddToBasket = 'add_to_basket',
  DeleteFromBasket = 'delete_from_basket',
  ClearCard = 'clear_card',
}

/**
 * ProductPriceAdviceWithSettings
 * Информация о рекомендации изменения цены с настройками и продуктом
 */
export interface ProductPriceAdviceWithSettings {
  /** Идентификатор продукта */
  product_id: number
  /**
   * Дата расчета рекомендуемой цены
   * @format date-time
   */
  advice_date: string
  /** Рекомендуемая цена */
  advice_price: number
  /** Рекомендуемая цена без скидок */
  advice_price_without_discount: number
  /** Детализация по цене в зависимости он маркетплейса */
  advice_price_detail?:
    | WBPriceDetail
    | OzonPriceDetail
    | (WBPriceDetail & OzonPriceDetail)
  /** Новая маржинальность */
  new_margin?: number
  /** Новая маржинальность в процентах */
  new_margin_percents?: number
  /** Цена была установлена */
  price_set: boolean
  /** Рекомендация отклонена */
  declined: boolean
  /** Обоснование для изменения цены */
  comments?: string
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /**
   * Начало действия настройки
   * @format date-time
   */
  settings_date?: string
  /** Минимально возможная цена */
  min_price?: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг - автоуправление ценой */
  auto_change_price?: boolean
  /** Периодичность проверки */
  advice_interval?: AdviceInterval
  /** Код продукта */
  sku: string
  /** Ссылка на продукт на маркетплейсе */
  marketplace_url?: string
  /** Идентификатор маркетплейса */
  marketplace_id: string
  /** Наименование продукта */
  name: string
  /** Сумма остатков на складах */
  stocks: number
  /** Текущая цена */
  price?: number
  /** Текущая маржинальность */
  margin?: number
  /** Относительная текущая маржинальность */
  margin_percents?: number
  /** Количество заказов за день */
  per_day?: number
  /** Входящая цена товара */
  incoming_price?: number
}

/** ProductPriceHistory */
export interface ProductPriceHistory {
  /** Идентификатор продукта */
  product_id: number
  /** Название продукта */
  name: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /** Название маркетплейса */
  marketplace_name: string
  /** Ссылка на продукт на маркетплейсе */
  marketplace_url?: string
  /** Номенклатура продукта на маркетплейсе */
  marketplace_code?: string
  /** Входящая цена товара */
  incoming_price?: number
  /** Рекомендованная розничная цена */
  rrp?: number
  /** Стоимость упаковки товара */
  copacking_cost?: number
  /** Пользователь, внесший изменение */
  changer: string
  /**
   * Дата цены
   * @format date-time
   */
  change_date: string
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Детализация изменения цены */
  price_info:
    | AppPimProductsCommonModelsProductpricehistoryOzonPriceInfo
    | AppPimProductsCommonModelsProductpricehistoryWBPriceInfo
    | SimpleProductPriceInfo
}

/** ProductProfitability */
export interface ProductProfitability {
  /** Наименование склада */
  marketplacewarehouse_name: string
  /** Доходность, руб */
  profit_fbo: number
  /** Доходность, % */
  per_profit_fbo: number
  /** Признак приоритетности */
  priority: boolean
  /**
   * Дата последнего обновления
   * @format date-time
   */
  update: string
}

/** ProductSumProfitability */
export interface ProductSumProfitability {
  /** Наименование склада */
  product_id: number
  /** Доходность, руб */
  incoming_price: number
  /** Доходность, % */
  copackingcost: number
  /**
   * Дата последнего обновления
   * @format date-time
   */
  min_date: string
  /**
   * Дата последнего обновления
   * @format date-time
   */
  max_date: string
  /** Дата последнего обновления */
  count: number
  /** Дата последнего обновления */
  sum_ppvz_for_pay: number
  /** Дата последнего обновления */
  sum_delivery_rub: number
  /** Дата последнего обновления */
  sum_incoming_price: number
  /** Дата последнего обновления */
  sum_copackingcost: number
  /** Дата последнего обновления */
  var_rate: number
  /** Дата последнего обновления */
  profit: number
}

/**
 * ProductWithPriceSettings
 * Информация о продукте и его политике управления ценой
 */
export interface ProductWithPriceSettings {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /** Идентификатор продукта */
  product_id: number
  /**
   * Начало действия настройки
   * @format date-time
   */
  settings_date?: string
  /** Минимально возможная цена */
  min_price?: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг - автоуправление ценой */
  auto_change_price?: boolean
  /** Периодичность проверки */
  advice_interval?: AdviceInterval
  /** Код продукта */
  sku: string
  /** Ссылка на продукт на маркетплейсе */
  marketplace_url?: string
  /** Идентификатор маркетплейса */
  marketplace_id: string
  /** Наименование продукта */
  name: string
  /** Сумма остатков на складах */
  stocks: number
  /** Текущая цена */
  price?: number
  /** Текущая маржинальность */
  margin?: number
  /** Относительная текущая маржинальность */
  margin_percents?: number
  /** Количество заказов за день */
  per_day?: number
  /** Входящая цена товара */
  incoming_price?: number
}

/**
 * ProductsBulkMethod
 * An enumeration.
 */
export enum ProductsBulkMethod {
  Archive = 'archive',
  Unarchive = 'unarchive',
}

/**
 * ProductsMethod
 * An enumeration.
 */
export enum ProductsMethod {
  Sync = 'sync',
}

/**
 * RatingCardParams
 * An enumeration.
 */
export enum RatingCardParams {
  ContentCard = 'content-card',
  SearchPhrase = 'search-phrase',
  SalesFunnel = 'sales-funnel',
  StocksReport = 'stocks-report',
  FeedbackRating = 'feedback-rating',
}

/** RelatedGoodsInfo */
export interface RelatedGoodsInfo {
  /** Идентификатор товара */
  goods_id: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор основного изображения товара
   * @example 1001
   */
  goods_base_image_id?: number
  /** Признак - в архиве */
  in_archive: boolean
}

/**
 * ReviewTypeOptions
 * An enumeration.
 */
export enum ReviewTypeOptions {
  Value1 = '1',
  Value2 = '2',
}

/** SPP */
export interface SPP {
  /** Текущая СПП */
  value?: number
  /**
   * Диапазоны СПП
   * @default []
   */
  ranges?: SppRange[]
  /**
   * Дата последнего обновления СПП
   * @format date-time
   */
  last_change_date?: string
}

/** Season */
export interface Season {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование сезона
   * @maxLength 100
   */
  name?: string
  /**
   * Код сезона
   * @maxLength 100
   */
  code?: string
  /**
   * Дата начала сезона
   * @format date-time
   */
  date_from: string
  /**
   * Дата конца сезона
   * @format date-time
   */
  date_to: string
}

/** SetOzonPriceInfo */
export interface SetOzonPriceInfo {
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date?: string
  /** Цена */
  price: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id?: number
  /**
   * Комментарий к изменению цены
   * @maxLength 255
   */
  comments?: string
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
  revert_date?: string
  /** Цена до скидки */
  old_price?: number
}

/** SetPriceInfo */
export interface SetPriceInfo {
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date?: string
  /** Цена */
  price: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id?: number
  /**
   * Комментарий к изменению цены
   * @maxLength 255
   */
  comments?: string
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
  revert_date?: string
}

/** SetWBPriceInfo */
export interface SetWBPriceInfo {
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date?: string
  /** Цена */
  price: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id?: number
  /**
   * Комментарий к изменению цены
   * @maxLength 255
   */
  comments?: string
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
  revert_date?: string
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   * @default 0
   */
  discount?: number
}

/** SimpleProduct */
export interface SimpleProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name: string
  /**
   * Наименование категории
   * @maxLength 255
   */
  category_name: string
  /**
   * Артикул продавца
   * @maxLength 255
   */
  code?: string
  /** Идентификатор продукта на маркетплейсе */
  sku?: string
  /** Цена */
  price?: number
  /** Запланированные изменения цен */
  future_prices?: PriceInfo[]
  /** История изменения цен */
  price_recent_history?: PriceHistory[]
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: Barcode[]
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Требования по упаковке */
  packagingrequirement?: string
  /** Новый короб */
  new_box: boolean
  /**
   * Требования по упаковке у маркетплейса
   * @maxLength 100
   */
  base_packagingrequirement?: string
  /** Автозаказ */
  auto_ordering: boolean
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /** Классификация продукта по ABC анализу */
  abc_class?: string
  /** Стоимость упаковки */
  copacking_cost_info?: CoPackingCostInfo[]
  /** Фотографии товара */
  photos?: MediaFile[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /** Единица измерения габаритов (рус) */
  dimension_unit: string
  /** Единица измерения габаритов */
  dimension_unit_rus?: string
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /** Единица измерения веса */
  weight_unit: string
  /** Единица измерения веса (рус) */
  weight_unit_rus?: string
}

/** SimpleProductPriceHistory */
export interface SimpleProductPriceHistory {
  /** Начальная цена диапазона истории цен */
  start_price?: number
  /** Список изменений цен */
  history: PriceInfo[]
}

/** SimpleProductPriceInfo */
export interface SimpleProductPriceInfo {
  /** Type */
  type: 'simple'
  /** Price */
  price: number
  /** Prev Price */
  prev_price?: number
}

/** SizeInfo */
export interface SizeInfo {
  /**
   * Наименование характеристики
   * @maxLength 255
   */
  label?: string
  /** Обязательность заполнения */
  required: boolean
  /**
   * Значение
   * @exclusiveMin 0
   */
  value?: number
  /** Единицы измерения */
  units?: string
}

/** SppRange */
export interface SppRange {
  /**
   * Max
   * @min 0
   */
  max: number
  /**
   * Min
   * @min 0
   */
  min: number
  /**
   * Spp
   * @min 0
   */
  spp: number
  /**
   * Count
   * @exclusiveMin 0
   */
  count?: number
  /**
   * Estimated Max
   * @min 0
   */
  estimated_max: number
  /**
   * Estimated Min
   * @min 0
   */
  estimated_min: number
}

/** Stats */
export interface Stats {
  /**
   * Общее количество продуктов
   * @min 0
   */
  total: number
  /**
   * Количество продуктов в архиве
   * @min 0
   */
  in_archive: number
  /**
   * Количество продуктов с признаком автозаказ
   * @min 0
   */
  auto_ordering: number
  /** Количество продуктов по ABC и XYZ классификации */
  classifications: Classification[]
}

/** StatusInfo */
export interface StatusInfo {
  /** Наименование статуса */
  name: string
  /** Комментарии к статусу */
  description?: string
  /** Вид отображения статуса */
  status: string
}

/** Stocks */
export interface Stocks {
  /** Склад маркетплейса */
  marketplacewarehouse_name?: string
  /** Доступно к заказу */
  available: number
}

/** TNVEDCode */
export interface TNVEDCode {
  /** Код ТН ВЭД */
  code: string
  /** Сборное описание кода */
  name: string
}

/**
 * TemplateFormat
 * An enumeration.
 */
export enum TemplateFormat {
  Excel = 'excel',
}

/** Unit */
export interface Unit {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование единицы измерения
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
}

/** UseExistingCard */
export interface UseExistingCard {
  /**
   * Название товара
   * @maxLength 500
   */
  name: string
  /** Артикул */
  offer_id: string
  /** Цена товара */
  price: number
  /** Идентификатор товара в системе Ozon — SKU */
  sku: number
  /** Ставка НДС для товара */
  vat: VatRate
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: any[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** Value */
export interface Value {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Название значения
   * @maxLength 255
   */
  name?: string
  /**
   * Код значения
   * @maxLength 100
   */
  code?: string
}

/** VariationAttribute */
export interface VariationAttribute {
  /** Идентификатор атрибута */
  attribute_id: number
  /**
   * Наименование атрибута
   * @maxLength 255
   */
  attribute_name: string
  /** Порядковый номер */
  order_number: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id: number
  /** Список уже использованных значений */
  values: string[]
}

/** VariationInfo */
export interface VariationInfo {
  /** Список атрибутов и их значений */
  attributes: VariationAttribute[]
  /** Список товаров и значений атрибутов вариации */
  goods: GoodsVariation[]
}

/**
 * VatRate
 * An enumeration.
 */
export enum VatRate {
  Value0 = 0,
  Value01 = 0.1,
  Value02 = 0.2,
}

/** WBMedia */
export interface WBMedia {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Порядковый номер */
  index_number: number
  /**
   * Имя файла
   * @maxLength 255
   */
  file_name?: string
  /**
   * Путь для скачивания
   * @maxLength 255
   */
  download_url?: string
  /** Медиа общее для всех номенклатур */
  media_global: boolean
}

/** WBNomenclature */
export interface WBNomenclature {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Категория товара
   * @maxLength 100
   */
  category: string
  /** Идентификатор категории товара */
  category_id: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /** Описание общее для всех номенклатур */
  description_global: boolean
  /** Поддержка различных размеров */
  size_support: boolean
  /** Идентификатор номенклатуры WB */
  nmid?: number
  /**
   * Артикул товара
   * @maxLength 255
   */
  vendor_code?: string
  /**
   * Наименование товара
   * @maxLength 100
   */
  name: string
  /** Наименование общее для всех номенклатур */
  name_global?: boolean
  /** Наличие атрибута цвета */
  colors_enabled: boolean
  /** Цвета */
  colors?: string[]
  /**
   * Цена
   * @deprecated
   */
  price?: number
  /**
   * Процент скидки
   * @deprecated
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
  /**
   * Запланированные изменения цен
   * @deprecated
   */
  future_prices?: AppPimProductsWbModelsCardWBPriceInfo[]
  /**
   * История изменения цен
   * @deprecated
   */
  price_recent_history?: PriceHistory[]
  /**
   * Ссылка на товар на маркетплейсе
   * @maxLength 255
   */
  marketplace_url?: string
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Wildberries */
  sync_status: StatusInfo
  /** Возможность синхронизации c Wildberries */
  sync_possible: boolean
  /**
   * Бренд
   * @maxLength 255
   */
  brand?: string
  /** Бренд общий для всех номенклатур */
  brand_global: boolean
  /**
   * Код ТН ВЭД
   * @maxLength 20
   */
  tnved_code?: string
  /** ТН ВЭД общий для всех номенклатур */
  tnved_global: boolean
  /** Наличие атрибута ТН ВЭД */
  tnved_enabled: boolean
  /**
   * Страна производства
   * @maxLength 255
   */
  countryproduction?: string
  /** Страна производства общий для всех номенклатур */
  countryproduction_global: boolean
  /** Атрибуты товара */
  attributes?: WBNomenclatureAttribute[]
  /** Ширина */
  width?: SizeInfo
  /** Высота */
  height?: SizeInfo
  /** Глубина */
  depth?: SizeInfo
  /** Вес */
  weight?: SizeInfo
  /** Размеры и вес общие для всех номенклатур */
  sizeinfo_global: boolean
  /** Идентификатор требований по упаковке */
  packagingrequirement_id?: number
  /**
   * Требования по упаковке
   * @maxLength 100
   */
  packagingrequirement?: string
  /**
   * Требования по упаковке у маркетплейса
   * @maxLength 100
   */
  base_packagingrequirement?: string
  /** Новый короб */
  new_box: boolean
  /** Размеры номенклатуры товара */
  variations: WBVariation[]
  /** Фотографии товара */
  photos?: WBMedia[]
  /** Видео ролик товара */
  video?: WBMedia
  /** Признак - в архиве */
  in_archive: boolean
  /** Другие номенклатуры на карточке WB */
  other_nomenclatures?: OtherNomenclature[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** WBNomenclatureAttribute */
export interface WBNomenclatureAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /**
   * Наименование атрибута
   * @maxLength 255
   */
  attribute_name: string
  /** Значения */
  values?: string[]
  /**
   * Единица измерения
   * @maxLength 20
   */
  unit_name?: string
  /** Обязательность заполнения */
  required: boolean
  /** Значения только из справочника */
  useonlydictionaryvalues: boolean
  /**
   * Наименование справочника
   * @maxLength 100
   */
  dictionary?: string
  /** Максимальное количество значений */
  maxcount?: number
  /** Тип данных значения */
  values_type: number
  /** Атрибут общий для всех номенклатур */
  attribute_global: boolean
}

/** WBNomenclatureStatus */
export interface WBNomenclatureStatus {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Wildberries */
  sync_status: StatusInfo
  /** Возможность синхронизации c Wildberries */
  sync_possible: boolean
}

/** WBPriceDetail */
export interface WBPriceDetail {
  /**
   * Цена
   * @min 0
   */
  price: number
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
  /**
   * Скидка постоянного покупателя
   * @min 0
   * @exclusiveMax 100
   */
  spp?: number
}

/** WBPriceHistory */
export interface WBPriceHistory {
  /** Начальная цена диапазона истории цен */
  start_price?: number
  /**
   * Начальный процент скидки диапазона истории цен
   * @min 0
   * @exclusiveMax 100
   */
  start_discount?: number
  /** Список изменений цен */
  history: AppPimProductsWbModelsCardWBPriceInfo[]
}

/** WBProductInfo */
export interface WBProductInfo {
  /** Идентификатор продукта */
  id: number
  /** Идентификатор номенклатуры на Wildberries */
  wbnomenclature_id: number
  /** Поддержка различных размеров */
  size_support: boolean
  /**
   * Наименование
   * @maxLength 1000
   */
  name?: string
  /**
   * Артикул товара на маркетплейсе
   * @maxLength 255
   */
  marketplace_code?: string
  /**
   * Наименование категории продукта на маркетплейсе
   * @maxLength 255
   */
  marketplace_category?: string
  /**
   * Наименование компании продавца на маркетплейсе
   * @maxLength 255
   */
  company_name: string
  /** Цена */
  price?: number
  /** Автозаказ */
  auto_ordering: boolean
  /** Наименование маркетплейса */
  marketplace_name: string
  /** Штрих-код */
  barcode?: string
  /** Ссылка на товар на маркетплейсе */
  marketplace_url?: string
}

/**
 * WBTemplateType
 * An enumeration.
 */
export enum WBTemplateType {
  WithCategory = 'with_category',
  WithoutCategory = 'without_category',
}

/** WBVariation */
export interface WBVariation {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Товар из каталога PIM
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Размер
   * @maxLength 255
   */
  size?: string
  /**
   * Рос размер
   * @maxLength 255
   */
  ru_size?: string
  /** Автозаказ */
  auto_ordering: boolean
  /** Автосинхронизация с WB */
  sync_enabled: boolean
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: Barcode[]
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /** Классификация продукта по ABC анализу */
  abc_class?: string
  /** Стоимость упаковки */
  copacking_cost_info?: CoPackingCostInfo[]
}

/** WbNomenclatureUpdateData */
export interface WbNomenclatureUpdateData {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Wildberries */
  sync_status: StatusInfo
  /** Возможность синхронизации c Wildberries */
  sync_possible: boolean
  /** Nmid */
  nmid: number
  /** Product Id */
  product_id: number
  /** Marketplace Url */
  marketplace_url: string
}

/**
 * GoodsMethod
 * An enumeration.
 */
export enum AppPimExcelTemplateModelsGoodsGoodsMethod {
  Archive = 'archive',
  Unarchive = 'unarchive',
}

/** GoodsComponent */
export interface AppPimGoodsContentModelsGoodscontentGoodsComponent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Компонент
   * @maxLength 100
   */
  component: string
  /** Процентное содержание */
  percents?: number
}

/**
 * GoodsMethod
 * An enumeration.
 */
export enum AppPimGoodsMediaModelsMediaGoodsMethod {
  CopyMedia = 'copy_media',
}

/** GoodsComponent */
export interface AppPimGoodsModelsGoodsGoodsComponent {
  /** Идентификатор товара, входящего в набор */
  component_id: number
  /** Количество */
  quantity: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор основного изображения товара
   * @example 1001
   */
  goods_base_image_id?: number
  /** Тип товара */
  goods_type: GoodsType
  /**
   * Код единицы измерения упаковки товара
   * @maxLength 100
   */
  unit_code?: string
  /**
   * Признак делимости единицы измерения
   * @default false
   */
  unit_partible?: boolean
}

/** OzonPriceInfo */
export interface AppPimProductsCommonModelsProductpricehistoryOzonPriceInfo {
  /** Type */
  type: 'ozon'
  /** Price */
  price: number
  /** Old Price */
  old_price?: number
  /** Prev Price */
  prev_price?: number
  /** Prev Old Price */
  prev_old_price?: number
}

/** WBPriceInfo */
export interface AppPimProductsCommonModelsProductpricehistoryWBPriceInfo {
  /** Type */
  type: 'wb'
  /** Price */
  price: number
  /** Discount */
  discount?: number
  /** Prev Price */
  prev_price?: number
  /** Prev Discount */
  prev_discount?: number
}

/** Event */
export interface AppPimProductsOzonHandlerEvent {
  /** Event */
  event: 'ozonproductupdate'
  /** Data */
  data?: OzonProductUpdateData | object | (OzonProductUpdateData & object)
}

/** OzonPriceInfo */
export interface AppPimProductsOzonModelsProductOzonPriceInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date: string
  /** Цена */
  price?: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id: number
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Путь к файлу при массовой загрузке */
  batch_file_url?: string
  /** Цена до скидки */
  old_price?: number
}

/** WBPriceInfo */
export interface AppPimProductsWbModelsCardWBPriceInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date: string
  /** Цена */
  price?: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id: number
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Путь к файлу при массовой загрузке */
  batch_file_url?: string
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
}

/** Event */
export interface AppPimProductsWbModelsEventEvent {
  /** Event */
  event: 'wbnomenclatureupdate'
  /** Data */
  data?: WbNomenclatureUpdateData | object | (WbNomenclatureUpdateData & object)
}

export namespace Product {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение общей информации о продукте
   * @tags Product
   * @name ProductGetProduct
   * @summary Получение общей информации о продукте
   * @request GET:/products/common/product/{product_id}
   * @secure
   */
  export namespace ProductGetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Перенос продукта на другой товар
   * @tags Product
   * @name ProductChangeProduct
   * @summary Перенос продукта на другой товар
   * @request PUT:/products/common/product/{product_id}
   * @secure
   */
  export namespace ProductChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditProduct
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение списка продуктов по товару
   * @tags Product
   * @name ProductSearchProducts
   * @summary Получение списка продуктов по товару
   * @request GET:/products/common/search
   * @secure
   */
  export namespace ProductSearchProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор товара */
      goods_id: number
      /** Идентификатор кабинета */
      companymarketplace_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Product[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Перенос продукта в архив
   * @tags Product
   * @name ProductArchiveProduct
   * @summary Перенос продукта в архив
   * @request PUT:/products/common/product/{product_id}/archive
   * @secure
   */
  export namespace ProductArchiveProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ProductArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Возвращение продукта из архива
   * @tags Product
   * @name ProductGetProductFromArchive
   * @summary Возвращение продукта из архива
   * @request DELETE:/products/common/product/{product_id}/archive
   * @secure
   */
  export namespace ProductGetProductFromArchive {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ProductDeArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения доходности
   * @tags Product
   * @name ProductGetProductProfitability
   * @summary Получения доходности
   * @request GET:/products/common/profitability
   * @secure
   */
  export namespace ProductGetProductProfitability {
    export type RequestParams = {}
    export type RequestQuery = {
      /** product_id */
      product_id?: number
      /** Новая цена */
      price?: number
      /** Приоритетность */
      priority?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductProfitability[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения доходности за период
   * @tags Product
   * @name ProductGetProductSumProfitability
   * @summary Получения доходности за период
   * @request GET:/products/common/sumprofitability
   * @secure
   */
  export namespace ProductGetProductSumProfitability {
    export type RequestParams = {}
    export type RequestQuery = {
      /** product_id */
      product_id: number
      /**
       * С даты
       * @format date
       */
      from_date?: string
      /**
       * По дату
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductSumProfitability
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История изменения цен у продуктов
   * @tags Product, All products
   * @name ProductGetPriceHistory
   * @summary История изменения цен у продуктов
   * @request GET:/products/pricehistory
   * @secure
   */
  export namespace ProductGetPriceHistory {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
      /**
       * Search String
       * Поиск по набору слов
       */
      search_string?: string
      /**
       * Marketplace Id
       * Идентификатор маркетплейса
       */
      marketplace_id?: number
      /**
       * Brand Id
       * Идентификатор бренда
       */
      brand_id?: number
      /**
       * Companymarketplace Id
       * Идентификатор кабинета
       */
      companymarketplace_id?: number
      /**
       * Contragent Id
       * Идентификатор постащика
       */
      contragent_id?: number
      /**
       * Trend
       * Тренд изменения цены
       */
      trend?: 'increased' | 'decreased'
      /**
       * Trend Percent Threshold
       * Порог изменения в процентах
       * @min 0
       */
      trend_percent_threshold?: number
      /**
       * Changer Id
       * Идентификатор пользователя
       */
      changer_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductPriceHistory[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Product, All products
   * @name ProductGetProductCompetitors
   * @summary Список конкурентов по продукту
   * @request GET:/products/{product_id}/competitors
   * @secure
   */
  export namespace ProductGetProductCompetitors {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Competitor[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Product, All products
   * @name ProductEditProductCompetitor
   * @summary Изменить конкурента продукта
   * @request PUT:/products/{product_id}/competitors
   * @secure
   */
  export namespace ProductEditProductCompetitor {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = Competitor
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение изображения продукта
   * @tags Product, All products
   * @name ProductGetProductThumbnail
   * @summary Получение изображения продукта
   * @request GET:/products/{product_id}/thumbnail
   * @secure
   */
  export namespace ProductGetProductThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение списка статусов карточки продукта
   * @tags Product, All products
   * @name ProductGetCardstatuses
   * @summary Получение списка статусов карточки продукта
   * @request GET:/products/cardstatuses
   * @secure
   */
  export namespace ProductGetCardstatuses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardStatus[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение списка статусов синхронизации карточки продукта
   * @tags Product, All products
   * @name ProductGetSyncstatuses
   * @summary Получение списка статусов синхронизации карточки продукта
   * @request GET:/products/syncstatuses
   * @secure
   */
  export namespace ProductGetSyncstatuses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardStatus[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение статистической информации о продуктах
   * @tags Product, All products
   * @name ProductGetStats
   * @summary Получение статистической информации о продуктах
   * @request GET:/products/stats
   * @secure
   */
  export namespace ProductGetStats {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Stats
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Product, All products
   * @name ProductGetProductChangelog
   * @summary Список изменений по продукту
   * @request GET:/products/{product_id}/changelog
   * @secure
   */
  export namespace ProductGetProductChangelog {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ChangeLog[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace WildberriesProduct {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Поиск продукта на Wildberries по номеру номенклатуры или артикулу
   * @tags Wildberries Product
   * @name WildberriesProductSearchProduct
   * @summary Поиск продукта на Wildberries по номеру номенклатуры или артикулу
   * @request GET:/products/wb/product/search
   * @secure
   */
  export namespace WildberriesProductSearchProduct {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Компания продавец */
      companymarketplace_id?: number
      /** Номенклатура или артикул */
      code: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBProductInfo[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Создание продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductCreateProduct
   * @summary Создание продукта на Wildberries
   * @request POST:/products/wb/product
   * @secure
   */
  export namespace WildberriesProductCreateProduct {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody =
      | CreateWBProduct
      | AddWBProduct
      | CopyWBProduct
      | (CreateWBProduct & AddWBProduct & CopyWBProduct)
    export type RequestHeaders = {}
    export type ResponseBody = NewWBProductResponse
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о продукте на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductGetProduct
   * @summary Получение информации о продукте на Wildberries
   * @request GET:/products/wb/product/{product_id}
   * @secure
   */
  export namespace WildberriesProductGetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclature
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о продукте на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductChangeProduct
   * @summary Изменение информации о продукте на Wildberries
   * @request PUT:/products/wb/product/{product_id}
   * @secure
   */
  export namespace WildberriesProductChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBNomenclature
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclature
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление продукта Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeleteProduct
   * @summary Удаление продукта Wildberries
   * @request DELETE:/products/wb/product/{product_id}
   * @secure
   */
  export namespace WildberriesProductDeleteProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Применить операцию к продукту на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductPatchProduct
   * @summary Применить операцию к продукту на Wildberries
   * @request PATCH:/products/wb/product/{product_id}
   * @secure
   */
  export namespace WildberriesProductPatchProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над продуктом */
      method: ProductMethod
      /** Параметр к операции */
      method_param?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclature
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о вариации продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductEditVariation
   * @summary Изменение информации о вариации продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/variations/{variation_id}
   * @secure
   */
  export namespace WildberriesProductEditVariation {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBVariation
    export type RequestHeaders = {}
    export type ResponseBody = WBVariation
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление штрих-кода для продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductCreateBarcode
   * @summary Добавление штрих-кода для продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/variations/{variation_id}/barcodes
   * @secure
   */
  export namespace WildberriesProductCreateBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение штрих-кода у продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductEditBarcode
   * @summary Изменение штрих-кода у продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/variations/{variation_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace WildberriesProductEditBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление штрих-кода у продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeleteBarcode
   * @summary Удаление штрих-кода у продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/variations/{variation_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace WildberriesProductDeleteBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии в номенклатуру продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductUploadPhotoByFile
   * @summary Добавление фотографии в номенклатуру продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/photos/file
   * @secure
   */
  export namespace WildberriesProductUploadPhotoByFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductUploadPhotoByFile
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductCheckPhotoFile
   * @summary Проверка фотографии на соответствие требованиям Wildberries
   * @request POST:/products/wb/product/{product_id}/photos/file/validator
   * @secure
   */
  export namespace WildberriesProductCheckPhotoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductCheckPhotoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии в номенклатуру продукта на Wildberries по URL
   * @tags Wildberries Product
   * @name WildberriesProductUploadPhotoByUrl
   * @summary Добавление фотографии в номенклатуру продукта на Wildberries по URL
   * @request POST:/products/wb/product/{product_id}/photos/url
   * @secure
   */
  export namespace WildberriesProductUploadPhotoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Wildberries по URL
   * @tags Wildberries Product
   * @name WildberriesProductCheckPhotoUrl
   * @summary Проверка фотографии на соответствие требованиям Wildberries по URL
   * @request POST:/products/wb/product/{product_id}/photos/url/validator
   * @secure
   */
  export namespace WildberriesProductCheckPhotoUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление видео в номенклатуру продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductUploadVideo
   * @summary Добавление видео в номенклатуру продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/video/file
   * @secure
   */
  export namespace WildberriesProductUploadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductUploadVideo
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductCheckVideoFile
   * @summary Проверка видео на соответствие требованиям Wildberries
   * @request POST:/products/wb/product/{product_id}/video/file/validator
   * @secure
   */
  export namespace WildberriesProductCheckVideoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductCheckVideoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDownloadPhoto
   * @summary Скачать фотографию номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace WildberriesProductDownloadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных по фотографии номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductChangePhoto
   * @summary Изменение данных по фотографии номенклатуры продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace WildberriesProductChangePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBMedia
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeletePhoto
   * @summary Удаление фотографии у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace WildberriesProductDeletePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать видео номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDownloadVideo
   * @summary Скачать видео номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/video/{file_id}
   * @secure
   */
  export namespace WildberriesProductDownloadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление видео у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeleteVideo
   * @summary Удаление видео у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/video/{file_id}
   * @secure
   */
  export namespace WildberriesProductDeleteVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDownloadPhotoThumbnail
   * @summary Получение миниатюры фотографии номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/photos/thumbnail/{file_id}
   * @secure
   */
  export namespace WildberriesProductDownloadPhotoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры видео номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDownloadVideoThumbnail
   * @summary Получение миниатюры видео номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/video/thumbnail/{file_id}
   * @secure
   */
  export namespace WildberriesProductDownloadVideoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных по фотографии номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductChangePhotos
   * @summary Массовое изменение данных по фотографии номенклатуры продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/photos
   * @secure
   */
  export namespace WildberriesProductChangePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBMedia[]
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeletePhotos
   * @summary Массовое удаление фотографий у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/photos
   * @secure
   */
  export namespace WildberriesProductDeletePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое копирование фотографий из продукта в товар
   * @tags Wildberries Product
   * @name WildberriesProductCopyPhotosFromProduct
   * @summary Массовое копирование фотографий из продукта в товар
   * @request PATCH:/products/wb/product/{product_id}/photos
   * @secure
   */
  export namespace WildberriesProductCopyPhotosFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Редактирование атрибута продукта
   * @tags Wildberries Product
   * @name WildberriesProductEditWbcardattribute
   * @summary Редактирование атрибута продукта
   * @request PUT:/products/wb/product/{product_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace WildberriesProductEditWbcardattribute {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBNomenclatureAttribute
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclatureAttribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление видео у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeleteVideos
   * @summary Массовое удаление видео у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/video
   * @secure
   */
  export namespace WildberriesProductDeleteVideos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Копирование фотографии из продукта в товар
   * @tags Wildberries Product
   * @name WildberriesProductCopyPhotoFromProduct
   * @summary Копирование фотографии из продукта в товар
   * @request PATCH:/products/wb/product/{product_id}/photos/{media_id}
   * @secure
   */
  export namespace WildberriesProductCopyPhotoFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение цены номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductGetPrice
   * @summary Получение цены номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/price
   * @secure
   */
  export namespace WildberriesProductGetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PIMWBPriceDetail
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Установка цены у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductSetPrice
   * @summary Установка цены у номенклатуры продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/price
   * @secure
   */
  export namespace WildberriesProductSetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SetWBPriceInfo
    export type RequestHeaders = {}
    export type ResponseBody = PIMWBPriceDetail
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Удаление будушей цены у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductDeletePrice
   * @summary Удаление будушей цены у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/price/{price_id}
   * @secure
   */
  export namespace WildberriesProductDeletePrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Price Id */
      priceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История установки цены у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name WildberriesProductGetPrices
   * @summary История установки цены у номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/pricehistory
   * @secure
   */
  export namespace WildberriesProductGetPrices {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBPriceHistory
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о статусе продукта
   * @tags Wildberries Product
   * @name WildberriesProductGetProductStatus
   * @summary Получение информации о статусе продукта
   * @request GET:/products/wb/product/{product_id}/status
   * @secure
   */
  export namespace WildberriesProductGetProductStatus {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclatureStatus
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения рейтингов по продукту
   * @tags Wildberries Product, rating_card
   * @name WildberriesProductRatingCard
   * @summary Получения рейтингов по продукту
   * @request GET:/products/wb/product/{product_id}/rating
   * @secure
   */
  export namespace WildberriesProductRatingCard {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** An enumeration. */
      method: RatingCardParams
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> </h2>
   * @tags Wildberries Product
   * @name WildberriesProductEventStream
   * @summary Event Stream
   * @request GET:/products/wb/product/{product_id}/stream
   * @secure
   */
  export namespace WildberriesProductEventStream {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppPimProductsWbModelsEventEvent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace OzonProduct {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Создание продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductCreateProduct
   * @summary Создание продукта на Ozon
   * @request POST:/products/ozon/product
   * @secure
   */
  export namespace OzonProductCreateProduct {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewOzonProductRequest
    export type RequestHeaders = {}
    export type ResponseBody = NewOzonProductResponse
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о продукте на Ozon
   * @tags Ozon Product
   * @name OzonProductGetProduct
   * @summary Получение информации о продукте на Ozon
   * @request GET:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace OzonProductGetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о продукте на Ozon
   * @tags Ozon Product
   * @name OzonProductChangeProduct
   * @summary Изменение информации о продукте на Ozon
   * @request PUT:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace OzonProductChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditOzonProduct
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление продукта Ozon
   * @tags Ozon Product
   * @name OzonProductDeleteProduct
   * @summary Удаление продукта Ozon
   * @request DELETE:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace OzonProductDeleteProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Применить операцию к продукту на Ozon
   * @tags Ozon Product
   * @name OzonProductPatchProduct
   * @summary Применить операцию к продукту на Ozon
   * @request PATCH:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace OzonProductPatchProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над продуктом */
      method: ProductMethod
      /** Параметр метода patch */
      method_param?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon
   * @tags Ozon Product
   * @name OzonProductUploadPhoto
   * @summary Добавление фотографии к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/photos/file
   * @secure
   */
  export namespace OzonProductUploadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPhoto
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon по URL
   * @tags Ozon Product
   * @name OzonProductUploadPhotoByUrl
   * @summary Добавление фотографии к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos/url
   * @secure
   */
  export namespace OzonProductUploadPhotoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name OzonProductCheckPhotoFile
   * @summary Проверка фотографии на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/photos/file/validator
   * @secure
   */
  export namespace OzonProductCheckPhotoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckPhotoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name OzonProductCheckPhotoUrl
   * @summary Проверка фотографии на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos/url/validator
   * @secure
   */
  export namespace OzonProductCheckPhotoUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPhoto
   * @summary Скачать фотографию у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductChangePhoto
   * @summary Изменение данных фотографии у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace OzonProductChangePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePhoto
   * @summary Удаление фотографии у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace OzonProductDeletePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPhotoThumbnail
   * @summary Получение миниатюры фотографии продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos/thumbnail/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPhotoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon
   * @tags Ozon Product
   * @name OzonProductUploadPhoto360
   * @summary Добавление фотографии к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/photos360/file
   * @secure
   */
  export namespace OzonProductUploadPhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPhoto360
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon по URL
   * @tags Ozon Product
   * @name OzonProductUploadPhoto360Url
   * @summary Добавление фотографии к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos360/url
   * @secure
   */
  export namespace OzonProductUploadPhoto360Url {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии 360 на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name OzonProductCheckPhotos360File
   * @summary Проверка фотографии 360 на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/photos360/file/validator
   * @secure
   */
  export namespace OzonProductCheckPhotos360File {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckPhotos360File
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии 360 на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name OzonProductCheckPhotos360Url
   * @summary Проверка фотографии 360 на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos360/url/validator
   * @secure
   */
  export namespace OzonProductCheckPhotos360Url {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию 360 у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPhoto360
   * @summary Скачать фотографию 360 у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos360/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных фотографии 360 у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductChangePhoto360
   * @summary Изменение данных фотографии 360 у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos360/{file_id}
   * @secure
   */
  export namespace OzonProductChangePhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePhoto360
   * @summary Удаление фотографии у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos360/{file_id}
   * @secure
   */
  export namespace OzonProductDeletePhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии 360 продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPhoto360Thumbnail
   * @summary Получение миниатюры фотографии 360 продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos360/thumbnail/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPhoto360Thumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление видео к продукту на Ozon
   * @tags Ozon Product
   * @name OzonProductUploadVideo
   * @summary Добавление видео к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/videos/file
   * @secure
   */
  export namespace OzonProductUploadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadVideo
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление видео к продукту на Ozon по URL
   * @tags Ozon Product
   * @name OzonProductUploadVideoByUrl
   * @summary Добавление видео к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videos/url
   * @secure
   */
  export namespace OzonProductUploadVideoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name OzonProductCheckVideosFile
   * @summary Проверка видео на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/videos/file/validator
   * @secure
   */
  export namespace OzonProductCheckVideosFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckVideosFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name OzonProductCheckVideosUrl
   * @summary Проверка видео на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videos/url/validator
   * @secure
   */
  export namespace OzonProductCheckVideosUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать видео у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadVideo
   * @summary Скачать видео у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/videos/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных видео у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductChangeVideo
   * @summary Изменение данных видео у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/videos/{file_id}
   * @secure
   */
  export namespace OzonProductChangeVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление видео у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeleteVideo
   * @summary Удаление видео у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/videos/{file_id}
   * @secure
   */
  export namespace OzonProductDeleteVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных видео у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductChangeVideos
   * @summary Массовое изменение данных видео у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/videos
   * @secure
   */
  export namespace OzonProductChangeVideos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры видео продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadVideoThumbnail
   * @summary Получение миниатюры видео продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/videos/thumbnail/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadVideoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductChangePhotos
   * @summary Массовое изменение данных фотографии у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos
   * @secure
   */
  export namespace OzonProductChangePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePhotos
   * @summary Массовое удаление фотографий у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos
   * @secure
   */
  export namespace OzonProductDeletePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое копирование фотографий из продукта в товар
   * @tags Ozon Product
   * @name OzonProductCopyPhotosFromProduct
   * @summary Массовое копирование фотографий из продукта в товар
   * @request PATCH:/products/ozon/product/{product_id}/photos
   * @secure
   */
  export namespace OzonProductCopyPhotosFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над продуктами */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных фотографии 360 у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductChangePhotos360
   * @summary Массовое изменение данных фотографии 360 у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos360
   * @secure
   */
  export namespace OzonProductChangePhotos360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий 360 у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePhotos360
   * @summary Массовое удаление фотографий 360 у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos360
   * @secure
   */
  export namespace OzonProductDeletePhotos360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление PDF документа к продукту на Ozon
   * @tags Ozon Product
   * @name OzonProductUploadPdf
   * @summary Добавление PDF документа к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/pdfs/file
   * @secure
   */
  export namespace OzonProductUploadPdf {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPdf
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление PDF документа к продукту на Ozon по URL
   * @tags Ozon Product
   * @name OzonProductUploadPdfUrl
   * @summary Добавление PDF документа к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/pdfs/url
   * @secure
   */
  export namespace OzonProductUploadPdfUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать PDF документ у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPdf
   * @summary Скачать PDF документ у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/pdfs/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPdf {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление PDF документа у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePdf
   * @summary Удаление PDF документа у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/pdfs/{file_id}
   * @secure
   */
  export namespace OzonProductDeletePdf {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры PDF документа продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPdfThumbnail
   * @summary Получение миниатюры PDF документа продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/pdfs/thumbnail/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPdfThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Редактирование атрибута продукта
   * @tags Ozon Product
   * @name OzonProductEditOzonattribute
   * @summary Редактирование атрибута продукта
   * @request PUT:/products/ozon/product/{product_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace OzonProductEditOzonattribute {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditOzonProductAttribute
    export type RequestHeaders = {}
    export type ResponseBody = OzonProductAttribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление PDF документов у продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePdfs
   * @summary Массовое удаление PDF документов у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/pdfs
   * @secure
   */
  export namespace OzonProductDeletePdfs {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Установка цены у продукта на маркетплейсе
   * @tags Ozon Product
   * @name OzonProductSetPrice
   * @summary Установка цены у продукта на маркетплейсе
   * @request POST:/products/ozon/product/{product_id}/price
   * @secure
   */
  export namespace OzonProductSetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SetOzonPriceInfo
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Удаление будушей цены у продукта на маркетплейсе
   * @tags Ozon Product
   * @name OzonProductDeletePrice
   * @summary Удаление будушей цены у продукта на маркетплейсе
   * @request DELETE:/products/ozon/product/{product_id}/price/{price_id}
   * @secure
   */
  export namespace OzonProductDeletePrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Price Id */
      priceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История установки цены у продукта на маркетплейсе
   * @tags Ozon Product
   * @name OzonProductGetPrices
   * @summary История установки цены у продукта на маркетплейсе
   * @request GET:/products/ozon/product/{product_id}/pricehistory
   * @secure
   */
  export namespace OzonProductGetPrices {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonPriceHistory
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о статусе продукта
   * @tags Ozon Product
   * @name OzonProductGetProductStatus
   * @summary Получение информации о статусе продукта
   * @request GET:/products/ozon/product/{product_id}/status
   * @secure
   */
  export namespace OzonProductGetProductStatus {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonProductStatus
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации о видеообложке продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductUploadVideocover
   * @summary Сохранение информации о видеообложке продукта на Ozon
   * @request POST:/products/ozon/product/{product_id}/videocover/file
   * @secure
   */
  export namespace OzonProductUploadVideocover {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadVideocover
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации о видеообложке продукта на Ozon по URL
   * @tags Ozon Product
   * @name OzonProductUploadVideocoverUrl
   * @summary Сохранение информации о видеообложке продукта на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videocover/url
   * @secure
   */
  export namespace OzonProductUploadVideocoverUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name OzonProductCheckVideocoverFile
   * @summary Проверка видео на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/videocover/file/validator
   * @secure
   */
  export namespace OzonProductCheckVideocoverFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckVideocoverFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name OzonProductCheckVideocoverUrl
   * @summary Проверка видео на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videocover/url/validator
   * @secure
   */
  export namespace OzonProductCheckVideocoverUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление информации о видеообложке продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeleteVideocover
   * @summary Удаление информации о видеообложке продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/videocover/{file_id}
   * @secure
   */
  export namespace OzonProductDeleteVideocover {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры видеообложки продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadCoverThumbnail
   * @summary Получение миниатюры видеообложки продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/videocover/thumbnail/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadCoverThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации об образце цвета продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductUploadPicturesample
   * @summary Сохранение информации об образце цвета продукта на Ozon
   * @request POST:/products/ozon/product/{product_id}/picture/file
   * @secure
   */
  export namespace OzonProductUploadPicturesample {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPicturesample
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации об образце цвета продукта на Ozon по URL
   * @tags Ozon Product
   * @name OzonProductUploadPicturesampleUrl
   * @summary Сохранение информации об образце цвета продукта на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/picture/url
   * @secure
   */
  export namespace OzonProductUploadPicturesampleUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии об образце цвета на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name OzonProductCheckPicturesampleFile
   * @summary Проверка фотографии об образце цвета на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/picture/file/validator
   * @secure
   */
  export namespace OzonProductCheckPicturesampleFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckPicturesampleFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии об образце цвета на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name OzonProductCheckPicturesampleUrl
   * @summary Проверка фотографии об образце цвета на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/picture/url/validator
   * @secure
   */
  export namespace OzonProductCheckPicturesampleUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление информации об образце цвета продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDeletePicturesample
   * @summary Удаление информации об образце цвета продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/picture/{file_id}
   * @secure
   */
  export namespace OzonProductDeletePicturesample {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры образца цвета продукта на Ozon
   * @tags Ozon Product
   * @name OzonProductDownloadPicturesampleThumbnail
   * @summary Получение миниатюры образца цвета продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/picture/thumbnail/{file_id}
   * @secure
   */
  export namespace OzonProductDownloadPicturesampleThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление штрих-кода для продукта Ozon
   * @tags Ozon Product
   * @name OzonProductCreateBarcode
   * @summary Добавление штрих-кода для продукта Ozon
   * @request POST:/products/ozon/product/{product_id}/barcodes
   * @secure
   */
  export namespace OzonProductCreateBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение штрих-кода у продукта Ozon
   * @tags Ozon Product
   * @name OzonProductEditBarcode
   * @summary Изменение штрих-кода у продукта Ozon
   * @request PUT:/products/ozon/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace OzonProductEditBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление штрих-кода у продукта Ozon
   * @tags Ozon Product
   * @name OzonProductDeleteBarcode
   * @summary Удаление штрих-кода у продукта Ozon
   * @request DELETE:/products/ozon/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace OzonProductDeleteBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Копирование фотографии из продукта в товар
   * @tags Ozon Product
   * @name OzonProductCopyPhotoFromProduct
   * @summary Копирование фотографии из продукта в товар
   * @request PATCH:/products/ozon/product/{product_id}/photos/{media_id}
   * @secure
   */
  export namespace OzonProductCopyPhotoFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения рейтингов по продукту
   * @tags Ozon Product, rating_card
   * @name OzonProductRatingCard
   * @summary Получения рейтингов по продукту
   * @request GET:/products/ozon/product/{product_id}/rating
   * @secure
   */
  export namespace OzonProductRatingCard {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Ozon Product
   * @name OzonProductEventStream
   * @summary Event Stream
   * @request GET:/products/ozon/product/{product_id}/stream
   * @secure
   */
  export namespace OzonProductEventStream {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppPimProductsOzonHandlerEvent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace SimpleProduct {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Создание продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductCreateProduct
   * @summary Создание продукта на маркетплейсе
   * @request POST:/products/simple/product
   * @secure
   */
  export namespace SimpleProductCreateProduct {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewSimpleProductRequest
    export type RequestHeaders = {}
    export type ResponseBody = NewSimpleProductResponse
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о продукте на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductGetProduct
   * @summary Получение информации о продукте на маркетплейсе
   * @request GET:/products/simple/product/{product_id}
   * @secure
   */
  export namespace SimpleProductGetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о продукте на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductChangeProduct
   * @summary Изменение информации о продукте на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}
   * @secure
   */
  export namespace SimpleProductChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditSimpleProduct
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление информации о продукте на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductDeleteProduct
   * @summary Удаление информации о продукте на маркетплейсе
   * @request DELETE:/products/simple/product/{product_id}
   * @secure
   */
  export namespace SimpleProductDeleteProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Установка цены у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductSetPrice
   * @summary Установка цены у продукта на маркетплейсе
   * @request POST:/products/simple/product/{product_id}/price
   * @secure
   */
  export namespace SimpleProductSetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SetPriceInfo
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Удаление будушей цены у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductDeletePrice
   * @summary Удаление будушей цены у продукта на маркетплейсе
   * @request DELETE:/products/simple/product/{product_id}/price/{price_id}
   * @secure
   */
  export namespace SimpleProductDeletePrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Price Id */
      priceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История установки цены у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductGetPrices
   * @summary История установки цены у продукта на маркетплейсе
   * @request GET:/products/simple/product/{product_id}/pricehistory
   * @secure
   */
  export namespace SimpleProductGetPrices {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProductPriceHistory
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление штрих-кода для продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductCreateBarcode
   * @summary Добавление штрих-кода для продукта на маркетплейсе
   * @request POST:/products/simple/product/{product_id}/barcodes
   * @secure
   */
  export namespace SimpleProductCreateBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение штрих-кода у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductEditBarcode
   * @summary Изменение штрих-кода у продукта на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace SimpleProductEditBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление штрих-кода у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductDeleteBarcode
   * @summary Удаление штрих-кода у продукта на маркетплейсе
   * @request DELETE:/products/simple/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace SimpleProductDeleteBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductUploadPhoto
   * @summary Добавление фотографии к продукту на маркетплейсе
   * @request POST:/products/simple/product/{product_id}/photos/file
   * @secure
   */
  export namespace SimpleProductUploadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodySimpleProductUploadPhoto
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям
   * @tags Simple Product
   * @name SimpleProductCheckPhotoFile
   * @summary Проверка фотографии на соответствие требованиям
   * @request POST:/products/simple/product/{product_id}/photos/file/validator
   * @secure
   */
  export namespace SimpleProductCheckPhotoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodySimpleProductCheckPhotoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на маркетплейсе по URL
   * @tags Simple Product
   * @name SimpleProductUploadPhotoByUrl
   * @summary Добавление фотографии к продукту на маркетплейсе по URL
   * @request POST:/products/simple/product/{product_id}/photos/url
   * @secure
   */
  export namespace SimpleProductUploadPhotoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям по URL
   * @tags Simple Product
   * @name SimpleProductCheckPhotoUrl
   * @summary Проверка фотографии на соответствие требованиям по URL
   * @request POST:/products/simple/product/{product_id}/photos/url/validator
   * @secure
   */
  export namespace SimpleProductCheckPhotoUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductDownloadPhoto
   * @summary Скачать фотографию у продукта на маркетплейсе
   * @request GET:/products/simple/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace SimpleProductDownloadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных фотографии у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductChangePhoto
   * @summary Изменение данных фотографии у продукта на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace SimpleProductChangePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у продукта на Ozon
   * @tags Simple Product
   * @name SimpleProductDeletePhoto
   * @summary Удаление фотографии у продукта на Ozon
   * @request DELETE:/products/simple/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace SimpleProductDeletePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductDownloadPhotoThumbnail
   * @summary Получение миниатюры фотографии продукта на маркетплейсе
   * @request GET:/products/simple/product/{product_id}/photos/thumbnail/{file_id}
   * @secure
   */
  export namespace SimpleProductDownloadPhotoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных фотографии у продукта на маркетплейсе
   * @tags Simple Product
   * @name SimpleProductChangePhotos
   * @summary Массовое изменение данных фотографии у продукта на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}/photos
   * @secure
   */
  export namespace SimpleProductChangePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий у продукта на Ozon
   * @tags Simple Product
   * @name SimpleProductDeletePhotos
   * @summary Массовое удаление фотографий у продукта на Ozon
   * @request DELETE:/products/simple/product/{product_id}/photos
   * @secure
   */
  export namespace SimpleProductDeletePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое копирование фотографий из продукта в товар
   * @tags Simple Product
   * @name SimpleProductCopyPhotosFromProduct
   * @summary Массовое копирование фотографий из продукта в товар
   * @request PATCH:/products/simple/product/{product_id}/photos
   * @secure
   */
  export namespace SimpleProductCopyPhotosFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над продуктами */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Копирование фоторгафии из продукта в товар
   * @tags Simple Product
   * @name SimpleProductCopyPhotoFromProduct
   * @summary Копирование фотографии из продукта в товар
   * @request PATCH:/products/simple/product/{product_id}/photos/{media_id}
   * @secure
   */
  export namespace SimpleProductCopyPhotoFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace Goods {
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Получение списка всех товаров по артикулу
   * @tags Goods, Goods
   * @name GoodsGetGoodsByCode
   * @summary Получение списка товаров по артикулу
   * @request GET:/goods/goodsbycode
   * @secure
   */
  export namespace GoodsGetGoodsByCode {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Code
       * Поиск по артикулу товара
       */
      code: string
      /**
       * With Variations
       * Показывать все вариации товаров выбранного артикула
       * @default false
       */
      with_variations?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsShortInfo[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Получение полной информации по указанному товару
   * @tags Goods, Goods
   * @name GoodsGetGoods
   * @summary Получение полной информации по товару
   * @request GET:/goods/{goods_id}
   * @secure
   */
  export namespace GoodsGetGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /**
       * Получить атрибуты
       * @default false
       */
      get_attributes?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoods
   * @summary Редактирование базовых характеристик товара
   * @request PUT:/goods/{goods_id}
   * @secure
   */
  export namespace GoodsEditGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoods
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoods
   * @summary Удаление товара
   * @request DELETE:/goods/{goods_id}
   * @secure
   */
  export namespace GoodsDeleteGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoods
   * @summary Создание товара
   * @request POST:/goods
   * @secure
   */
  export namespace GoodsCreateGoods {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewGoods
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodsPack
   * @summary Создание спайки из существующего товара
   * @request POST:/goods/pack
   * @secure
   */
  export namespace GoodsCreateGoodsPack {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewGoodsPack
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsattributes4Variation
   * @summary Список атрибутов товара, которые доступны для создания вариации
   * @request GET:/goods/{goods_id}/attributes4variation
   * @secure
   */
  export namespace GoodsGetGoodsattributes4Variation {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsAttribute4Variation[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodsvariation
   * @summary Создание вариации товара
   * @request POST:/goods/{goods_id}/variation
   * @secure
   */
  export namespace GoodsCreateGoodsvariation {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsVariation
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsproducts
   * @summary Список продуктов по товару
   * @request GET:/goods/{goods_id}/products
   * @secure
   */
  export namespace GoodsGetGoodsproducts {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsProduct[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodscompetitors
   * @summary Список конкурентов по товару
   * @request GET:/goods/{goods_id}/competitors
   * @secure
   */
  export namespace GoodsGetGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Competitor[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoodscompetitors
   * @summary Изменить конкурента товара
   * @request PUT:/goods/{goods_id}/competitors
   * @secure
   */
  export namespace GoodsEditGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = Competitor
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodscompetitors
   * @summary Добавить конкурента к товару
   * @request POST:/goods/{goods_id}/competitors
   * @secure
   */
  export namespace GoodsCreateGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = Competitor
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodscompetitors
   * @summary Удалить конкурента из товара
   * @request DELETE:/goods/{goods_id}/competitors/{competitor_id}
   * @secure
   */
  export namespace GoodsDeleteGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Competitor Id */
      competitorId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsTransformGoodsToAssortment
   * @summary Преобразование товара в ассортиментную карточку
   * @request POST:/goods/{goods_id}/assortment
   * @secure
   */
  export namespace GoodsTransformGoodsToAssortment {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsAssortment[]
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsTransformAssortmentToGoods
   * @summary Преобразование ассортиментной карточки в обычный товар
   * @request DELETE:/goods/{goods_id}/assortment
   * @secure
   */
  export namespace GoodsTransformAssortmentToGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodsAssortment
   * @summary Добавление другого товара в ассортиментный товар
   * @request POST:/goods/{goods_id}/assortment/{assortment_id}
   * @secure
   */
  export namespace GoodsCreateGoodsAssortment {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Assortment Id */
      assortmentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodsAssortment
   * @summary Удаление другого товара из ассортиментного товара
   * @request DELETE:/goods/{goods_id}/assortment/{assortment_id}
   * @secure
   */
  export namespace GoodsDeleteGoodsAssortment {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Assortment Id */
      assortmentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsTransformGoodsToBundle
   * @summary Преобразование товара в спайку или комплект
   * @request POST:/goods/{goods_id}/components
   * @secure
   */
  export namespace GoodsTransformGoodsToBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsBundleComponent[]
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsTransformBundleToGoods
   * @summary Преобразование спайки или комплекта в обычный товар
   * @request DELETE:/goods/{goods_id}/components
   * @secure
   */
  export namespace GoodsTransformBundleToGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsEditComponentInBundle
   * @summary Изменение количества у компонента в спайке или комплекте
   * @request PUT:/goods/{goods_id}/components/{component_id}
   * @secure
   */
  export namespace GoodsEditComponentInBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Component Id */
      componentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsBundleComponent
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsAddComponentToBundle
   * @summary Добавление нового компонента в комплект
   * @request POST:/goods/{goods_id}/components/{component_id}
   * @secure
   */
  export namespace GoodsAddComponentToBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Component Id */
      componentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsBundleComponent
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsDelComponentFromBundle
   * @summary Удаление компонента из спайки или комплекта
   * @request DELETE:/goods/{goods_id}/components/{component_id}
   * @secure
   */
  export namespace GoodsDelComponentFromBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Component Id */
      componentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GoodsArchivateGoods
   * @summary Перенос товара в архив
   * @request PUT:/goods/{goods_id}/archive
   * @secure
   */
  export namespace GoodsArchivateGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsFromArchive
   * @summary Возвращение товара из архива
   * @request DELETE:/goods/{goods_id}/archive
   * @secure
   */
  export namespace GoodsGetGoodsFromArchive {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Массовое копирование фотографий из товара в продукт
   * @tags Goods, Goods
   * @name GoodsCopyPhotosFromGoods
   * @summary Массовое копирование фотографий из товара в продукт
   * @request PATCH:/goods/{goods_id}/photos
   * @secure
   */
  export namespace GoodsCopyPhotosFromGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Product Id */
      product_id: number
      /** Операция над медиа товара */
      method?: AppPimGoodsMediaModelsMediaGoodsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Копирование фотографии из товара в продукт
   * @tags Goods, Goods
   * @name GoodsCopyPhotoFromGoods
   * @summary Копирование фотографии из товара в продукт
   * @request PATCH:/goods/{goods_id}/photos/{media_id}
   * @secure
   */
  export namespace GoodsCopyPhotoFromGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Product Id */
      product_id: number
      /** Операция над медиа товара */
      method?: AppPimGoodsMediaModelsMediaGoodsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsChangelog
   * @summary Список изменений по товару
   * @request GET:/goods/{goods_id}/changelog
   * @secure
   */
  export namespace GoodsGetGoodsChangelog {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ChangeLog[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsThumbnail
   * @summary Получение миниатюры изображения товара
   * @request GET:/goods/{goods_id}/thumbnail
   * @secure
   */
  export namespace GoodsGetGoodsThumbnail {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height?: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodssupplier
   * @summary Добавление поставщика в товар
   * @request POST:/goods/{goods_id}/suppliers
   * @secure
   */
  export namespace GoodsCreateGoodssupplier {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsSupplier
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplier
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoodssupplier
   * @summary Изменение информации по поставщику товара
   * @request PUT:/goods/{goods_id}/suppliers/{supplier_id}
   * @secure
   */
  export namespace GoodsEditGoodssupplier {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsSupplier
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplier
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodssupplier
   * @summary Удаление информации по поставщику товара
   * @request DELETE:/goods/{goods_id}/suppliers/{supplier_id}
   * @secure
   */
  export namespace GoodsDeleteGoodssupplier {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodssuppliercode
   * @summary Добавление кода поставщика в товар
   * @request POST:/goods/{goods_id}/suppliers/{supplier_id}/codes
   * @secure
   */
  export namespace GoodsCreateGoodssuppliercode {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsSupplierCode
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierCode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoodssuppliercode
   * @summary Редактирование кода поставщика у товара
   * @request PUT:/goods/{goods_id}/suppliers/{supplier_id}/codes/{code_id}
   * @secure
   */
  export namespace GoodsEditGoodssuppliercode {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Code Id */
      codeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsSupplierCode
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierCode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodssuppliercode
   * @summary Удаление кода поставщика у товара
   * @request DELETE:/goods/{goods_id}/suppliers/{supplier_id}/codes/{code_id}
   * @secure
   */
  export namespace GoodsDeleteGoodssuppliercode {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Code Id */
      codeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodssupplierpackaging
   * @summary Добавление информации по упаковке поставщика в товар
   * @request POST:/goods/{goods_id}/suppliers/{supplier_id}/packaging
   * @secure
   */
  export namespace GoodsCreateGoodssupplierpackaging {
    export type RequestParams = {
      /**
       * Goods Id
       * @exclusiveMin 0
       * @max 9223372036854776000
       */
      goodsId: number
      /**
       * Supplier Id
       * @exclusiveMin 0
       * @max 9223372036854776000
       */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsSupplierPackaging
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierPackaging
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoodssupplierpackaging
   * @summary Редактирование информации по упаковке поставщика у товара
   * @request PUT:/goods/{goods_id}/suppliers/{supplier_id}/packaging/{packaging_id}
   * @secure
   */
  export namespace GoodsEditGoodssupplierpackaging {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Packaging Id */
      packagingId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsSupplierPackaging
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierPackaging
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodssupplierpackaging
   * @summary Удаление информации по упаковке поставщика у товара
   * @request DELETE:/goods/{goods_id}/suppliers/{supplier_id}/packaging/{packaging_id}
   * @secure
   */
  export namespace GoodsDeleteGoodssupplierpackaging {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Packaging Id */
      packagingId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsattributes
   * @summary Список атрибутов товара
   * @request GET:/goods/{goods_id}/attributes
   * @secure
   */
  export namespace GoodsGetGoodsattributes {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsAttributes
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodsattribute
   * @summary Сохранение нового атрибута товара
   * @request POST:/goods/{goods_id}/attributes
   * @secure
   */
  export namespace GoodsCreateGoodsattribute {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewAttribute
    export type RequestHeaders = {}
    export type ResponseBody = Attribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoodsattribute
   * @summary Редактирование атрибута товара
   * @request PUT:/goods/{goods_id}/attributes/{goodsattribute_id}
   * @secure
   */
  export namespace GoodsEditGoodsattribute {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Goodsattribute Id */
      goodsattributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditAttribute
    export type RequestHeaders = {}
    export type ResponseBody = Attribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodsattribute
   * @summary Удаление атрибута товара
   * @request DELETE:/goods/{goods_id}/attributes/{goodsattribute_id}
   * @secure
   */
  export namespace GoodsDeleteGoodsattribute {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Goodsattribute Id */
      goodsattributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodscontent
   * @summary Состав товара
   * @request GET:/goods/{goods_id}/content
   * @secure
   */
  export namespace GoodsGetGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody =
      AppPimGoodsContentModelsGoodscontentGoodsComponent[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodscontent
   * @summary Сохранение нового компонента в состав товара
   * @request POST:/goods/{goods_id}/content
   * @secure
   */
  export namespace GoodsCreateGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsComponent
    export type RequestHeaders = {}
    export type ResponseBody =
      AppPimGoodsContentModelsGoodscontentGoodsComponent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsEditGoodscontent
   * @summary Редактирование компонента в составе товара
   * @request PUT:/goods/{goods_id}/content/{content_id}
   * @secure
   */
  export namespace GoodsEditGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Content Id */
      contentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsComponent
    export type RequestHeaders = {}
    export type ResponseBody =
      AppPimGoodsContentModelsGoodscontentGoodsComponent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodscontent
   * @summary Удаление компонента из состава товара
   * @request DELETE:/goods/{goods_id}/content/{content_id}
   * @secure
   */
  export namespace GoodsDeleteGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Content Id */
      contentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsGetGoodsmedia
   * @summary Медиа файлы по товару
   * @request GET:/goods/{goods_id}/media
   * @secure
   */
  export namespace GoodsGetGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsChangeGoodsmedia
   * @summary Массовое изменение информации по медиа о товаре
   * @request PUT:/goods/{goods_id}/media
   * @secure
   */
  export namespace GoodsChangeGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsMedia
    export type RequestHeaders = {}
    export type ResponseBody = GoodsMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodsmedias
   * @summary Массовое удаление информации по медиа о товаре
   * @request DELETE:/goods/{goods_id}/media
   * @secure
   */
  export namespace GoodsDeleteGoodsmedias {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsUploadGoodsmedia
   * @summary Загрузка нового файла в товар
   * @request POST:/goods/{goods_id}/media/file
   * @secure
   */
  export namespace GoodsUploadGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = BodyGoodsUploadGoodsmedia
    export type RequestHeaders = {}
    export type ResponseBody = GoodsFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2> Проверка видео на соответствие требованиям
   * @tags Goods, Goods
   * @name GoodsCheckGoodsmediaFile
   * @summary Проверка видео на соответствие требованиям
   * @request POST:/goods/{goods_id}/media/file/validator
   * @secure
   */
  export namespace GoodsCheckGoodsmediaFile {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = BodyGoodsCheckGoodsmediaFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsCreateGoodsmediaurl
   * @summary Добавление ссылки на медиа в товар
   * @request POST:/goods/{goods_id}/media/url
   * @secure
   */
  export namespace GoodsCreateGoodsmediaurl {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = NewGoodsFileURL
    export type RequestHeaders = {}
    export type ResponseBody = GoodsFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2> Проверка видео на соответствие требованиям по URL
   * @tags Goods, Goods
   * @name GoodsCheckGoodsmediaUrl
   * @summary Проверка видео на соответствие требованиям по URL
   * @request POST:/goods/{goods_id}/media/url/validator
   * @secure
   */
  export namespace GoodsCheckGoodsmediaUrl {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = NewGoodsFileURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDeleteGoodsmedia
   * @summary Удаление информации по медиа о товаре
   * @request DELETE:/goods/{goods_id}/media/{media_id}
   * @secure
   */
  export namespace GoodsDeleteGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsDownloadGoodsmedia
   * @summary Скачать файла товара
   * @request GET:/goods/{goods_id}/media/file/{file_id}
   * @secure
   */
  export namespace GoodsDownloadGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GoodsGetMediaThumbnail
   * @summary Получение миниатюры изображения файла товара
   * @request GET:/goods/{goods_id}/media/filethumbnail/{file_id}
   * @secure
   */
  export namespace GoodsGetMediaThumbnail {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height?: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
}

export namespace Category {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех категорий в системе
   * @tags Category, Categories
   * @name CategoryGetCategories
   * @summary Получение списка категорий
   * @request GET:/categories
   * @secure
   */
  export namespace CategoryGetCategories {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор родительской категории
       * При необходимости можно отфильтровать возвращаемый список по определённой родительской категории. Если передать 0, то будут возвращены корневые категории.
       */
      parentcategory_id?: number
      /**
       * Поиск категорий, содержащих подстроку
       * Получение списка всех категорий, которые содержат определённую подстроку. Если у данной категории есть дочерние, то они также выводятся. Выводятся только конечные категории, то есть у которых нет дочерних.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Category[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name CategoryCreateCategory
   * @summary Создание категории
   * @request POST:/categories
   * @secure
   */
  export namespace CategoryCreateCategory {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewCategory
    export type RequestHeaders = {}
    export type ResponseBody = Category
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение дерева категорий в системе
   * @tags Category, Categories
   * @name CategoryGetTreeCategories
   * @summary Получение дерева категорий
   * @request GET:/categories/tree
   * @secure
   */
  export namespace CategoryGetTreeCategories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Category[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по категории
   * @tags Category, Categories
   * @name CategoryGetCategory
   * @summary Информация по категории
   * @request GET:/categories/{category_id}
   * @secure
   */
  export namespace CategoryGetCategory {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Category
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name CategoryEditCategory
   * @summary Редактирование категории
   * @request PUT:/categories/{category_id}
   * @secure
   */
  export namespace CategoryEditCategory {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditCategory
    export type RequestHeaders = {}
    export type ResponseBody = Category
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление категории из системы
   * @tags Category, Categories
   * @name CategoryDeleteCategory
   * @summary Удаление категории
   * @request DELETE:/categories/{category_id}
   * @secure
   */
  export namespace CategoryDeleteCategory {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех атрибутов у категории в системе
   * @tags Category, Categories
   * @name CategoryGetCategoryAttributes
   * @summary Получение списка атрибутов у категории
   * @request GET:/categories/{category_id}/attributes
   * @secure
   */
  export namespace CategoryGetCategoryAttributes {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {
      /**
       * Поиск атрибута/ов, содержащих подстроку
       * Получение списка одного/всех атрибута/ов,который/е содержит/ат определённую подстроку.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name CategoryCreateCategoryAttribute
   * @summary Создание атрибута у категории
   * @request POST:/categories/{category_id}/attributes
   * @secure
   */
  export namespace CategoryCreateCategoryAttribute {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewCategoryAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по атрибуту категории
   * @tags Category, Categories
   * @name CategoryGetCategoryAttribute
   * @summary Информация по атрибуту категории
   * @request GET:/categories/{category_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace CategoryGetCategoryAttribute {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name CategoryEditCategoryAttribute
   * @summary Редактирование атрибута категории
   * @request PUT:/categories/{category_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace CategoryEditCategoryAttribute {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditCategoryAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление атрибута для категории из системы
   * @tags Category, Categories
   * @name CategoryDeleteCategoryAttribute
   * @summary Удаление атрибута для конкретной категории
   * @request DELETE:/categories/{category_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace CategoryDeleteCategoryAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
      categoryId: string
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Attribute {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех атрибутов в системе
   * @tags Attribute, Attributes
   * @name AttributeGetAttributes
   * @summary Получение списка атрибутов
   * @request GET:/attributes
   * @secure
   */
  export namespace AttributeGetAttributes {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Лимит (макс 10)
       * @default 10
       */
      limit?: number
      /**
       * Параметр пагинации
       * @default 0
       */
      page?: number
      /**
       * Поиск атрибута/ов, содержащих подстроку
       * Получение списка одного/всех атрибута/ов, который/е содержит/ат определённую подстроку.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AttributesSearchResult
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Attribute, Attributes
   * @name AttributeCreateAttribute
   * @summary Создание атрибута
   * @request POST:/attributes
   * @secure
   */
  export namespace AttributeCreateAttribute {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CommonNewAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CommonAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по атрибуту
   * @tags Attribute, Attributes
   * @name AttributeGetAttribute
   * @summary Информация по атрибуту
   * @request GET:/attributes/{attribute_id}
   * @secure
   */
  export namespace AttributeGetAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CommonAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Attribute, Attributes
   * @name AttributeEditAttribute
   * @summary Редактирование атрибута
   * @request PUT:/attributes/{attribute_id}
   * @secure
   */
  export namespace AttributeEditAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = CommonEditAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CommonAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление атрибута из системы
   * @tags Attribute, Attributes
   * @name AttributeDeleteAttribute
   * @summary Удаление атрибута
   * @request DELETE:/attributes/{attribute_id}
   * @secure
   */
  export namespace AttributeDeleteAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Dictionary {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionaryGetAllDictionaryValues
   * @summary Список всех возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}
   * @secure
   */
  export namespace DictionaryGetAllDictionaryValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionarySearchDictionaryValues
   * @summary Список возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}/values
   * @secure
   */
  export namespace DictionarySearchDictionaryValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {
      /**
       * Поиск значения, содержащих подстроку
       * Получение списка одного/всех значений, который/е содержит/ат определённую подстроку.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Value[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionaryCreateDictionaryValue
   * @summary Создание значения у словаря
   * @request POST:/dictionaries/{dictionary_id}/values
   * @secure
   */
  export namespace DictionaryCreateDictionaryValue {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewValue
    export type RequestHeaders = {}
    export type ResponseBody = Value
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionaryEditDictionaryValue
   * @summary Изменение значения у словаря
   * @request PUT:/dictionaries/{dictionary_id}/values/{value_id}
   * @secure
   */
  export namespace DictionaryEditDictionaryValue {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
      /** Value Id */
      valueId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditValue
    export type RequestHeaders = {}
    export type ResponseBody = Value
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionaryDeleteDictionaryValue
   * @summary Удаление значения у словаря
   * @request DELETE:/dictionaries/{dictionary_id}/values/{value_id}
   * @secure
   */
  export namespace DictionaryDeleteDictionaryValue {
    export type RequestParams = {
      /** Value Id */
      valueId: number
      dictionaryId: string
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionaryGetFilteredDictionaryValues
   * @summary Отфильтрованный список возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}/filter/{search_string}
   * @secure
   */
  export namespace DictionaryGetFilteredDictionaryValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
      /** Подстрока поиска */
      searchString: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DictionaryGetDictionaryEmptyValues
   * @summary Отфильтрованный список возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}/filter/
   * @secure
   */
  export namespace DictionaryGetDictionaryEmptyValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Brand {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех брендов в системе
   * @tags Brand, Brands
   * @name BrandGetBrands
   * @summary Получение списка брендов
   * @request GET:/brands
   * @secure
   */
  export namespace BrandGetBrands {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Brand[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Brand, Brands
   * @name BrandCreateBrand
   * @summary Создание бренда
   * @request POST:/brands
   * @secure
   */
  export namespace BrandCreateBrand {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewBrand
    export type RequestHeaders = {}
    export type ResponseBody = Brand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по бренду
   * @tags Brand, Brands
   * @name BrandGetBrand
   * @summary Информация по бренду
   * @request GET:/brands/{brand_id}
   * @secure
   */
  export namespace BrandGetBrand {
    export type RequestParams = {
      /** Brand Id */
      brandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Brand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Brand, Brands
   * @name BrandEditBrand
   * @summary Редактирование бренда
   * @request PUT:/brands/{brand_id}
   * @secure
   */
  export namespace BrandEditBrand {
    export type RequestParams = {
      /** Brand Id */
      brandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingBrand
    export type RequestHeaders = {}
    export type ResponseBody = Brand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление бренда из системы
   * @tags Brand, Brands
   * @name BrandDeleteBrand
   * @summary Удаление бренда
   * @request DELETE:/brands/{brand_id}
   * @secure
   */
  export namespace BrandDeleteBrand {
    export type RequestParams = {
      /** Brand Id */
      brandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Season {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех сезонов в системе
   * @tags Season, Seasons
   * @name SeasonGetSeasons
   * @summary Получение списка сезонов
   * @request GET:/seasons
   * @secure
   */
  export namespace SeasonGetSeasons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Season[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Season, Seasons
   * @name SeasonCreateSeason
   * @summary Создание сезона
   * @request POST:/seasons
   * @secure
   */
  export namespace SeasonCreateSeason {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewSeason
    export type RequestHeaders = {}
    export type ResponseBody = Season
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по сезону
   * @tags Season, Seasons
   * @name SeasonGetSeason
   * @summary Информация по сезону
   * @request GET:/seasons/{season_id}
   * @secure
   */
  export namespace SeasonGetSeason {
    export type RequestParams = {
      /** Season Id */
      seasonId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Season
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Season, Seasons
   * @name SeasonEditSeason
   * @summary Редактирование сезона
   * @request PUT:/seasons/{season_id}
   * @secure
   */
  export namespace SeasonEditSeason {
    export type RequestParams = {
      /** Season Id */
      seasonId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingSeason
    export type RequestHeaders = {}
    export type ResponseBody = Season
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление сезона из системы
   * @tags Season, Seasons
   * @name SeasonDeleteSeason
   * @summary Удаление сезона
   * @request DELETE:/seasons/{season_id}
   * @secure
   */
  export namespace SeasonDeleteSeason {
    export type RequestParams = {
      /** Season Id */
      seasonId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Country {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех стран в системе
   * @tags Country, Dictionaries
   * @name CountryGetCountries
   * @summary Получение списка стран
   * @request GET:/countries
   * @secure
   */
  export namespace CountryGetCountries {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Country[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по стране
   * @tags Country, Dictionaries
   * @name CountryGetCountry
   * @summary Информация по стране
   * @request GET:/countries/{country_id}
   * @secure
   */
  export namespace CountryGetCountry {
    export type RequestParams = {
      /** Country Id */
      countryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Country
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace BulkOperation {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetBulkOperations
   * @summary Получение списка массовых операций
   * @request GET:/ops
   * @secure
   */
  export namespace BulkOperationGetBulkOperations {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Bulkoperation Id */
      bulkoperation_id?: number
      /** Bulkoperation Type Id */
      bulkoperation_type_id?: number
      /** User Id */
      user_id?: number
      /**
       * Page
       * @default 0
       */
      page?: number
      /**
       * Page Size
       * @default 15
       */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BulkOperation[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetBulkOperationTypes
   * @summary Получение типов массовых операций
   * @request GET:/ops/types
   * @secure
   */
  export namespace BulkOperationGetBulkOperationTypes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BulkOperationType[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetExcelTemplate
   * @summary Получение шаблона для загрузки товаров в формате Excel
   * @request GET:/ops/goods
   * @secure
   */
  export namespace BulkOperationGetExcelTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationCreateGoodsexcel
   * @summary Загрузка товаров в формате Excel
   * @request POST:/ops/goods
   * @secure
   */
  export namespace BulkOperationCreateGoodsexcel {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationCreateGoodsexcel
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationMasseGoodsArchiving
   * @summary Массовая архивация товаров
   * @request PATCH:/ops/goods
   * @secure
   */
  export namespace BulkOperationMasseGoodsArchiving {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID причины архивации товара */
      archivationreason_id?: number
      /** Комментарий архивации товара */
      archivation_comments?: string
      /** Тип операции над товарами */
      method: AppPimExcelTemplateModelsGoodsGoodsMethod
    }
    export type RequestBody = BodyBulkOperationMasseGoodsArchiving
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetBulkOperation
   * @summary Получение массовой операции
   * @request GET:/ops/{bulkoperation_id}
   * @secure
   */
  export namespace BulkOperationGetBulkOperation {
    export type RequestParams = {
      /** Bulkoperation Id */
      bulkoperationId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BulkOperation
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetGoodsSuppliersTemplate
   * @summary Получение шаблона для массового обновления информации о поставщиках товара
   * @request GET:/ops/goods/change/suppliers
   * @secure
   */
  export namespace BulkOperationGetGoodsSuppliersTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Contragent Id
       * Идентификатор поставщика
       */
      contragent_id?: number
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeGoodsSuppliers
   * @summary Массовое обновление информации о поставщиках товара
   * @request POST:/ops/goods/change/suppliers
   * @secure
   */
  export namespace BulkOperationChangeGoodsSuppliers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationChangeGoodsSuppliers
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetWbPricesTemplate
   * @summary Получение шаблона для массового обновления цен на Wildberries
   * @request POST:/ops/products/wb/change/price/template
   * @secure
   */
  export namespace BulkOperationGetWbPricesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = ChangePriceTemplateFilters
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeWbPrices
   * @summary Массовое обновление цен на Wildberries
   * @request POST:/ops/products/wb/change/price
   * @secure
   */
  export namespace BulkOperationChangeWbPrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Дата изменения цены
       * @format date-time
       */
      change_date?: string
      /** Идентификатор причины изменения цены */
      pricechangereason_id: number
      /**
       * Дата возврата к исходной цене
       * @format date-time
       */
      revert_date?: string
    }
    export type RequestBody = BodyBulkOperationChangeWbPrices
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetOzonPricesTemplate
   * @summary Получение шаблона для массового обновления цен на Ozon
   * @request POST:/ops/products/ozon/change/price/template
   * @secure
   */
  export namespace BulkOperationGetOzonPricesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = ChangePriceTemplateFilters
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeOzonPrices
   * @summary Массовое обновление цен на Ozon
   * @request POST:/ops/products/ozon/change/price
   * @secure
   */
  export namespace BulkOperationChangeOzonPrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Дата изменения цены
       * @format date-time
       */
      change_date?: string
      /** Идентификатор причины изменения цены */
      pricechangereason_id: number
      /**
       * Дата возврата к исходной цене
       * @format date-time
       */
      revert_date?: string
    }
    export type RequestBody = BodyBulkOperationChangeOzonPrices
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetSimplePricesTemplate
   * @summary Получение шаблона для массового обновления цен продуктов упрощенного вида
   * @request POST:/ops/products/simple/change/price/template
   * @secure
   */
  export namespace BulkOperationGetSimplePricesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = ChangePriceTemplateFilters
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeSimplePrices
   * @summary Массовое обновление цен продуктов упрощенного вида
   * @request POST:/ops/products/simple/change/price
   * @secure
   */
  export namespace BulkOperationChangeSimplePrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Дата изменения цены
       * @format date-time
       */
      change_date?: string
      /** Идентификатор причины изменения цены */
      pricechangereason_id: number
      /**
       * Дата возврата к исходной цене
       * @format date-time
       */
      revert_date?: string
    }
    export type RequestBody = BodyBulkOperationChangeSimplePrices
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetNewSimpleProductsTemplate
   * @summary Получение шаблона для массового создания Simple Product
   * @request GET:/ops/products/simple/product
   * @secure
   */
  export namespace BulkOperationGetNewSimpleProductsTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationNewSimpleProducts
   * @summary Массовое создание Simple Product
   * @request POST:/ops/products/simple/product
   * @secure
   */
  export namespace BulkOperationNewSimpleProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Использовать медиа товара */
      use_goods_media: boolean
    }
    export type RequestBody = BodyBulkOperationNewSimpleProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetWbProductTemplate
   * @summary Получение шаблона для массового создания продуктов Wildberries
   * @request GET:/ops/products/wb/product
   * @secure
   */
  export namespace BulkOperationGetWbProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Название категории */
      category?: string
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** @default "excel" */
      template_format?: TemplateFormat
      /** @default "with_category" */
      template_type?: WBTemplateType
      /**
       * BrandInputType:
       * fixed - задается на этапе скачивания шаблона
       * variable - заполняется в шаблоне
       * @default "variable"
       */
      brand?: BrandInputType
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationNewWbProducts
   * @summary Массовое создание продуктов Wildberries
   * @request POST:/ops/products/wb/product
   * @secure
   */
  export namespace BulkOperationNewWbProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Название категории */
      category?: string
      /** Использовать медиа товара */
      use_goods_media: boolean
      /** Название бренда */
      brand?: string
      /** @default "with_category" */
      template_type?: WBTemplateType
    }
    export type RequestBody = BodyBulkOperationNewWbProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetOzonProductTemplate
   * @summary Получение шаблона для массового создания продуктов Ozon
   * @request GET:/ops/products/ozon/product
   * @secure
   */
  export namespace BulkOperationGetOzonProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор категории
       * @exclusiveMin 0
       */
      category_id?: number
      /**
       * Идентификатор родительской категории
       * @exclusiveMin 0
       */
      parent_category_id?: number
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** @default "excel" */
      template_format?: TemplateFormat
      /** @default "by_category" */
      template_type?: OzonTemplateType
      /**
       * BrandInputType:
       * fixed - задается на этапе скачивания шаблона
       * variable - заполняется в шаблоне
       * @default "variable"
       */
      brand?: BrandInputType
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationNewOzonProducts
   * @summary Массовое создание продуктов Ozon
   * @request POST:/ops/products/ozon/product
   * @secure
   */
  export namespace BulkOperationNewOzonProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Использовать медиа товара */
      use_goods_media: boolean
      /**
       * Идентификатор категории
       * @exclusiveMin 0
       */
      category_id?: number
      /**
       * Идентификатор родительской категории
       * @exclusiveMin 0
       */
      parent_category_id?: number
      /**
       * Идентификатор бренда
       * @min 0
       */
      brand_id?: number
      /** @default "by_category" */
      template_type?: OzonTemplateType
    }
    export type RequestBody = BodyBulkOperationNewOzonProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetOzonChangeProductTemplate
   * @summary Получение шаблона для массового редактирования продуктов Ozon
   * @request GET:/ops/products/ozon/change/product
   * @secure
   */
  export namespace BulkOperationGetOzonChangeProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор бренда
       * @min 0
       */
      brand_id?: number
      /**
       * Идентификатор категории
       * @exclusiveMin 0
       */
      category_id: number
      /**
       * Идентификатор родительской категории
       * @exclusiveMin 0
       */
      parent_category_id: number
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Начало диапазона для даты создания
       * @format date
       */
      date_from?: string
      /**
       * Конец диапазона для даты создания
       * @format date
       */
      date_to?: string
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeOzonProducts
   * @summary Массовое редактирование продуктов Ozon
   * @request POST:/ops/products/ozon/change/product
   * @secure
   */
  export namespace BulkOperationChangeOzonProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Идентификатор категории
       * @exclusiveMin 0
       */
      category_id: number
      /**
       * Идентификатор родительской категории
       * @exclusiveMin 0
       */
      parent_category_id: number
      /**
       * Идентификатор бренда
       * @min 0
       */
      brand_id?: number
    }
    export type RequestBody = BodyBulkOperationChangeOzonProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetWbChangeProductTemplate
   * @summary Получение шаблона для массового редактирования продуктов Wildberries
   * @request GET:/ops/products/wb/change/product
   * @secure
   */
  export namespace BulkOperationGetWbChangeProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Бренд */
      brand?: string
      /**
       * Категория товара
       * @maxLength 255
       */
      category: string
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Начало диапазона для даты создания
       * @format date
       */
      date_from?: string
      /**
       * Конец диапазона для даты создания
       * @format date
       */
      date_to?: string
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeWbProducts
   * @summary Массовое редактирование продуктов Wildberries
   * @request POST:/ops/products/wb/change/product
   * @secure
   */
  export namespace BulkOperationChangeWbProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Категория товара
       * @maxLength 255
       */
      category: string
      /** Идентификатор бренда */
      brand?: string
    }
    export type RequestBody = BodyBulkOperationChangeWbProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetSimpleChangeProductTemplate
   * @summary Получение шаблона для массового редактирования продуктов упрощенного вида
   * @request GET:/ops/products/simple/change/product
   * @secure
   */
  export namespace BulkOperationGetSimpleChangeProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Начало диапазона для даты создания
       * @format date
       */
      date_from?: string
      /**
       * Конец диапазона для даты создания
       * @format date
       */
      date_to?: string
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeSimpleProducts
   * @summary Массовое редактирование продуктов упрощенного вида
   * @request POST:/ops/products/simple/change/product
   * @secure
   */
  export namespace BulkOperationChangeSimpleProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
    }
    export type RequestBody = BodyBulkOperationChangeSimpleProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Применить операцию к элементам массовой операции
   * @tags Bulk Operation
   * @name BulkOperationPatchProduct
   * @summary Применить операцию к элементам массовой операции
   * @request PATCH:/ops/products
   * @secure
   */
  export namespace BulkOperationPatchProduct {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Batch Id */
      batch_id: string
      /** Операция над продуктами */
      method: ProductsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetGoodsTemplate
   * @summary Получение шаблона для массовой архивации товаров
   * @request GET:/ops/goods/template
   * @secure
   */
  export namespace BulkOperationGetGoodsTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
      /** Тип операции над товарами */
      method: AppPimExcelTemplateModelsGoodsGoodsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetProductsTemplate
   * @summary Получение шаблона для массовой архивации продуктов
   * @request GET:/ops/products/template
   * @secure
   */
  export namespace BulkOperationGetProductsTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
      /** Операция над продуктами */
      method: ProductsBulkMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationMasseProductsArchiving
   * @summary Массовая архивация продуктов
   * @request PATCH:/ops/product
   * @secure
   */
  export namespace BulkOperationMasseProductsArchiving {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID причины архивации продукта */
      archivationreason_id?: number
      /** Комментарий архивации продукта */
      archivation_comments?: string
      /** Операция над продуктами */
      method: ProductsBulkMethod
    }
    export type RequestBody = BodyBulkOperationMasseProductsArchiving
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetChangeProductAttributesTemplate
   * @summary Получение шаблона для массового редактирования атрибутов продуктов
   * @request GET:/ops/products/change/product/attributes
   * @secure
   */
  export namespace BulkOperationGetChangeProductAttributesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор компании */
      company_id?: number
      /** Идентификатор маркетплейса */
      marketplace_id?: number
      /** Идентификатор поставщика */
      supplier_id?: number
      /** Идентификатор кабинета */
      companymarketplace_id?: number
      /** Идентификатор бренда продукта */
      brand_id?: number
      /** Флаг получения пустого шаблона */
      blank_template?: boolean
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationChangeProductsAttributes
   * @summary Массовое редактирование атрибутов продуктов
   * @request POST:/ops/products/change/product/attributes
   * @secure
   */
  export namespace BulkOperationChangeProductsAttributes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationChangeProductsAttributes
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetChangeProductDescriptions
   * @summary Получение шаблона для массового редактирования наименования и описания продуктов
   * @request GET:/ops/products/change/product/namedescription
   * @secure
   */
  export namespace BulkOperationGetChangeProductDescriptions {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор компании */
      company_id?: number
      /** Идентификатор маркетплейса */
      marketplace_id?: number
      /** Идентификатор кабинета */
      companymarketplace_id?: number
      /** Идентификатор бренда продукта */
      brand_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationPostChangeProductDescriptions
   * @summary Массовое редактирование наименования и описания продуктов
   * @request POST:/ops/products/change/product/namedescription
   * @secure
   */
  export namespace BulkOperationPostChangeProductDescriptions {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationPostChangeProductDescriptions
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetGoodsCreateSuppliercode
   * @summary Получение шаблона для массового добавления нового кода поставщика в товар
   * @request GET:/ops/goods/create/suppliercode
   * @secure
   */
  export namespace BulkOperationGetGoodsCreateSuppliercode {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Contragent Id
       * Идентификатор поставщика
       */
      contragent_id?: number
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationPostGoodsCreateSuppliercode
   * @summary Массовое добавление нового кода поставщика в товар
   * @request POST:/ops/goods/create/suppliercode
   * @secure
   */
  export namespace BulkOperationPostGoodsCreateSuppliercode {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationPostGoodsCreateSuppliercode
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetAddGoodsCompetitorTemplate
   * @summary Получение шаблона для массового добавления конкурентов
   * @request GET:/ops/goods/add/competitors/template
   * @secure
   */
  export namespace BulkOperationGetAddGoodsCompetitorTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Marketplace Id
       * Маркетплейс
       */
      marketplace_id: number
      /**
       * Признак – учитывать в расчете цен
       * @default false
       */
      reckoned_flag?: boolean
      /**
       * Признак - тот же товар или аналог
       * @default false
       */
      analogue_flag?: boolean
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationPostAddAddGoodsCompetitor
   * @summary Массовое добавления конкурентов
   * @request POST:/ops/goods/add/competitors
   * @secure
   */
  export namespace BulkOperationPostAddAddGoodsCompetitor {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Marketplace Id
       * Маркетплейс
       */
      marketplace_id: number
      /**
       * Признак – учитывать в расчете цен
       * @default false
       */
      reckoned_flag?: boolean
      /**
       * Признак - тот же товар или аналог
       * @default false
       */
      analogue_flag?: boolean
    }
    export type RequestBody = BodyBulkOperationPostAddAddGoodsCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetDeleteGoodsCompetitorTemplate
   * @summary Получение шаблона для массового удаления конкурентов
   * @request GET:/ops/goods/delete/competitors/template
   * @secure
   */
  export namespace BulkOperationGetDeleteGoodsCompetitorTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationPostDeleteGoodsCompetitor
   * @summary Массовое удаление конкурентов с товара
   * @request POST:/ops/goods/delete/competitors
   * @secure
   */
  export namespace BulkOperationPostDeleteGoodsCompetitor {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationPostDeleteGoodsCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationGetChangeReviewSettingTemplate
   * @summary Получение шаблона для настройки бота по отзывам/вопросам
   * @request GET:/ops/bot/review
   * @secure
   */
  export namespace BulkOperationGetChangeReviewSettingTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Тип */
      review_type_id: ReviewTypeOptions
      /** Тип */
      limit?: number
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name BulkOperationPostChangeReviewSetting
   * @summary Массовое настройка бота по отзывам/вопросам
   * @request POST:/ops/bot/review
   * @secure
   */
  export namespace BulkOperationPostChangeReviewSetting {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Тип */
      review_type_id: ReviewTypeOptions
    }
    export type RequestBody = BodyBulkOperationPostChangeReviewSetting
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Administration {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка контрагентов
   * @tags Administration, Administration operations
   * @name AdministrationGetContragents
   * @summary Получение списка контрагентов
   * @request GET:/admin/contragents
   * @secure
   */
  export namespace AdministrationGetContragents {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ContragentShortInfo[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationCreateContragent
   * @summary Создание контрагента
   * @request POST:/admin/contragents
   * @secure
   */
  export namespace AdministrationCreateContragent {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewContragent
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по контрагенту
   * @tags Administration, Administration operations
   * @name AdministrationGetContragent
   * @summary Информация по контрагенту
   * @request GET:/admin/contragents/{contragent_id}
   * @secure
   */
  export namespace AdministrationGetContragent {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationEditContragent
   * @summary Редактирование контрагента
   * @request PUT:/admin/contragents/{contragent_id}
   * @secure
   */
  export namespace AdministrationEditContragent {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingContragent
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление контрагента из системы
   * @tags Administration, Administration operations
   * @name AdministrationDeleteContragent
   * @summary Удаление контрагента
   * @request DELETE:/admin/contragents/{contragent_id}
   * @secure
   */
  export namespace AdministrationDeleteContragent {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationChangeContragentMaster
   * @summary Редактирование мастера контрагента
   * @request PUT:/admin/contragents/{contragent_id}/master
   * @secure
   */
  export namespace AdministrationChangeContragentMaster {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {
      /** Systemuser Id */
      systemuser_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationEditContragentMaster
   * @summary Добавление мастера контрагента
   * @request POST:/admin/contragents/{contragent_id}/master
   * @secure
   */
  export namespace AdministrationEditContragentMaster {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {
      /** Systemuser Id */
      systemuser_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationDeleteContragentMaster
   * @summary Удаления мастера контрагента
   * @request DELETE:/admin/contragents/{contragent_id}/master
   * @secure
   */
  export namespace AdministrationDeleteContragentMaster {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {
      /** Systemuser Id */
      systemuser_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationLoadContragentOrderform
   * @summary Загрузка бланка заказа по форме поставщика
   * @request POST:/admin/contragents/{contragent_id}/orderform
   * @secure
   */
  export namespace AdministrationLoadContragentOrderform {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyAdministrationLoadContragentOrderform
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationDeleteContragentOrderform
   * @summary Удаление бланка заказа по форме поставщика
   * @request DELETE:/admin/contragents/{contragent_id}/orderform
   * @secure
   */
  export namespace AdministrationDeleteContragentOrderform {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationCheckContragentOrderform
   * @summary Проверка бланка заказа по форме поставщика
   * @request PATCH:/admin/contragents/{contragent_id}/orderform
   * @secure
   */
  export namespace AdministrationCheckContragentOrderform {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationCreateContragentBrand
   * @summary Добавление бренда товаров контрагента
   * @request POST:/admin/contragents/{contragent_id}/brands
   * @secure
   */
  export namespace AdministrationCreateContragentBrand {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewContragentBrand
    export type RequestHeaders = {}
    export type ResponseBody = ContragentBrand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationDeleteContragentBrand
   * @summary Удаление бренда товаров контрагента
   * @request DELETE:/admin/contragents/{contragent_id}/brands/{contragentbrand_id}
   * @secure
   */
  export namespace AdministrationDeleteContragentBrand {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
      /** Contragentbrand Id */
      contragentbrandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationCreateContragentUser
   * @summary Добавление менеджера контрагента
   * @request POST:/admin/contragents/{contragent_id}/users
   * @secure
   */
  export namespace AdministrationCreateContragentUser {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewContragentUser
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationUpdateContragentUser
   * @summary Изменение даты закрытия доступа менеджера контрагента
   * @request PUT:/admin/contragents/{contragent_id}/users/{contragentuser_id}
   * @secure
   */
  export namespace AdministrationUpdateContragentUser {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditContragentUser
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationDeleteContragentUser
   * @summary Удаление менеджера контрагента
   * @request DELETE:/admin/contragents/{contragent_id}/users/{contragentuser_id}
   * @secure
   */
  export namespace AdministrationDeleteContragentUser {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Список пользователей
   * @tags Administration
   * @name AdministrationGetUsers
   * @summary Список пользователей
   * @request GET:/admin/users
   * @secure
   */
  export namespace AdministrationGetUsers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PIMUser[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Информация о доступных контрагентах пользователя
   * @tags Administration
   * @name AdministrationGetUserContragents
   * @summary Информация о доступных контрагентах пользователя
   * @request GET:/admin/users/{user_id}/contragents
   * @secure
   */
  export namespace AdministrationGetUserContragents {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationCreateUserContragent
   * @summary Добавление менеджера контрагента
   * @request POST:/admin/users/{user_id}/contragents
   * @secure
   */
  export namespace AdministrationCreateUserContragent {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewUserContragent
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationUpdateUserContragent
   * @summary Изменение даты закрытия доступа менеджера контрагента
   * @request PUT:/admin/users/{user_id}/contragents/{contragentuser_id}
   * @secure
   */
  export namespace AdministrationUpdateUserContragent {
    export type RequestParams = {
      /** User Id */
      userId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditContragentUser
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationDeleteUserContragent
   * @summary Удаление менеджера контрагента
   * @request DELETE:/admin/users/{user_id}/contragents/{contragentuser_id}
   * @secure
   */
  export namespace AdministrationDeleteUserContragent {
    export type RequestParams = {
      /** User Id */
      userId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка складов по маркетплейсу
   * @tags Administration, Administration operations
   * @name AdministrationGetMarketplacewarehouses
   * @summary Получение списка складов по маркетплейсу
   * @request GET:/admin/marketplaces/{marketplace_id}/marketplacewarehouses
   * @secure
   */
  export namespace AdministrationGetMarketplacewarehouses {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceWarehouse[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name AdministrationUpdateMarketplacewarehouses
   * @summary Изменить параметры склада маркетплейса
   * @request PUT:/admin/marketplaces/{marketplace_id}/marketplacewarehouses/{marketplacewarehouse_id}
   * @secure
   */
  export namespace AdministrationUpdateMarketplacewarehouses {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
      /** Marketplacewarehouse Id */
      marketplacewarehouseId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMarketplaceWarehouse
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceWarehouse
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace PriceWizard {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PriceWizardGetIntervals
   * @summary Получение списка возможных временных интервалов для формирования предложений
   * @request GET:/pricecontrol/interval
   * @secure
   */
  export namespace PriceWizardGetIntervals {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AdviceIntervalOption[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PriceWizardGetPriceSettings
   * @summary Получение списка продуктов вместе с настройками управления ценами
   * @request GET:/pricecontrol/pricesettings
   * @secure
   */
  export namespace PriceWizardGetPriceSettings {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Поставщик */
      supplier_id?: number
      /** Маркетплейс */
      marketplace_id?: number
      /** Продавец */
      company_id?: number
      /** Строка для поиска */
      search_string?: string
      /** Бренд */
      brand_id?: number
      /** Столбец для сортировки */
      sort?: string
      /**
       * Порядок сортировки
       * @default "asc"
       */
      order?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductWithPriceSettings[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PriceWizardPutPriceSettings
   * @summary Редактирование данных для управления ценой
   * @request PUT:/pricecontrol/pricesettings
   * @secure
   */
  export namespace PriceWizardPutPriceSettings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = EditPriceSettings
    export type RequestHeaders = {}
    export type ResponseBody = PriceSettings
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PriceWizardGetPriceAdvice
   * @summary Получение списка рекомендаций к изменению цены
   * @request GET:/pricecontrol/priceadvice
   * @secure
   */
  export namespace PriceWizardGetPriceAdvice {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Поставщик */
      supplier_id?: number
      /** Маркетплейс */
      marketplace_id?: number
      /** Продавец */
      company_id?: number
      /** Строка для поиска */
      search_string?: string
      /** Бренд */
      brand_id?: number
      /** Столбец для сортировки */
      sort?: string
      /**
       * Порядок сортировки
       * @default "asc"
       */
      order?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductPriceAdviceWithSettings[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PriceWizardPatchPriceAdvice
   * @summary Применить действие к рекомендации цены
   * @request PATCH:/pricecontrol/priceadvice
   * @secure
   */
  export namespace PriceWizardPatchPriceAdvice {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Возможные действия, совершаемые над рекомендованной ценой */
      action: PriceAdviceAction
    }
    export type RequestBody = PriceAdviceIdentifier[]
    export type RequestHeaders = {}
    export type ResponseBody = PriceAdvice[]
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace Miscellaneous {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка контрагентов
   * @tags Miscellaneous, Contragent
   * @name MiscellaneousGetContragents
   * @summary Получение списка контрагентов
   * @request GET:/contragents
   * @secure
   */
  export namespace MiscellaneousGetContragents {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Только поставщики
       * При необходимости можно отфильтровать возвращаемый список контрагентов, чтобы вернулись только те, которые являются поставщиками товаров
       * @default false
       */
      only_suppliers?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ContragentShortInfo[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех маркетплейсов в системе
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetMarketplaces
   * @summary Получение списка маркетплейсов
   * @request GET:/marketplaces
   * @secure
   */
  export namespace MiscellaneousGetMarketplaces {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Marketplace[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка складов по маркетплейсу
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetMarketplacewarehouses
   * @summary Получение списка складов по маркетплейсу
   * @request GET:/marketplacewarehouses
   * @secure
   */
  export namespace MiscellaneousGetMarketplacewarehouses {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор маркетплейса */
      marketplace_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceWarehouse[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка кабинетов компании
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetCompanymarketplaces
   * @summary Получение списка кабинетов компании
   * @request GET:/companymarketplaces
   * @secure
   */
  export namespace MiscellaneousGetCompanymarketplaces {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор компании */
      company_id: number
      /** Идентификатор маркетплейса */
      marketplace_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyMarketplace[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех единиц измерения
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetUnits
   * @summary Получение списка единиц измерения
   * @request GET:/units
   * @secure
   */
  export namespace MiscellaneousGetUnits {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Применимость для упаковки
       * При необходимости можно отфильтровать возвращаемый список единиц измерения, чтобы вернулись только те, которые можно использовать для измерения упаковки товара
       * @default false
       */
      packaging_support?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Unit[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех видов упаковки
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetPackagingtypes
   * @summary Получение списка видов упаковки
   * @request GET:/packagingtypes
   * @secure
   */
  export namespace MiscellaneousGetPackagingtypes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PackagingType[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка требований к упаковке
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetPackagingrequirements
   * @summary Получение списка требований к упаковке
   * @request GET:/packagingrequirements
   * @secure
   */
  export namespace MiscellaneousGetPackagingrequirements {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PackagingRequirement[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка причин архивации
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetArchivationreasons
   * @summary Получение списка причин архивации
   * @request GET:/archivationreasons
   * @secure
   */
  export namespace MiscellaneousGetArchivationreasons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ArchivationReason[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка причин изменения цены
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetPricechangereasons
   * @summary Получение списка причин изменения цены
   * @request GET:/pricechangereasons
   * @secure
   */
  export namespace MiscellaneousGetPricechangereasons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PriceChangeReason[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех кодов с описанием, в которых встречается переданная строка поиска
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousSearchTnveds
   * @summary Поиск кодов ТН ВЭД по строке
   * @request GET:/tnveds/{search_string}
   * @secure
   */
  export namespace MiscellaneousSearchTnveds {
    export type RequestParams = {
      /** Search String */
      searchString: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TNVEDCode[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Miscellaneous, Dictionaries
   * @name MiscellaneousGetContentcomponentvalues
   * @summary Список возможных значений для состава товара
   * @request GET:/goodscontent/filter/{search_string}
   * @secure
   */
  export namespace MiscellaneousGetContentcomponentvalues {
    export type RequestParams = {
      /** Подстрока поиска */
      searchString: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
}

//none
