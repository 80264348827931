/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationMasseGoodsArchiving, ImportMetadata, AppPimExcelTemplateModelsGoodsGoodsMethod } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** ID причины архивации товара */
    archivationreason_id?: number,
  /** Комментарий архивации товара */
    archivation_comments?: string,
  /** Тип операции над товарами */
    method: AppPimExcelTemplateModelsGoodsGoodsMethod,

},
  data: BodyBulkOperationMasseGoodsArchiving
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.patch<ResponseType>(`/pim/ops/goods`,data, { params: query }).then(res => res.data)


export const useMasseGoodsArchiving = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

