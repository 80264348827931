import { IUserInfo } from 'shared/services/interfaces/user'

import { IUserState } from './index'

export const userSelector = (state: { userReducer: IUserState }): IUserInfo =>
  state.userReducer.user

// export const userSettingsSelector = (state: {
//   userReducer: IUserState
// }): IUserSettings | undefined => state.userReducer.userSettings

export const authErrorMessage = (state: {
  userReducer: IUserState
}): string | null =>
  state.userReducer.error ? state.userReducer.error.message : null

export const errorMessageSelector = (state: {
  userReducer: IUserState
}): string | undefined => state.userReducer.error?.message
