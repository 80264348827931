import React from 'react'
import _uniqueId from 'lodash/uniqueId'
import { Button, TextField } from '@mui/material'
import { CheckboxField, Field } from 'shared/ui/components'
import { StyleChips } from 'shared/ui/styled/StyledChips'
import { useForm, useFormState } from 'react-final-form'

export const GoodsSyncModalForm = ({ handleSubmit }: { handleSubmit: () => void }) => {
  const { change } = useForm()
  const { values } = useFormState()

  return (
    <form onSubmit={handleSubmit}>
      <div style={ { padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px', width: '300px' } }>
        <Field
          name="syncMedia"
          label="Синхронизировать медиа"
          component={ CheckboxField }
          type="checkbox"
        />
        <Field
          name="skipMediaChecking"
          label="Пропустить проверку медиа"
          component={ CheckboxField }
          type="checkbox"
        />
        <TextField
          multiline={true}
          InputProps={ { autoComplete: 'off' } }
          size="small"
          label="Артикул (123, 1234, 12345)"
          maxRows={10}
          value={values.vendorCodes}
          onChange={(e) =>
            change('vendorCodes', e.target.value.split(/[\n,]/))
          }/>
        { (values.vendorCodes && values.vendorCodes?.length !== 0) ?
          <div style={ { display: 'flex', flexDirection: 'row', gap: '4px', flexWrap: 'wrap' } }>
            { values?.vendorCodes?.map(el => {
              if (el.length !== 0) return (
                <StyleChips
                  size="small"
                  key={ _uniqueId('vendorCodes-') } label={ el } onDelete={ () => {
                    change('vendorCodes', values.vendorCodes.filter(elem => elem !== el ))
                  } } />
              )
              return null
            }) }
          </div>
          :
          null
        }
        <Field
          name="updateGoods"
          label="Обновить товары"
          component={ CheckboxField }
          type="checkbox"
        />
        <Button type="submit">Обновить</Button>
      </div>
    </form>
  )
}