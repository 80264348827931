import { DOC_LIST } from 'shared/config/routes'
import { useEffect, useRef } from 'react'
import { prop } from 'ramda'
import styled from 'styled-components'
import { useParams, useLocation, Link as RouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle, Preloader } from 'shared/ui/components'
import { Breadcrumbs, Link, Typography, Paper } from '@mui/material'
import usePending from 'shared/lib/hooks/PendingHook'
import { docDetail as docDetailAction, docSelector, initSocketListener } from 'store/doc'
import { docStatus } from 'shared/services/interfaces/doc'
import { getDateFromIso } from 'shared/lib/utils/dateToString'

import { Docs } from './Docs'

import { DocDetailLocationState } from '../interfaces'


const BreadcrumbsStyled = styled(Breadcrumbs)`
  font-size: inherit;
  p{
    font-size: inherit;
  }
`
interface RouteParams {
  id: string
}
// ms * sec * min
const fifteenMinutesInterval = 1000 * 60 * 15

export const WarehouseDetail = () => {
  const dispatch = useDispatch()
  const { id } = useParams<RouteParams>()
  const { state } = useLocation<DocDetailLocationState>()
  const [getDocDetail, isDocDetailLoading] = usePending(docDetailAction)
  const document = useSelector(docSelector)
  const docDetail = prop('detail', document)
  const socketStarted = useRef(false)
  const interval = useRef<null | ReturnType<typeof setInterval>>(null)

  useEffect(() => { getDocDetail(id) }, [])
  useEffect(() => {
    const isStarted = docDetail?.status === docStatus.started

    if (!socketStarted.current && isStarted) {
      interval.current = setInterval(() => {
        dispatch(initSocketListener(id))
      }, fifteenMinutesInterval)

      dispatch(initSocketListener(id))
      socketStarted.current = true
    } else if (!isStarted) {
      socketStarted.current = false
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      socketStarted.current = false
    }
  }, [docDetail])

  const backLinkTo = state?.docListFilterQuery ? `${DOC_LIST}?${state.docListFilterQuery}` : DOC_LIST

  return (
    <div>
      <PageTitle>
        <BreadcrumbsStyled aria-label="breadcrumb">
          <Link color="primary" component={RouterLink} to={backLinkTo}>
            Приход на склад
          </Link>
          <Typography color="textPrimary">Приемка товара по документу № {docDetail && docDetail.docNumber}
            &nbsp;от {getDateFromIso(docDetail.docDate, '.')}</Typography>
        </BreadcrumbsStyled>
      </PageTitle>
      <Paper>
        {!isDocDetailLoading ? <Docs /> : <Preloader />}
      </Paper>
    </div>
  )
}