import { Box, Paper } from '@mui/material'
import React, { memo } from 'react'
import { analyticsApi } from 'shared/api'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { ChartsDataTypes } from 'features/analytics/chart/types/types'
import { useHistory } from 'react-router'
import { Products } from 'pages/product/productList'
import { SelectionState } from '@devexpress/dx-react-grid'
import { TableSelection as DXTableSelection } from '@devexpress/dx-react-grid-material-ui'
import { availableFilters } from 'pages/analytics/charts/ui/ChartsTabs'

import { Charts } from './ui/Charts'
import { Header } from './ui/Header'
import { columnsConfig } from './model/tableColumnsConfig'

import { ChartsProvider } from '../../../lib/useChartContext'
import { ChartsPaper, StyledLoader, StyledProducts } from '../../../styled'
import { CustomSelectedComponent } from '../../table/ui'

export const StyledBlurContainer = styled(Box)<{ $isLoading: boolean }>`
  transition: 1s;
  div {
    filter: ${({ $isLoading }) => $isLoading ? 'blur(2px) ' : ''}
  }
`

const TimelineRange: Record<number, Object> = {
  0: {
    length: {
      startValue: subtractDays(new Date(), 7),
      endValue: getPrevDay()
    },
    startValue: subtractMonths(new Date(), 1),
    endValue:  getPrevDay()
  },
  1: {
    length: {
      startValue: subtractMonths(new Date(), 1),
      endValue: getPrevDay()
    },
    startValue: subtractMonths(new Date(), 4),
    endValue:  getPrevDay()
  },
  2: {
    length: {
      startValue: subtractMonths(new Date(), 12),
      endValue: getPrevDay()
    },
    startValue: undefined,
    endValue:  getPrevDay()
  },
  3: {
    length: {}
  }
}

export const OrdersAndStocksPage = memo(() => {
  const { search } = useLocation()
  const history = useHistory()
  const searchObj: ChartsDataTypes = queryString.parse(search, { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' })
  const historySearch = queryString.parse(window.location.search)

  const range = searchObj.range || 0
  const units = searchObj.units || 'items'
  const startValue = range === 3 ? searchObj?.startValue : TimelineRange[searchObj?.range?.toString() || 0].startValue
  const endValue = range === 3 ? searchObj?.endValue?.toString() : TimelineRange[searchObj?.range?.toString() || 0].endValue
  const timelineStart = historySearch.timelineStart?.toString() || TimelineRange[searchObj?.range?.toString() || 0].length.startValue
  const timelineEnd = historySearch.timelineEnd?.toString() || TimelineRange[searchObj?.range?.toString() || 0].length.endValue

  const handleChartParams = (params) => {
    const querySearch = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    const historyStr = queryString.stringify(
      { ...querySearch, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace(`?${historyStr}`)
  }

  const { data: ordersData, isFetching: ordersFetching } =
  analyticsApi.useAnalyticsOrdersQuery(
    {
      dateFrom: startValue,
      dateTo: endValue,
      marketplaceId: searchObj.marketplaceId,
      units,
      searchString: searchObj.searchString,
      companyId: searchObj.companyId,
      productId: searchObj.productId,
      companymarketplaceId: searchObj.companymarketplaceId,
      goodsBrandId: searchObj.goodsBrandId,
      category: searchObj.category,
      orderStatus: searchObj?.orderStatus?.[0],
      orderStatusInterval: searchObj?.orderStatusInterval?.[0] || 30,
      syncStatus: searchObj?.syncStatus?.[0],
      stocksStatus: searchObj?.stocksStatus?.[0],
      autoOrdering: searchObj?.autoOrdering?.[0],
      contragentId: searchObj.contragentId,
      abcClass: searchObj.abcClass,
      xyzClass: searchObj.xyzClass
    })

  const { data: stocksData, isFetching: stocksFetching,  } =
    analyticsApi.useAnalyticsStocksQuery(
      {
        dateFrom: startValue,
        dateTo: endValue,
        marketplaceId: searchObj.marketplaceId,
        units,
        productId: searchObj.productId,
        searchString: searchObj.searchString,
        companymarketplaceId: searchObj.companymarketplaceId,
        orderStatus: searchObj?.orderStatus?.[0],
        orderStatusInterval: searchObj?.orderStatusInterval?.[0] || 30,
        companyId: searchObj.companyId,
        goodsBrandId: searchObj.goodsBrandId,
        category: searchObj.category,
        syncStatus: searchObj?.syncStatus?.[0],
        stocksStatus: searchObj?.stocksStatus?.[0],
        autoOrdering: searchObj?.autoOrdering?.[0],
        contragentId: searchObj.contragentId,
        abcClass: searchObj.abcClass,
        xyzClass: searchObj.xyzClass
      })

  const onSelChange = (sel) => {
    const row = sel[sel.length - 1]
    handleChartParams({
      productId: row
    })
  }

  const SelectionCellComponent = (props: DXTableSelection.RowProps) => (
    <CustomSelectedComponent oneSelected={!!searchObj.productId} props={props}/>)

  const contextSearchObj = { ...searchObj, startValue, endValue, timelineEnd, timelineStart, range, units }
  return (
    <ChartsPaper component={Paper}>
      <ChartsProvider
        value={{
          searchObj: contextSearchObj,
          handleChartParams,
          ordersData,
          stocksData }}>

        <Header/>
        {(ordersData && stocksData) ?
          <StyledBlurContainer $isLoading={stocksFetching || ordersFetching}>
            <Charts isLoading={stocksFetching || ordersFetching}/>
          </StyledBlurContainer>
          :
          <div
            style={{
              width: '100%',
              height: '590px'
            }} >
            <StyledLoader size={60}/>
          </div>
        }
        <StyledProducts>
          <Products
            showOrderStatus={true}
            columnsSettingsId="stocksProductsTable"
            columnsConfig={columnsConfig}
            availableFilters={availableFilters}>
            <SelectionState
              selection={[searchObj.productId || 0]}
              onSelectionChange={onSelChange}
            />
            <DXTableSelection
              rowComponent={SelectionCellComponent}
              highlightRow={true} showSelectionColumn={false} selectByRowClick={true}/>
          </Products>
        </StyledProducts>
      </ChartsProvider>
    </ChartsPaper>
  )
}
)