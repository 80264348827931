import React, { useState } from 'react'
import { StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { Autocomplete, Paper, Popover, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { SelectWrapper } from 'features/analytics/chart/ui/charts/OrdersAndSales/ui/styled'
import { Filters as ProductsFilters } from 'shared/lib/generated/products/Api'
import { Filters as GoodsFilters } from 'shared/lib/generated/goods/Api'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { debouncedSearch } from 'shared/ui/components/Table/ui/TableSearch/TableSearch'
import { isNotNil } from 'shared/lib/checkers'
import { dayTitle } from 'shared/lib/utils/DayTitle'
import { StyleChips } from 'shared/ui/styled/StyledChips'

import {
  FilterWrapper, 
  SliderWrapper,
  StyledAutocompleteInput,
  StyledChipsContainer,
  StyledSlider
} from '../styled'
import { FilterNames, filtersListParamsType } from '../../model/types'

interface ITableFilters {
  filtersListParams: filtersListParamsType,
  filters: ProductsFilters | GoodsFilters | any
  showOrderStatus?: boolean
}

const CustomPaper = (props) => <Paper style={{ width: 'max-content', maxWidth: '300px' }} {...props} />

export const TableFilter = ({ filtersListParams, filters, showOrderStatus }: ITableFilters) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [ sliderCount, setSliderCount ] = useState<number>(30)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    handleTableParams(filtersParameters)
  }
  
  const open = Boolean(anchorEl)

  const { search } = useLocation()
  const history = useHistory()
  const searchObj = queryString.parse(search, { arrayFormat: 'bracket' })
  const newObj = {}

  // eslint-disable-next-line no-return-assign
  Object.keys(filtersListParams).forEach(el => {
    if (isNotNil(filtersListParams[el])) {
      if (Array.isArray(filtersListParams[el])) {
        newObj[el] = filtersListParams?.[el]?.map(elem => ({
          label: filters[el]?.find((element) => element.value === elem)?.label || '',
          value: elem,
          count: filters[el]?.find((element) => element.value === elem)?.count || null,
        }))
      } else if (el === 'orderStatusInterval') {
        newObj[el] = [{
          label: `${filtersListParams[el]} ${dayTitle(filtersListParams[el])}`,
          value: filtersListParams[el]
        }]
      } else {
        newObj[el] = [{
          label: filters?.[el]?.find(elem => elem.value === filtersListParams[el])?.label,
          value: filters?.[el]?.find(elem => elem.value === filtersListParams[el])?.value,
          count: filters?.[el]?.find(elem => elem.value === filtersListParams[el])?.count,
        }]
      }
    }
  }
  )
  const [filtersParameters, setFiltersParameters] = useState<any>(newObj)
  
  const allItemsDelete = () => {
    setFiltersParameters(prev => {
      const { ...rest } = prev
      Object.keys(rest).forEach((el) => {
        rest[el] = []
      })
      handleTableParams(rest)
      return rest
    })
  }
  const onItemDelete = (item, filter) => () => {
    const newFilt = filtersParameters
    newFilt[filter] = newFilt[filter].filter(e => JSON.stringify(e) !== JSON.stringify(item))
    handleTableParams(newFilt)
    setFiltersParameters(newFilt)
  }

  const handleTableParams = (params) => {
    const parametersObject = {}
    // eslint-disable-next-line no-return-assign
    Object.keys(params).map(el => parametersObject[el] = params[el].map(elem => elem.value) )
    // eslint-disable-next-line consistent-return
    const historyStr = queryString.stringify(
      { ...searchObj, ...parametersObject },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    if (JSON.stringify(filtersListParams, (key, value) => {
      if (value === undefined) {
        return []
      } return value
    }) !== JSON.stringify(parametersObject)) {
      history.replace({ search: `?${historyStr}` })
    }
  }

  // @ts-ignore
  const filtersExist = Object.values(filtersParameters).find(el => el?.length > 0)

  const handleSearchParams = (params) => {
    const searchParams = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    const historyStr = queryString.stringify(
      { ...searchParams, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace({ search: `?${historyStr}` })
  }

  return (
    <FilterWrapper>
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        defaultValue={searchObj.searchString}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        }} 
        onChange={(e) => {
          debouncedSearch(() => handleSearchParams({ searchString: e.target.value }))
        }}
        InputProps={{
          endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
        }}/>
      <div style={{ display: 'flex', flexDirection: 'column' }} id="table-filter">
        <StyledFilterContainer>
          {Object.keys(filters).map((el, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SelectWrapper key={index} width={(FilterNames[el]?.length * 8.17) + 60}>
              <Autocomplete
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                options={filters[el]}
                noOptionsText="Нет фильтров"
                value={filtersParameters[el] || []}
                clearOnBlur={false}
                openText="Открыть"
                closeText="Закрыть"
                disableCloseOnSelect={true}
                disableClearable={true}
                multiple={true}
                size="small"
                PaperComponent={CustomPaper}
                isOptionEqualToValue={isOptionEqual}
                onBlur={() => handleTableParams(filtersParameters)}
                onChange={(event, value, reason, details) => {
                  if (value) {
                    setFiltersParameters(
                      prev =>({ ...prev, [el]: (typeof filtersListParams[el]) === 'object' ? value : [details!.option] }))
                  }
                  if (value.length === 0) {
                    setFiltersParameters(prev =>({ ...prev, [el]: [] }))
                  }
                }}
                getOptionLabel={(option) => `${option.label} ${option.count || ''}` || ''}
                renderOption={(props: object, option: any) => (
                  <div {...props}>
                    {option.label}
                    {option.count &&
                    <div style={{ paddingLeft: '16px', marginLeft: 'auto', marginRight: '0' }}>
                      <span style={{ fontSize: '16px', color: '#00000099' }}>{option.count}</span>
                    </div>
                    }
                  </div>
                )}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={`${FilterNames[el]}`}/>
                )}
              />
            </SelectWrapper>
          ))}
          { showOrderStatus ?
            <>
              <Popover
                id="simle-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <SliderWrapper>
                  <StyledSlider
                    aria-label="Small steps"
                    defaultValue={sliderCount}
                    onChangeCommitted={(_ ,value) => {
                      setSliderCount(value as number)
                      setFiltersParameters(prev =>({ ...prev, orderStatusInterval: [{
                        label: `${value} ${dayTitle(value)}`,
                        value
                      }] }))
                    }}
                    size="small"
                    marks={true}
                    min={3}
                    max={30}
                    valueLabelDisplay="auto"
                  />
                  <div>
                    <Typography
                      variant="body2"
                    >
                      3 дня
                    </Typography>
                    <Typography
                      variant="body2"
                    >
                      30 дней
                    </Typography>
                  </div>
                </SliderWrapper>
              </Popover>
              <StyledAutocompleteInput onClick={handleClick}>
                Период динамики
              </StyledAutocompleteInput>
            </>
            :
            null
          }
        </StyledFilterContainer>
      
        {filtersExist && 
        <StyledChipsContainer> 
          <> 
            { Object.keys(filtersParameters).map((el) => (filtersParameters[el].map((elem) => ( 
              <StyleChips 
                size="small" 
                key={elem.value} label={elem.label} onDelete={onItemDelete(elem, el)}/> 
            ))))} 
            <StyleChips size="small"  label="Сбросить все" onClick={allItemsDelete}/> 
          </> 
        </StyledChipsContainer> 
        } 
      </div>
    </FilterWrapper>
  )
}