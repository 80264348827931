import { useQuery, QueryFunctionContext } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

import { ActivitiesType } from './model'

import { toSnakeCase } from '../../lib/transform'
import { toSnake } from '../../lib/transform/toSnakeCase'


export interface ActivitiesParams {
  searchString?: string
  campaignStatus?: number
  involvement?: boolean
  typeId?: number
  marketplaceId?: number
  companyId?: number
  companymarketplaceId?: number
  customersActivity?: boolean
  page?: number
  pageSize?: number
}

export const getActivitiesQueryKey = (queryParams: ActivitiesParams) =>
  ['get', 'activity/activities', queryParams] as const

type GetActivitiesQueryKey = ReturnType<typeof getActivitiesQueryKey>

function getActivities(queryParams: ActivitiesParams) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get<ActivitiesType>(
    '/activity/activities', { params: queryParamsSnake })
}

const getAllActivities = (context: QueryFunctionContext<GetActivitiesQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getActivities(queryParams)
}

export const useGetActivitiesQuery = (queryParams: ActivitiesParams) =>
  useQuery<
    AxiosResponse<ActivitiesType>,
    errorType,
    AxiosResponse<ActivitiesType>,
    GetActivitiesQueryKey
  >(
    getActivitiesQueryKey(queryParams),
    getAllActivities,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
