import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'

interface CreateUserContragentPayload {
  userId: UniqueId
  contragentData: pimApi.admin.NewUserContragent
}

const createUserContragent = ({
  userId,
  contragentData,
}: CreateUserContragentPayload) =>
  api.post<pimApi.admin.ContragentUser>(
    `/pim/admin/users/${userId}/contragents`,
    contragentData
  )

export const useCreateUserContragentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.ContragentUser>,
    AxiosError<errorType>,
    CreateUserContragentPayload
  >(createUserContragent)
