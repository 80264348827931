import { Field } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import { history } from 'app/providers/with-router'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import imagePlaceholder from 'assets/images/usersCreation.svg'
import Box from '@mui/material/Box'
import {
  InputField,
  InputLabel,
} from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import styled from 'styled-components'
import React from 'react'

const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
    left: -15px;
  }
`

export const CreateUserForm = ({ handleSubmit, isLoading, errors, valid, visited, checked, setChecked }) => {
  const handleBack = () => {
    history.goBack()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box padding="32px 28px">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '230px' }}>
          <div>
            <Box width={440} marginBottom="44px">
              <InputLabel label="Имя пользователя" required={true}/>
              <HelperTextSupply>
                <Field
                  name="name"
                  placeholder="Имя пользователя"
                  component={InputField}
                  required={true}
                  errorAfterTouch={true}
                  helperText={visited.name ? errors?.name : ''}
                  fullWidth={true}
                />
              </HelperTextSupply>
            </Box>

            <Box width={440} marginBottom="35px">
              <InputLabel label="Почта" required={true}/>
              <HelperTextSupply>
                <Field
                  name="email"
                  placeholder="Почта"
                  component={InputField}
                  required={true}
                  errorAfterTouch={true}
                  helperText={visited.email ? errors?.email : ''}
                  fullWidth={true}
                />
              </HelperTextSupply>
            </Box>
            <FormControlLabel
              control={<Checkbox
                defaultChecked={true}
                onChange={handleChange}
              />}
              label="Использовать почту как логин"
              checked={checked}
              sx={{ marginBottom: '36px' }}
            />
            {!checked ?
              <Box width={440} mb="32px">
                <InputLabel label="Логин" required={true}/>
                <HelperTextSupply>
                  <Field
                    name="login"
                    placeholder="Логин"
                    required={true}
                    errorAfterTouch={true}
                    component={InputField}
                  />
                </HelperTextSupply>
              </Box> :
              <Box>
                <div style={{ height: '108.125px' }}/>
              </Box>
            }
          </div>
          <div>
            <img src={imagePlaceholder} alt="placeholder"/>
          </div>
        </div>
        <Divider variant="middle" sx={{ margin: '0 0 24px 0' }}/>
        <Box justifyContent="flex-end" display="flex">
          <LoadingButton
            variant="text"
            fullWidth={false}
            onClick={handleBack}
            loading={isLoading}
            sx={{ mr: 2 }}
          >
            НАЗАД
          </LoadingButton>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            fullWidth={false}
            loading={isLoading}
            disabled={!valid}
          >
            СОЗДАТЬ
          </LoadingButton>
        </Box>
      </Box>
    </form>
  )
}
