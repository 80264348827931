import { useHistory } from 'react-router-dom'
import { generatePath } from 'react-router'
import { Form } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { snackActions } from 'shared/lib/react/snackbar'
import { ADMIN_PANEL_EDIT_USER } from 'shared/config'
import { useState } from 'react'
import { customerGen } from 'shared/lib/generated'

import { CreateUserForm } from './ui/CreateUserForm'
import {
  validationSchema,
  validationSchemaWithLogin,
} from './lib/validationSchema'

export const CreateUser = () => {
  const [checked, setChecked] = useState(true)
  const history = useHistory()

  const { mutate: createUserMutation, isLoading } =
    customerGen.administration.CreateUser.useCreateUser()

  const validate = useValidationSchema(
    checked ? validationSchema : validationSchemaWithLogin
  )

  const onSubmit = (values) => {
    createUserMutation(
      {
        data: {
          email: values.email,
          name: values.name,
          login: checked ? values.email : values.login,
        },
      },
      {
        onSuccess: (data) => {
          snackActions.info('Сохранено!')
          history.push(generatePath(ADMIN_PANEL_EDIT_USER, { id: data.id }))
        },
      }
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, errors, valid, visited }) => (
        <CreateUserForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          checked={checked}
          setChecked={setChecked}
          errors={errors}
          valid={valid}
          visited={visited}
        />
      )}
    />
  )
}
