import { useRef } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { Tooltip, TooltipProps } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'
import { BaseInputFieldI } from 'shared/ui/components/TextInput/TextInputI'

import { InputStyled } from './styled'

import { HelpWithMargin } from '../../../styled/Help'
import { InputWrapper } from '../../../styled/InputWrapper'
import { IsAspect } from '../../../product/AttributesFlag/IsAspect'

interface IInputProps extends FieldRenderProps<string>, BaseInputFieldI {
  label?: string;
  helpText?: string;
  helpDirection: TooltipProps['placement']
}

const _InputField = ({
  input,
  label,
  helpText,
  isAspect,
  meta,
  helpDirection = 'right',
  ...rest
}: IInputProps) => {
  const el = useRef<HTMLInputElement>(null)

  return (
    <InputWrapper>
      <InputStyled
        ref={el}
        label={label}
        variant="outlined"
        size="small"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <>
              {isAspect ? <IsAspect/>: null}
            </>
          ),
        }}
        {...input}
        {...rest}
      />
      {helpText &&
      <Tooltip title={helpText} placement={helpDirection}>
        <HelpWithMargin>
          <InfoOutlined/>
        </HelpWithMargin>
      </Tooltip>}
    </InputWrapper>
  )
}

export const InputField = _InputField
