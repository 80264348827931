import { useQuery, useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { GoodsComponent } from 'domains/pim/goods'
import { pimApi } from 'shared/api'

import { api } from '../../../base'

type WBProductContentQueryKey = ReturnType<typeof getWBProductContentQueryKey>

export const getWBProductContentQueryKey = (productId?: UniqueId) =>
  ['get', `pim/products/wb/product/${productId}/content`, productId] as const

export const fetchWBProductContent = (
  productId: UniqueId
) => api.get<Array<GoodsComponent>>(`/pim/products/wb/product/${productId}/content`).then((res) => res.data)

export const useFetchWBProductContentQuery = (goodsId?: UniqueId) => useQuery<
  Array<GoodsComponent>,
  AxiosError<errorType>,
  Array<GoodsComponent>,
  WBProductContentQueryKey
>(
  getWBProductContentQueryKey(goodsId),
  (context) => fetchWBProductContent(context.queryKey[2] as UniqueId),
)

interface CreateContentPayload {
  productId: UniqueId
  content: pimApi.goods.NewGoodsComponent
}

export const createContent = ({ productId, content }: CreateContentPayload) =>
  api.post<pimApi.goods.GoodsComponent>(
    `/pim/products/wb/product/${productId}/content`,
    content
  )

export const useCreateContentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    CreateContentPayload
  >(createContent)
interface UpdateContentPayload {
  contentId: UniqueId
  productId: UniqueId
  content: pimApi.goods.EditGoodsComponent
}

export const updateContent = ({
  productId,
  contentId,
  content,
}: UpdateContentPayload) =>
  api.put<pimApi.goods.GoodsComponent>(
    `/pim/products/wb/product/${productId}/content/${contentId}`,
    content
  )

export const useUpdateContentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    UpdateContentPayload
  >(updateContent)

interface DeleteContentPayload {
  contentId: UniqueId
  productId: UniqueId
  content: pimApi.goods.DeleteGoodsComponent
}

export const deleteContent = ({
  productId,
  contentId,
  content,
}: DeleteContentPayload) =>
  api.delete<pimApi.goods.GoodsComponent>(
    `/pim/products/wb/product/${productId}/content/${contentId}`,
    { data: content }
  )

export const useDeleteContentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    DeleteContentPayload
  >(deleteContent)
  