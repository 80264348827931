/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyWildberriesProductCheckVideoFile } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: BodyWildberriesProductCheckVideoFile
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.post(`/pim/products/wb/product/${productId}/video/file/validator`,data).then(res => res.data)


export const useCheckVideoFile = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

