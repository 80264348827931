import { useQueryClient } from 'react-query'
import { getGetPimGoodsQueryKey } from 'features/goods/getGoods'
import { createGoodsSupplier, updateGoodsSupplier, deleteGoodsSupplier,
  Goods, NewGoodsSupplier,  EditGoodsSupplier } from 'shared/api/pim/goods'


export const useGoodsSupplier = () => {
  const queryClient = useQueryClient()

  const create = (goodsId: UniqueId, newSupplier: NewGoodsSupplier, cb?: (data) => void) => {
    createGoodsSupplier(goodsId, newSupplier).then((res) => {
      queryClient.setQueryData<Goods>(
        getGetPimGoodsQueryKey(goodsId),
        (curr) => {
          const newGoods = { ...curr }
          newGoods?.suppliers?.push(res.data)
          return newGoods as Goods
        }
      )
      if (cb) cb(res?.data)
    })
  }

  const update = (goodsId: UniqueId, editedSupplier: EditGoodsSupplier, cb?: (data) => void) => {
    updateGoodsSupplier(goodsId, editedSupplier).then((res) => {
      queryClient.setQueryData<Goods>(
        getGetPimGoodsQueryKey(goodsId),
        (curr) => {
          const newGoods = { ...curr }
          newGoods?.suppliers?.map(
            (supplier) => supplier.id === res.data.id ? res.data : supplier
          )
          return newGoods as Goods
        }
      )
      if (cb) cb(res?.data)
    })
  }

  const remove = (goodsId: UniqueId, supplier: DeletingObject, cb?: () => void) => {
    deleteGoodsSupplier(goodsId, supplier).then(() => {
      queryClient.setQueryData<Goods>(
        getGetPimGoodsQueryKey(goodsId),
        (curr) => {
          const newGoods = { ...curr }
          const deletingSupplier = newGoods?.suppliers?.findIndex((s) => s.id === supplier.id)
          if (deletingSupplier !== undefined && deletingSupplier > -1) {
            newGoods?.suppliers?.splice(deletingSupplier, 1)
          }
          return newGoods as Goods
        }
      )
      if(cb) cb()
    })
  }

  return { create, update, remove } as const
}
