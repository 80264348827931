import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosResponse } from 'axios'



function getPrivileges(): Promise<AxiosResponse<pimApi.admin.Privilege[]>> {
  return api.get<pimApi.admin.Privilege[]>('/customer/admin/privileges')
}

type QueryKey = ReturnType< typeof getPrivilegesQueryKey>

export const getPrivilegesQueryKey = () => ['get', 'customer/admin/privileges'] as const


function getPrivilegesFn() {
  return getPrivileges().then((res) => res.data)
}

export const useGetPrivilegesQuery = () =>
  useQuery<
    pimApi.admin.Privilege[],
    errorType,
    pimApi.admin.Privilege[],
    QueryKey
    >(
      getPrivilegesQueryKey(),
      getPrivilegesFn
    )
