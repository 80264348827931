/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationChangeWbProducts, ImportMetadata } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Идентификатор кабинета */
    companymarketplace_id: number,
  /**
   * Категория товара
   * @maxLength 255
   */
    category: string,
  /** Идентификатор бренда */
    brand?: string,

},
  data: BodyBulkOperationChangeWbProducts
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/products/wb/change/product`,data, { params: query }).then(res => res.data)


export const useChangeWbProducts = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

