import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import { AnalyticsSale } from 'shared/api/analytics'
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Grid,
  Label,
  Legend,
  Point,
  Series,
  Tick,
  Tooltip,
  ValueAxis, Size
} from 'devextreme-react/chart'
import { registerGradient } from 'devextreme/common/charts'
import styled from 'styled-components'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'


interface DiffDataParams {
  title: string
  data?: number
  diff?: number
  currentSalesData?: Array<AnalyticsSale>
  prevSalesData?: Array<AnalyticsSale>
  chartUrl?: string
}

const currentSeriesColor = {
  fillId: registerGradient('linear', {
    colors: [{
      offset: '30%',
      color: 'rgba(33, 150, 243, 0.08)'
    },
    {
      offset: '80%',
      color: '#2196F300'
    }],
    rotationAngle: 90
  })
}

const prevSeriesColor = {
  fillId: registerGradient('linear', {
    colors: [{
      offset: '30%',
      color: 'rgba(0, 0, 0, 0.02)'
    },
    {
      offset: '90%',
      color: 'rgba(255,255,255,0)'
    }],
    rotationAngle: 90
  })
}

interface dataType {
  currentSale: number
  prevSale?: number
  orderDate: Date
}

const ChartContainer = styled(Box)`
  margin-top: 10px;
  height: 100%;
`
export const DeltaWithMediumChart = ({ title, data, diff, chartUrl, currentSalesData, prevSalesData }: DiffDataParams) => {
  const chartContainer = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    // @ts-ignore
    setHeight(chartContainer?.current?.clientHeight)
  })

  const chartData: Array<dataType> | undefined = currentSalesData?.map((el, index) => ({
    currentSale: el.quantity,
    prevSale: prevSalesData?.[index].quantity,
    orderDate: new Date(el.saleDate),
  }
  ))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
            {title}
          </Typography>
          {(data !== undefined && diff !== undefined) &&
          <ValueDelta value={data} delta={diff}/>
          }
        </Box>
        <Box>
          {chartUrl &&
          <Box>
            <a
              style={{ display: 'flex', alignItems: 'center' }}
              href={chartUrl}
              target="_blank" rel="noreferrer">
              <BarChartIcon sx={{ color: '#00000061' }}/>
            </a>
          </Box>
          }
        </Box>
      </Box>
      <ChartContainer ref={chartContainer}>
        <Chart
          palette="Ocean"
          dataSource={chartData}
        >
          <Size height={height}/>
          <CommonSeriesSettings
            argumentField="orderDate"
            hoverMode="none"
          />
          <Series
            type="stackedspline"
            color="#E0E0E0"
            width={1.7}
            valueField="prevSale">
            <Point visible={false}/>
          </Series>
          <Series
            type="splinearea"
            color={prevSeriesColor}
            width={1.4}
            opacity={0.1}
            valueField="prevSale">
            <Point visible={false}/>
          </Series>
          <Series
            type="splinearea"
            color={currentSeriesColor}
            width={1.4}
            valueField="currentSale">
            <Point visible={false}/>
          </Series>
          <Series
            type="spline"
            color="#2196F3"
            width={1.35}
            opacity={0.3}
            valueField="currentSale">
            <Point visible={false}/>
          </Series>
          <ValueAxis
            visible={false}
          >
            <Tick visible={false}/>
            <Label visible={false}/>
            <Grid  visible={false} />
          </ValueAxis>
          <ArgumentAxis
            valueMarginsEnabled={false}
            visible={true}
            argumentType="string"
            discreteAxisDivisionMode="crossLabels"
            color="#E0E0E0"
            endOnTick={true}
          >
            <Tick visible={false}/>
            <Label font={{ color: '#00000061' }} customizeText={customDate}/>
            <Grid visible={false}/>
          </ArgumentAxis>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="bottom"
            visible={false}
          />
          <Tooltip enabled={false} />
        </Chart>
      </ChartContainer>
    </Box>
  )
}

const customDate = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    return days[utc.getDay()]
  }
  return ''
}
