/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReportParam } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  reportId: number,
  query: {
  /** user_id */
    user_id: number,

},
  data: ReportParam[]
}>

export const apiFunction = ({ reportId,query,data }: IRequest) =>
  api.post<ResponseType>(`/customer/reports/v2/report/${reportId}/stat`,data, { params: query }).then(res => res.data)


export const useGetReportInfo2 = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

