/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { DeletingObject } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  priceId: number,
  data: DeletingObject
}>

export const apiFunction = ({ productId,priceId,data }: IRequest) =>
  api.delete(`/pim/products/ozon/product/${productId}/price/${priceId}`,{ data: data}).then(res => res.data)


export const useDeletePrice = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

