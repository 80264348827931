import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { reviewApi } from 'shared/api'
import { api } from 'shared/api/base'

interface newTemplate {
  text: string
  review_type: number
  name: string
  templatemodel_id: number
}
const createReviewTemplate = (newTemplate: newTemplate) =>
  api.post('/review/models/template', { ...newTemplate, value: 0 })


export const useCreateReviewTemplate = () => useMutation<
  AxiosResponse<reviewApi.ReviewTemplate>,
  AxiosError<errorType>,
  newTemplate
>(createReviewTemplate)