/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { MarketplaceShortInfo } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<MarketplaceShortInfo[]>



export const apiFunction = () =>
  api.get<ResponseType>(`/customer/marketplaces`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', `/customer/marketplaces`] as const

export const useGetMarketplaces = (options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(),
    () => apiFunction(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

