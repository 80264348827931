import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

interface DeleteTemplate {
  templateId: UniqueId
}
const deleteTemplate = (deletedTemplate: DeleteTemplate) =>
  api.delete(
    `/review/models/template/${deletedTemplate.templateId}`,
  )


export const useDeleteTemplateMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteTemplate
  >(deleteTemplate)