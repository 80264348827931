import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const Title = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.688%;
`

export const Subtitle = styled.span`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
`
export const CompetitorItem = styled.div`
  display: flex;
  width: 536px;
  height: 80px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`

export const ProductImage = styled.div<{ $photoBase64?: boolean }>`
  position: relative;
  min-width: 80px;
  min-height: 78px;
  max-width: 80px;
  max-height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEAEA;
  img {
    width: ${({ $photoBase64 }) => $photoBase64 ? '80px' : '60px'};
    height: ${({ $photoBase64 }) => $photoBase64 ? '78px' : '60px'};
    object-fit: cover;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  > div:first-child{
    height: 80px;
    width: 80px;
  }
`

export const CompetitorsContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`

export const StyledLoader = styled(CircularProgress)`
  position: relative;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const CompetitorName = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.15px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: #007DFF;
`

export const ShortInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    height: 10px;
    padding: 0 5px;
    display: flex;
    font-size: 12px;
    border-left: 1px solid rgba(0, 0, 0, 0.60);
    align-items: center;
    
    :first-child {
      padding: 0 5px 0 0;
      border-left: none;
    }
    
    :last-child {
      padding: 0 0 0 5px;
    }
  }
`