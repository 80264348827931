import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { customerApi } from 'shared/api'


interface EditPrivilege {
  privilegeId: UniqueId,
  privilegeData: customerApi.EditPrivilege
}
export const editPrivilege = ({ privilegeId, privilegeData }: EditPrivilege) =>
  api.put<customerApi.Privilege>(
    `/customer/admin/privileges/${privilegeId}`,
    privilegeData
  )


export const useEditPrivilegeMutation = () =>
  useMutation<
    AxiosResponse<customerApi.Privilege>,
    AxiosError<errorType>,
    EditPrivilege
  >(editPrivilege)
