import React, { memo, useContext, useState } from 'react'
import _ from 'lodash'
import { IconButton, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import { APP_VERSION } from 'shared/config'
import { user } from 'shared/api'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { getUserSettingsQueryKey } from 'shared/api/user/settings'
import { userSelector } from 'store/user/selectors'
import { WidgetList } from 'features/widgetVariables/WidgetList'
import { SidebarContext } from 'shared/ui/layouts/AppLayout'

import { ShowcaseLayoutFunctional } from '../Widgets'
import { StyledHeader } from '../Widgets/styled'

const getTimesOfDay = () => {
  const today = new Date()
  const currentHour = today.getHours()

  if (currentHour >= 4 && currentHour <= 11) {
    return 'Доброе утро, '
  } if (currentHour > 11 && currentHour <= 17) {
    return 'Добрый день, '
  } if (currentHour > 17 && currentHour <= 22) {
    return 'Добрый вечер, '
  }
  return 'Доброй ночи, '
}

const Layout = [
  { w: 1, h: 1, x: 2, y: 4, i: '0', isResizable: false, maxW: 1, maxH: 1, widgetName: 'orders' },
  { w: 2, h: 1, x: 0, y: 4, i: '1', maxW: 4, maxH: 4, minW: 2, minH: 1, widgetName: 'ordersAndStocks' },
  { w: 2, h: 1, x: 4, y: 0, i: '2', maxW: 4, maxH: 4, minW: 2, minH: 1,  widgetName: 'salesDynamics' },
  { w: 2, h: 2, x: 4, y: 2, i: '3', isResizable: false, maxW: 2, maxH: 2, minW: 2, minH: 2, widgetName: 'abcXyz' },
  { w: 1, h: 1, x: 3, y: 4, i: '4', isResizable: false, maxW: 1, maxH: 1, minW: 1, minH: 1, widgetName: 'stocks' },
  { w: 2, h: 2, x: 0, y: 0, i: '5', maxW: 4, maxH: 4, minW: 1, minH: 1,widgetName: 'weeklySales' },
  { w: 1, h: 1, x: 5, y: 4, i: '6', isResizable: false, maxW: 1, maxH: 1, minW: 1, minH: 1, widgetName: 'archived' },
  { w: 1, h: 1, x: 4, y: 4, i: '7', isResizable: false, maxW: 1, maxH: 1, minW: 1, minH: 1,widgetName: 'active' },
]
function createElement(el) {
  const i = el.add ? '+' : el.i
  const square = el.w * el.h

  // eslint-disable-next-line default-case
  if (square < 3) {
    if (el.w === 2 && el.h === 1) {
      return (
        WidgetList[el.widgetName].doubleSmallSize({ el, i })
      )
    }
    return WidgetList[el.widgetName].smallSize({ el, i })
  }
  if ((square > 2) && (square < 9)) {
    return WidgetList[el.widgetName].mediumSize({ el, i })
  }
  return WidgetList[el.widgetName].largeSize({ el, i })
}

export const DashboardPage = memo(({ userSettingsQuery }: { userSettingsQuery: any }) => {
  const { name } = useSelector(userSelector)
  const { sidebarOpen } = useContext(SidebarContext)

  const [children, setChildren] = useState(userSettingsQuery.dashboardLayoutData || Layout)
  const [editable, setEditable] = useState(false)

  const queryClient = useQueryClient()
  
  const { mutate: updateUserSettings } =
      user.settings.useUpdateUserSettingsMutation()

  const handleLayoutChange = (s) => {
    if (editable) {
      setChildren((prevState) => {
        // eslint-disable-next-line array-callback-return
        s.map((el, index) => {
          // eslint-disable-next-line no-param-reassign
          el.widgetName = prevState[index].widgetName
        })
        return s
      })
    }
  }
  const handleSaveLayoutChanges = () => {
    if (editable && JSON.stringify(userSettingsQuery.dashboardLayoutData) !== JSON.stringify(children)) {
      updateUserSettings({
        settings: {
          ...userSettingsQuery,
          dashboardLayoutData: children,
        },
      }, {
        onSuccess: (response) => {
          const resp = response
          resp.data.dashboardLayoutData = children
          queryClient.setQueryData(getUserSettingsQueryKey(), resp)
        }
      })
    }
    setEditable(prev => !prev)
  }
  
  return (
    <>
      <StyledHeader open={sidebarOpen}>
        <div id="header-container">
          <span id="greetings-text">{getTimesOfDay()}{name}</span>
          <div id="settings-button-container">
            <Typography id="version-text">
              Версия {APP_VERSION}
            </Typography>
            <IconButton size="small" onClick={handleSaveLayoutChanges}>
              <SettingsIcon style={{ color: editable ? '#2196F3' : '#00000042' }}/>
            </IconButton>
          </div>
        </div>
      </StyledHeader>
      <div style={{ marginTop: '85px' }}>
        <ShowcaseLayoutFunctional
          editable={editable}
          setChildren={setChildren}
          onLayoutChange={(s) => {handleLayoutChange(s)}}
          compactType="vertical"
          gridProps={{
            className: 'layout',
            rowHeight: 110,
            cols: { lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 },
            margin: [16,16]
          }}
          layouts={children}
        >
          {_.map(children, el => createElement(el))}
        </ShowcaseLayoutFunctional>
      </div>
    </>
  )
}
)
