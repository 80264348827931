import React, { useMemo } from 'react'
import Paper from '@mui/material/Paper'
import { Table } from 'shared/ui/components/Table'
import { pimApi } from 'shared/api'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { SearchObj } from 'shared/ui/components/Table/model/types'
import { isNotNil } from 'shared/lib/checkers'

import { StyledLoader, StyledTableBox } from './styled'

import { columnsConfig } from '../model/columnsConfig'
import { pagingPanelLocale } from '../lib/localization'
import { TableFilters } from '../../../filter/tableFilters'


const ROW_HEIGHT = 108
const HEADER_HEIGHT = 56
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 500
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5
const getRowId = (row) => row.rowId
export const DeclinedAdvicesTable = ({ queryParameters, setQueryParameters }) => {
  const query = pimApi.price.useGetPriceAdviceQuery(queryParameters)
  const { data } = query
  const history = useHistory()
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)
  const handleTableParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true }
    )
    history.replace({ search: `?${historyStr}` })
  }
  const fileThumbnailSize = { maxWidth: 70, maxHeight: 70 }
  const bonusCols = Math.floor(
    (window.innerHeight -
        DEFAULT_ROWS_COUNT * ROW_HEIGHT -
        HEADER_HEIGHT -
        TABLE_CONTROLS -
        TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
      bonusCols > MIN_ROWS_COUNT
        ? DEFAULT_ROWS_COUNT + bonusCols
        : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.limit)
    ? parseInt(searchObj.limit, 10)
    : calculatedRowsPerPage

  const limit = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit,
      page: 0,
    }),
    [data]
  )


  const new_array = data?.
    filter(el => el.priceSet === false && el.declined === true)
    .map((el, index) => ({
      rowId: index + 1,
      id: el.id,
      photo: pimApi.products.getFileThumbnailApiPath(
        el.productId,
        fileThumbnailSize
      ),
      marketplaceUrl: el.marketplaceUrl,
      newMargin: el.newMargin,
      newMarginPercents: el.newMarginPercents,
      advicePriceWithoutDiscount: el.advicePriceWithoutDiscount,
      advicePriceDetail: el.advicePriceDetail,
      marketplaceId: el.marketplaceId,
      priceSet: el.priceSet,
      declined: el.declined,
      versionNo: el.versionNo,
      settingsDate: el.settingsDate,
      targetMargin: el.targetMargin,
      targetMarginPercents: el.targetMarginPercents,
      autoChangePrice: el.autoChangePrice,
      adviceInterval: el.adviceInterval,
      perDay: el.perDay,
      margin: el.margin,
      marginPercents: el.marginPercents,
      incomingPrice: el.incomingPrice,
      productId: el.productId,
      adviceDate: el.adviceDate,
      advicePrice: el.advicePrice,
      comments: el.comments,
      minPrice: el.minPrice,
      name: el.name,
      price: el.price,
      sku: el.sku,
      stocks: el.stocks
    }))

  return (
    <StyledTableBox component={Paper}>
      <TableFilters queryParameters={queryParameters} setQueryParameters={setQueryParameters} handleTableParams={handleTableParams}/>
      {(query.isLoading || query.isFetching) && (<StyledLoader size={60} />)}
      {new_array && (
        <Table
          tableList={new_array}
          totalCount={new_array.length}
          getRowId={getRowId}
          allRows={false}
          isTableEditable={true}
          paginationLocale={pagingPanelLocale}
          tableParams={memoTableParams}
          calculatedRowsPerPage={calculatedRowsPerPage}
          columnsConfig={columnsConfig}
          searching="internal"
          pagination="internal"
          sorting="internal"
          filtering="internal" />
      )
      }
    </StyledTableBox>
  )
}