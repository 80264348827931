import { Box } from '@mui/material'
import styled from 'styled-components'

export const WordWrap = styled(Box)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const WordWrapCell = ({ value }) => <WordWrap>{value}</WordWrap>

