/* eslint-disable */
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBotUpdatereviewsbot, ReviewBot } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewBot>

type IRequest = CamelCasedPropertiesDeep<{
  recId: number,
  data: BodyBotUpdatereviewsbot
}>

export const apiFunction = ({ recId,data }: IRequest) =>
  api.put<ResponseType>(`/review/bot/${recId}`,toSnakeCase(data)).then(res => res.data)


export const useUpdatereviewsbot = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

