import React, { useState } from 'react'
import { pimApi } from 'shared/api'
import { diff } from 'deep-object-diff'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import { snackActions } from '../../../../../../lib/react/snackbar'


const NumberInput = styled.input`
  width: 73px;
  height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 8px;
`

const PercentInput = styled.input`
  width: 63px;
  height: 28px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 4px 16px 4px 8px;
  background-color: #E9F4FE;
`
export const RepriceMinPriceCell = ({ row }: {row: any}) => {
  const queryClient = useQueryClient()
  const { mutate: editPriceSettings } =
    pimApi.price.useEditPriceSettingsMutation()

  const handleSaveChanges = (values: pimApi.price.PriceSettings) => {
    if (
      row
      && values
      && Object.keys(diff(values, row)).length !== 0
    ) {
      editPriceSettings( {
        editedSettings: {
          id: values.id,
          versionNo: values.versionNo,
          productId: values.productId,
          minPrice: values.minPrice,
          targetMargin: values.targetMargin,
          targetMarginPercents: values.targetMarginPercents,
          autoChangePrice: values.autoChangePrice,
          adviceInterval: values.adviceInterval
        }
      },
      {
        onSuccess: (response) => {
          queryClient.setQueriesData<pimApi.price.ProductPriceAdvice[]>(
            ['get', 'pim/pricecontrol/priceadvice'],
            (priceSettings) => {
              const updater = priceSettings
              const arrIndex = priceSettings!.findIndex(el => el.productId === response.data.productId)
              if (arrIndex !== -1) {
                const item: any = priceSettings?.[arrIndex!]
                Object.keys(response.data).forEach((key) => {
                  item[key] = response.data[key]
                })
                // eslint-disable-next-line no-param-reassign
                priceSettings![arrIndex] = item
                return priceSettings as pimApi.price.ProductPriceAdvice[]
              }
              return updater as pimApi.price.ProductPriceAdvice[]
            }
          )
          queryClient.setQueriesData<pimApi.price.ProductPriceAdvice[]>(
            ['get', 'pim/pricecontrol/pricesettings'],
            (priceSettings) => {
              const arrIndex = priceSettings!.findIndex(el => el.productId === response.data.productId)
              const item: any = priceSettings?.[arrIndex!]
              Object.keys(response.data).forEach((key) => {
                item[key] = response.data[key]
              })
                // eslint-disable-next-line no-param-reassign
                priceSettings![arrIndex] = item
                return priceSettings as pimApi.price.ProductPriceAdvice[]
            }
          )
          snackActions.info('Изменение сохранено!')
        },
      })
    }
  }

  const [values, setValues] = useState(row)
  const handleChange = e => {
    const { name, value } = e.target
    if (name === 'targetMarginPercents' && (value > 100 || !(value.match(/^\d{0,}(\.\d{0,1})?$/)))) {
      return
    }
    if ((name === 'minPrice' || name === 'targetMargin') && value < 0) {
      return
    }
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleBlur = () => {
    handleSaveChanges(values)
  }


  return (
    <div style={{
      height: '100%',
      paddingTop: 15,
    }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16
        }}
      >
        <NumberInput
          name="minPrice"
          id="minPrice"
          value={values.minPrice}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          type="number"
        />
        <div style={{
          display: 'flex',
          gap: 8
        }}>
          <NumberInput
            name="targetMargin"
            id="targetMargin"
            value={values.targetMargin}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={true}
            autoComplete="off"
            type="number"
          />
          <div style={{ position: 'relative' }}>
            <PercentInput
              name="targetMarginPercents"
              id="targetMarginPercents"
              value={values.targetMarginPercents}
              onChange={handleChange}
              disabled={true}
              onBlur={handleBlur}
              autoComplete="off"
              type="number"
            />
            <span style={{
              position: 'absolute',
              boxSizing: 'border-box',
              top: '5px' ,
              left: '48px',
            }}>%</span>
          </div>
        </div>
      </div>
    </div>
  )
}
