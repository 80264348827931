import React, { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SearchIcon from '@mui/icons-material/Search'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'
import { FilterWrapper, SelectWrapper, StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'

const sortOptions = [
  {
    label: 'По возрастанию цены',
    value: 0,
    sort: 'price',
    order: 'asc'
  },
  {
    label: 'По убыванию цены',
    value: 1,
    sort: 'price',
    order: 'desc'
  },
  {
    label: 'Наибольшие остатки',
    value: 2,
    sort: 'stocks',
    order: 'desc'
  },
  {
    label: 'Наименьшие остатки',
    value: 3,
    sort: 'stocks',
    order: 'asc'
  },
]

export const TableFilters = ({ queryParameters, setQueryParameters, handleTableParams }) => {
  const { data: brandListQuery } = pimApi.dictionaries.useBrandListQuery()
  const { data: marketplaceListQuery } = pimApi.dictionaries.useMarketplaceList().marketplaceListQuery
  const { contragentListQuery } = pimApi.dictionaries.useContragentList({ onlySuppliers: true })
  const { companyListQuery } = pimApi.dictionaries.useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(queryParameters.marketplaceId, queryParameters.companyId)
  
  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery]
  )
  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data])
  
  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const brandOptions = useMemo(
    () => brandListQuery?.map(getOptions) || [],
    [brandListQuery]
  )

  const contragentOptions = useMemo(
    () => contragentListQuery.data?.map(getOptions) || [],
    [contragentListQuery.data])

  return (
    <>
      <FilterWrapper>
        <TextField
          className="searchInput"
          size="small"
          id="searchInput"
          variant="standard"
          autoComplete="off"
          placeholder="Поиск"
          defaultValue={queryParameters.searchString}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              document.getElementById('searchInput')?.blur()
            }
          }}
          onBlur={(e) => {
            setQueryParameters(prevState => ({
              ...prevState,
              searchString: e.target.value
            }))
            handleTableParams({ searchString: e.target.value })
          }}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
          }}/>
        <StyledFilterContainer>
          <SelectWrapper width={200}>
            <Autocomplete
              options={sortOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={sortOptions.find(el =>
                (queryParameters.sort === el.sort) && (queryParameters.order === el.order))
            || { label: '', value: -1, sort: '', order: '' }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setQueryParameters(prev =>({ ...prev, sort: null, order: null }))
                  handleTableParams({ sort: null, order: null })
                } else {
                  setQueryParameters(prev => ({ ...prev, sort: value?.sort, order: value?.order }))
                  handleTableParams({ sort: value?.sort, order: value?.order })
                }
              }}
              disableClearable={!queryParameters.order}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Сортировка"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SwapVertIcon sx={{ color: '#BDBDBD' }}/>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={marketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={marketplaceOptions.find(el => (queryParameters.marketplaceId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setQueryParameters(prev =>({ ...prev, marketplaceId: null, companymarketplaceId: null }))
                  handleTableParams({ marketplaceId: null, companymarketplaceId: null })
                } else {
                  setQueryParameters(prev => ({ ...prev, marketplaceId: value?.value }))
                  handleTableParams({ marketplaceId: value?.value })
                }
              }}
              disableClearable={!queryParameters.marketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Маркетплейс"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companyOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={companyOptions.find(el => (queryParameters.companyId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setQueryParameters(prev =>({ ...prev, companyId: null, companymarketplaceId: null }))
                  handleTableParams({ companyId: null, companymarketplaceId: null })
                } else {
                  setQueryParameters(prev => ({ ...prev, companyId: value?.value }))
                  handleTableParams({ companyId: value?.value })
                }
              }}
              disableClearable={!queryParameters.companyId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Продавец"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companymarketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disabled={companymarketplaceOptions.length === 0}
              disableCloseOnSelect={true}
              size="small"
              value={companymarketplaceOptions.find(el => (queryParameters.companymarketplaceId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setQueryParameters(prev =>({ ...prev, companymarketplaceId: value?.value }))
                handleTableParams({ companymarketplaceId: value?.value })
              }}
              disableClearable={!queryParameters.companymarketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Кабинет"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={brandOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={brandOptions.find(el => (queryParameters.brandId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setQueryParameters(prev =>({ ...prev, brandId: value?.value }))
                handleTableParams({ brandId: value?.value })
              }}
              disableClearable={!queryParameters.brandId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Бренд"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={180}>
            <Autocomplete
              options={contragentOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={contragentOptions.find(el => (queryParameters.supplierId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setQueryParameters(prev =>({ ...prev, supplierId: value?.value }))
                handleTableParams({ supplierId: value?.value })
              }}
              disableClearable={!queryParameters.supplierId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Поставщик"/>
              )}
            />
          </SelectWrapper>
        </StyledFilterContainer>
      </FilterWrapper>
    </>
  )
}