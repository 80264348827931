/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Attribute, NewAttribute } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Attribute>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: NewAttribute
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/attributes`,data).then(res => res.data)


export const useCreateGoodsattribute = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

