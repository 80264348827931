import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

const downloadWBProductBulkEditTemplateFile = async ({
  category,
  companymarketplaceId,
  brand,
  dateFrom,
  dateTo,
}: {
  categoryId?: UniqueId
  companymarketplaceId?: UniqueId
  templateType?: string
  dateFrom?: string
  dateTo?: string
  brand?: string
  category?: string
}) =>
  api.get<File>(
    '/pim/ops/products/wb/change/product',
    {
      params: {
        companymarketplace_id: companymarketplaceId,
        template_format: 'excel',
        category, brand,
        date_from: dateFrom,
        date_to: dateTo
      },
      responseType: 'blob',
    }
  )

export const useDownloadWBProductBulkEditTemplateFile = (options?) =>
  useMutation(downloadWBProductBulkEditTemplateFile, options)


const uploadWBProductBulkEditFile = async ({
  file,
  companymarketplaceId,
  brand,
  category,
}: {
  file: File | null
  companymarketplaceId: UniqueId
  brand?: UniqueId
  templateType?: string
  category?: string
}) => {
  const body = new FormData()
  body.append('file', file || '')

  return api.post(
    '/pim/ops/products/wb/change/product',
    body,
    { params: { companymarketplace_id: companymarketplaceId, template_format: 'excel', category, brand } }
  )
}

export const useUploadWBProductBulkEditFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    {
      file: File
      companymarketplaceId: UniqueId
      categoryId?: UniqueId
      templateType?: string
      brand?: UniqueId
      category?: string
    }
  >
) => useMutation(uploadWBProductBulkEditFile, options)
