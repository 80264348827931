/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'







export const apiFunction = () =>
  api.get(`/pim/ops/goods`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', `/pim/ops/goods`] as const

export const useGetExcelTemplate = (options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(),
    () => apiFunction(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

