import React from 'react'
import { Typography, Box } from '@mui/material'
import { generatePath } from 'react-router'
import { GOODS_EDIT } from 'shared/config'

import { StyledLink } from '../styled'

export const EmptyCompetitorsPage = ({ goodsId }: { goodsId: number }) => (
  <Box sx={{ marginTop: '52px', display: 'flex', justifyContent: 'center', width: '100%' }}>
    <Typography sx={{ color: 'rgba(0, 0, 0, 0.26)' }}>
      {'Нет отслеживаемых конкурентов\u00a0'}
    </Typography>
    <StyledLink href={generatePath(GOODS_EDIT, { id: goodsId, step: 'competitors' })} target="_blank" rel="noreferrer">товара</StyledLink>
  </Box>
)