import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { api } from '../base'

interface ICampaignStatus {
  id: number
  name: string
}
export function fetchCampaignStatus(): Promise<AxiosResponse<ICampaignStatus[]>> {
  return api.get<ICampaignStatus[]>('/activity/activity/type/campaignstatus')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'activity/activity/type/campaignstatus'] as const

function getCampaignStatus() {
  return fetchCampaignStatus().then((res) => res.data)
}

export const useCampaignStatusQuery = () => useQuery<
  Array<ICampaignStatus>,
  errorType,
  Array<ICampaignStatus>,
  QueryKey
>(QUERY_KEY, getCampaignStatus, {
  refetchOnMount: false,
})