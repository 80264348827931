import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper, Tooltip, Typography } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { memo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { InfoOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import { tooltipClasses } from '@mui/material/Tooltip'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { StyledTabPanel } from './styled'
import { PriceAdviceTab } from './tabs/priceAdviceTab/PriceAdviceTab'
import { DeclinedPriceAdviceTab } from './tabs/declinedPriceAdvice/declinedPriceAdviceTab'
import { PriceSettingsTab } from './tabs/priceSettings/PriceSettingsTab'

interface parametersTypes {
  supplierId?: number | null
  marketplaceId?: number | null
  companyId?: number | null
  companymarketplaceId?: number | null
  brandId?: number | null
  searchString?: string | null
  sort?: string | null
  order?: string | null
}
const TABS = [
  {
    label: 'Настройка продуктов',
    Component: PriceSettingsTab,
    text: 'На вкладке “Настройка продуктов” отражаются продукты, у которых есть конкуренты и указан поставщик.\n\n' +
      ' Здесь происходит первая настройка вводных данных ценообразования продукта,' +
      ' которая может меняться в дальнейшем. После настройки запускается процесс формирования рекомендации.'
  },
  {
    label: 'Рекомендации',
    Component: PriceAdviceTab,
    text: 'На вкладке “Рекомендации” отражаются рекомендации по ' +
      'изменению цены продуктов, исходя из заранее заданных параметров и внешних условий рынка.\n\n' +
      'Рекомендация может быть принята или отклонена. ' +
      'После подтверждения рекомендации, цена продукта изменится на рекомендованное значение.\n\n' +
      'В случае изменения вводных данных ценообразования продукта,' +
      ' вне зависимости от подтверждения или отклонения рекомендации, изменения вступят в силу ' +
      'только при формировании новой рекомендации по изменению цены'
  },
  {
    label: 'Отклоненные',
    Component: DeclinedPriceAdviceTab,
    text: ''
  },
] as const

export const Help = styled.span`
  color: #BDBDBD;
  display: inline-flex;
  align-items: center;
  :hover {
    color: #E0E0E0;
  }
  svg {
    font-size: 17px;
  }
`

const HelpWithMargin = styled(Help)`
  padding-bottom: 3px;
  padding-left: 8px;
`

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 6px 9px -7px rgba(0, 0, 0, 0.2), 0px 19px 30px 3px rgba(0, 0, 0, 0.14), 0px 9px 16px 8px rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    padding: 0,
    minWidth: '600px',
    maxWidth: '600px'
  },
}))

const StyledDescription = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  white-space: pre-line
`

export const RepriceTabs = memo(() => {
  const { search } = useLocation()
  const searchObj = queryString.parse(search)
  const history = useHistory()
  const { supplierId,sort, marketplaceId, companyId, companymarketplaceId, brandId, searchString, order } = searchObj
  
  const [ queryParameters, setQueryParameters ] = useState<parametersTypes>({
    supplierId: Number(supplierId) || null,
    marketplaceId: Number(marketplaceId) || null,
    companymarketplaceId: Number(companymarketplaceId) || null,
    companyId: Number(companyId) || null,
    brandId: Number(brandId) || null,
    searchString: searchString?.toString() || null,
    sort: sort?.toString() || null,
    order: order?.toString() || null
  })

  const handleChartParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true }
    )
    history.replace({ search: `?${historyStr}` })
  }

  const [value, setValue] = React.useState( searchObj.tab ? Number(searchObj.tab) : 1)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation()
    handleChartParams({ tab: newValue } )
    setValue(newValue)
  }

  return (
    <>
      <Paper>
        <StyledTabPanel>
          <Box sx={{ width: '100%' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {TABS.map((el, index) => (
                <Tab
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  sx={{ minWidth: '150px', maxHeight: '48px', minHeight: '48px' }}
                  icon={el.label !== 'Отклоненные' ?
                    <CustomTooltip title={
                      <Box>
                        <Box sx={{
                          paddingLeft: '24px',
                          height: '64px',
                          borderBottom: '1px solid #E0E0E0',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                          <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
                            {el.label}
                          </Typography>
                        </Box>
                        <Box sx={{ padding: '16px 24px' }}>
                          <StyledDescription>
                            {el.text}
                          </StyledDescription>
                        </Box>
                      </Box>
                    } placement="bottom">
                      <HelpWithMargin>
                        <InfoOutlined/>
                      </HelpWithMargin>
                    </CustomTooltip> : <></>
                  }
                  iconPosition="end"
                  label={el.label}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </StyledTabPanel>

        {TABS.map(({ Component }, index) => (
          <TabPanel
            value={value}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={index}
          >
            <Component queryParameters={queryParameters} setQueryParameters={setQueryParameters}/>
          </TabPanel>
        ))}
      </Paper>
    </>
  )
}
)