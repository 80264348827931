import { Box, Typography } from '@mui/material'

export const Header = () => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt="28px" height="36px">
    <Box display="flex" alignItems="center">
      <Typography variant="h3" fontSize="24px">
        Волшебник цен
      </Typography>
    </Box>
  </Box>
)