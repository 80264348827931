import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'
import { DataParamsTypes } from 'features/reviews/lib/types'
import { reviewApi } from 'shared/api'

export const getProductsReviewsQueryKey = (queryParams: DataParamsTypes) =>
  ['get', '/review/review', queryParams] as const

type GetProductsReviewsQueryKey = ReturnType<typeof getProductsReviewsQueryKey>

function getProductsReviews(queryParams: DataParamsTypes) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get(
    '/review/review', { params: { ...queryParamsSnake } }
  ).then(res => res.data)
}

const getAllProductsReviews = (context: QueryFunctionContext<GetProductsReviewsQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getProductsReviews(queryParams)
}

export const useProductsReviewsQuery = (queryParams: DataParamsTypes) =>
  useQuery<
    Array<reviewApi.Review>,
    errorType,
    Array<reviewApi.Review>,
    GetProductsReviewsQueryKey
  >(
    getProductsReviewsQueryKey(queryParams),
    getAllProductsReviews,
    { refetchOnMount: false, staleTime: Infinity }
  )
