/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'




export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  query?: {
  /**
   * Максимальная высота изображения
   * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
   */
    max_height?: number,
  /**
   * Максимальная ширина изображения
   * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
   */
    max_width?: number,

}
}>

export const apiFunction = ({ goodsId,query }: IRequest) =>
  api.get(`/pim/goods/${goodsId}/thumbnail`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ goodsId,query }: IRequest) => ['get', `/pim/goods/${goodsId}/thumbnail`, { goodsId,query }] as const

export const useGetGoodsThumbnail = (queryParams: IRequest, options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

