/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Contragent } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Contragent>

export type IRequest = CamelCasedPropertiesDeep<{
  contragentId: number
}>

export const apiFunction = ({ contragentId }: IRequest) =>
  api.delete<ResponseType>(`/pim/admin/contragents/${contragentId}/orderform`).then(res => res.data)


export const useDeleteContragentOrderform = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

