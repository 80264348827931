import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosResponse } from 'axios'



function getRoles(): Promise<AxiosResponse<pimApi.admin.Roles[]>> {
  return api.get<pimApi.admin.Roles[]>('/customer/admin/roles')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'customer/admin/roles'] as const


function getRolesFn() {
  return getRoles().then((res) => res.data)
}

export const useGetRolesQuery = () =>
  useQuery<
    pimApi.admin.Roles[],
    errorType,
    pimApi.admin.Roles[],
    QueryKey
    >(
      QUERY_KEY,
      getRolesFn, {
        refetchOnMount: false
      }
    )
