/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'




export type IRequest = CamelCasedPropertiesDeep<{
  contragentId: number
}>

export const apiFunction = ({ contragentId }: IRequest) =>
  api.patch(`/pim/admin/contragents/${contragentId}/orderform`).then(res => res.data)


export const useCheckContragentOrderform = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

