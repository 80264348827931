import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'

import { UploadWrapper } from './styled'

const FILE_EXTENSION =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const BulkFileActionsFooter = ({
  isDownloading,
  isUploading,
  download,
  upload,
  blankTemplateDownload,
  onGoBack,
  disabled
}: {
  isDownloading?: boolean
  isUploading?: boolean
  blankTemplateDownload?: () => void
  download?: () => void
  upload: ({ file }: { file: File }) => void
  onGoBack: () => void
  disabled?: boolean
}) => {
  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      upload({ file })
    }
    // Чтобы очищать значение инпута и можно будет закидывать одинаковые файлы
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }

  return (
    <Box display="flex" justifyContent={download ? 'space-between' : 'end'} mt={5}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        {blankTemplateDownload && (
          <LoadingButton
            color="primary"
            variant="outlined"
            loading={isDownloading}
            onClick={blankTemplateDownload}
            size="small"
          >
            Пустой шаблон
          </LoadingButton>
        )}
        {download && (
          <LoadingButton
            color="primary"
            loading={isDownloading}
            startIcon={<DownloadIcon />}
            onClick={download}
            disabled={disabled}
            size="small"
          >
            Скачать шаблон
          </LoadingButton>
        )}
      </Box>
      <UploadWrapper>
        <Button color="primary" onClick={onGoBack}>
          НАЗАД
        </Button>
        <Box ml={1} display="inline-flex">
          <label htmlFor="excel-file">
            <input
              accept={FILE_EXTENSION}
              disabled={disabled}
              id="excel-file"
              type="file"
              onChange={uploadFile}
            />
            <LoadingButton
              color="primary"
              variant="contained"
              loading={isUploading}
              disabled={disabled}
              component="span"
              startIcon={<UploadIcon />}
            >
              ИМПОРТ
            </LoadingButton>
          </label>
        </Box>
      </UploadWrapper>
    </Box>
  )
}
