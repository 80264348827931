/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Stats } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  companyId: number,
  cabinetId: number,
  query?: {
  /**
   * Stats
   * ID типов статистики
   * @default []
   */
    stats?: number[],
  /**
   * Date From
   * с дату
   * @format date
   */
    date_from?: string,
  /**
   * Date To
   * по даты
   * @format date
   */
    date_to?: string,

}
}>

export const apiFunction = ({ companyId,cabinetId,query }: IRequest) =>
  api.patch<ResponseType>(`/customer/companies/${companyId}/cabinets/${cabinetId}/stats`, {}, { params: query}).then(res => res.data)


export const useSyncStats = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

