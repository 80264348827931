import { Route, Switch } from 'react-router'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { NestedRoute, Preloader } from 'shared/ui/components'
import { viewerModel } from 'store/user'
import { LAYOUT_WIDTH } from 'shared/config'
import { NotFound } from 'shared/ui/components/NotFound'

import type { Route as RouteType } from './interface'
import home from './home'
import auth from './auth'
import docs from './docs'
import goods from './goods'
import bulkOperations from './bulkOperations'
import analytics from './analytics'
import products from './product'
import settings from './settings'
import stocks from './stocks'
import supplyOrders from './supplyOrders'
import adminPanel from './adminPanel'
import priceHistory from './priceHistory'
import reviews from './reviews'
import brandquad from './brandquad'

import { isNotNil } from '../shared/lib/checkers'
import { privilegeCheck } from '../shared/api/base'

const RootRoutes: Array<RouteType> = [
  ...analytics(),
  ...home(),
  ...priceHistory(),
  ...reviews(),
  ...bulkOperations(),
  ...docs(),
  ...goods(),
  ...brandquad(),
  ...products(),
  ...settings(),
  ...stocks(),
  ...supplyOrders(),
  ...adminPanel(),
  ...auth(),
  {
    protectedStatus: 'unknown',
    path: '*',
    layout: ({ children }) => children,
    layoutWidth: LAYOUT_WIDTH.content,
    component: Preloader
  }
]

export const Routing = () => {
  const status = useSelector(viewerModel.viewerStatusSelector)
  const protectedRoutes = useMemo(
    () => RootRoutes.filter(({ protectedStatus }) => protectedStatus ? protectedStatus === status : true).
      filter(({ privilegeRequired }) => {
        if (isNotNil(privilegeRequired)) {
          return privilegeCheck(privilegeRequired)
        }
        return true
      }),
    [status]
  )

  return (
    <Switch>
      {protectedRoutes.map(route => (
        <NestedRoute key={route.path} {...route} />
      ))}
      <Route component={NotFound} />
    </Switch>
  )
}


