import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { FilterWrapper as SharedFilterWrapper } from 'features/analytics/chart/styled'
import { StyledSlider as SharedSlider } from 'features/priceHistory/Header/ui/styled'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;

  .Toolbar-toolbar {
    >.MuiIconButton-root {
      order: 3;
      :last-child {
        margin-bottom: auto;
        margin-top: 0;
        padding-top: 24px;
      }
    }
    .SearchPanelInput-root {
      min-width: 220px;
      margin-top: 0;
      padding-top: 16px;
      margin-bottom: auto;
      max-width: 220px;
      margin-left: 0;
      order: 1;
      display: none;
    }
    #table-filter {
      width: auto;
      order: 2;
    }
  }
  
  .MuiTableCell-root {
    :first-child {
      padding-left: 16px;
    }
    height: 52px;
  }
`

export const FilterWrapper = styled(SharedFilterWrapper)`
  margin-top: 0;
  padding: 16px 0;

  .searchInput {
    color: rgba(0, 0, 0, 0.26);
    display: flex;
    min-width: 100px;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledChipsContainer = styled(Box)`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  div {
    :last-child {
      margin-right: 0;
    }
  }
`

export const StyledSlider = styled(SharedSlider)`
  width: 160px;
  padding: 5px 0;
`

export const StyledAutocompleteInput = styled.div`
  box-shadow: 0 1px 3px 0 #0000001F;
  padding: 1px 12px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: rgba(0, 0, 0, 0.60);
`

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  gap: 5px;
  padding: 40px 16px 16px;
  
  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`