import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'

interface EditUserRolePayload {
  userId: UniqueId
  roleId: UniqueId
  roleEditedData: pimApi.admin.EditContragentUser
}
export const editUserRole = ({
  userId,
  roleId,
  roleEditedData,
}: EditUserRolePayload) =>
  api.put<pimApi.admin.EditContragentUser>(
    `/customer/admin/users/${userId}/roles/${roleId}`,
    roleEditedData
  )

export const useEditUserRoleMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.EditContragentUser>,
    AxiosError<errorType>,
    EditUserRolePayload
  >(editUserRole)
