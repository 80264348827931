/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditSupplyOrder, SupplyOrder } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<SupplyOrder>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyorderId: number,
  data: EditSupplyOrder
}>

export const apiFunction = ({ supplyorderId,data }: IRequest) =>
  api.put<ResponseType>(`/trade/orders/supplyorders/${supplyorderId}`,data).then(res => res.data)


export const useChangeSupplyOrder = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

