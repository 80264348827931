import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'

interface CreateUserRolePayload {
  userId: UniqueId,
  roleData: pimApi.admin.NewUserRole,
}


const createUserRole = ({ userId, roleData }: CreateUserRolePayload) =>
  api.post<pimApi.admin.UserRole>(
    `/customer/admin/users/${userId}/roles`,
    roleData
  )

export const useCreateUserRoleMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.UserRole>,
    AxiosError<errorType>,
    CreateUserRolePayload
    >(createUserRole)