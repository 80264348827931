/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Goods, NewGoodsAssortment } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Goods>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: NewGoodsAssortment[]
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/assortment`,data).then(res => res.data)


export const useTransformGoodsToAssortment = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

