import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  CombinedState,
  Reducer,
} from 'redux'
import { all, fork } from 'redux-saga/effects'
import { diContainer } from 'shared/lib'
import { IUser } from 'shared/services/interfaces/user'
import { TYPES } from 'shared/services/types'
import { memoryStore } from 'shared/services/memoryStorу'
import { viewerModel } from 'store/user'
import { dialogModel } from 'shared/ui/components/dialog'

import { userReducer, userWatcher } from './user'
import {
  pendingReducer,
  pendingWatcher,
} from './shared/pending'
import { companyWatcher, companyReducer } from './company'
import { categoryWatcher, categoryReducer } from './category'
import { tnvedWatcher, tnvedReducer } from './tnved'
import { docWatcher, docReducer } from './doc'
import { catalogWatcher, catalogReducer, initCatalogFetching } from './catalog'
import { goodsEditReducer } from './goodsEdit'
import { sagaMiddleware } from './saga'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reducers = combineReducers({
  dialogConfirm: dialogModel.reducer,
  viewer: viewerModel.reducer,
  userReducer,
  pendingReducer,
  companyReducer,
  categoryReducer,
  tnvedReducer,
  catalogReducer,
  docReducer,
  goodsEditReducer
})

const user = diContainer.get<IUser>(TYPES.User)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* initServiceWorker() {
  try {
    yield user.authWatcher()
    if (memoryStore.get('X-Authorization')) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration && registration.active) {
            registration.active.postMessage(
              `Bearer ${memoryStore.get('X-Authorization')}`
            )
          }
        })
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error', error)
  }
}

function* sagas() {
  yield all([
    userWatcher,
    viewerModel.rootSaga(),
    pendingWatcher,
    companyWatcher,
    categoryWatcher,
    tnvedWatcher,
    catalogWatcher,
    docWatcher,
    fork(initCatalogFetching),
  ])
}

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(sagas)

// мини хак, возможно можно легче достать общий стейт
export type RootStore<T = typeof reducers> = T extends Reducer<CombinedState<infer S>, any> ? S : never

export default store
