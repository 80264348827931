/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationPostAddAddGoodsCompetitor, ImportMetadata, Marketplace } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Marketplace Id
   * Маркетплейс
   */
    marketplace_id: number,
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
    reckoned_flag?: boolean,
  /**
   * Признак - тот же товар или аналог
   * @default false
   */
    analogue_flag?: boolean,

},
  data: BodyBulkOperationPostAddAddGoodsCompetitor
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/goods/add/competitors`,data, { params: query }).then(res => res.data)


export const usePostAddAddGoodsCompetitor = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

