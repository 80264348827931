import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

import { toSnakeCase } from '../../../../lib/transform'

interface QueryParamsType {
  categoryId?: UniqueId
  parentCategoryId?: UniqueId
  companymarketplaceId?: UniqueId
  brandId?: UniqueId
  dateFrom?: string
  dateTo?: string
}
const downloadOzonProductBulkEditTemplateFile = async (queryParams: QueryParamsType) => api.get<File>(
  '/pim/ops/products/ozon/change/product',
  {
    params: toSnakeCase(queryParams),
    responseType: 'blob',
  }
)

export const useDownloadOzonProductBulkEditTemplateFile = (options?) =>
  useMutation(downloadOzonProductBulkEditTemplateFile, options)

const uploadOzonProductBulkEditFile = async ({
  brandId,
  companymarketplaceId,
  categoryId,
  parentCategoryId,
  file,
}: {
  brandId?: UniqueId
  companymarketplaceId: UniqueId
  parentCategoryId?: UniqueId
  categoryId?: UniqueId
  file: File | null
}) => {
  const body = new FormData()
  body.append('file', file || '')

  return api.post(
    '/pim/ops/products/ozon/change/product',
    body, {
      params: {
        brand_id: brandId,
        companymarketplace_id: companymarketplaceId,
        category_id: categoryId,
        parent_category_id: parentCategoryId
      }
    }
  )
}

export const useUploadOzonProductBulkEditFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    {
      file: File
      companymarketplaceId: UniqueId
      parentCategoryId?: UniqueId
      categoryId?: UniqueId
      brandId?: UniqueId
    }
  >
) => useMutation(uploadOzonProductBulkEditFile, options)
