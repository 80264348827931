/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptanceShortInfo {
  /** @example 1000 */
  id: number;
  positions: ReceiptDocPositionShortInfo[];
}

export interface ChangeReceiptDocStatus {
  id: number;
  version_no: number;

  /** @example start */
  operation: OperationsEnum;
  storage_id?: number;
}

export interface DeletingObject {
  id: number;
  version_no: number;
  comments?: string;
}

export interface EditReceiptDoc {
  id: number;
  version_no: number;

  /** @format date-time */
  expected_arrival_date?: string;

  /**
   * @max 100
   * @example 4
   */
  pallet_quantity?: number;

  /**
   * @max 100
   * @example 27
   */
  box_quantity?: number;

  /** @example Телефон водителя 123 */
  transport_info?: string;

  /** @example По приезду сообщить в отдел логистики */
  comments?: string;
  default_storage_id?: number;
}

export interface EditReceiptDocAcceptance {
  id: number;
  version_no: number;

  /** @example 10 */
  quantity_accepted?: number;

  /**
   * @min 0
   * @example 0
   */
  quantity_defective?: number;
}

export interface ErrorMessage {
  code?: number;
  message?: string;
}

export interface Goods {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;

  /** @example Coca Cola */
  brand: string;
  code: string;
  aux_code?: string;

  /** @example Fanta напиток газированый 2л */
  name: string;
  goods_type: GoodsType;
  images?: GoodsImage[];
  barcodes?: GoodsPackaging[];
}

export interface GoodsImage {
  id: number;

  /** @example 0 */
  index: number;
}

export interface GoodsPackaging {
  /** @example ООО Поставщик */
  supplier: string;

  /** @example Единичный товар */
  packagingtype: string;

  /** @example 2221114443 */
  barcode: string;
}

/**
 * An enumeration.
 */
export enum GoodsType {
  Common = "common",
  Bundle = "bundle",
  Pack = "pack",
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface ManualNewReceiptDocPosition {
  /** @example FTH-1022/B */
  supplier_code: string;

  /** @example 1001 */
  goods_id?: number;

  /** @example доложено поставщиком */
  reason: string;

  /** @example Зубная щетка XXL */
  comments?: string;

  /** @example 420 */
  quantity: number;
}

export interface NewReceiptDoc {
  id?: number;

  /** @example 00-000001 */
  warehouse_code: string;

  /** @example 1C-ERP */
  source_system_code: string;

  /** @format date-time */
  doc_date: string;

  /** @example ТН-2011/6 */
  doc_number: string;

  /** @format date-time */
  supplier_doc_date: string;

  /** @example ТН-2011/6 */
  supplier_doc_number: string;

  /** @example 1658159352 */
  supplier_inn: string;

  /** @example 165801001 */
  supplier_kpp?: string;

  /** @example 1658159352 */
  recipient_inn: string;

  /** @example 165801001 */
  recipient_kpp?: string;

  /** @example По приезду сообщить в отдел логистики */
  comments?: string;
  positions: NewReceiptDocPosition[];
}

export interface NewReceiptDocAcceptance {
  /** @example 12345678909 */
  barcode?: string;

  /** @example 10 */
  quantity_accepted: number;

  /**
   * @min 0
   * @example 0
   */
  quantity_defective?: number;
}

export interface NewReceiptDocPosition {
  /** @example FTH-1022/B */
  supplier_code: string;

  /** @example 1001 */
  goods_external_id: number;

  /** @example 99.99 */
  price: number;

  /** @example 420 */
  quantity: number;
}

export interface NotAuthenticatedError {
  detail?: string;
}

/**
 * An enumeration.
 */
export enum OperationsEnum {
  Start = "start",
  Pause = "pause",
  Resume = "resume",
  Finish = "finish",
}

export interface ReceiptDoc {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;

  /** @format date-time */
  doc_date: string;

  /** @example ТН-2011/6 */
  doc_number: string;

  /** @example ООО Отправитель */
  supplier: string;

  /** @example 2011 */
  supplier_id: number;

  /** @example ООО Получатель */
  recipient: string;

  /** @format date-time */
  supplier_doc_date: string;

  /** @example ТН-2011/6 */
  supplier_doc_number: string;

  /** @format date-time */
  expected_arrival_date?: string;

  /** @format date-time */
  receipt_start_date?: string;

  /** @format date-time */
  receipt_finish_date?: string;

  /** @example 4 */
  pallet_quantity?: number;

  /** @example 27 */
  box_quantity?: number;

  /** @example Телефон водителя 123 */
  transport_info?: string;

  /** @example По приезду сообщить в отдел логистики */
  comments?: string;

  /** @example 8890 */
  default_storage_id?: number;

  /** @example new */
  status: ReceiptDocStatusEnum;
  positions?: ReceiptDocPosition[];
}

export interface ReceiptDocAcceptance {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;

  /** @example Малая коробка */
  packaging_type: string;

  /** @example 12345678909 */
  barcode: string;

  /** @example 18 */
  quantity_in_packaging: number;

  /** @example 10 */
  quantity_accepted: number;

  /** @example 0 */
  quantity_defective: number;
}

export interface ReceiptDocPosition {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;

  /** @example FTH-1022/B */
  supplier_code: string;

  /** @example 1001 */
  goods_id: number;

  /** @example Зубная щетка XXL */
  goods_name: string;

  /** @example 902221 */
  goods_external_id?: string;

  /** @example 1001 */
  goods_base_image_id?: number;

  /** @example 99.99 */
  price?: number;

  /** @example 420 */
  quantity: number;
  new_position?: boolean;
  acceptance?: ReceiptDocAcceptance[];
}

export interface ReceiptDocPositionShortInfo {
  /** @example 1001 */
  id: number;
  acceptance?: ReceiptDocAcceptance[];
}

export interface ReceiptDocShortInfo {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;

  /** @format date-time */
  doc_date: string;

  /** @example ТН-2011/6 */
  doc_number: string;

  /** @example ООО Отправитель */
  supplier: string;

  /** @example ООО Получатель */
  recipient: string;

  /** @format date-time */
  supplier_doc_date: string;

  /** @example ТН-2011/6 */
  supplier_doc_number: string;

  /** @format date-time */
  expected_arrival_date?: string;

  /** @format date-time */
  receipt_start_date?: string;

  /** @format date-time */
  receipt_finish_date?: string;

  /** @example Телефон водителя 123 */
  transport_info?: string;

  /** @example 8890 */
  default_storage_id?: number;

  /** @example new */
  status: ReceiptDocStatusEnum;
}

/**
 * An enumeration.
 */
export enum ReceiptDocStatusEnum {
  New = "new",
  Started = "started",
  Paused = "paused",
  Finished = "finished",
  FinishedWithDiff = "finished_with_diff",
}

export interface Storage {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;

  /** @example Ряд 1 */
  name?: string;

  /** @example 898899992 */
  barcode?: string;

  /** @example Зона приёмки */
  storagetype?: string;
  parentstorage_id?: number;
}

export interface ValidationError {
  loc: string[];
  msg: string;
  type: string;
}

export interface Warehouse {
  id: number;
  version_no: number;

  /** @format date-time */
  changed: string;
  changer: string;
  name?: string;
  code?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api/wms";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title E-COM WMS API
 * @version 0.2.0
 * @baseUrl /api/wms
 *
 * E-COM PORTAL Warehouse Management System API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  dictionaries = {
    /**
     * @description Получение списка всех складов в системе
     *
     * @tags Dictionaries
     * @name GetWarehousesWarehousesGet
     * @summary Получение списка складов
     * @request GET:/warehouses
     * @secure
     */
    getWarehousesWarehousesGet: (params: RequestParams = {}) =>
      this.request<Warehouse[], ErrorMessage | NotAuthenticatedError>({
        path: `/warehouses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение информации о складе по переданному идентификатору
     *
     * @tags Dictionaries
     * @name GetWarehouseWarehousesWarehouseIdGet
     * @summary Получение информации о складе
     * @request GET:/warehouses/{warehouse_id}
     * @secure
     */
    getWarehouseWarehousesWarehouseIdGet: (warehouseId: number, params: RequestParams = {}) =>
      this.request<Warehouse, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/warehouses/${warehouseId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение списка мест хранения по указанному складу
     *
     * @tags Dictionaries
     * @name GetStoragesStoragesGet
     * @summary Получение списка мест хранения
     * @request GET:/storages
     * @secure
     */
    getStoragesStoragesGet: (query: { warehouse_id: number }, params: RequestParams = {}) =>
      this.request<Storage[], ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/storages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение информации о месте хранения по переданному идентификатору
     *
     * @tags Dictionaries
     * @name GetStorageStoragesStorageIdGet
     * @summary Получение информации о месте хранения
     * @request GET:/storages/{storage_id}
     * @secure
     */
    getStorageStoragesStorageIdGet: (storageId: number, params: RequestParams = {}) =>
      this.request<Storage, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/storages/${storageId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  receiptDocuments = {
    /**
     * @description Получение списка всех документов прихода товара по указанному складу
     *
     * @tags Receipt documents
     * @name GetReceiptdocsReceiptdocsGet
     * @summary Получение списка документов прихода товара
     * @request GET:/receiptdocs
     * @secure
     */
    getReceiptdocsReceiptdocsGet: (query: { warehouse_id: number }, params: RequestParams = {}) =>
      this.request<ReceiptDocShortInfo[], ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создание документа прихода товара с указанием всех позиций документа
     *
     * @tags Receipt documents
     * @name CreateReceiptdocReceiptdocsPost
     * @summary Создание документа прихода товара
     * @request POST:/receiptdocs
     * @secure
     */
    createReceiptdocReceiptdocsPost: (data: NewReceiptDoc, params: RequestParams = {}) =>
      this.request<ReceiptDoc, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение полной информации по документу прихода товара, включая список позиций и фактическое количество оприходованного товара
     *
     * @tags Receipt documents
     * @name GetReceiptdocReceiptdocsDocIdGet
     * @summary Получение полной информации по конкретному документу прихода товара
     * @request GET:/receiptdocs/{doc_id}
     * @secure
     */
    getReceiptdocReceiptdocsDocIdGet: (docId: number, params: RequestParams = {}) =>
      this.request<ReceiptDoc, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Редактирование атрибутов документа прихода товара
     *
     * @tags Receipt documents
     * @name ChangeReceiptdocReceiptdocsDocIdPut
     * @summary Редактирование атрибутов документа прихода товара
     * @request PUT:/receiptdocs/{doc_id}
     * @secure
     */
    changeReceiptdocReceiptdocsDocIdPut: (docId: number, data: EditReceiptDoc, params: RequestParams = {}) =>
      this.request<ReceiptDoc, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Применение метода изменения статуса над документом прихода товара. Проверяет возможность применения метода в зависимости от текущего статуса документа
     *
     * @tags Receipt documents
     * @name ChangeReceiptdocStatusReceiptdocsDocIdPatch
     * @summary Изменение статуса документа прихода товара
     * @request PATCH:/receiptdocs/{doc_id}
     * @secure
     */
    changeReceiptdocStatusReceiptdocsDocIdPatch: (
      docId: number,
      data: ChangeReceiptDocStatus,
      params: RequestParams = {},
    ) =>
      this.request<ReceiptDocShortInfo, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Регистрация приёмки товара по его штрих-коду. Метод будет доступен с ТСД.
     *
     * @tags Receipt documents
     * @name AcceptBarcodeReceiptdocsDocIdAcceptPost
     * @summary Приём товара по документу прихода товара
     * @request POST:/receiptdocs/{doc_id}/accept
     * @secure
     */
    acceptBarcodeReceiptdocsDocIdAcceptPost: (docId: number, query: { barcode: string }, params: RequestParams = {}) =>
      this.request<AcceptanceShortInfo, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}/accept`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавление позиции по новой номенклатуре в документ прихода товара
     *
     * @tags Receipt documents
     * @name CreateReceiptdocpositionReceiptdocsDocIdPositionsPost
     * @summary Добавление позиции в документ прихода товара
     * @request POST:/receiptdocs/{doc_id}/positions
     * @secure
     */
    createReceiptdocpositionReceiptdocsDocIdPositionsPost: (
      docId: number,
      data: ManualNewReceiptDocPosition,
      params: RequestParams = {},
    ) =>
      this.request<ReceiptDocPosition, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}/positions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление позиции из документа прихода товара, которая ранее была создана вручную
     *
     * @tags Receipt documents
     * @name DeleteReceiptdocpositionReceiptdocsDocIdPositionsPositionIdDelete
     * @summary Удаление позиции из документа прихода товара
     * @request DELETE:/receiptdocs/{doc_id}/positions/{position_id}
     * @secure
     */
    deleteReceiptdocpositionReceiptdocsDocIdPositionsPositionIdDelete: (
      docId: number,
      positionId: number,
      data: DeletingObject,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}/positions/${positionId}`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Добавление информации о принятом количестве по позиции документа прихода товара
     *
     * @tags Receipt documents
     * @name CreateReceiptdocacceptanceReceiptdocsDocIdPositionsPositionIdAcceptancePost
     * @summary Добавление информации о принятом количестве по позиции документа прихода товара
     * @request POST:/receiptdocs/{doc_id}/positions/{position_id}/acceptance
     * @secure
     */
    createReceiptdocacceptanceReceiptdocsDocIdPositionsPositionIdAcceptancePost: (
      docId: number,
      positionId: number,
      data: NewReceiptDocAcceptance,
      params: RequestParams = {},
    ) =>
      this.request<ReceiptDocAcceptance, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}/positions/${positionId}/acceptance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Изменение информации о принятом количестве по позиции документа прихода товара
     *
     * @tags Receipt documents
     * @name EditReceiptdocacceptanceReceiptdocsDocIdPositionsPositionIdAcceptanceAcceptanceIdPut
     * @summary Изменение информации о принятом количестве по позиции документа прихода товара
     * @request PUT:/receiptdocs/{doc_id}/positions/{position_id}/acceptance/{acceptance_id}
     * @secure
     */
    editReceiptdocacceptanceReceiptdocsDocIdPositionsPositionIdAcceptanceAcceptanceIdPut: (
      docId: number,
      positionId: number,
      acceptanceId: number,
      data: EditReceiptDocAcceptance,
      params: RequestParams = {},
    ) =>
      this.request<ReceiptDocAcceptance, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}/positions/${positionId}/acceptance/${acceptanceId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление информации о принятом количестве по позиции документа прихода товара
     *
     * @tags Receipt documents
     * @name DeleteReceiptdocacceptanceReceiptdocsDocIdPositionsPositionIdAcceptanceAcceptanceIdDelete
     * @summary Удаление информации о принятом количестве по позиции документа прихода товара
     * @request DELETE:/receiptdocs/{doc_id}/positions/{position_id}/acceptance/{acceptance_id}
     * @secure
     */
    deleteReceiptdocacceptanceReceiptdocsDocIdPositionsPositionIdAcceptanceAcceptanceIdDelete: (
      docId: number,
      positionId: number,
      acceptanceId: number,
      data: DeletingObject,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/receiptdocs/${docId}/positions/${positionId}/acceptance/${acceptanceId}`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  goods = {
    /**
     * @description Получение краткой информации по запрошенной номенклатуре товара
     *
     * @tags Goods
     * @name GetGoodsGoodsGoodsIdGet
     * @summary Получение информации по товару
     * @request GET:/goods/{goods_id}
     * @secure
     */
    getGoodsGoodsGoodsIdGet: (goodsId: number, params: RequestParams = {}) =>
      this.request<Goods, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/goods/${goodsId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Поиск товара по его штрих-коду
     *
     * @tags Goods
     * @name SearchGoodsGoodsGet
     * @summary Поиск товара
     * @request GET:/goods
     * @secure
     */
    searchGoodsGoodsGet: (query: { barcode: string; supplier_id?: number }, params: RequestParams = {}) =>
      this.request<Goods[], ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/goods`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение изображения товара по идентификатору изображения
     *
     * @tags Goods
     * @name GetGoodsfileGoodsGoodsIdImageImageIdGet
     * @summary Получение изображения товара
     * @request GET:/goods/{goods_id}/image/{image_id}
     */
    getGoodsfileGoodsGoodsIdImageImageIdGet: (
      goodsId: number,
      imageId: number,
      query?: { max_height?: number; max_width?: number },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorMessage | NotAuthenticatedError | HTTPValidationError>({
        path: `/goods/${goodsId}/image/${imageId}`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
}
