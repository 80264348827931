import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { customerApi } from 'shared/api'

interface ReportData {
  id: UniqueId,
  privilegeId: UniqueId | null
}
interface EditReportsAccessPayload {
  reportId: UniqueId,
  reportData: ReportData
}
export const editReportsAccess = ({ reportId, reportData }: EditReportsAccessPayload) =>
  api.put<customerApi.AdminReport>(
    `/customer/admin/reports/${reportId}`,
    reportData
  )

export const useEditReportsAccessMutation = () =>
  useMutation<
    AxiosResponse<customerApi.AdminReport>,
    AxiosError<errorType>,
    EditReportsAccessPayload
  >(editReportsAccess)
