import { IconButton } from '@mui/material'
import React, { memo } from 'react'
import { isNil } from 'shared/lib/checkers/isNil'

import { StyledBox, StyledClassText, StyledDot, StyledInfoIcon, StyledText, StyledTool, StyledToolTipDiv } from './styled'

interface classProps {
    abc: string | undefined
}
const class_colors = [
  { name: 'A', color: '#4CAF50' },
  { name: 'B', color: '#FFB547' },
  { name: 'C', color: '#F50057' }
]

export const AbcClassCard = memo((props: classProps) => {
  const { abc } = props

  const current_color = class_colors.find(c => abc ? c.name === abc[0] : false)?.color
  const fir_letter = abc ? abc[0] : ''
  const sec_letter = abc ? abc[1] : ''

  if (isNil(abc)) {
    return null
  }

  return (
    <StyledBox>
      <div>
        <StyledDot color={current_color}/>
      </div>
      <div>
        <StyledText>{abc}</StyledText>
        <StyledClassText>Класс</StyledClassText>
      </div>
      <StyledToolTipDiv>
        <StyledTool title= {<span>{fir_letter} - Классификация по сумме продаж <br/>
          {sec_letter} - Классификация по количеству продаж</span>} placement="right">
          <IconButton>
            <StyledInfoIcon/>
          </IconButton>
        </StyledTool>
      </StyledToolTipDiv>
    </StyledBox>
  )
}
)


