import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface EditProductCompetitorPayload {
  productId: UniqueId,
  editedCompetitor: pimApi.goods.EditingCompetitor
}
export const editProductCompetitor = ({ productId, editedCompetitor }: EditProductCompetitorPayload) =>
  api.put<pimApi.goods.Competitor>(
    `/pim/products/${productId}/competitors`,
    editedCompetitor
  )


export const useEditProductCompetitorMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.Competitor>,
    AxiosError<errorType>,
    EditProductCompetitorPayload
  >(editProductCompetitor)
