import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { reviewApi } from 'shared/api/index'
import { toSnake } from 'shared/lib/transform/toSnakeCase'

interface QueryParamsType {
  templateId?: UniqueId;
  reviewType?: number;
  category?: string
  searchString?: string
}
export const getReviewsTemplateQueryKey = (queryParams: { reviewType?: number }) =>
  ['get', '/review/models/templates', queryParams] as const

type GetReviewsTemplateQueryKey = ReturnType<typeof getReviewsTemplateQueryKey>

function getReviewsTemplate(queryParams: QueryParamsType) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get(
    '/review/models/templates', { params: queryParamsSnake }
  ).then(res => res.data)
}

const getAllReviewsTemplate = (context: QueryFunctionContext<GetReviewsTemplateQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getReviewsTemplate(queryParams)
}

export const useReviewsTemplateQuery = (queryParams: QueryParamsType) =>
  useQuery<
    Array<reviewApi.ReviewTemplate>,
    errorType,
    Array<reviewApi.ReviewTemplate>,
    GetReviewsTemplateQueryKey
  >(
    getReviewsTemplateQueryKey({ reviewType: queryParams.reviewType }),
    getAllReviewsTemplate,
    { staleTime: Infinity, keepPreviousData: true }
  )
