/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Category } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Category[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Идентификатор родительской категории
   * При необходимости можно отфильтровать возвращаемый список по определённой родительской категории. Если передать 0, то будут возвращены корневые категории.
   */
    parentcategory_id?: number,
  /**
   * Поиск категорий, содержащих подстроку
   * Получение списка всех категорий, которые содержат определённую подстроку. Если у данной категории есть дочерние, то они также выводятся. Выводятся только конечные категории, то есть у которых нет дочерних.
   */
    search_string?: string,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/pim/categories`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/categories`, { query }] as const

export const useGetCategories = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

