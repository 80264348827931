import React, { memo, useCallback, useRef } from 'react'
import { CircularProgress } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { customerApi, pimApi } from 'shared/api'
import { UserImage } from 'features/admin/User/EditUser/ui/UserImage'
import { Avatar, DeleteAvatar, EditPhoto } from 'features/admin/User/EditUser/ui/styled'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { getUserPhotoQueryKey } from 'shared/api/customer/admin'
import { snackActions } from 'shared/lib/react/snackbar'


interface AvatarProps {
  values: pimApi.admin.CurrentUser
  isClosed: boolean
}


export const UserAvatar = memo(({ values, isClosed }: AvatarProps) => {
  const { id } = useParams<{ id: string }>()
  const userId = parseInt(id, 10)
  const queryClient = useQueryClient()
  
  const { data: userPhoto } = customerApi.admin.useGetUserPhotoQuery(userId)
  const { mutate: deletePhoto } = customerApi.admin.useDeletePhotoUser()
  const { mutate: addPhoto } = customerApi.admin.useAddAdminUserPhoto()

  const handleDeletePhoto = () => {
    const emptyBlob = new Blob([], { type: 'image/jpeg' })
    deletePhoto({
      userId
    }, {
      onSuccess: () => {
        queryClient.setQueryData(getUserPhotoQueryKey(userId), { data: emptyBlob })
        snackActions.info('Аватар пользователя удалён')
      },
    })
  }

  const handleUploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const formData = new FormData()
      formData.append('users_photo', event.target.files[0] || '')
      addPhoto({
        userId,
        file: formData
      }, {
        onSuccess: () => {
          if (event.target.files?.[0]) {
            const file = event.target.files[0]
            file.arrayBuffer().then((arrayBuffer) => {
              const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type })
              queryClient.setQueryData(getUserPhotoQueryKey(userId), { data: blob })
              // eslint-disable-next-line no-param-reassign
              event.target.value = ''
            })
          }
          snackActions.info('Аватар пользователя изменен')
        },
      })
    }
  }
  const inputRef = useRef<HTMLInputElement>(null)
  const handleAvatarPlaceHolderClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [inputRef])
  
  if (isClosed) {
    return (
      <Avatar>
        {userPhoto ?
          <UserImage fontSize={36} size={96} src={URL.createObjectURL(userPhoto.data)} userName={values.name}/> :
          <CircularProgress/>
        }
      </Avatar>
    )
  }
  return (
    <Avatar>
      {userPhoto ?
        <>
          <UserImage fontSize={36} size={96} src={URL.createObjectURL(userPhoto.data)} userName={values.name}/>
          {userPhoto.data.size > 0 &&
            <DeleteAvatar onClick={handleDeletePhoto}>
              <DeleteIcon sx={{ margin: '4px 5px 5px 3px', color: 'rgba(0, 0, 0, 0.38)' }}/>
            </DeleteAvatar>}
          <EditPhoto onClick={handleAvatarPlaceHolderClick}>
            <CameraAltIcon sx={{ margin: '4px 5px 5px 3px', color: 'rgba(0, 0, 0, 0.38)' }}/>
            <input
              ref={inputRef}
              accept="image/jpg, image/jpeg, image/png, image/webp"
              type="file"
              multiple={true}
              onChange={handleUploadAvatar}
              style={{ display: 'none' }}
            />
          </EditPhoto>
        </> :
        <CircularProgress/>
      }
    </Avatar>
  )
}
)
