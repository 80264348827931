import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface EditPriceSettingsPayload {
  editedSettings: pimApi.price.PriceSettings
}
export const editPriceSettings = ({ editedSettings }: EditPriceSettingsPayload) =>
  api.put<pimApi.price.PriceSettings>(
    '/pim/pricecontrol/pricesettings',
    editedSettings
  )


export const useEditPriceSettingsMutation = () =>
  useMutation<
    AxiosResponse<pimApi.price.PriceSettings>,
    AxiosError<errorType>,
    EditPriceSettingsPayload
  >(editPriceSettings)
