/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationPostChangeReviewSetting, ImportMetadata, ReviewTypeOptions } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Идентификатор кабинета */
    companymarketplace_id: number,
  /** Тип */
    review_type_id: ReviewTypeOptions,

},
  data: BodyBulkOperationPostChangeReviewSetting
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/bot/review`,data, { params: query }).then(res => res.data)


export const usePostChangeReviewSetting = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

