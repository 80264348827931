import React, { memo } from 'react'
import usePending from 'shared/lib/hooks/PendingHook'
import { userDownloadBadge } from 'store/user'
import { LoadingButton } from '@mui/lab'

const BadgeDownload = () => {
  const [getCatalog, catalogIsLoading] = usePending(userDownloadBadge)
  return (
    <LoadingButton sx={{ height: '32px', width: '177px', padding: '0 10px' }}
      variant="outlined" onClick={getCatalog} loading={catalogIsLoading}>
      Распечатать бейдж
    </LoadingButton>
  )
}

export default memo(BadgeDownload)
