import styled from 'styled-components'
import { Card } from '@mui/material'

export const StyledCard = styled(Card)`
  min-width: 176px;
  border: 1px solid #e4e4e4;
  padding: 8px 16px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  svg {
    cursor: pointer;
  }

  .spp-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    height: 28px;
    margin-right: -16px;
    gap: 8px;
    border-top: 1px solid #e0e0e0;
  }
`

export const StyledBox = styled.div<{ isArchived?: boolean }>`
  min-width: 144px;
  height: 40px;
  margin-bottom: 8px;
  #current-price {
    opacity: ${({ isArchived }) => (isArchived ? '80%' : '')};
  }
  #old-price-container {
    width: 100%;
    #old-price-text {
      opacity: ${({ isArchived }) => (isArchived ? '80%' : '')};
    }
    #discount-container {
      opacity: 100;
    }
  }
`

export const StyledDiscount = styled.div`
  width: auto;
  height: 20px;
  display: flex;
  font-size: 12px;
  color: #2196f3;
  margin-left: auto;
  margin-right: 0;
  padding: 1px 6px;
  border-radius: 64px;
  border: 1px solid #007dff;
  margin-top: auto;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: rgba(0, 0, 0, 0.26);

  svg {
    transition: all 0.1s ease-out 0.1s;
    :hover {
      color: #007dff;
    }
  }
`
