import { FC, memo, useEffect, useRef, useState } from 'react'
import { IconButton, SelectChangeEvent } from '@mui/material'
import { Select } from 'shared/ui/components'
import ArrowForwardDotted from 'assets/images/ArrowForwardDotted.svg'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { sort } from 'shared/lib/utils/sort'
import produce from 'immer'

import { LeftComponent } from './LeftComponents'

import {
  NormalizedAttributesLeft as NormalizedAttributesLeftType,
  NormalizedAttributesLeftWaitFilled,
  NormalizedAttributesRight
} from '../../lib/getNormalizedAttributes'

interface NormalizedAttributesLeftProps {
  normalizedAttributesLefts: Array<NormalizedAttributesLeftType | NormalizedAttributesLeftWaitFilled>
  normalizedAttributesRights: Array<NormalizedAttributesRight>
  onSelectMapAttributeChange: (
    index: number,
  ) => (event: SelectChangeEvent) => void
  disabled?: boolean
}

export const NormalizedAttributesLeft: FC<NormalizedAttributesLeftProps> = memo(({
  normalizedAttributesLefts,
  normalizedAttributesRights,
  onSelectMapAttributeChange,
  disabled
}) => {
  const [ leftAttributes, setLeftAttributes ] = useState(normalizedAttributesLefts.map(el => ({
    ...el,
    fieldCreated: false
  })))

  const firstMount = useRef<boolean>(true)

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false
      return
    }
    setLeftAttributes(normalizedAttributesLefts.map(el => ({
      ...el,
      fieldCreated: false
    })))
  }, [normalizedAttributesLefts, normalizedAttributesRights])
  return (
    <>
      { leftAttributes.map(({ left, fieldCreated }, index) => (
        <InputsWrapper
          key={ JSON.stringify(left) }
          arrow={
            <IconButton aria-label="set" onClick={() => {
              setLeftAttributes(produce((draft) => {
                // eslint-disable-next-line no-param-reassign
                draft[index].fieldCreated = true
              }))
            }} disabled={ disabled } size="small">
              <img src={ ArrowForwardDotted } alt="close icon"/>
            </IconButton>
          }
        >
          <LeftComponent attribute={ left }/>
          { fieldCreated && (
            <Select
              name="unusedProp"
              // @ts-ignore
              onChange={ onSelectMapAttributeChange(index) }
              options={ sort(normalizedAttributesRights.map(({ right }, rightIndex) => ({
                label: right.attributeName,
                value: `${ rightIndex }`
              })), 'label') }
              disabled={ disabled }
            />
          ) }
        </InputsWrapper>
      )
      ) }
    </>
  )
}, (prevProps, nextProps) => 
  (JSON.stringify(prevProps.normalizedAttributesRights) === JSON.stringify(nextProps.normalizedAttributesRights) &&
    (JSON.stringify(prevProps.normalizedAttributesLefts) === JSON.stringify(nextProps.normalizedAttributesLefts) ) &&
    JSON.stringify(prevProps.disabled) === JSON.stringify(nextProps.disabled)
  ))