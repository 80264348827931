import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName
    extends Omit<
        tradeApi.CoPackingOrderPosition,
        | 'versionNo'
        | 'changed'
        | 'changer'
        > {
  actionColumn?: string
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  goodsType: 'goodsType',
  marketplace: 'marketplace',
  productId: 'productId',
  productCode: 'productCode',
  expectedQuantity: 'expectedQuantity',
  sku: 'sku',
  goods: 'goods',
  id: 'id',
  marketplaceUrl: 'marketplaceUrl',
  productName: 'productName',
  quantity: 'quantity',
  actionColumn: 'actionColumn',
  autoOrdering: 'autoOrdering'
}

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config: [
    {
      name: columns.goodsType,
      columnName: columns.goodsType,
      title: 'Тип',
      width: 72,
      sortingEnabled: true,
      cellType: CellType.GoodsType
    },
    {
      name: columns.productCode,
      columnName: columns.productCode,
      title: 'Артикул',
      width: 106,
      sortingEnabled: false,
    },
    {
      name: columns.productName,
      columnName: columns.productName,
      title: 'Наименование',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.NameLink,

    },
    {
      name: columns.marketplace,
      columnName: columns.marketplace,
      title: 'Маркетплейс',
      width: 178,
      sortingEnabled: false,
      cellType: CellType.MarketplaceWithIcon
    },
    {
      name: columns.sku,
      columnName: columns.sku,
      title: 'Номенклатура маркетплейса',
      width: 128,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.MarketplaceLink,
    },
    {
      name: columns.quantity,
      columnName: columns.quantity,
      title: 'Количество продукта',
      width: 112,
      sortingEnabled: false,
      wordWrapEnabled: true,
      editingEnabled: true,
      cellType: CellType.EditField,
    },
    {
      name: columns.expectedQuantity,
      columnName: columns.expectedQuantity,
      title: 'В заказе',
      width: 104,
      sortingEnabled: false,
      isShowGreen: true,
      quantityData: columns.quantity,
      cellType: CellType.QuantityInCopacking
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 48,
      cellType: CellType.ActionRemove,
    },
  ],
}
