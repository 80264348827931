import React, { useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import { Table } from 'shared/ui/components/Table'
import { pimApi } from 'shared/api'
import { IconButton } from '@mui/material'
import { Getter, Plugin } from '@devexpress/dx-react-core'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { EditingState, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid'
import { TableEditColumn, TableSelection as DXTableSelection } from '@devexpress/dx-react-grid-material-ui'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getPriceAdviceQueryKey } from 'shared/api/pim/price'
import { snackActions } from 'shared/lib/react/snackbar'
import { isNotNil } from 'shared/lib/checkers'
import { SearchObj } from 'shared/ui/components/Table/model/types'

import { StyledLoader, StyledTableBox } from './styled'

import { columnsConfig } from '../model/columnsConfig'
import { pagingPanelLocale } from '../lib/localization'
import { CustomCheckbox } from '../../../ui/CustomCheckbox'
import { CustomTableEdit } from '../../../ui/CustomTableEdit'
import { CustomHeaderEdit } from '../../../ui/CustomHeaderEdit'
import { CustomHeaderCheckbox } from '../../../ui/CustomHeaderCheckbox'
import { TableFilters } from '../../../filter/tableFilters'


const ROW_HEIGHT = 115
const HEADER_HEIGHT = 88
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

const getRowId = (row) => row.rowId
export const PriceAdvicesTable = ({ queryParameters, setQueryParameters }) => {
  const query = pimApi.price.useGetPriceAdviceQuery(queryParameters)
  const { data } = query
  const history = useHistory()
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)
  const handleTableParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true }
    )
    history.replace({ search: `?${historyStr}` })
  }
  const queryClient = useQueryClient()
  const fileThumbnailSize = { maxWidth: 50, maxHeight: 50 }
  const bonusCols = Math.floor(
    (window.innerHeight -
        DEFAULT_ROWS_COUNT * ROW_HEIGHT -
        HEADER_HEIGHT -
        TABLE_CONTROLS -
        TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
      bonusCols > MIN_ROWS_COUNT
        ? DEFAULT_ROWS_COUNT + bonusCols
        : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.limit)
    ? parseInt(searchObj.limit, 10)
    : calculatedRowsPerPage

  const limit = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit,
      page: 0,
    }),
    [data]
  )

  const { mutate: patchPrice } = pimApi.price.usePatchPriceAdviceMutation()

  const handlePriceSet = (selectedAdvices) => {
    patchPrice({
      action: 'approve',
      selectedAdvices
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<pimApi.price.ProductPriceAdvice[]>(
          getPriceAdviceQueryKey(queryParameters),
          (priceAdvice) => {
            const newObj = priceAdvice?.
              filter((el) =>
                !selectedAdvices.some((f) => f.productId === el.productId && f.adviceDate === el.adviceDate))
            return newObj as pimApi.price.ProductPriceAdvice[]
          }
        )
        snackActions.info('Рекомендация применена')
        setSelection([])
      },
    }
    )
  }
  const handleDecline = (selectedAdvices) => {
    patchPrice({
      action: 'declined',
      selectedAdvices
    },
    {
      onSuccess: (response) => {
        const resp = response.data[0]
        queryClient.setQueryData<pimApi.price.ProductPriceAdvice[]>(
          getPriceAdviceQueryKey(queryParameters),
          (priceAdvice) => {
            const arrIndex = priceAdvice!.findIndex(el =>
              el.productId === resp.productId &&
              el.adviceDate === resp.adviceDate)
            const item: any = priceAdvice?.[arrIndex!]
            Object.keys(resp).forEach((key) => {
              item[key] = resp[key]
            })
            // eslint-disable-next-line no-param-reassign
            priceAdvice![arrIndex] = item
            return priceAdvice as pimApi.price.ProductPriceAdvice[]
          }
        )
        snackActions.info('Рекомендация отклонена')
        setSelection([])
      },
    }
    )
  }

  const new_array = data?.
    filter(el => el.priceSet === false && el.declined === false)
    .map((el, index) => ({
      rowId: index + 1,
      id: el.id,
      photo: pimApi.products.getFileThumbnailApiPath(
        el.productId,
        fileThumbnailSize
      ),
      marketplaceUrl: el.marketplaceUrl,
      newMargin: el.newMargin,
      newMarginPercents: el.newMarginPercents,
      advicePriceWithoutDiscount: el.advicePriceWithoutDiscount,
      advicePriceDetail: el.advicePriceDetail,
      marketplaceId: el.marketplaceId,
      priceSet: el.priceSet,
      declined: el.declined,
      versionNo: el.versionNo,
      settingsDate: el.settingsDate,
      targetMargin: el.targetMargin,
      targetMarginPercents: el.targetMarginPercents,
      autoChangePrice: el.autoChangePrice,
      adviceInterval: el.adviceInterval,
      perDay: el.perDay,
      margin: el.margin,
      marginPercents: el.marginPercents,
      incomingPrice: el.incomingPrice,
      productId: el.productId,
      adviceDate: el.adviceDate,
      advicePrice: el.advicePrice,
      comments: el.comments,
      minPrice: el.minPrice,
      name: el.name,
      price: el.price,
      sku: el.sku,
      stocks: el.stocks
    }))

  const [selection, setSelection] = useState<Array<number>>([])
  const onSelChange = (sel) => {
    setSelection(sel)
  }

  const SomeRowSelected = selection.length === 0

  const SelectionCellComponent = (props: DXTableSelection.CellProps) => (
    <CustomCheckbox {...props}/>)

  const HeaderSelComponent = (props: DXTableSelection.HeaderCellProps) => (
    <CustomHeaderCheckbox {...props}/>
  )
  const EditTableCellComponent = (props: TableEditColumn.CellProps) => (
    <CustomTableEdit {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <IconButton
          disabled={!SomeRowSelected}
          onClick={() => {
            const { row } = props
            const advice = [row]
            handlePriceSet(advice.map(el => ({
              productId: el.productId,
              adviceDate: el.adviceDate
            })))
          }}>
          <CheckIcon style={{ display: 'grid' }} color={!SomeRowSelected ? 'disabled' : 'primary'}/>
        </IconButton>
        <IconButton
          disabled={!SomeRowSelected}
          onClick={() => {
            const { row } = props
            const advice = [row]
            handleDecline(advice.map(el => ({
              productId: el.productId,
              adviceDate: el.adviceDate
            })))
          }}>
          <ClearIcon style={{ display: 'grid' }}/>
        </IconButton>
      </div>
    </CustomTableEdit>)
  
  const EditColumnHeaderCell = (props: TableEditColumn.HeaderCellProps) => (
    <CustomHeaderEdit  {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <IconButton
          disabled={SomeRowSelected}
          onClick={() => {
            const selectedAdvice = new_array?.filter(item => selection.includes(item.rowId))
            handlePriceSet(selectedAdvice?.map(el => ({
              productId: el.productId,
              adviceDate: el.adviceDate
            })))
          }}
        >
          <CheckIcon style={{ display: 'grid' }} color={SomeRowSelected ? 'disabled' : 'primary'}/>
        </IconButton>

        <IconButton
          disabled={SomeRowSelected}
          onClick={() => {
            const selectedAdvice = new_array?.filter(item => selection.includes(item.rowId))
            handleDecline(selectedAdvice?.map(el => ({
              productId: el.productId,
              adviceDate: el.adviceDate
            })))
          }}>
          <ClearIcon style={{ display: 'grid' }}/>
        </IconButton>
      </div>
    </CustomHeaderEdit>)

  return (
    <StyledTableBox component={Paper}>
      <TableFilters queryParameters={queryParameters} setQueryParameters={setQueryParameters} handleTableParams={handleTableParams}/>
      {(query.isLoading || query.isFetching) && (<StyledLoader size={60} />)}
      {new_array && (
        <Table
          tableList={new_array}
          totalCount={new_array.length}
          getRowId={getRowId}
          allRows={false}
          isTableEditable={true}
          paginationLocale={pagingPanelLocale}
          tableParams={memoTableParams}
          calculatedRowsPerPage={calculatedRowsPerPage}
          columnsConfig={columnsConfig}
          searching="internal"
          pagination="internal"
          sorting="internal"
          filtering="internal"
        >
          <Plugin>
            <SelectionState
              selection={selection}
              onSelectionChange={onSelChange}
            />
            <IntegratedSelection />
            <DXTableSelection cellComponent={SelectionCellComponent} showSelectAll={true} headerCellComponent={HeaderSelComponent}/>
            <EditingState onCommitChanges={() => {}}/>
            <TableEditColumn
              headerCellComponent={EditColumnHeaderCell}
              cellComponent={EditTableCellComponent}
            />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => [
                ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
                { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 100 }
              ]
              }
            />
          </Plugin>
        </Table>
      )
      }
    </StyledTableBox>
  )
}