/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditGoodsMedia, GoodsMedia } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsMedia>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: EditGoodsMedia
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/goods/${goodsId}/media`,data).then(res => res.data)


export const useChangeGoodsmedia = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

