import { generatePath } from 'react-router'
import {
  GoodsEditPageStep,
  GOODS_EDIT_PAGE_STEPS,
} from 'pages/goods/edit/router-helper'
import { SupplyOrderEditPageTab, SUPPLY_ORDER_EDIT_PAGE_TABS } from 'pages/supplyOrders/edit/model/types'
import {
  ADMIN_GOODS_CONTENT_TABS,
  ADMIN_PANEL_CONTENT_PAGES, AdminGoodsPageTabs,
  AdminPanelContentPages
}
  from 'pages/adminPanel/AdminPanelRoot/Content/Pages/model/types'
import {
  DICTS_TAB,
  DictsPageTab
} from 'pages/adminPanel/AdminPanelRoot/Content/Pages/Dicts/model/types'
import {
  BULK_OPERATIONS_PAGE_TABS,
  BulkOperationsPageTab,
  BulkOperationsPageOperationType,
  BULK_OPERATIONS_PAGE_OPERATION_TYPES
} from 'pages/bulkOperations/bulkOperationsPage/model/types'

export const HOME = '/'

export const LOGIN = '*'

export const BULK_OPERATIONS = `/bulkoperations/:tab(${BULK_OPERATIONS_PAGE_TABS.join(
  '|'
)})?/:operation(${BULK_OPERATIONS_PAGE_OPERATION_TYPES.join('|')})?`

export const getBulkOperationsPath = (
  tab: BulkOperationsPageTab = 'products',
  operation?: BulkOperationsPageOperationType
) =>
  generatePath(BULK_OPERATIONS, {
    tab,
    operation,
  })

export const BULK_OPERATIONS_MAIN = `/bulkoperations/:tab(${BULK_OPERATIONS_PAGE_TABS.join(
  '|'
)})?`

export const STOCKS_GOODSSUPPLYING = '/stocks/goodssupplying'
export const SUPPLY_ORDER_LIST = '/supplyorders'
export const SUPPLY_ORDER_CREATE = '/supplyorders/create'

export const PRICE_HISTORY = '/pricehistory'

export const REVIEWS = '/reviews'
export const SUPPLY_ORDER_EDIT = `/supplyorders/edit/:id/:tab(${SUPPLY_ORDER_EDIT_PAGE_TABS.join('|')})?`

export const getSupplyOrderEditPath = (
  id: UniqueId | string,
  tab: SupplyOrderEditPageTab = 'goodspositions',
) =>
  generatePath(SUPPLY_ORDER_EDIT, {
    id,
    tab,
  })


export const ANALYTICS_PRICE_ADVICER = '/analytics/pricewizard'

export const ANALYTICS_CHARTS = '/analytics/charts'
export const ANALYTICS_ACTIVITIES = '/analytics/activities'
export const ANALYTICS_EDIT_ACTIVITY = '/analytics/activities/edit/:id'
export const REPORTS = '/reports'

export const GOODS_LIST = '/goods'
export const GOODS_CREATE = '/goods/edit'
export const GOODS_EDIT = `/goods/edit/:id/:step(${GOODS_EDIT_PAGE_STEPS.join('|')})`
export const GOODS_VARIANT = '/goods/variant/:id'

export const getGoodsEditPath = (
  id: UniqueId | string,
  step: GoodsEditPageStep = 'info'
) =>
  generatePath(GOODS_EDIT, {
    id,
    step,
  })

export const DOC_LIST = '/docs'
export const DOC_DETAIL_URL = '/docs/:id'
export const SETTINGS_PROFILE = '/settings/profile'
export const COPACKING_ORDERS = '/copacking'
export const COPACKING_ORDERS_DETAIL = '/copacking/:id'

export const ADMIN_PANEL = '/admin'
export const ADMIN_PANEL_CREATE_SUPPLIER = '/admin/suppliers/create'
export const ADMIN_PANEL_EDIT_SUPPLIER = '/admin/suppliers/edit/:id'
export const ADMIN_PANEL_SUPPLIERS_LIST = '/admin/suppliers'

export const ADMIN_PANEL_EDIT_COMPANY = '/admin/company/edit/:id'

export const ADMIN_PANEL_CONTENT = `/admin/:step(${ADMIN_PANEL_CONTENT_PAGES.join('|')})`
export const ADMIN_GOODS_CONTENT = `/admin/goods/:tab(${ADMIN_GOODS_CONTENT_TABS.join('|')})`

export const ADMIN_EDIT_CATEGORY = '/admin/goods/categories/:id'

export const getAdminPanelContentPath = (step: AdminPanelContentPages) =>
  generatePath(ADMIN_PANEL_CONTENT, { step })

export const getGoodsPanelContentPath = (tab: AdminGoodsPageTabs = 'categories') =>
  generatePath(ADMIN_GOODS_CONTENT, { tab })


export const getAdminEditSupplierPath = (id: UniqueId | string) =>
  generatePath(ADMIN_PANEL_EDIT_SUPPLIER, { id })

export const getAdminEditCompanyPath = (id: UniqueId | string) =>
  generatePath(ADMIN_PANEL_EDIT_COMPANY, { id })

export const ADMIN_PANEL_DICTS_TAB = `/admin/dicts/:step(${DICTS_TAB.join('|')})`
export const getAdminPanelDictsPath = (step: DictsPageTab) =>
  generatePath(ADMIN_PANEL_DICTS_TAB, { step }
  )

export const ADMIN_PANEL_CREATE_USER = '/admin/users/create'
export const ADMIN_PANEL_EDIT_USER = '/admin/users/edit/:id'

export const getAdminEditUserPath = (id: UniqueId | string) =>
  generatePath(ADMIN_PANEL_EDIT_USER, {
    id,
  })

export const PRODUCT_LIST = '/products'
export const PRODUCT_EDIT = '/products/edit/:id'
export const PRODUCT_PRICE_HISTORY = '/products/edit/:id/pricehistory'

export const USER_PASSWORD_RECOVERY= '/forgetpassword'
export const USER_PASSWORD_CHANGE = '/setpassword'
export const SULOGIN = '/sulogin'

export const BRANDQUAD = '/showcase/:clientId/'

export const getProductEditPath = (id: UniqueId | string) =>
  generatePath(PRODUCT_EDIT, {
    id,
  })

export const getOrderEditPath = (id: UniqueId | string) =>
  generatePath(SUPPLY_ORDER_EDIT, {
    id,
  })

export const isShowBackArrowException = [
  '/',
  '/goods',
  '/reviews',
  '/products',
  '/bulkoperations/products',
  '/bulkoperations/goods',
  '/stocks/goodssupplying',
  '/supplyorders',
  '/pricehistory',
  '/analytics/ozoncompetitors',
  '/analytics/pricewizard',
  '/analytics/charts',
  '/reports',
  '/docs',
  '/settings/profile',
  '/admin',
  '/copacking',
  '/setpassword',
  '/analytics/activities',
  '/admin/goods/categories',
  '/admin/goods/attributes'
]
