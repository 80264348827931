/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditSupplyOrderGoodsPosition, SupplyOrderGoodsPosition } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<SupplyOrderGoodsPosition>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyorderId: number,
  positionId: number,
  data: EditSupplyOrderGoodsPosition
}>

export const apiFunction = ({ supplyorderId,positionId,data }: IRequest) =>
  api.put<ResponseType>(`/trade/orders/supplyorders/${supplyorderId}/goodspositions/${positionId}`,data).then(res => res.data)


export const useEditSupplyOrderGoodsPosition = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

