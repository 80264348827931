import { useEffect, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { useNotificationsStore } from 'store/notifications/useNotificationsStore'

export const useWebSocket = () => {
  const ws = useRef<any>(null)
  const setWebSocket = useNotificationsStore((state) => state.setWebSocket)
  const setMessage = useNotificationsStore((state) => state.setMessage)
  const sendMessage = useNotificationsStore((state) => state.sendMessage)

  const connect = (attempts: number) => {
    ws.current = new WebSocket('/api/notifications/ws')

    ws.current.onopen = () => {
      setWebSocket(ws.current)

      const intervalId = setInterval(() => {
        sendMessage({ type: 'ping' })
      }, 30000)

      ws.current.onclose = (e) => {
        clearInterval(intervalId)
        if (!e.wasClean && attempts < 25) {
          Sentry.captureException(JSON.stringify({ reason: e.reason, target: e.currentTarget }))
          setTimeout( () => {
            connect(attempts + 1)
          }, attempts * 1000)
        }
      }
    }

    ws.current.onmessage = (event) => {
      const notification = JSON.parse(event.data)
      if (notification.type !== 'pong' && typeof notification !== 'string') {
        setMessage(notification)
      }
    }
  }

  useEffect(() => {
    connect(1) // первое подключение при монтировании компонента

    return () => {
      if (ws.current) {
        ws.current.close(1000, 'Closing normally') // закрыть соединение при размонтировании компонента
      }
    }
  }, [setWebSocket, setMessage])

  return ws.current
}