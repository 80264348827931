import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { copackingApi } from 'shared/api'

interface ICreateCopackingOrder {
  externalId: string
}
const createCopackingOrder = (newOrder: ICreateCopackingOrder) =>
  api.post<copackingApi.CoPackingOrder>(
    '/customer/admin/users',
    newOrder
  )

export const useCreateCopackingOrderMutation = () =>
  useMutation<
    AxiosResponse<copackingApi.CoPackingOrder>,
    AxiosError<errorType>,
    ICreateCopackingOrder
  >(createCopackingOrder)