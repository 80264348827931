/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ProductForOrder } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ProductForOrder[]>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number,
  query?: {
  /** Артикул или номенклатура продукта */
    code?: string,
  /** Идентификатор товара */
    goods_id?: number,

}
}>

export const apiFunction = ({ copackingorderId,query }: IRequest) =>
  api.get<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}/positions/newproducts`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ copackingorderId,query }: IRequest) => ['get', `/trade/orders/copackingorders/${copackingorderId}/positions/newproducts`, { copackingorderId,query }] as const

export const useSearchProductsForCopackingOrder = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

