/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationNewOzonProducts, ImportMetadata, OzonTemplateType } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Идентификатор кабинета */
    companymarketplace_id: number,
  /** Использовать медиа товара */
    use_goods_media: boolean,
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
    category_id?: number,
  /**
   * Идентификатор родительской категории
   * @exclusiveMin 0
   */
    parent_category_id?: number,
  /**
   * Идентификатор бренда
   * @min 0
   */
    brand_id?: number,
  /** @default "by_category" */
    template_type?: OzonTemplateType,

},
  data: BodyBulkOperationNewOzonProducts
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/products/ozon/product`,data, { params: query }).then(res => res.data)


export const useNewOzonProducts = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

