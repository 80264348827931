import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface EditUserPayload {
  userId: UniqueId,
  editedUser: pimApi.admin.EditingUser
}
export const editUser = ({ userId, editedUser }: EditUserPayload) =>
  api.put<pimApi.admin.CurrentUser>(
    `/customer/admin/users/${userId}`,
    editedUser
  )


export const useEditUserMutation = () =>
  useMutation<
      AxiosResponse<pimApi.admin.CurrentUser>,
      AxiosError<errorType>,
      EditUserPayload
      >(editUser)
