/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CategoryAttribute, NewCategoryAttribute } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CategoryAttribute>

export type IRequest = CamelCasedPropertiesDeep<{
  categoryId: number,
  data: NewCategoryAttribute
}>

export const apiFunction = ({ categoryId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/categories/${categoryId}/attributes`,data).then(res => res.data)


export const useCreateCategoryAttribute = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

