/* eslint-disable */
// @ts-nocheck

import React, { useCallback } from 'react'
import * as R from 'ramda'

import { TextInputI } from '../TextInputI'

const useHandleChange = ({ type, min, max, onChange }: TextInputI<{}>) => {
  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value
      let validationIsOk = true

      if (type === 'number') {
        const onlyNumber = Number.parseInt(
          newValue.toString().replace(/[^0-9]/g, '')
        )
        if (isNaN(onlyNumber)) {
          validationIsOk = false
        }
        if (!R.isNil(min) && !R.isNil(max)) {
          if (onlyNumber <= min || onlyNumber >= max) {
            validationIsOk = false
          }
        }
        if (newValue === '') {
          validationIsOk = true
        }
      }
      if (validationIsOk) {
        onChange(event)
      }
    },
    [min, max, type]
  )
  return handleChange
}

export default useHandleChange
