import React from 'react'
import * as yup from 'yup'
import { generatePath, useHistory } from 'react-router'
import { Form } from 'react-final-form'
import { Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { PRODUCT_EDIT } from 'shared/config'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { Field, InputField } from 'shared/ui/components/form'
import { pimApi } from 'shared/api'

import { ModalFormMarketplaceProps } from '../../config'


interface CreateSimpleProductFormValues {
  category?: string
  sku?: string
}

const validationSchema = yup.object().shape({
  category: yup.string().required(),
})

export const ModalFormMarketplaceSimple: React.FC<ModalFormMarketplaceProps> = ({
  companymarketplaceId,
  goodsId,
  useGoodsMedia,
  handleClose,
}) => {
  const history = useHistory()
  const { mutate: createProductSimple, isLoading } = pimApi.products.simple.useCreateProductSimpleMutation()
  const validate = useValidationSchema(validationSchema)

  const onSubmit = (formValues) => {
    createProductSimple(
      {
        goodsId,
        companymarketplaceId,
        useGoodsMedia,
        category: formValues.category,
        sku: formValues.sku
      },
      {
        onSuccess: ({ data }) => {
          handleClose()
          history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
        },
      }
    )
  }

  return (
    <Form<CreateSimpleProductFormValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ category: '', sku: '' }}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box mt={3}>
              <Box mb={2}>
                <span style={{ fontSize: '16px', fontWeight: 500 }}>Категория</span>
              </Box>
              <Box mb={2}>
                <Field
                  name="category"
                  component={InputField}
                  label="Категория"
                  errorAfterTouch={true}
                />
              </Box>
              <Field
                name="sku"
                component={InputField}
                label="Номенклатура (идентификатор товара на маркетплейсе)"
                errorAfterTouch={true}
              />
            </Box>
          </Box>
          <Box pt={3} sx={{ float: 'right' }}>
            <Button onClick={handleClose} color="primary">
              ОТМЕНА
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!valid}
              sx={{ ml: 2 }}
            >
              СОЗДАТЬ
            </LoadingButton>
          </Box>
        </form>
      )}
    />
  )
}
