import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { reviewApi } from 'shared/api/index'


interface ReplyToReviewPayload {
  id: UniqueId
  answer: string
}
interface EditRespondPayload extends ReplyToReviewPayload {
  answerId: UniqueId
  versionNo: number
}
const respondToReview = ({ id, answer }: ReplyToReviewPayload) =>
  api.patch<reviewApi.Review>(
    `/review/review/${id}/answer`,
    { id, answer }
  )


export const useRespondToReviewMutation = () =>
  useMutation<
    AxiosResponse<reviewApi.Review>,
    AxiosError<errorType>,
    ReplyToReviewPayload
  >(respondToReview)

const editRespond = ({ id, answer, answerId, versionNo }: EditRespondPayload) =>
  api.put<reviewApi.Review>(
    `/review/review/${id}/answer/${answerId}`,
    { id, versionNo, answer }
  )

export const useEditRespondMutation = () =>
  useMutation<
    AxiosResponse<reviewApi.Review>,
    AxiosError<errorType>,
    EditRespondPayload
  >(editRespond)
