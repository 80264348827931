import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface DeleteUserPrivilegePayload {
  userId: UniqueId,
  privilegeId: UniqueId,
  value: pimApi.DeletingObject
}

const deleteUserPrivilege = ({ userId, privilegeId, value }: DeleteUserPrivilegePayload) =>
  api.delete(`/customer/admin/users/${userId}/privileges/${privilegeId}`, { data: value })

export const useDeleteUserPrivilegeMutation = () =>
  useMutation<AxiosResponse,
    AxiosError<errorType>,
    DeleteUserPrivilegePayload>(deleteUserPrivilege)