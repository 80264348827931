import styled from 'styled-components'

export const NestedWidgetContainer = styled.div`
  padding: 8px 48px 8px 48px;
  
  .colored-container {
    background-color: rgba(250, 250, 250, 1);
    padding-top: 4px;

    .widget-header {
      padding: 0 16px 0 16px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      justify-content: space-between;

      .Mui-disabled {
        svg {
          display: block;
        }
      }
    }
  }
  
  .Toolbar-toolbar {
    border-bottom: none;
    height: 52px;
    padding-left: 16px;
    @media (min-width: 600px) {
      min-height: 0;
    }
  }
  
  
  
  table {
    background-color: rgba(250, 250, 250, 1);
    width: 100%;
    min-width: inherit !important;
  }
  
  .MuiTableCell-root {
    height: 36px;
  }
  
  .Pager-pager {
    height: 36px;
  } 
  
  tbody {
    .MuiTableRow-root {
      :first-child {
        .MuiTableCell-root {
          border: 0;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }
  }
  
  .MuiTableRow-head {
    .MuiTableCell-root {
      border: 0;
    }
  }
  .CellLayout-cell:first-of-type {
    padding-left: 16px;
  }
  
  .MuiTableBody-root {
    .TableCell-cell {
      padding-top: 0;
      padding-bottom: 0;
      
      :first-child {
        padding-left: 16px;
      }
    }
  }
`

export const StyledPlug = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
`