/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Goods } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Goods>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  componentId: number
}>

export const apiFunction = ({ goodsId,componentId }: IRequest) =>
  api.delete<ResponseType>(`/pim/goods/${goodsId}/components/${componentId}`).then(res => res.data)


export const useDelComponentFromBundle = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

