import { DialogTitle, IconButton, Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SxProps } from '@mui/system'

interface DialogTitleProps {
  onClose?: () => void;
  description?: string
  children: React.ReactNode | string | number;
  description?: string
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const sxIconButton = {
  position: 'absolute',
  padding: 0,
  right: 5,
  top: 8,
} as const

export const DialogTitleWithBtn = ({
  onClose,
  description,
  disabled,
  children,
  sx,
}: DialogTitleProps) => (
  <DialogTitle sx={sx} style={{ whiteSpace: 'break-spaces', display: 'flex', flexDirection: 'column', gap: '8px' }}>
    {children}
    { description && <span style={ { fontSize: '16px', fontWeight: '400' } }>{ description }</span> }
    { onClose && (
      <IconButton
        aria-label="close"
        onClick={ onClose }
        sx={sxIconButton}
        disabled={disabled}
      >
        <CloseIcon />
      </IconButton>
    )}
  </DialogTitle>
)