import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import {
  Box,
  Grid,
} from '@mui/material'
import { AxiosResponse } from 'axios'
import { Field, InputField } from 'shared/ui/components/form'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { downloadFile, getServerReadableDateTime, toMinDateTime } from 'shared/lib/utils'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { history } from 'app/providers/with-router'
import { BulkOperationsResultModal } from 'widgets/product'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { Accordion } from 'shared/ui/components/Accordion'

import { MarketplaceFormProps } from '../../config'

const DEFAULT_FILE_NAME = 'template.xlsx'

const currentDate = new Date()
const DEFAULT_MIN_DATE = new Date(currentDate).setMonth(
  toMinDateTime(currentDate).getMonth() - 1
)
const defaultCalendarMinDate = getServerReadableDateTime(DEFAULT_MIN_DATE)
const defaultCalendarMaxDate = getServerReadableDateTime(new Date())

export const MarketplaceFormSimple: React.FC<MarketplaceFormProps> = ({
  companymarketplaceId,
}) => {
  const { change, batch, getState  } = useForm()
  const [datePeriodOpen, setDatePeriodOpen] = useState(false)
  const { mutate: downloadMutation, isLoading: isDownloading } =
    pimApi.products.simple.useDownloadSimpleProductBulkEditTemplateFile()
  const { mutate: uploadMutation, isLoading: isUploading } =
    pimApi.products.simple.useUploadSimpleProductBulkEditFile()

  const { values: { dateFrom, dateTo } } = getState()
  
  const download = () => {
    downloadMutation(
      {
        companymarketplaceId,
        ...(datePeriodOpen && dateFrom ? { dateFrom } : {}),
        ...(datePeriodOpen && dateTo ? { dateTo } : {}),
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName =
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ) || DEFAULT_FILE_NAME

          downloadFile(file, decodeURIComponent(fileName.replace(/%2C/g, ',')))
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
        companymarketplaceId,
      },
      {
        onSuccess: (
          data: AxiosResponse<pimApi.products.ImportMetadata>
        ) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleDatePeriodExpand = () => {
    setDatePeriodOpen(true)
    batch(() => {
      change('dateFrom', defaultCalendarMinDate)
      change('dateTo', defaultCalendarMaxDate)
    })
  }
  const handleDatePeriodClose = async () => {
    setDatePeriodOpen(false)
    batch(() => {
      change('dateFrom', null)
      change('dateTo', null)
    })
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box mb={1}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Accordion 
            title="Выберите продукты созданные за период"
            onOpen={handleDatePeriodExpand}
            onClose={handleDatePeriodClose}
          >
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={2} pb={0}>
                <Field
                  name="dateFrom"
                  label="Начало периода"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={InputField}
                />
              </Grid>
              <Grid item={true} xs={2} pb={0}>
                <Field
                  name="dateTo"
                  label="Конец периода"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={InputField}
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
        <Grid item={true} xs={12} p={0}>
          <BulkFileActionsFooter
            download={download}
            upload={upload}
            isUploading={isUploading}
            isDownloading={isDownloading}
            onGoBack={handleGoBack}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
