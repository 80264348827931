/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CheckTokenResponse, MethodParam } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CheckTokenResponse>

export type IRequest = CamelCasedPropertiesDeep<{
  marketplaceId: number,
  credentialId: number,
  query: {
  /** Операция над токеном */
    method: "check",

},
  data: MethodParam
}>

export const apiFunction = ({ marketplaceId,credentialId,query,data }: IRequest) =>
  api.patch<ResponseType>(`/customer/marketplaces/${marketplaceId}/credentials/${credentialId}`,data, { params: query }).then(res => res.data)


export const usePatchCredential = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

