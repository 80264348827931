/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyDictionarySetCompanyLogo } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  companyId: number,
  data: BodyDictionarySetCompanyLogo
}>

export const apiFunction = ({ companyId,data }: IRequest) =>
  api.post(`/customer/companies/${companyId}/logo`,data).then(res => res.data)


export const useSetCompanyLogo = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

