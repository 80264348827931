/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Barcode, NewBarcode } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Barcode>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  variationId: number,
  data: NewBarcode
}>

export const apiFunction = ({ productId,variationId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/products/wb/product/${productId}/variations/${variationId}/barcodes`,data).then(res => res.data)


export const useCreateBarcode = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

