/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AttributesSearchResult } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<AttributesSearchResult>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Лимит макс 10
   * @default 10
   */
    limit?: number,
  /**
   * Параметр пагинации
   * @default 0
   */
    page?: number,
  /**
   * Поиск атрибута/ов, содержащих подстроку
   * Получение списка одного/всех атрибута/ов, который/е содержит/ат определённую подстроку. 
   */
    search_string?: string,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/pim/attributes`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/attributes`, { query }] as const

export const useGetAttributes = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

