
import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { SimpleProduct } from '../models'
import { api } from '../../../base'

export const fetchProductSimple = (productId: UniqueId) =>
  api.get<SimpleProduct>(`/pim/products/simple/product/${productId}`)

type GetProductSimpleQueryKey = ReturnType<typeof getProductSimpleQueryKey>

const getProductSimpleQueryKey = (productId: UniqueId) =>
  ['get', '/products/simple/product/', productId] as const

export const useFetchProductSimpleQuery = (productId: UniqueId) =>
  useQuery<
    AxiosResponse<SimpleProduct>,
    AxiosError<errorType>,
    SimpleProduct,
    GetProductSimpleQueryKey
  >(
    getProductSimpleQueryKey(productId),
    (ctx) => fetchProductSimple(ctx.queryKey[2])
  )