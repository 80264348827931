import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosError } from 'axios'

import { Operation } from '../../lib/generated/copacking/Api'

const getOperations = () => api.get<Operation[]>('/copacking/operations').then((res) => res.data)

type QueryKey = ReturnType<typeof QUERY_KEY>

const QUERY_KEY = () => ['get', 'copacking/operations'] as const

export const useGetOperationsQuery = () =>
  useQuery<
    Operation[],
    AxiosError<errorType>,
    Operation[],
    QueryKey
  >(
    QUERY_KEY(),
    () => getOperations()
  )