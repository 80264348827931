import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'

interface DeleteUserContragentPayload {
  contragentId: UniqueId
  managerId: UniqueId
  value: pimApi.DeletingObject
}

const deleteUserContragent = ({
  contragentId,
  managerId,
  value,
}: DeleteUserContragentPayload) =>
  api.delete(`/pim/admin/users/${managerId}/contragents/${contragentId}`, {
    data: value,
  })

export const useDeleteUserContragentMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteUserContragentPayload
  >(deleteUserContragent)
