import {
  FormControlLabel,
  Typography,
  CircularProgress,
} from '@mui/material'
import styled from 'styled-components'
import { InputWrapper } from 'pages/auth/passRecovery/styled'

export const Wrapper = styled.div<{ showCustomerCodeField?: boolean }>`
  height: ${props => props.showCustomerCodeField ? '414px' : '344px'};
.MuiFormControl-marginNormal {
  margin-top: 8px;
}
`
export const TypographyStyled = styled(Typography)`
  margin-bottom: 8px;
  white-space: break-spaces;
  font-size: 22px;
  font-weight: 500;
`
export const FormControlLabelStyled = styled(FormControlLabel)`
user-select: none;
.MuiTypography-body1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
`
export const CircularProgressStyled = styled(CircularProgress)`
color: #ffffff;
position: absolute;
left: 25px;
`

export const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
    left: -15px;
  }
`