/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CategoryAttribute, EditCategoryAttribute } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CategoryAttribute>

export type IRequest = CamelCasedPropertiesDeep<{
  categoryId: number,
  attributeId: number,
  data: EditCategoryAttribute
}>

export const apiFunction = ({ categoryId,attributeId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/categories/${categoryId}/attributes/${attributeId}`,data).then(res => res.data)


export const useEditCategoryAttribute = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

