import React, { FC, memo } from 'react'
import { IconButton } from '@mui/material'
import CrossDottedRed from 'assets/images/CrossDottedRed.svg'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper/ui'
import { ArrowForwardStyled } from 'shared/ui/product/LeftRightInputWrapper/ui/styled'
import { Attribute } from 'shared/api/pim/goods'

import { UnmapArrowStyled } from './styled'
import { LeftComponent } from './LeftComponents'

import { NormalizedAttributesMapped as NormalizedAttributesMappedType, WBMapingAttribute } from '../../lib/getNormalizedAttributes'
import { RightComponent } from '../RightComponent'

interface NormalizedAttributesMappedProps {
  normalizedAttributesMappeds: Array<NormalizedAttributesMappedType>
  onUnmapAttribute: (index: number) => () => void
  showGoodsInfo: boolean
  onAttributeValueChange: (
    id: number
  ) => (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void
  disabled?: boolean
}

interface INormalizedAttribute {
  left: Attribute
  right: WBMapingAttribute
  index: number
  showGoodsInfo: boolean
  disabled?: boolean
  onAttributeValueChange: any
  onUnmapAttribute: any
}

export const NormalizedAttributesMapped: FC<NormalizedAttributesMappedProps> = memo(({
  normalizedAttributesMappeds,
  onUnmapAttribute,
  showGoodsInfo,
  onAttributeValueChange,
  disabled
}) => (
  <>
    {normalizedAttributesMappeds.map(({ left, right }, index) => (
      <NormalizedAttribute 
        key={`${left.name}${right.attributeName}`}
        onAttributeValueChange={onAttributeValueChange}
        onUnmapAttribute={onUnmapAttribute}
        showGoodsInfo={showGoodsInfo}
        index={ index }
        left={ left }
        right={ right }
        disabled={ disabled }
      />
    )
    )}
  </>
), (prevProps, nextProps) =>
  JSON.stringify(prevProps.normalizedAttributesMappeds) === JSON.stringify(nextProps.normalizedAttributesMappeds) &&
  JSON.stringify(prevProps.disabled) === JSON.stringify(nextProps.disabled) &&
  JSON.stringify(prevProps.showGoodsInfo) === JSON.stringify(nextProps.showGoodsInfo)
)

const NormalizedAttribute = memo(({ left, right, index, disabled, onUnmapAttribute, onAttributeValueChange, showGoodsInfo }:
  INormalizedAttribute) => 
  (
    <InputsWrapper
      hideGoodsInfo={!showGoodsInfo}
      arrow={
        <UnmapArrowStyled $disabled={!showGoodsInfo}>
          <ArrowForwardStyled />
          <IconButton aria-label="unmap" onClick={onUnmapAttribute(index)} size="large" disabled={!showGoodsInfo}>
            <img src={CrossDottedRed} alt="Удалить связывание" />
          </IconButton>
        </UnmapArrowStyled>
      }
      globalAttributeName={`attributes.${right.index}.attributeGlobal`}
    >
      <LeftComponent attribute={left} />
      <RightComponent right={right} onChange={onAttributeValueChange(right.id)} disabled={disabled}/>
    </InputsWrapper>
  ),(prevProps, nextProps) =>
  JSON.stringify(prevProps.right) === JSON.stringify(nextProps.right) &&
  JSON.stringify(prevProps.left) === JSON.stringify(nextProps.left) &&
  JSON.stringify(prevProps.disabled) === JSON.stringify(nextProps.disabled) &&
  JSON.stringify(prevProps.showGoodsInfo) === JSON.stringify(nextProps.showGoodsInfo)
)