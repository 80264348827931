/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditWBNomenclatureAttribute, WBNomenclatureAttribute } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<WBNomenclatureAttribute>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  attributeId: number,
  data: EditWBNomenclatureAttribute
}>

export const apiFunction = ({ productId,attributeId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/products/wb/product/${productId}/attributes/${attributeId}`,data).then(res => res.data)


export const useEditWbcardattribute = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

