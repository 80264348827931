/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyGoodsCheckGoodsmediaFile } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  query: {
  /** Тип медиа */
    media_type: "photo" | "video" | "document",

},
  data: BodyGoodsCheckGoodsmediaFile
}>

export const apiFunction = ({ goodsId,query,data }: IRequest) =>
  api.post(`/pim/goods/${goodsId}/media/file/validator`,data, { params: query }).then(res => res.data)


export const useCheckGoodsmediaFile = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

