import { Collapse, Drawer, List, ListItemButton } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const DrawerStyled = styled(Drawer)`
  .MuiPaper-root {
    transition: all 0.3s ease-out 0.1s;
  }
`
export const Logo = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  color: inherit;
  display: flex;
  align-items: center;

  > img {
    top: 18px;
    position: absolute;
    margin-right: 5px;
  }
`
export const LogoWrapper = styled.div<{ $isActive: boolean }>`
  transition: all 0.3s ease-out 0.1s;
  width: min-content;
  pointer-events: none;
  height: 50px;
  min-height: 50px;
  padding-top: 18px;
  margin-bottom: 8px;
  padding-left: ${ (props) => (props.$isActive ? '29px' : '12px') };
`

export const ListItemNested = styled(ListItemButton)<any>`
  color: rgba(0, 0, 0, 0.6);
  max-height: 38px !important;
  cursor: pointer;
  margin-bottom: 2px;
  gap: 16px;
  border-radius: 4px;

  .MuiListItemText-root {
    margin: 2px 0;
    flex-shrink: 0;
  }

  .MuiListItemIcon-root {
    min-width: 20px;
  }

  span {
    font-size: 14px;
  }

  :hover {
    transition: all 0.1s ease-out 0.1s;
    text-decoration: none;
    background-color: #00000008;
  }
`
export const ListItemStyled = styled(ListItemButton)<{ $includes: boolean }>`
  padding-left: 8px;
  padding-right: 8px;
  max-height: 38px;
  color: rgba(0, 0, 0, 0.6);

  .MuiListItemText-root {
    > span {
      transition: all 0.3s ease-out 0.1s;
      font-size: 14px;
      color: ${({ $includes }) => $includes ? '#2196F3' : '' };
    }
  }

  :hover {
    background-color: rgba(255, 255, 255, 0);

    img {
      opacity: 0.5;
    }
    
    svg {
      opacity: 0.5;
    }
  }

  svg {
    transition: all 0.3s ease-out 0.1s;;
    color: ${({ $includes }) => $includes ? '#2196F3' : '#00000042' };
  }
  img {
    filter: ${({ $includes }) => $includes ? 'invert(61%) sepia(52%) saturate(5269%) hue-rotate(183deg) brightness(95%) contrast(100%)'
    : '' };
  }
`

export const ListStyled = styled(List)<{ $isActive: boolean }>`
  transition: all 0.3s ease-out 0.1s;
  padding: ${ (props) => (props.$isActive ? '0 18px' : '0 8px') };
  height: calc(100% - 56px);
  overflow: auto;

  .menu-link-active {
    text-decoration: none;
    color: #2196F3;
    background-color: #e9f4fe;

    img {
      filter: invert(52%) sepia(37%) saturate(4129%) hue-rotate(184deg) brightness(96%) contrast(99%);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 40px;
  }
`
export const CollapseButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #9E9E9E;
  border: 1px dashed #9E9E9E;
  opacity: 0.4;
  border-radius: 50%;
  width: 35px;
  height: 35px;
`
export const CollapseWrapper = styled.div<{ $isActive: boolean }>`
  z-index: 1;
  display: flex;
  justify-content: flex-end;
`
export const DrawerWrapper = styled.div<{ $isActive: boolean }>`
  .MuiPaper-root {
    width: ${ (props) => (props.$isActive ? '250px' : '56px') };
    overflow-x: hidden;
    overflow-y: auto;
    background: #FAFAFA;
    border-right: #FAFAFA;
  }

  .MuiList-root {
    overflow-y: auto;
    overflow-x: hidden;
  }
`
export const CollapseStyled = styled(Collapse)<{ $isActive: boolean }>`
  margin-bottom: 10px;

  .MuiList-root {
    display: flex;
    justify-content: center;
  }
`