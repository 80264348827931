import {
  useMutation,
  useQueryClient,
} from 'react-query'
import { GoodsInfoValuesSubmit } from 'pages/goods/components/Steps/InfoStep/interface'
import * as GoodsService from 'shared/api/pim/goods'
import { Goods, EditGoods } from 'shared/api/pim/goods'
import { pimApi } from 'shared/api'

import { getInfoValuesFromGoods, getGoodsFromInfoValues } from './serializers'

export const getGetPimGoodsQueryKey = (goodsId?: UniqueId, withAttributes = false) =>
  ['get', 'pim/goods', goodsId, withAttributes] as const


export function useGoods(goodsId?: UniqueId, withAttributes = false) {
  const queryClient = useQueryClient()
  const goodsQuery = pimApi.goods.useFetchGoodsQuery(goodsId, withAttributes)

  const { mutate } = useMutation(
    getGetPimGoodsQueryKey(goodsId, withAttributes),
    async (goodsSubmitValues: EditGoods): Promise<Goods> => {
      const res = await GoodsService.editOneGoods(goodsSubmitValues)
      return res.data
    },
    {
      onSuccess(changedGoods) {
        queryClient.setQueryData(getGetPimGoodsQueryKey(goodsId, withAttributes), changedGoods)
      },
    }
  )

  const uploadGoods = (goodsSubmitValues: GoodsInfoValuesSubmit) =>
    new Promise((resolve, reject) =>
      mutate(getGoodsFromInfoValues(goodsSubmitValues) as EditGoods, { onSuccess: resolve, onError: reject })
    )

  const changeInfo = (newGoods: GoodsInfoValuesSubmit) => {
    queryClient.setQueryData<Goods>(
      getGetPimGoodsQueryKey(goodsId, withAttributes),
      getGoodsFromInfoValues(newGoods) as Goods
    )
  }

  const changeInfoWithoutForm = (newGoods: Goods) => {
    queryClient.setQueryData<Goods>(
      getGetPimGoodsQueryKey(goodsId, withAttributes),
      newGoods
    )
  }

  return {
    goodsQuery,
    changeInfo,
    changeInfoWithoutForm,
    uploadGoods,
    getGoodsReserialized: getInfoValuesFromGoods,
  } as const
}
