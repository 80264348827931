/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<string[]>

export type IRequest = CamelCasedPropertiesDeep<{
  dictionaryId: number,
  searchString: string
}>

export const apiFunction = ({ dictionaryId,searchString }: IRequest) =>
  api.get<ResponseType>(`/pim/dictionaries/${dictionaryId}/filter/${searchString}`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ dictionaryId,searchString }: IRequest) => ['get', `/pim/dictionaries/${dictionaryId}/filter/${searchString}`, { dictionaryId,searchString }] as const

export const useGetFilteredDictionaryValues = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

