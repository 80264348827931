/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationNewWbProducts, ImportMetadata, WBTemplateType } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Идентификатор кабинета */
    companymarketplace_id: number,
  /** Название категории */
    category?: string,
  /** Использовать медиа товара */
    use_goods_media: boolean,
  /** Название бренда */
    brand?: string,
  /** @default "with_category" */
    template_type?: WBTemplateType,

},
  data: BodyBulkOperationNewWbProducts
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/products/wb/product`,data, { params: query }).then(res => res.data)


export const useNewWbProducts = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

