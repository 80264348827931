import styled from 'styled-components'

export const DescritionWrapper = styled.div<{ $disabled?: boolean; }>`
  .ql-toolbar {
    border-radius: 4px 4px 0 0;
    height: ${({ $disabled }) => $disabled && '0 !important' };
    padding: ${({ $disabled }) => $disabled && '0 !important' };
    border-top: ${({ $disabled }) => $disabled && 'none !important' };
  }

  .ql-container {
    border-radius: 0 0 4px 4px;
    font-size: 16px;
    color: ${({ $disabled }) => $disabled && 'rgba(0, 0, 0, 0.38)' }
  }
`