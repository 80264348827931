import React, { useEffect, useRef, useState } from 'react'
import { InputLabel, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'
import { InfoOutlined } from '@mui/icons-material'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { InputSimpleWrapper } from 'pages/goods/components/common'

import { Option } from '../interface'
import { renderSelectMultipleChips } from '../renderSelectMultipleChips'

interface SelectFieldProps extends FieldRenderProps<string> {
  options: Array<Option<string>>
  withReset?: boolean
  multipleValues?: boolean
}

const SelectField = (props: SelectFieldProps): React.FunctionComponentElement<typeof FormControl> => {
  const {
    input: { name, value, onChange, ...restInput },
    label,
    options,
    withReset,
    multipleValues,
    sx,
    meta: { error },
    ...rest
  } = props
  const el = useRef<HTMLSelectElement>(null)
  const [isBigText, setIsBigText] = useState(false)

  useEffect(() => {
    setIsBigText(el.current !== null && label?.length > el.current.offsetWidth / 10)
  }, [el.current])
  const isResetAvailable = !multipleValues && withReset

  return (
    <InputSimpleWrapper>
      <FormControl
        variant="outlined"
        size="small"
        fullWidth={true}
        error={error}
      >
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          ref={el}
          sx={sx}
          MenuProps={{
            // @ts-ignore
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
          {...restInput}
          {...rest}
          renderValue={multipleValues ?
            renderSelectMultipleChips :
            undefined
          }
          value={value}
          label={label}
          onChange={onChange}
          name={name}
        >
          {isResetAvailable && <MenuItem value={undefined}>Не выбрано</MenuItem>}
          {
            options.map(item => (
              <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      {isBigText && (
        <Tooltip title={label}>
          <HelpWithMargin>
            <InfoOutlined/>
          </HelpWithMargin>
        </Tooltip>
      )}
    </InputSimpleWrapper>
  )
}

export default SelectField
