/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  contragentId: number,
  query: {
  /** Systemuser Id */
    systemuser_id: number,

}
}>

export const apiFunction = ({ contragentId,query }: IRequest) =>
  api.put<ResponseType>(`/pim/admin/contragents/${contragentId}/master`, {}, { params: query}).then(res => res.data)


export const useChangeContragentMaster = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

