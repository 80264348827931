/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyOrdersPatchSupplyOrder, SupplyOrder } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<SupplyOrder>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyorderId: number,
  data: BodyOrdersPatchSupplyOrder
}>

export const apiFunction = ({ supplyorderId,data }: IRequest) =>
  api.patch<ResponseType>(`/trade/orders/supplyorders/${supplyorderId}`,data).then(res => res.data)


export const usePatchSupplyOrder = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

