import { Box } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useNotificationsStore } from 'store/notifications/useNotificationsStore'
import styled from 'styled-components'

import { StyledCountBadge } from '../../styled/CountBadge'


const StyledIconContainer = styled(Box)`
  height: 100%;
  cursor: pointer;
  margin-right: 16px;
  position: relative;
  svg {
    margin-top: 14px;
    color: #0000008A
  }
  
  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(15deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
  }
  
  .shaking {
    animation: shake 0.5s ease-in-out;
  }
`

export const NotificationIcon = () => {
  const messages = useNotificationsStore((store) => store.messages).filter(el => el.status !== 'read')
  const switchShowMessage = useNotificationsStore((store) => store.switchShowMessage)

  return (
    <>

      { messages &&
        <StyledIconContainer id="notification-icon" onClick={switchShowMessage}>
          <NotificationsIcon id="notification-bell-icon"/>
          {messages.length > 0 && <StyledCountBadge>{ messages.length > 99 ? '99+' : messages.length }</StyledCountBadge> }
        </StyledIconContainer>
      }
    </>
  )
}