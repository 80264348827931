/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<string>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number,
  query: {
  /** ID склада */
    copackingdistributionwarehouse_id: number,

}
}>

export const apiFunction = ({ copackingorderId,query }: IRequest) =>
  api.get<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}/distributions/excel`,{ params: query, responseType: 'blob'})


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ copackingorderId,query }: IRequest) => ['get', `/trade/orders/copackingorders/${copackingorderId}/distributions/excel`, { copackingorderId,query }] as const

export const useGetCopackingDistributionsInExcel = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

