/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReportParameter, ReportParameterWithValues } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReportParameterWithValues[]>

export type IRequest = CamelCasedPropertiesDeep<{
  reportId: number,
  data: ReportParameter[]
}>

export const apiFunction = ({ reportId,data }: IRequest) =>
  api.post<ResponseType>(`/reports/report/${reportId}/parameters/values`,data).then(res => res.data)


export const useGetReportParametersValues = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

