import { useForm, useFormState } from 'react-final-form'
import { useEffect } from 'react'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { AddBox, SyncOutlined } from '@mui/icons-material'
import { isSameValues } from 'shared/lib/checkers'
import isNil from 'lodash/isNil'

import { FixedFormContainer } from './styled'

import { FormValuesToEditWBCardComparatorSerializer } from '../lib'

export const SaveForm = ({
  isSyncWBLoading,
  handleDelete,
  isDeleteProductLoading,
  isVariationsArchived,
  isUpdateProductLoading,
  wbProductQuery }) => {
  const { values, initialValues } = useFormState({ subscription: { values: true, initialValues: true } })
  const { change } = useForm()
  const { action, ...rFormValues } = values
  const hasChanges = !isSameValues(
    initialValues && FormValuesToEditWBCardComparatorSerializer(initialValues as any),
    FormValuesToEditWBCardComparatorSerializer(rFormValues as any)
  )

  const handleLeave = (event: BeforeUnloadEvent) => {
    if (hasChanges) {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''
      return 'Вы уверены, что хотите покинуть страницу? Все несохранненые изменения будут утеряны.'
    }
    return undefined
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleLeave)
    return () => window.removeEventListener('beforeunload', handleLeave)
  }, [hasChanges])
  return (
    <FixedFormContainer>
      <Box ml="auto">
        <LoadingButton
          variant="contained"
          loading={isUpdateProductLoading}
          disabled={!hasChanges || isVariationsArchived}
          type="submit"
          onClick={() => change('action', 'save')}
        >
          СОХРАНИТЬ
        </LoadingButton>
      </Box>
      <Box ml={2}>
        <LoadingButton
          type="submit"
          onClick={() => change('action', 'sync')}
          variant="contained"
          loading={isSyncWBLoading}
          disabled={
            !wbProductQuery?.data.syncPossible || isVariationsArchived
          }
          startIcon={
            wbProductQuery.data.nmid ? <SyncOutlined /> : <AddBox />
          }
        >
          {`${
            wbProductQuery.data.nmid
              ? 'СИНХРОНИЗИРОВАТЬ С МП'
              : 'СОЗДАТЬ НА WILDBERRIES'
          }`}
        </LoadingButton>
      </Box>
      {isNil(wbProductQuery.data.nmid) && (
        <Box ml={2}>
          <LoadingButton
            variant="contained"
            loading={isDeleteProductLoading}
            onClick={handleDelete}
            color="error"
          >
            УДАЛИТЬ ЧЕРНОВИК
          </LoadingButton>
        </Box>
      )}
    </FixedFormContainer>
  )
}