/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyBulkOperationNewSimpleProducts, ImportMetadata } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Идентификатор кабинета */
    companymarketplace_id: number,
  /** Использовать медиа товара */
    use_goods_media: boolean,

},
  data: BodyBulkOperationNewSimpleProducts
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/pim/ops/products/simple/product`,data, { params: query }).then(res => res.data)


export const useNewSimpleProducts = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

