/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditMediaFile, MediaFile } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<MediaFile>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  fileId: number,
  data: EditMediaFile
}>

export const apiFunction = ({ productId,fileId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/products/ozon/product/${productId}/photos/${fileId}`,data).then(res => res.data)


export const useChangePhoto = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

