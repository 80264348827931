/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Goods, NewGoodsVariation } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Goods>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: NewGoodsVariation
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/variation`,data).then(res => res.data)


export const useCreateGoodsvariation = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

