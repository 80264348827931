import keyMirror from 'keymirror-nested'
import watcherCreator, { requesterFunctionType } from 'store/watcherCreator'
import { put } from 'redux-saga/effects'
import { actionsCreator, actionType } from 'store/actionsCreator'
import {
  IUser,
  IUserInfo,
  IUserSettings,
  IUserData,
  UserProfile,
  IUserAvatar,
} from 'shared/services/interfaces/user'
import { TYPES } from 'shared/services/types'
import { diContainer } from 'shared/lib'
import { saveAs } from 'file-saver'
import { snackActions } from 'shared/lib/react/snackbar'

const URL = window.URL || window.webkitURL

const UserModel: IUserInfo = {
  id: null,
  customerCode: null,
  customerName: null,
  email: null,
  iss: null,
  login: null,
  name: null,
  services: [],
  sub: null,
  versionNo: null,
  avatarUrl: null,
  telegramCode: null
}

export interface IUserState {
  user: IUserInfo
  error: errorType | null | undefined
  userSettings: IUserSettings | undefined
}

type UserActionType = actionType<
  IUserInfo | IUserSettings | {} | File | IUserAvatar,
  errorType
>

export const USER = keyMirror(
  {
    AUTH: null,
    LOGOUT: null,
    SETTINGS: null,
    SETTINGS_UPDATE: null,
    GET_PROFILE: null,
    DOWNLOAD_BADGE: null,
    GET_USER_PHOTO: null,
    PUT_USER_PHOTO: null,
    GET_USER_INFO: null,
    PUT_USER_INFO: null,
    DELETE_USER_PHOTO: null,
  },
  '_',
  'USER'
)

export const logout = (): actionType<undefined> => ({ type: USER.LOGOUT })
export const userSettings = actionsCreator(USER.SETTINGS)
export const userSettingsUpdate = actionsCreator(USER.SETTINGS_UPDATE)
export const userProfile = actionsCreator(USER.GET_PROFILE)
export const userDownloadBadge = actionsCreator(USER.DOWNLOAD_BADGE)
export const userInfo = actionsCreator(USER.GET_USER_INFO)
export const updateUserInfo = actionsCreator(USER.PUT_USER_INFO)
export const getUserPhoto = actionsCreator(USER.GET_USER_PHOTO)
export const uploadUserPhoto = actionsCreator(USER.PUT_USER_PHOTO)
export const deleteUserPhoto = actionsCreator(USER.DELETE_USER_PHOTO)

export const userSelector = (state: { userReducer: IUserState }): IUserState =>
  state.userReducer

const initialState = {
  user: UserModel,
  error: null,
  userSettings: {},
}

export const userReducer = (
  state: IUserState = initialState,
  action: UserActionType
): IUserState => {
  switch (action.type) {
    case userInfo.getType('success'):
      return { ...state, user: action.data as IUserInfo }
    case userInfo.getType('error'):
      return { ...state, error: action.error }

    case updateUserInfo.getType('success'): {
      snackActions.info('Сохранено')
      return {
        ...state,
        user: { ...state.user, ...action.data as IUserInfo },
        error: initialState.error,
      }
    }
    case updateUserInfo.getType('error'):
      return { ...state, error: action.error }

    case getUserPhoto.getType('success'):
      return {
        ...state,
        user: { ...state.user, avatarUrl: action.data as string },
        error: initialState.error,
      }

    case uploadUserPhoto.getType('error'):
      return { ...state, error: action.error }

    case getUserPhoto.getType('error'):
      return { ...state, error: action.error }

    case deleteUserPhoto.getType('success'):
      return {
        ...state,
        user: { ...state.user, avatarUrl: null },
        error: initialState.error,
      }
    case deleteUserPhoto.getType('error'):
      return { ...state, error: action.error }

    case userSettings.getType('success'):
      return { ...state, userSettings: action.data || undefined }
    case userSettings.getType('error'):
      return { ...state, error: action.error }

    case userSettingsUpdate.getType('success'):
      return { ...state, userSettings: action.data || {} }
    case userSettingsUpdate.getType('error'):
      return { ...state, error: action.error }
    default:
      return state
  }
}

const user = diContainer.get<IUser>(TYPES.User)
export const userWatcher = watcherCreator(
  'USER',
  function* userWorker({ type, data }: UserActionType, requester: requesterFunctionType) {
    if (type === getUserPhoto.getType('pending')) {
      const { result } = yield requester<string, IUser>(
        user,
        'getUserPhoto'
      ).getResult()
      const url: string = yield URL.createObjectURL(result)
      yield put(getUserPhoto.success(url))
    }
    if (type === userInfo.getType('pending')) {
      yield user.authWatcher()
      yield put(getUserPhoto.pending())
      yield requester<IUserInfo, IUser>(
        user,
        'getUserInfo'
      ).callActions(userInfo)
      // yield requester<IUserSettings, IUser>(
      //   user,
      //   'getUserSettings'
      // ).callActions(userSettings)
    }
    if (type === updateUserInfo.getType('pending')) {
      yield requester<IUserData, IUser, [UserProfile]>(
        user,
        'updateUserProfile',
        <UserProfile>data
      ).callActions(updateUserInfo)
    }
    if (type === userSettings.getType('pending')) {
      yield requester<IUserSettings, IUser>(
        user,
        'getUserSettings'
      ).callActions(userSettings)
    }
    if (type === userSettingsUpdate.getType('pending')) {
      yield requester<IUserSettings, IUser, [IUserSettings]>(
        user,
        'updateUserSettings',
        <IUserSettings>data
      ).callActions(userSettingsUpdate)
    }
    if (type === userDownloadBadge.getType('pending')) {
      const { result } = yield requester<void, IUser>(
        user,
        'downloadUserBadge'
      ).callActions(userDownloadBadge)
      yield Promise.resolve(saveAs(result, 'badge.pdf'))
    }
    if (type === uploadUserPhoto.getType('pending')) {
      const formData = new FormData()
      // @ts-ignore
      formData.append('users_photo', data)
      yield requester<void, IUser, [FormData]>(
        user,
        'uploadUserPhoto',
        <FormData>formData
      ).callActions(uploadUserPhoto)
      yield put(getUserPhoto.pending())
    }
    if (type === deleteUserPhoto.getType('pending')) {
      yield requester<void, IUser>(
        user,
        'deleteUserPhoto'
      ).callActions(deleteUserPhoto)
    }
  }
)

export * as viewerModel from './viewerModel'