import { gql } from '@apollo/client'

import { Filters } from '../../lib/generated/goods/Api'

export const getGoodsList = (filtersConfig?: Filters) => gql(`
  query MyQuery(
    $id: Int = null
    $searchString: String = null
    $season: [String!] = []
    $excludeInArchive: Boolean = false
    $category: [String!] = []
    $goodsType: [GoodsType!] = null
    $contragentId: [Int!] = []
    $goodsBrandId: [Int!] = []
    $limit: Int,
    $page: Int! = 0,
    $order: SortOrder = null,
    $sort: String = null) {
    goods(
      id: $id,
      searchString: $searchString,
      season: $season,
      excludeInArchive: $excludeInArchive,
      category: $category,
      goodsType: $goodsType,
      contragentId: $contragentId,
      goodsBrandId: $goodsBrandId
      ) {
      total
      result(
        limit: $limit,
        page: $page,
        order: $order,
        sort: $sort
        ) {
      auxCode
      brand
      category
      code
      goodsType
      hasPhoto
      id
      inArchive
      name
      season
      suppliers
      products {
        autoOrdering
        barcodes
        cabinetName
        companyName
        inArchive
        marketplaceCategory
        marketplaceCode
        marketplaceName
        marketplaceUrl
        name
        price
        productId
        stocks
        supplierCode
      }
    }
      filters {
      ${filtersConfig ? Object.keys(filtersConfig).map(el => {
    if (filtersConfig[el] === true) {
      return `${el} { value label count }`
    }
    return ''
  }) : `
        category {
          label
          value
          count
        }
        contragentId {
          label
          value
          count
        }
        goodsBrandId {
          label
          value
          count
        }
        goodsType {
          label
          value
          count
        }
        season {
          label
          value
          count
        }
      ` 
}
      }
    }
  }
  `
)
