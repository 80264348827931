import React from 'react'
import { useQueryClient } from 'react-query'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { Box } from '@mui/material'
import { history } from 'app/providers/with-router'
import { productModel } from 'entities/pim/product'
import { DeleteConfirmModal } from 'entities/pim/goodsComponents'
import { SimpleProductContextProvider } from 'entities/pim/product/model/simple/useSimpleProductContext'
import { pimApi } from 'shared/api'
import { PRODUCT_LIST } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { Preloader } from 'shared/ui/components'
import { Form } from 'shared/ui/components/form'
import { dialogModel } from 'shared/ui/components/dialog'
import { ProductHeader } from 'shared/ui/product/ProductHeader'

import { ProductInfoTabs } from './ProductInfoTabs'
import { MemoProductCardForm } from './ProductCardForm'
import { SaveForm } from './SaveForm'

import { validationSchema } from '../lib/validationSchema'

const focusOnError = createDecorator()
const decorators = [focusOnError]

interface SimpleProductEditProps {
  productId: number
  commonProduct: pimApi.products.common.Product
}

export const SimpleProductEdit = ({
  productId,
}: SimpleProductEditProps) => {
  const queryClient = useQueryClient()
  const goods = useGoodsContext()

  const simpleProductQuery =
    pimApi.products.simple.useFetchSimpleProductQuery(productId)
  const { mutate: editSimpleProduct, isLoading: editSimpleProductInProgress } = pimApi.products.simple.useEditSimpleProductMutation()
  const { mutate: deleteProduct, isLoading: isDeleteProductLoading } =
    pimApi.products.simple.useDeleteProductMutation()
  const validate = useValidationSchema(validationSchema)
  const isArchived = simpleProductQuery.data?.inArchive

  const initialValues: productModel.simpleModel.EditInitialFormValues =
    simpleProductQuery.data as NonNullable<typeof simpleProductQuery.data>

  const handleFormSubmit = (formValues) => {
    editSimpleProduct({
      productId,
      editedSimpleProduct: formValues
    }, {
      onSuccess( data ) {
        snackActions.info('Сохранено!')
        queryClient.setQueryData(
          pimApi.products.simple.getSimpleProductQueryKey(productId),
          data
        )
      },
    })
  }

  const handleDelete = () => {
    dialogModel.openDialog({
      component: DeleteConfirmModal,
      onAccept: () => {
        deleteProduct(
          {
            productId,
            // @ts-ignore
            id: simpleProductQuery.data.id,
            // @ts-ignore
            versionNo: simpleProductQuery.data.versionNo,
          },
          {
            onSuccess: () => {
              history.push(PRODUCT_LIST)
              snackActions.info('Продукт успешно удален!')
            },
          }
        )
      },
    })
  }

  if (!simpleProductQuery.data) return <Preloader />

  return (
    <SimpleProductContextProvider simpleProductQuery={simpleProductQuery}>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators
        }}
        decorators={decorators}
        validate={validate}
        subscription={{ initialValues: true, hasValidationErrors: true, submitFailed: true }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ maxWidth: 'inherit' }}>
            <Box 
              sx={{ 
                border: '1px solid rgba(33, 150, 243, 0.5)',
                padding: '24px 24px 16px 24px'
              }}>
              <ProductHeader
                goods={goods}
                title={simpleProductQuery.data?.name}
                isArchived={simpleProductQuery.data?.inArchive}
                archivationComments={simpleProductQuery.data?.archivationComments}
                archivationReason={simpleProductQuery.data?.archivationreason}
              />
              <MemoProductCardForm productId={productId}/>
            </Box>
            <Box mt={3}>
              <ProductInfoTabs
                goods={goods}
                isArchived={simpleProductQuery?.data?.inArchive}
              />
            </Box>
            <SaveForm 
              isDeleteProductLoading={isDeleteProductLoading}
              editSimpleProductInProgress={editSimpleProductInProgress}
              isArchived={isArchived} 
              handleDelete={handleDelete}
            />
          </form>
        )}
      />
    </SimpleProductContextProvider>
  )
}
