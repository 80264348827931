import React from 'react'
import { generatePath, useHistory } from 'react-router'
import { Form } from 'react-final-form'
import { productModel } from 'entities/pim/product'
import { Button, Box } from '@mui/material'
import { pimApi } from 'shared/api'
import { PRODUCT_EDIT } from 'shared/config'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { Field, RadioGroupField } from 'shared/ui/components/form'

import { NewWBCard } from './NewWBCard'
import { ExistingWBCard } from './ExistingWBCard'
import { validationSchema } from './validationSchema'
import { StyledLoadingButton } from './styled'

import { ModalFormMarketplaceProps, PRODUCT_STATUS_WB } from '../../config'

export const ModalFormMarketplaceWB = ({
  goodsId,
  companymarketplaceId,
  useGoodsMedia,
  handleClose,
} : ModalFormMarketplaceProps) => {
  const history = useHistory()
  const { mutate: createProductWB, isLoading } = pimApi.products.wb.useCreateProductWBMutation()
  const validate = useValidationSchema(validationSchema)

  const onSubmit = (formValues) => {
    createProductWB(
      {
        companymarketplaceId,
        goodsId,
        useGoodsMedia,
        ...(formValues.wbnomenclatureId ? { wbnomenclatureId: parseInt(formValues?.wbnomenclatureId, 10) } : {}),
        ...(formValues.newVariation ? { newVariation: true } : {}),
        ...(formValues.cardType === 'new' ? { category_id: formValues?.category?.value } : {})
      } as any,
      {
        onSuccess: ({ data }) => {
          handleClose()
          history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
        },
      }
    )
  }

  return (
    <Form<productModel.wbModel.CreateWBProductFormValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ cardType: 'new' }}
      render={({ handleSubmit, values, valid }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box mt={3}>
              <Field
                name="cardType"
                component={RadioGroupField}
                size="small"
                options={PRODUCT_STATUS_WB}
                row={true}
                style={{ display: 'flex', gap: '10px' }}
              />
              {(values.cardType==='new') && <NewWBCard companymarketplaceId={companymarketplaceId}/>}
              {(values.cardType==='old') && <ExistingWBCard companymarketplaceId={companymarketplaceId}/>}
            </Box>
          </Box>
          <Box pt={3} sx={{ float: 'right' }}>
            <Button onClick={handleClose} color="primary">
              ОТМЕНА
            </Button>
            <StyledLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!valid}
            >
              СОЗДАТЬ
            </StyledLoadingButton>
          </Box>
        </form>
      )}
    />
  )
}
