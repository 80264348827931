/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NewSeason, Season } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Season>

export type IRequest = CamelCasedPropertiesDeep<{
  data: NewSeason
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/pim/seasons`,data).then(res => res.data)


export const useCreateSeason = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

