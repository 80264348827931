/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { GoodsSupplierCode, NewGoodsSupplierCode } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsSupplierCode>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  supplierId: number,
  data: NewGoodsSupplierCode
}>

export const apiFunction = ({ goodsId,supplierId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/suppliers/${supplierId}/codes`,data).then(res => res.data)


export const useCreateGoodssuppliercode = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

