/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { GoodsShortInfo } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsShortInfo[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Code
   * Поиск по артикулу товара
   */
    code: string,
  /**
   * With Variations
   * Показывать все вариации товаров выбранного артикула
   * @default false
   */
    with_variations?: boolean,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/pim/goods/goodsbycode`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/goods/goodsbycode`, { query }] as const

export const useGetGoodsByCode = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

