import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api'

interface patchProductProps {
  productId: UniqueId
}

interface operationWithBasketProps {
  productId: UniqueId
  method: 'add_to_basket' | 'delete_from_basket'
}

const syncProductWithWildberries =
  ({ productId } : patchProductProps) =>
    api.patch<pimApi.products.wb.WBNomenclature>(`/pim/products/wb/product/${productId}?method=sync`)

export const useSyncProductWithWildberriesMutation = () =>
  useMutation<
    AxiosResponse<pimApi.products.wb.WBNomenclature>,
    AxiosError<errorType>,
    patchProductProps
  >(syncProductWithWildberries)


interface setProductAsNewCardProps {
  productId: UniqueId
}

const setProductAsNewCard =
  ({ productId } : setProductAsNewCardProps) =>
    api.patch<pimApi.products.wb.WBNomenclature>(`/pim/products/wb/product/${productId}?method=create_card`)

export const useSetProductAsNewCardMutation = () =>
  useMutation<
    AxiosResponse<pimApi.products.wb.WBNomenclature>,
    AxiosError<errorType>,
    setProductAsNewCardProps
    >(setProductAsNewCard)


interface moveProductToAnotherCardProps {
  productId: UniqueId,
  relatedProductId: UniqueId
}

const moveProductToAnotherCard =
  ({ productId, relatedProductId } : moveProductToAnotherCardProps) =>
    api.patch<pimApi.products.wb.WBNomenclature>
    (`/pim/products/wb/product/${productId}?method=move_to_card&method_param=${relatedProductId}`)

export const useMoveProductToAnotherCard = () =>
  useMutation<
    AxiosResponse<pimApi.products.wb.WBNomenclature>,
    AxiosError<errorType>,
    moveProductToAnotherCardProps
    >(moveProductToAnotherCard)

interface changeWBProductCategoryProps {
  productId: UniqueId,
  category: string
}

const changeWBProductCategory =
  ({ productId, category } : changeWBProductCategoryProps) =>
    api.patch<pimApi.products.wb.WBNomenclature>
    (`/pim/products/wb/product/${productId}?method=change_category&method_param=${category}`)

export const useChangeWBProductCategory = () =>
  useMutation<
    AxiosResponse<pimApi.products.wb.WBNomenclature>,
    AxiosError<errorType>,
    changeWBProductCategoryProps
    >(changeWBProductCategory)


const operationsWithProductsBasket = ({ productId, method }: operationWithBasketProps) =>
  api.patch<pimApi.products.wb.WBNomenclature>(`/pim/products/wb/product/${productId}`,{} , { params: {
    method
  } })

export const useOperationsWithProductsBasket = () =>
  useMutation<
    AxiosResponse<pimApi.products.wb.WBNomenclature>,
    AxiosError<errorType>,
    operationWithBasketProps
  >(operationsWithProductsBasket)

