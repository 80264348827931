/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { GoodsProduct } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<GoodsProduct[]>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number
}>

export const apiFunction = ({ goodsId }: IRequest) =>
  api.get<ResponseType>(`/pim/goods/${goodsId}/products`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ goodsId }: IRequest) => ['get', `/pim/goods/${goodsId}/products`, { goodsId }] as const

export const useGetGoodsproducts = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

