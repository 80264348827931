import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { Interval } from './models'

import { api } from '../../base'

export function fetchIntervals(): Promise<AxiosResponse<Interval[]>> {
  return api.get<Interval[]>('/pim/pricecontrol/interval')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', '/pim/pricecontrol/interval'] as const

function getIntervals() {
  return fetchIntervals().then((res) => res.data)
}

export function useIntervalList() {
  const intervalListQuery = useQuery<
    Array<Interval>,
    errorType,
    Array<Interval>,
    QueryKey
  >(QUERY_KEY, getIntervals, {
    refetchOnMount: false
  })

  return {
    intervalListQuery,
  } as const
}