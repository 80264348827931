/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CoPackingOrderPosition, EditCoPackingOrderPosition } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CoPackingOrderPosition>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number,
  positionId: number,
  data: EditCoPackingOrderPosition
}>

export const apiFunction = ({ copackingorderId,positionId,data }: IRequest) =>
  api.put<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}/positions/${positionId}`,data).then(res => res.data)


export const useEditCopackingOrderPosition = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

