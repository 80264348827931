/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_administration-set_users_photo */
export interface BodyAdministrationSetUsersPhoto {
  /**
   * Users Photo
   * @format binary
   */
  users_photo: File
}

/** Body_current_user-save_users_photo */
export interface BodyCurrentUserSaveUsersPhoto {
  /**
   * Users Photo
   * @format binary
   */
  users_photo: File
}

/** Body_dictionary-set_company_logo */
export interface BodyDictionarySetCompanyLogo {
  /**
   * Company Logo
   * @format binary
   */
  company_logo: File
}

/** CheckTokenResponse */
export interface CheckTokenResponse {
  /** Status */
  status: 'ok' | 'error' | 'try_later'
  /** Message */
  message?: string
}

/** Company */
export interface Company {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование компании
   * @maxLength 255
   */
  name: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn: string
  /**
   * URL сайта компании
   * @maxLength 255
   */
  site_url?: string
  /** В архиве */
  in_archive: boolean
  /** Подключенные кабинеты маркетплейсов */
  cabinets?: CompanyMarketplace[]
}

/** CompanyMarketplace */
export interface CompanyMarketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование кабинета
   * @maxLength 100
   */
  cabinet_name: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  marketplace_name: string
  /** URL на каталог продуктов в формате YML */
  yml_catalog_url?: string
  /** Параметры настройки */
  credentials?: CompanyMarketplaceCredential[]
  /** В архиве */
  in_archive: boolean
}

/** CompanyMarketplaceCredential */
export interface CompanyMarketplaceCredential {
  /** Идентификатор параметра настройки */
  credential_id: number
  /** Наименование параметра настройки */
  credential_name: string
  /** Признак секретности */
  secret_flag: boolean
  /**
   * Значение
   * @maxLength 512
   */
  value?: string
  /** Установлено ли значение */
  value_set: boolean
  /** В архиве */
  in_archive: boolean
}

/** CompanyShortInfo */
export interface CompanyShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование компании
   * @maxLength 255
   */
  name: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn: string
  /**
   * URL сайта компании
   * @maxLength 255
   */
  site_url?: string
  /** В архиве */
  in_archive: boolean
}

/** Credential */
export interface Credential {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование настройки маркетплейса
   * @maxLength 100
   */
  name?: string
  /** Секретная настройка */
  secret_flag: boolean
}

/** DeleteUserPrivilege */
export interface DeleteUserPrivilege {
  /**
   * Список Идентификаторов привилегии
   * @exclusiveMin 0
   */
  id: number
  /**
   * Версия обьекта
   * @exclusiveMin 0
   */
  version_no: number
}

/** DeletingObject */
export interface DeletingObject {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditCompany */
export interface EditCompany {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование компании
   * @maxLength 255
   */
  name?: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * URL сайта компании
   * @maxLength 255
   */
  site_url?: string
  /** В архиве */
  in_archive?: boolean
}

/** EditCompanyMarketplace */
export interface EditCompanyMarketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование кабинета
   * @maxLength 100
   */
  cabinet_name?: string
  /** Параметры настройки */
  credentials?: EditCompanyMarketplaceCredential[]
  /** В архиве */
  in_archive?: boolean
}

/** EditCompanyMarketplaceCredential */
export interface EditCompanyMarketplaceCredential {
  /** Идентификатор параметра настройки */
  credential_id: number
  /**
   * Значение
   * @maxLength 512
   */
  value?: string
  /** В архиве */
  in_archive?: boolean
  /** Установлено ли значение */
  value_set: boolean
}

/** EditPrivilege */
export interface EditPrivilege {
  /**
   * Идентификатор привилегии
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование привилегии
   * @maxLength 100
   */
  name?: string
  /**
   * Код привилегии
   * @maxLength 100
   */
  code?: string
  /**
   * Описание привилегии
   * @maxLength 255
   */
  description?: string
}

/** EditReport */
export interface EditReport {
  /**
   * Идентификатор параметра отчёта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Привилегия для доступа к отчёту
   * @exclusiveMin 0
   */
  privilege_id?: number
}

/** EditUser */
export interface EditUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Имя пользователя
   * @maxLength 100
   */
  name?: string
  /**
   * Логин
   * @maxLength 100
   */
  login?: string
  /**
   * Действующий пароль
   * @maxLength 100
   */
  old_password?: string
  /**
   * Новый пароль
   * @maxLength 100
   */
  new_password?: string
  /** ID чата телеграмма */
  telegram_code?: string
}

/** EditUserInfo */
export interface EditUserInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Имя пользователя
   * @maxLength 100
   */
  name?: string
  /**
   * Логин
   * @maxLength 100
   */
  login?: string
  /**
   * Email пользователя
   * @maxLength 100
   */
  email?: string
  /** Пароль пользователя */
  password?: string
  /**
   * Дата истечения пароля
   * @format date-time
   */
  password_expired?: string
  /** ID чата телеграмма */
  telegram_code?: string
}

/** EditUserPrivilege */
export interface EditUserPrivilege {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * По дату
   * @format date-time
   */
  date_out: string
}

/** EditUserRole */
export interface EditUserRole {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * По дату
   * @format date-time
   */
  date_out: string
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Код ошибки */
  code?: number
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  message?: string
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** Marketplace */
export interface Marketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /** Параметры настройки маркетплейса */
  credentials?: Credential[]
}

/** MarketplaceShortInfo */
export interface MarketplaceShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
}

/** MethodParam */
export interface MethodParam {
  /** Value */
  value: string
}

/** NewCompany */
export interface NewCompany {
  /**
   * Наименование компании
   * @maxLength 255
   */
  name: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn: string
  /**
   * URL сайта компании
   * @maxLength 255
   */
  site_url?: string
}

/** NewCompanyMarketplace */
export interface NewCompanyMarketplace {
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /**
   * Наименование кабинета
   * @maxLength 100
   */
  cabinet_name: string
  /** Параметры настройки */
  credentials?: NewCompanyMarketplaceCredential[]
}

/** NewCompanyMarketplaceCredential */
export interface NewCompanyMarketplaceCredential {
  /** Идентификатор параметра настройки */
  credential_id: number
  /**
   * Значение
   * @maxLength 512
   */
  value?: string
}

/** NewPrivilege */
export interface NewPrivilege {
  /**
   * Наименование привилегии
   * @maxLength 100
   */
  name: string
  /**
   * Код привилегии
   * @maxLength 100
   */
  code?: string
  /**
   * Описание привилегии
   * @maxLength 255
   */
  description?: string
  /**
   * ID группы
   * @default 1
   */
  privilegegroup_id?: number
}

/** NewUserInfo */
export interface NewUserInfo {
  /**
   * Имя пользователя
   * @maxLength 100
   */
  name: string
  /**
   * Логин
   * @maxLength 100
   */
  login: string
  /**
   * Email пользователя
   * @maxLength 100
   */
  email?: string
}

/** NewUserPrivilege */
export interface NewUserPrivilege {
  /** Список Идентификаторов привилегии */
  privilege_ids: number[]
  /**
   * С даты
   * @format date-time
   */
  date_in?: string
  /**
   * Дата закрытия доступа
   * @format date-time
   */
  date_out?: string
}

/** NewUserRole */
export interface NewUserRole {
  /** Идентификатор роли */
  role_id: number
  /**
   * С даты
   * @format date-time
   */
  date_in?: string
  /**
   * Дата закрытия доступа
   * @format date-time
   */
  date_out?: string
}

/** NotAuthenticatedError */
export interface NotAuthenticatedError {
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  detail?: string
}

/** PatchUserInfo */
export interface PatchUserInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
}

/** Privilege */
export interface Privilege {
  /**
   * Идентификатор привилегии
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование привилегии
   * @maxLength 100
   */
  name: string
  /**
   * Код привилегии
   * @maxLength 100
   */
  code?: string
  /**
   * Описание привилегии
   * @maxLength 255
   */
  description?: string
  /** Количество пользователей с привилегией */
  users_quantity: number
  /** Признак базовой привилегии */
  global_privilege: boolean
  /** Группа привилегии */
  privilegegroup_id: number
}

/** PrivilegeGroup */
export interface PrivilegeGroup {
  /**
   * Наименование группы привелегий
   * @maxLength 100
   */
  name: string
  /** Дочерние группы привелегий */
  groups?: PrivilegeGroup[]
  /** Список привелегий */
  privileges?: Privilege[]
}

/** ReportInfo */
export interface ReportInfo {
  /** Наименование отчета */
  name: string
  /** Описание отчета */
  description?: string
  /** Модуль python, реализующий отчёт */
  python_module: string
  /** Список параметров отчёта */
  parameters?: AppReportsModelReportinfoReportParameter[]
}

/** ReportParam */
export interface ReportParam {
  /** ID параметра */
  id: number
  /** Значение параметра */
  value?: any
}

/**
 * ReportParameterDataType
 * An enumeration.
 */
export enum ReportParameterDataType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Object = 'object',
  File = 'file',
  NumberList = 'number_list',
}

/** Role */
export interface Role {
  /**
   * Идентификатор роли
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование роли
   * @maxLength 100
   */
  name: string
}

/** Stats */
export interface Stats {
  /**
   * Id
   * ID статистики
   */
  id: number
  /**
   * Name
   * Наименование статистики
   */
  name: string
  /**
   * Code
   * Код статистики
   */
  code: string
  /**
   * Marketplace Id
   * ID маркетплейса
   */
  marketplace_id: number
}

/** SyncGoodsSettings */
export interface SyncGoodsSettings {
  /**
   * Sync Media
   * @default false
   */
  sync_media?: boolean
  /**
   * Skip Media Checking
   * @default false
   */
  skip_media_checking?: boolean
  /**
   * Vendor Codes
   * @default []
   */
  vendor_codes?: string[]
  /**
   * Update Goods
   * @default false
   */
  update_goods?: boolean
}

/** UserInfo */
export interface UserInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Имя пользователя
   * @maxLength 100
   */
  name: string
  /**
   * Логин
   * @maxLength 100
   */
  login: string
  /**
   * Статус учетной записи
   * @maxLength 100
   */
  state: string
  /**
   * Email пользователя
   * @maxLength 100
   */
  email?: string
  /**
   * Дата открытия доступа
   * @format date-time
   */
  date_in: string
  /**
   * Дата закрытия доступа
   * @format date-time
   */
  date_out?: string
  /**
   * Дата блокировки доступа
   * @format date-time
   */
  block_date?: string
  /**
   * Дата истечения пароля
   * @format date-time
   */
  password_expired?: string
  /** Роли пользователя */
  roles?: UserRole[]
  /** Привилегии пользователя */
  privileges?: UserPrivilege[]
  /** Ссылка на сброс пароля */
  password_change_url?: string
  /** ID чата телеграмма */
  telegram_code?: string
}

/**
 * UserMethod
 * An enumeration.
 */
export enum UserMethod {
  Block = 'block',
  Unblock = 'unblock',
  Close = 'close',
  SendPassword = 'send_password',
}

/** UserPrivilege */
export interface UserPrivilege {
  /**
   * ID обьекта
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no?: number
  /**
   * ID Пользователя, сделавший последнее изменение
   * @format date-time
   */
  changed?: string
  /** Пользователь, сделавший последнее изменение */
  changer?: string
  /** Идентификатор привилегии */
  privilege_id: number
  /** Наименование привилегии */
  privilege_name: string
  /**
   * Дата открытия доступа
   * @format date-time
   */
  date_in?: string
  /**
   * Дата закрытия доступа
   * @format date-time
   */
  date_out?: string
  /** Признак привилегии добавленной с помощью роли */
  privilege_of_the_role: boolean
}

/** UserRole */
export interface UserRole {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Идентификатор роли */
  role_id: number
  /** Наименование роли */
  role_name: string
  /**
   * Дата открытия доступа
   * @format date-time
   */
  date_in: string
  /**
   * Дата закрытия доступа
   * @format date-time
   */
  date_out?: string
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: any[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** Report */
export interface AppAdminModelReportsReport {
  /**
   * Идентификатор параметра отчёта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование отчёта
   * @maxLength 100
   */
  name: string
  /**
   * Описание отчёта
   * @maxLength 4000
   */
  description?: string
  /**
   * Привилегия для доступа к отчёту
   * @exclusiveMin 0
   */
  privilege_id?: number
}

/** ReportGroup */
export interface AppAdminModelReportsReportGroup {
  /**
   * Наименование группы отчётов
   * @maxLength 100
   */
  name: string
  /** Дочерние группы отчётов */
  groups?: AppAdminModelReportsReportGroup[]
  /** Список отчётов */
  reports?: AppAdminModelReportsReport[]
}

/** User */
export interface AppAdminModelUsersUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Имя пользователя
   * @maxLength 100
   */
  name: string
  /**
   * Логин
   * @maxLength 100
   */
  login: string
  /**
   * Статус учетной записи
   * @maxLength 100
   */
  state: string
}

/** ReportParameter */
export interface AppReportsModelReportinfoReportParameter {
  /**
   * Идентификатор параметра отчёта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование параметра
   * @maxLength 100
   */
  name: string
  /**
   * Код параметра
   * @maxLength 100
   */
  code: string
  /** Флаг обязательности */
  required: boolean
  /** Тип параметра */
  data_type: number
}

/** Report */
export interface AppReportsModelReportslistReport {
  /**
   * Идентификатор параметра отчёта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование отчёта
   * @maxLength 100
   */
  name: string
  /**
   * Описание отчёта
   * @maxLength 4000
   */
  description?: string
  /** Список параметров отчёта */
  parameters?: AppReportsModelReportslistReportParameter[]
}

/** ReportGroup */
export interface AppReportsModelReportslistReportGroup {
  /**
   * Наименование группы отчётов
   * @maxLength 100
   */
  name: string
  /** Дочерние группы отчётов */
  groups?: AppReportsModelReportslistReportGroup[]
  /** Список отчётов */
  reports?: AppReportsModelReportslistReport[]
}

/** ReportParameter */
export interface AppReportsModelReportslistReportParameter {
  /**
   * Идентификатор параметра отчёта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование параметра
   * @maxLength 100
   */
  name: string
  /**
   * Описание параметра
   * @maxLength 4000
   */
  description?: string
  /** Флаг обязательности */
  required: boolean
  /** Тип параметра */
  data_type: ReportParameterDataType
  /** Значение по умолчанию */
  default_value?: any
}

/** User */
export interface AppUserModelUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Имя пользователя
   * @maxLength 100
   */
  name: string
  /**
   * Логин
   * @maxLength 100
   */
  login: string
  /** ID телеграмма */
  telegram_code?: string
}

export namespace Administration {
  /**
   * @description Список доступных ролей
   * @tags Administration
   * @name AdministrationGetRoles
   * @summary Список доступных ролей
   * @request GET:/admin/roles
   * @secure
   */
  export namespace AdministrationGetRoles {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Role[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Список доступных привилегий
   * @tags Administration
   * @name AdministrationGetPrivileges
   * @summary Список доступных привилегий
   * @request GET:/admin/privileges
   * @secure
   */
  export namespace AdministrationGetPrivileges {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Customer
       * Признак собственных привелегий
       */
      customer?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PrivilegeGroup[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создание новой привилегии
   * @tags Administration
   * @name AdministrationCreatePrivilege
   * @summary Создание новой привилегии
   * @request POST:/admin/privileges
   * @secure
   */
  export namespace AdministrationCreatePrivilege {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewPrivilege
    export type RequestHeaders = {}
    export type ResponseBody = Privilege
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Информация о привилегии
   * @tags Administration
   * @name AdministrationGetPrivilege
   * @summary Информация о привилегии
   * @request GET:/admin/privileges/{privilege_id}
   * @secure
   */
  export namespace AdministrationGetPrivilege {
    export type RequestParams = {
      /**
       * Privilege Id
       * ID привелегий
       */
      privilegeId: number
    }
    export type RequestQuery = {
      /**
       * Customer
       * Признак собственных привелегий
       */
      customer?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Privilege
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Редактирование привилегии
   * @tags Administration
   * @name AdministrationChangePrivilege
   * @summary Редактирование привилегии
   * @request PUT:/admin/privileges/{privilege_id}
   * @secure
   */
  export namespace AdministrationChangePrivilege {
    export type RequestParams = {
      /** Privilege Id */
      privilegeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditPrivilege
    export type RequestHeaders = {}
    export type ResponseBody = Privilege
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Удаление привилегии
   * @tags Administration
   * @name AdministrationDeletePrivilege
   * @summary Удаление привилегии
   * @request DELETE:/admin/privileges/{privilege_id}
   * @secure
   */
  export namespace AdministrationDeletePrivilege {
    export type RequestParams = {
      /** Privilege Id */
      privilegeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Список пользователей компании
   * @tags Administration
   * @name AdministrationGetUsers
   * @summary Список пользователей компании
   * @request GET:/admin/users
   * @secure
   */
  export namespace AdministrationGetUsers {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Код привилегии
       * Отфильтровать список пользователей по наличию привилегии
       */
      with_privilege_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppAdminModelUsersUser[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создание нового пользователя
   * @tags Administration
   * @name AdministrationCreateUser
   * @summary Создание нового пользователя
   * @request POST:/admin/users
   * @secure
   */
  export namespace AdministrationCreateUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewUserInfo
    export type RequestHeaders = {}
    export type ResponseBody = UserInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Информация о пользователе
   * @tags Administration
   * @name AdministrationGetUserInfo
   * @summary Информация о пользователе
   * @request GET:/admin/users/{user_id}
   * @secure
   */
  export namespace AdministrationGetUserInfo {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменение информации о пользователе
   * @tags Administration
   * @name AdministrationEditUser
   * @summary Изменение информации о пользователе
   * @request PUT:/admin/users/{user_id}
   * @secure
   */
  export namespace AdministrationEditUser {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditUserInfo
    export type RequestHeaders = {}
    export type ResponseBody = UserInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменение информации о пользователе
   * @tags Administration
   * @name AdministrationPatchUser
   * @summary Изменение информации о пользователе
   * @request PATCH:/admin/users/{user_id}
   * @secure
   */
  export namespace AdministrationPatchUser {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {
      /** Операция над пользователем */
      method: UserMethod
    }
    export type RequestBody = PatchUserInfo
    export type RequestHeaders = {}
    export type ResponseBody = UserInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение файла с фотографией пользователя.
   * @tags Administration
   * @name AdministrationGetUsersPhoto
   * @summary Получение аватара пользователя
   * @request GET:/admin/users/{user_id}/photo
   * @secure
   */
  export namespace AdministrationGetUsersPhoto {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Загрузка файла с фотографией пользователя.
   * @tags Administration
   * @name AdministrationSetUsersPhoto
   * @summary Загрузка аватара пользователя
   * @request POST:/admin/users/{user_id}/photo
   * @secure
   */
  export namespace AdministrationSetUsersPhoto {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyAdministrationSetUsersPhoto
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Удаление фотографии пользователя.
   * @tags Administration
   * @name AdministrationDeleteUsersPhoto
   * @summary Удаление аватара пользователя
   * @request DELETE:/admin/users/{user_id}/photo
   * @secure
   */
  export namespace AdministrationDeleteUsersPhoto {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * No description
   * @tags Administration, Administration
   * @name AdministrationAddRoleToUser
   * @summary Добавление роли пользователю
   * @request POST:/admin/users/{user_id}/roles
   * @secure
   */
  export namespace AdministrationAddRoleToUser {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewUserRole
    export type RequestHeaders = {}
    export type ResponseBody = UserRole
    export const XPrivilegeCheck = undefined
  }
  /**
   * No description
   * @tags Administration, Administration
   * @name AdministrationCloseRoleOfUser
   * @summary Изменение даты закрытия роли у пользователя
   * @request PUT:/admin/users/{user_id}/roles/{userrole_id}
   * @secure
   */
  export namespace AdministrationCloseRoleOfUser {
    export type RequestParams = {
      /** User Id */
      userId: number
      /** Userrole Id */
      userroleId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditUserRole
    export type RequestHeaders = {}
    export type ResponseBody = UserRole
    export const XPrivilegeCheck = undefined
  }
  /**
   * No description
   * @tags Administration, Administration
   * @name AdministrationDeleteRoleFromUser
   * @summary Удаление роли у пользователя
   * @request DELETE:/admin/users/{user_id}/roles/{userrole_id}
   * @secure
   */
  export namespace AdministrationDeleteRoleFromUser {
    export type RequestParams = {
      /** User Id */
      userId: number
      /** Userrole Id */
      userroleId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * No description
   * @tags Administration, Administration
   * @name AdministrationClosePrivilegeOfUser
   * @summary Изменение даты закрытия привилегии у пользователя
   * @request PUT:/admin/users/{user_id}/privileges
   * @secure
   */
  export namespace AdministrationClosePrivilegeOfUser {
    export type RequestParams = {
      /** ID пользователя */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditUserPrivilege[]
    export type RequestHeaders = {}
    export type ResponseBody = UserInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * No description
   * @tags Administration, Administration
   * @name AdministrationAddPrivilegeToUser
   * @summary Добавление привилегии пользователю
   * @request POST:/admin/users/{user_id}/privileges
   * @secure
   */
  export namespace AdministrationAddPrivilegeToUser {
    export type RequestParams = {
      /** ID пользователя */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewUserPrivilege
    export type RequestHeaders = {}
    export type ResponseBody = UserInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * No description
   * @tags Administration, Administration
   * @name AdministrationDeletePrivilegeFromUser
   * @summary Удаление привилегии у пользователя
   * @request DELETE:/admin/users/{user_id}/privileges
   * @secure
   */
  export namespace AdministrationDeletePrivilegeFromUser {
    export type RequestParams = {
      /** ID пользователя */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeleteUserPrivilege[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Список отчётов
   * @tags Administration
   * @name AdministrationGetReportsList
   * @summary Список отчётов
   * @request GET:/admin/reports
   * @secure
   */
  export namespace AdministrationGetReportsList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppAdminModelReportsReportGroup[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменение доступа к отчётам
   * @tags Administration
   * @name AdministrationEditReport
   * @summary Изменение доступа к отчётам
   * @request PUT:/admin/reports/{report_id}
   * @secure
   */
  export namespace AdministrationEditReport {
    export type RequestParams = {
      /** Report Id */
      reportId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditReport
    export type RequestHeaders = {}
    export type ResponseBody = AppAdminModelReportsReport
    export const XPrivilegeCheck = undefined
  }
}

export namespace CurrentUser {
  /**
   * @description Получение настроек пользователя. В теле ответа будет возвращён json объект с настройками пользователя
   * @tags Current User
   * @name CurrentUserGetUsersSettings
   * @summary Получение настроек пользователя
   * @request GET:/user/settings
   * @secure
   */
  export namespace CurrentUserGetUsersSettings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Сохранение настроек пользователя. В теле запроса необходимо передавать json объект с настройками пользователя
   * @tags Current User
   * @name CurrentUserSaveUsersSettings
   * @summary Сохранение настроек пользователя
   * @request PUT:/user/settings
   * @secure
   */
  export namespace CurrentUserSaveUsersSettings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = object
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение PDF файла с бейджем пользователя. Используется для авторизации по QR коду на ТСД
   * @tags Current User
   * @name CurrentUserGetUsersBadge
   * @summary Получение PDF файла с бейджем пользователя
   * @request GET:/user/badge
   * @secure
   */
  export namespace CurrentUserGetUsersBadge {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение файла с фотографией пользователя.
   * @tags Current User
   * @name CurrentUserGetUsersPhoto
   * @summary Получение аватара
   * @request GET:/user/photo
   * @secure
   */
  export namespace CurrentUserGetUsersPhoto {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Сохранение фотографии пользователя.
   * @tags Current User
   * @name CurrentUserSaveUsersPhoto
   * @summary Сохранение аватара
   * @request POST:/user/photo
   * @secure
   */
  export namespace CurrentUserSaveUsersPhoto {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyCurrentUserSaveUsersPhoto
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Удаление фотографии пользователя.
   * @tags Current User
   * @name CurrentUserDeleteUsersPhoto
   * @summary Удаление аватара
   * @request DELETE:/user/photo
   * @secure
   */
  export namespace CurrentUserDeleteUsersPhoto {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение информации по текущему пользователю
   * @tags Current User
   * @name CurrentUserGetCurrentUserInfo
   * @summary Информация по текущему пользователю
   * @request GET:/user
   * @secure
   */
  export namespace CurrentUserGetCurrentUserInfo {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppUserModelUser
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменение информации по текущему пользователю
   * @tags Current User
   * @name CurrentUserEditCurrentUserInfo
   * @summary Изменение информации по текущему пользователю
   * @request PUT:/user
   * @secure
   */
  export namespace CurrentUserEditCurrentUserInfo {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = EditUser
    export type RequestHeaders = {}
    export type ResponseBody = AppUserModelUser
    export const XPrivilegeCheck = undefined
  }
}

export namespace Report {
  /**
   * @description Список доступных отчётов
   * @tags Report
   * @name ReportGetReportsList
   * @summary Список доступных отчётов
   * @request GET:/reports/list
   * @secure
   */
  export namespace ReportGetReportsList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppReportsModelReportslistReportGroup[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Список доступных отчётов
   * @tags Report
   * @name ReportGetReportInfov2
   * @summary Метаинформация по отчёту
   * @request GET:/reports/v2/report/{report_id}
   */
  export namespace ReportGetReportInfov2 {
    export type RequestParams = {
      /** Report Id */
      reportId: number
    }
    export type RequestQuery = {
      /** customer_code */
      customer_code: string
      /** user_id */
      user_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReportInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Записать статистику по отчету
   * @tags Report
   * @name ReportGetReportInfo
   * @summary Записать статистику по отчету
   * @request POST:/reports/report/{report_id}/stat
   * @secure
   */
  export namespace ReportGetReportInfo {
    export type RequestParams = {
      /** Report Id */
      reportId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ReportParam[]
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Записать статистику по отчету
   * @tags Report
   * @name ReportGetReportInfo2
   * @summary Записать статистику по отчету
   * @request POST:/reports/v2/report/{report_id}/stat
   * @originalName reportGetReportInfo
   * @duplicate
   */
  export namespace ReportGetReportInfo2 {
    export type RequestParams = {
      /** Report Id */
      reportId: number
    }
    export type RequestQuery = {
      /** user_id */
      user_id: number
    }
    export type RequestBody = ReportParam[]
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
}

export namespace Stats {
  /**
   * @description Получить виды статистики на маркетплейсе
   * @tags Stats
   * @name StatsGetstats
   * @summary Получить виды статистики на маркетплейсе
   * @request GET:/stats
   * @secure
   */
  export namespace StatsGetstats {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Company Id
       * ID компании
       */
      company_id?: number
      /**
       * Cabinet Id
       * ID кабинета
       */
      cabinet_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Stats[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Обновить статистику по всем кабинетам с маркетплейса
   * @tags Stats
   * @name StatsSyncStatsAll
   * @summary Обновить статистику по всем кабинетам с маркетплейса
   * @request PATCH:/stats
   * @secure
   */
  export namespace StatsSyncStatsAll {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Stats
       * ID типов статистики
       * @default []
       */
      stats?: number[]
      /**
       * Date From
       * с дату
       * @format date
       */
      date_from?: string
      /**
       * Date To
       * по даты
       * @format date
       */
      date_to?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Обновить статистику на маркетплейсе
   * @tags Stats
   * @name StatsSyncStats
   * @summary Обновить статистику на маркетплейсе
   * @request PATCH:/companies/{company_id}/cabinets/{cabinet_id}/stats
   * @secure
   */
  export namespace StatsSyncStats {
    export type RequestParams = {
      /**
       * Company Id
       * ID компании
       */
      companyId: number
      /**
       * Cabinet Id
       * ID кабинета
       */
      cabinetId: number
    }
    export type RequestQuery = {
      /**
       * Stats
       * ID типов статистики
       * @default []
       */
      stats?: number[]
      /**
       * Date From
       * с дату
       * @format date
       */
      date_from?: string
      /**
       * Date To
       * по даты
       * @format date
       */
      date_to?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
}

export namespace ProductScraping {
  /**
   * @description Запустить скрапинг по продукту
   * @tags Product Scraping
   * @name ProductScrapingWbscrapingnm
   * @summary Запустить скрапинг по продукту
   * @request PATCH:/scraping/nmid/{nmid}
   * @secure
   */
  export namespace ProductScrapingWbscrapingnm {
    export type RequestParams = {
      /**
       * Nmid
       * Номенклатура
       */
      nmid: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Запустить скрапинг по всем продуктам
   * @tags Product Scraping
   * @name ProductScrapingSchedulescrapingwb
   * @summary Запустить скрапинг по всем продуктам
   * @request PATCH:/scraping/nmid
   * @secure
   */
  export namespace ProductScrapingSchedulescrapingwb {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
}

export namespace Dictionary {
  /**
   * @description Получение списка компаний клиента
   * @tags Dictionary
   * @name DictionaryGetCompanies
   * @summary Получение списка компаний клиента
   * @request GET:/companies
   * @secure
   */
  export namespace DictionaryGetCompanies {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyShortInfo[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создать новую компанию
   * @tags Dictionary
   * @name DictionaryCreateCompany
   * @summary Создать новую компанию
   * @request POST:/companies
   * @secure
   */
  export namespace DictionaryCreateCompany {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewCompany
    export type RequestHeaders = {}
    export type ResponseBody = Company
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение информации по компании
   * @tags Dictionary
   * @name DictionaryGetCompany
   * @summary Информация по компании
   * @request GET:/companies/{company_id}
   * @secure
   */
  export namespace DictionaryGetCompany {
    export type RequestParams = {
      /** Company Id */
      companyId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Company
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Отредактировать компанию
   * @tags Dictionary
   * @name DictionaryEditCompany
   * @summary Отредактировать компанию
   * @request PUT:/companies/{company_id}
   * @secure
   */
  export namespace DictionaryEditCompany {
    export type RequestParams = {
      /** Company Id */
      companyId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditCompany
    export type RequestHeaders = {}
    export type ResponseBody = Company
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получить логотип компании
   * @tags Dictionary
   * @name DictionaryGetCompanyLogo
   * @summary Получить логотип компании
   * @request GET:/companies/{company_id}/logo
   * @secure
   */
  export namespace DictionaryGetCompanyLogo {
    export type RequestParams = {
      /** Company Id */
      companyId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Установить логотип компании
   * @tags Dictionary
   * @name DictionarySetCompanyLogo
   * @summary Установить логотип компании
   * @request POST:/companies/{company_id}/logo
   * @secure
   */
  export namespace DictionarySetCompanyLogo {
    export type RequestParams = {
      /** Company Id */
      companyId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyDictionarySetCompanyLogo
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создать новый кабинет маркетплейса
   * @tags Dictionary
   * @name DictionaryCreateCompanymarketplace
   * @summary Создать новый кабинет маркетплейса
   * @request POST:/companies/{company_id}/cabinets
   * @secure
   */
  export namespace DictionaryCreateCompanymarketplace {
    export type RequestParams = {
      /** Company Id */
      companyId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewCompanyMarketplace
    export type RequestHeaders = {}
    export type ResponseBody = CompanyMarketplace
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменить данные кабинета маркетплейса
   * @tags Dictionary
   * @name DictionaryEditCompanymarketplace
   * @summary Изменить данные кабинета маркетплейса
   * @request PUT:/companies/{company_id}/cabinets/{cabinet_id}
   * @secure
   */
  export namespace DictionaryEditCompanymarketplace {
    export type RequestParams = {
      /** Company Id */
      companyId: number
      /** Cabinet Id */
      cabinetId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditCompanyMarketplace
    export type RequestHeaders = {}
    export type ResponseBody = CompanyMarketplace
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Обновить товары с маркетплейса
   * @tags Dictionary
   * @name DictionarySyncGoods
   * @summary Обновить товары с маркетплейса
   * @request PATCH:/companies/{company_id}/cabinets/{cabinet_id}/goods
   * @secure
   */
  export namespace DictionarySyncGoods {
    export type RequestParams = {
      /** Company Id */
      companyId: number
      /** Cabinet Id */
      cabinetId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SyncGoodsSettings
    export type RequestHeaders = {}
    export type ResponseBody = Marketplace
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Применить действие к токену
   * @tags Dictionary
   * @name DictionaryPatchCredential
   * @summary Применить действие к токену
   * @request PATCH:/marketplaces/{marketplace_id}/credentials/{credential_id}
   * @secure
   */
  export namespace DictionaryPatchCredential {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
      /** Credential Id */
      credentialId: number
    }
    export type RequestQuery = {
      /** Операция над токеном */
      method: 'check'
    }
    export type RequestBody = MethodParam
    export type RequestHeaders = {}
    export type ResponseBody = CheckTokenResponse
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение списка всех маркетплейсов в системе
   * @tags Dictionary
   * @name DictionaryGetMarketplaces
   * @summary Получение списка маркетплейсов
   * @request GET:/marketplaces
   * @secure
   */
  export namespace DictionaryGetMarketplaces {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceShortInfo[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение информации о маркетплейсе
   * @tags Dictionary
   * @name DictionaryGetMarketplace
   * @summary Получение информации о маркетплейсе
   * @request GET:/marketplaces/{marketplace_id}
   * @secure
   */
  export namespace DictionaryGetMarketplace {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Marketplace
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение логотипа маркетплейса
   * @tags Dictionary
   * @name DictionaryGetMarketplaceLogo
   * @summary Получение логотипа маркетплейса
   * @request GET:/marketplaces/{marketplace_id}/logo
   * @secure
   */
  export namespace DictionaryGetMarketplaceLogo {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
    export const XPrivilegeCheck = undefined
  }
}

//none
