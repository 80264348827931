import styled from 'styled-components'

export const StyledNotificationsContainer = styled.div`
  .container {
    transition: all 0.3s;
    position: fixed;
    right: 56px;
    top: 61px;
    width: 448px;
    z-index: 10;
    box-shadow: 0 1px 3px 0 #0000001F, 0 1px 1px 0 #00000024, 0 2px 1px -1px #00000033;
    border-radius: 4px;
    background-color: #ffffff;

    .title {
      border-bottom: 1px solid #E0E0E0;
      align-items: center;
      height: 56px;
      padding: 16px 24px 16px 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > span {
        font-size: 20px;
        color: #00000099;
      }

      .text-button {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #007DFF;
      }
    }

    .notifications-container {
      padding: 8px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;

      .notification-item {
        padding: 8px;
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          flex-direction: row;
          align-items: center;

          .viewed-flag-container {
            min-width: 24px;
            display: flex;
            justify-content: start;

            .new-notification-flag {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #007DFF;
            }
          }

          .item-title {
            word-wrap: break-word;
            width: 65%;
            font-size: 14px;
            font-weight: 500;
            padding-right: 24px;
          }

          .notification-date {
            font-size: 12px;
            color: #00000099;
          }

          .delete-icon {
            cursor: pointer;
            color: #00000042;
            margin-left: auto;
          }
        }

        .description-container {
          margin-top: 8px;
          font-size: 12px;
          color: #00000099;
          padding: 0 0 0 24px;
        }

        .markdown-description-container {
          margin-top: 8px;
          font-size: 12px;
          color: #00000099;
          padding: 0 0 0 24px;
        }

        .link-container {
          display: flex;
          flex-direction: row;
          gap: 24px;
          margin-top: 8px;
          padding: 0 0 0 24px;
          
          a {
            width: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #007DFF;
            text-decoration: none;
            gap: 8px;
            font-size: 12px;
            font-weight: 400;
          }

          button {
            text-transform: none;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .container.show {
    opacity: 1;
    right: 56px
  }

  .container.hide {
    opacity: 0;
    right: -20px;
  }
`

export const StyledPlugContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 343px;
  font-size: 16px;
  
  img {
    width: 120px;
    height: 120px;
  }
  span {
    color: #00000061;
  }
`