import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'

interface CreateUserPrivilegePayload {
  userId: UniqueId,
  privilegeData: pimApi.admin.NewUserPrivilege,
}

const createUserPrivilege = ({ userId, privilegeData }: CreateUserPrivilegePayload) =>
  api.post<pimApi.admin.UserPrivilege>(
    `/customer/admin/users/${userId}/privileges`,
    privilegeData
  )

export const useCreateUserPrivilegeMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.UserPrivilege>,
    AxiosError<errorType>,
    CreateUserPrivilegePayload
    >(createUserPrivilege)