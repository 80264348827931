/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CompanyMarketplace, NewCompanyMarketplace } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CompanyMarketplace>

export type IRequest = CamelCasedPropertiesDeep<{
  companyId: number,
  data: NewCompanyMarketplace
}>

export const apiFunction = ({ companyId,data }: IRequest) =>
  api.post<ResponseType>(`/customer/companies/${companyId}/cabinets`,data).then(res => res.data)


export const useCreateCompanymarketplace = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

