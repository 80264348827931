import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface PatchUserPayload {
  userId: UniqueId,
  editedUser: pimApi.admin.PatchUser
  method: string
}
export const patchUser = ({ userId, editedUser, method }: PatchUserPayload) =>
  api.patch<pimApi.admin.CurrentUser>(
    `/customer/admin/users/${userId}?method=${method}`,
    editedUser
  )


export const usePatchUserMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.CurrentUser>,
    AxiosError<errorType>,
    PatchUserPayload
    >(patchUser)
