import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ProductChangeGoodsModal } from 'features/product/ProductChangeGoodsModal'
import { ArchiveModal } from 'features/product/OZON/ArchiveModal'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu } from 'shared/ui/components'
import { dialogModel } from 'shared/ui/components/dialog'
import { DefaultModal } from 'shared/ui/components/ModalComponents'
import { MoreHoriz } from '@mui/icons-material'
import * as React from 'react'
import { ProductCopyModal } from 'features/product/OZON/ProductCopyModal/ProductCopyModal'
import { SettingsMenuItem } from 'pages/product/edit/main/model/types'

export const ProductSettings = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const commonProductQuery = pimApi.products.common.useFetchCommonProductQuery(parseInt(id, 10))
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(parseInt(id, 10))
  const { mutate: unarchiveProductMutation } = pimApi.products.common.useUnarchiveProductMutation()
  const isArchived = ozonProductQuery.data?.inArchive
  
  const settingsOptions: Array<SettingsMenuItem> = [
    {
      label: ozonProductQuery.data?.inArchive ? 'Вернуть из архива': 'Перенести в архив',
      handler: () => {
        if(ozonProductQuery.data?.inArchive && commonProductQuery.data) {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => 
                <DefaultModal 
                  close={close}
                  accept={accept}
                  title="Вернуть продукт из архива?"
                />,
              onAccept: () => {
                unarchiveProductMutation({
                  productId: commonProductQuery.data?.productId, 
                  deArchivationInfo: {
                    productId: commonProductQuery.data?.productId,
                    versionNo: commonProductQuery.data?.versionNo,
                  }
                }, {
                  onSuccess: ({ data }) => {
                    queryClient.setQueryData(pimApi.products.common.getFetchCommonProductQueryKey(parseInt(id, 10)), data)
                    ozonProductQuery.refetch()
                    snackActions.info('Продукт успешно возвращен из архива!')
                  }
                })
              },
            }
          )
          
        } else {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) =>
                commonProductQuery.data && <ArchiveModal close={close} accept={accept} product={commonProductQuery.data}/>,
              onAccept: () => {},
            }
          )
        }
      }
    },
    {
      label: 'Создать копию',
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close }) =>
              <ProductCopyModal commonProductData={commonProductQuery!.data!} close={close}/>
          }
        )
      }
    },
    { 
      label: 'Перенести на другой товар',
      disabled: isArchived,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) => <ProductChangeGoodsModal close={close} accept={accept} productId={id}/>,
            onAccept: () => {},
          }
        )
      }
    },
  ]

  return (
    <SettingsMenu
      options={settingsOptions}
      iconType={<MoreHoriz />}
    />
  )
}
