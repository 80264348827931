import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api/index'

function getAllReports() {
  return api.get<customerApi.AdminReportGroupCamel[]>('customer/admin/reports').then((res) => res.data)
}

type QueryKey = ReturnType<typeof getAllReportsQueryKey>

export const getAllReportsQueryKey = () => ['get', 'customer/admin/reports'] as const

export const useGetAllReportsQuery = () =>
  useQuery<
    customerApi.AdminReportGroupCamel[],
    errorType,
    customerApi.AdminReportGroupCamel[],
    QueryKey
  >(
    getAllReportsQueryKey(),
    getAllReports
  )
