/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditGoodsBundleComponent, Goods } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Goods>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  componentId: number,
  data: EditGoodsBundleComponent
}>

export const apiFunction = ({ goodsId,componentId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/components/${componentId}`,data).then(res => res.data)


export const useAddComponentToBundle = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

