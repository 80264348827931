import { memo } from 'react'
import ReactQuill from 'react-quill'
import { FieldRenderProps } from 'react-final-form'
import { AutocompleteValue } from '@mui/material/useAutocomplete'
import { Box } from '@mui/material'
import { Option } from 'shared/ui/components/interface'
import { Text } from 'shared/ui/components'
import { replaceEditorTags } from 'shared/lib/utils'

import { DescritionWrapper } from './styled'

export interface DescriptionProps extends FieldRenderProps<string> {
  input: any,
  disabled?: boolean
}

export const Description = ({ input, disabled }: DescriptionProps) => {
  const handleChange = (value: AutocompleteValue<Option<any>, false, undefined, undefined>) => {
    if(input) input.onChange(value)
  }

  return (
    <DescritionWrapper $disabled={disabled}>
      {/* @ts-ignore */}
      <ReactQuill
        value={input.value}
        /* @ts-ignore */
        onChange={handleChange}
        modules={{
          toolbar: disabled ? [] : [{ 'list': 'bullet' }],
          clipboard: {
            matchVisual: false,
          }
        }}
        formats={['list']}
        preserveWhitespace={true}
        readOnly={disabled}
      />
      <Box mt={1}>
        <Text>Символов: {replaceEditorTags(input.value).length}/3000</Text>
      </Box>
    </DescritionWrapper>
    
  )
}

export const DescriptionMemo = memo(Description)
