import { customerApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName
  extends Omit<
    customerApi.Privilege,
    | 'id'
    | 'versionNo'
    | 'changer'
  > {
  actionColumn?: string
  padding?: number
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  name: 'name',
  padding: 'padding',
  code: 'code',
  description: 'description',
  usersQuantity: 'usersQuantity',
  actionColumn: 'actionColumn',
  globalPrivilege: 'globalPrivilege'
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config: [
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование привилегии',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.PrivilegesName
    },
    {
      name: columns.usersQuantity,
      columnName: columns.usersQuantity,
      title: ' ',
      width: 280,
      sortingEnabled: false,
      cellType: CellType.PrivilegeUsersQuantity
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Код',
      width: 232,
      sortingEnabled: false,
      cellType: CellType.PrivilegeCode
    },
    {
      name: columns.globalPrivilege,
      columnName: columns.globalPrivilege,
      title: '',
      width: 0,
      sortingEnabled: true,
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 35,
      cellType: CellType.PrivilegeEdit,
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 35,
      cellType: CellType.PrivilegeRemove,
    },
    {
      name: columns.padding,
      columnName: columns.padding,
      title: ' ',
      sortingEnabled: false,
      width: 10,
    }
  ],
}
