import { Box, Grid, CircularProgress } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { AlertComponent } from 'entities/pim/product'
import { pimApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { Field, useField } from 'react-final-form'
import { InputField, RadioGroupField } from 'shared/ui/components'
import { ProductShortInfoWidget } from 'features/product/widgets'

export const NEW_VARIATION = [
  {
    value: '',
    label: 'Добавить новую номенклатуру'
  },
  {
    value: 'size',
    label: 'Добавить новый размер в номенклатуру'
  }
]

interface Props {
  companymarketplaceId?: number
  productCopy?: boolean
  multipleSelect?: boolean
}

export const ExistingWBCard = memo(({ companymarketplaceId, productCopy, multipleSelect } : Props) => {
  const nomenclatureCode = useField('nomenclatureCode').input.value
  const productIdChange = useField('productId').input.onChange

  const wbNomenclatureChange = useField('wbnomenclatureId').input.onChange
  
  const [selectedProduct, setSelectedProduct] = useState<any>()

  const debouncedSearchQuery = useDebounce(nomenclatureCode, 600)
  const {
    data: wbFoundProducts,
    isLoading,
    isError,
  } = pimApi.products.wb.useFetchWBProductSearchQuery({
    companymarketplaceId, options: { enabled: !!debouncedSearchQuery }, code: debouncedSearchQuery })

  useEffect(() => {
    if (multipleSelect) {
      if ( selectedProduct ) {
        wbNomenclatureChange(selectedProduct.wbnomenclatureId)
        productIdChange(selectedProduct.id)
      } else {
        wbNomenclatureChange(undefined)
        productIdChange(undefined)
      }
    } else if (wbFoundProducts && wbFoundProducts.length > 0) {
      wbNomenclatureChange(wbFoundProducts[0].wbnomenclatureId ?? undefined)
      productIdChange(wbFoundProducts[0].id ?? undefined)
    } else {
      wbNomenclatureChange(undefined)
      productIdChange(undefined)
    }
  }, [selectedProduct, wbFoundProducts])

  return (
    <>
      <Box mb={1} mt={2.5}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Поиск карточки</span>
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              component={InputField}
              label="Введите номенклатуру"
              isError={false}
              name="nomenclatureCode"
              endAdornment={isLoading ? <CircularProgress /> : undefined}
            />
          </Grid>
        </Grid>
      </Box>
      {(isError || (wbFoundProducts && wbFoundProducts.length===0)) && (
        <Box mt={1.5}>
          <AlertComponent
            title="Карточка с такой номенклатурой не найдена!"
            status="error"
            maxLength={false}
          />
        </Box>
      )}
      {(wbFoundProducts && wbFoundProducts.length>0) && (
        <>
          {/* eslint-disable-next-line no-nested-ternary */}
          {multipleSelect ? (
            !selectedProduct ?
              wbFoundProducts.map(el =>
                <ProductShortInfoWidget
                  productId={el.id}
                  name={el.name}
                  addInfo={true}
                  marketplaceCode={el.marketplaceCode}
                  marketplaceUrl={el.marketplaceUrl}
                  marketplaceName={el.marketplaceName}
                  marketplaceCategory={el.marketplaceCategory}
                  barcode={el.barcode}
                  price={el.price}
                  companyName={el.companyName}
                  autoOrdering={el.autoOrdering}
                  onAddClick={() => {setSelectedProduct(el)}}
                />) 
              : 
              (
                <ProductShortInfoWidget
                  productId={selectedProduct.id}
                  name={selectedProduct.name}
                  addInfo={true}
                  marketplaceCode={selectedProduct.marketplaceCode}
                  marketplaceUrl={selectedProduct.marketplaceUrl}
                  marketplaceName={selectedProduct.marketplaceName}
                  marketplaceCategory={selectedProduct.marketplaceCategory}
                  barcode={selectedProduct.barcode}
                  price={selectedProduct.price}
                  companyName={selectedProduct.companyName}
                  autoOrdering={selectedProduct.autoOrdering}
                  onRemoveClick={() => {setSelectedProduct(null)}}
                />
              )
          ) 
            :
            (
              <ProductShortInfoWidget
                productId={wbFoundProducts[0].id}
                name={wbFoundProducts[0].name}
                marketplaceCode={wbFoundProducts[0].marketplaceCode}
                marketplaceUrl={wbFoundProducts[0].marketplaceUrl}
                marketplaceName={wbFoundProducts[0].marketplaceName}
                marketplaceCategory={wbFoundProducts[0].marketplaceCategory}
                barcode={wbFoundProducts[0].barcode}
                price={wbFoundProducts[0].price}
                companyName={wbFoundProducts[0].companyName}
                autoOrdering={wbFoundProducts[0].autoOrdering}
              />
            )
          }
          { !productCopy &&
          <Box mt={1}>
            <Field
              name="newVariation"
              component={RadioGroupField}
              options={NEW_VARIATION}
              row={true}
            />
          </Box>
          }
        </>
      )}
    </>
  )
})
