import React, { useEffect, useMemo, useState } from 'react'
import { diff } from 'deep-object-diff'
import { pimApi } from 'shared/api'
import { useQueryClient } from 'react-query'

import { useIntervalList } from '../../../../../../api/pim/dictionaries/interval'
import { snackActions } from '../../../../../../lib/react/snackbar'


interface OptionItem {
  label: string
  value: string
  commentsRequired?: boolean
}
const getOptions = (optionItem: OptionItem): { label: string; value: string } => ({
  value: optionItem.value,
  label: optionItem.label,
})
export const RepriceAutoChangeCell = ({ row }: {row: any}) => {

  const queryClient = useQueryClient()
  const { mutate: editPriceSettings } =
    pimApi.price.useEditPriceSettingsMutation()

  const { adviceDate } = row
  const { intervalListQuery } = useIntervalList()

  const intervalOptions = useMemo(
    () => intervalListQuery.data?.map(getOptions) || [],
    [intervalListQuery.data]
  )

  const handleSaveChanges = (values: pimApi.price.PriceSettings) => {
    if (
      row
      && values
      && Object.keys(diff(values, row)).length !== 0
    ) {
      editPriceSettings( {
        editedSettings: {
          id: values.id,
          versionNo: values.versionNo,
          productId: values.productId,
          minPrice: values.minPrice,
          targetMargin: values.targetMargin,
          targetMarginPercents: values.targetMarginPercents,
          autoChangePrice: values.autoChangePrice,
          adviceInterval: values.adviceInterval
        }
      },
      {
        onSuccess: (response) => {
          queryClient.setQueriesData<pimApi.price.ProductPriceAdvice[]>(
            ['get', 'pim/pricecontrol/priceadvice'],
            (priceSettings) => {
              const updater = priceSettings
              const arrIndex = priceSettings!.findIndex(el => el.productId === response.data.productId)
              if (arrIndex !== -1) {
                const item: any = priceSettings?.[arrIndex!]
                Object.keys(response.data).forEach((key) => {
                  item[key] = response.data[key]
                })
                // eslint-disable-next-line no-param-reassign
                priceSettings![arrIndex] = item
                return priceSettings as pimApi.price.ProductPriceAdvice[]
              }
              return updater as pimApi.price.ProductPriceAdvice[]
            }
          )
          queryClient.setQueriesData<pimApi.price.ProductPriceAdvice[]>(
            ['get', 'pim/pricecontrol/pricesettings'],
            (priceSettings) => {
              const arrIndex = priceSettings!.findIndex(el => el.productId === response.data.productId)
              const item: any = priceSettings?.[arrIndex!]
              Object.keys(response.data).forEach((key) => {
                item[key] = response.data[key]
              })
              // eslint-disable-next-line no-param-reassign
              priceSettings![arrIndex] = item
              return priceSettings as pimApi.price.ProductPriceAdvice[]
            }
          )
          snackActions.info('Изменение сохранено!')
        },
      })
    }
  }

  const [values, setValues] = useState(row)

  const handleChange = e => {
    const { name, value, checked } = e.target
    setValues(prevState => ({
      ...prevState,
      [name]: name === 'autoChangePrice' ? checked : value
    }))
  }

  useEffect(() => {
    handleSaveChanges(values)
  }, [values])
  
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <input
        name="autoChangePrice"
        checked={values.autoChangePrice}
        onChange={handleChange}
        type="checkbox"
        style={{
          height: 15,
          width: 15,
          marginTop: '16px',
          color: 'rgba(0, 0, 0, 0.26)'
        }}
      />
      <select
        id="adviceInterval"
        name="adviceInterval"
        value={values.adviceInterval || ''}
        onChange={handleChange}
        style={{
          width: adviceDate ? 115 : 140,
          height: 28,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
          fontSize: 14,
          marginLeft: '3px',
          marginTop: '22px',
        }}
      >
        {intervalOptions.map((el, index) =>
          // eslint-disable-next-line react/no-array-index-key
          <option key={index} value={el.value}>{el.label}</option>)}
      </select>
    </div>
  )
}