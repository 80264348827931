import { Checkbox, TableCell } from '@mui/material'
import React from 'react'

export const CustomHeaderCheckbox = ({ allSelected, someSelected, onToggle }) => (
  <TableCell>
    <Checkbox
      sx={{
        color: 'rgba(0, 0, 0, 0.26)',
      }}
      size="small"
      indeterminate={someSelected || allSelected}
      onClick={(e) => {
        e.stopPropagation()
        onToggle(!someSelected && !allSelected)
      }}
    />
  </TableCell>
)